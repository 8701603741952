import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl } from "../../utils";
import { IRdv, RdvQuery } from "./rdv.type";

export const RdvApi = createApi({
	reducerPath: "RdvApi",
	tagTypes: ["rdv"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getRdv: build.query<PaginationResults<IRdv>, RdvQuery>({
			query: (query) => QueryUrl("rdv/", query),
			providesTags: ["rdv"],
		}),
	}),
});

export const { useGetRdvQuery } = RdvApi;
