import React from "react";
import { IRemboursement } from "../../../../utils/api/remboursement/remboursement.type";
import { useLocationState } from "../../../../utils/hooks";
import {
	formatMontant,
	formatRemboursementStatus,
	formattedDateTime,
	getName,
} from "../../../../utils/utils";
import DetailUserModal from "../../../modal/admin/DetailUserModal";
import { ButtonGoBack } from "../../../common/Button";
import { Link } from "react-router-dom";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { Color } from "../../../../utils/theme";
import PieceJointeModal from "../../../modal/admin/PieceJointeModal";

const DetailRemboursement = () => {
	const item = useLocationState<IRemboursement>(undefined);
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3 mt-4">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header d-flex align-items-center">
						<ButtonGoBack />
						<h4 className="crypta-dash-header-titre m-0">
							Détail remboursement{" "}
							<span className="fw-400">#{item?.matricule}</span>
						</h4>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="detail-transaction">
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Type de remboursement
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value text-capitalize">
									{item?.type}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Patient
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<Link
									className="text-info-transaction-value underline"
									to={`/admin/utilisateur/${item?.patient?.slug}`}
								>
									{getName(item?.patient)}
								</Link>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Hôpital
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{item?.hopital}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Date & Heure
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{formattedDateTime(item?.created_at)}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Montant
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{formatMontant(item?.montant)}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Montant à rembourser
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{formatMontant(item?.montant_a_payer)}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Statut
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value text-capitalize">
									Paiement{" "}
									{formatRemboursementStatus(item?.status)}
								</p>
							</div>
						</div>
						{item?.status === "rejetee" && (
							<div className="flex-sb align-items-center mb-2">
								<div className="content-text-info-transaction-libelle">
									<p className="text-info-transaction-libelle">
										Motif
									</p>
								</div>
								<div className="content-text-info-transaction-value">
									<p className="text-info-transaction-value">
										{item?.motif}
									</p>
								</div>
							</div>
						)}
						{item?.fichier && (
							<button
								className="btn btn-export-csv btn-candidate me-3"
								data-bs-toggle="modal"
								data-bs-target="#PieceJointeModal"
							>
								<BsFileEarmarkPdf
									color={Color.danger}
									fontSize={24}
								/>
								<span className="ps-2">Voir le justificatif</span>
							</button>
						)}
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="DetailUserModal"
				aria-labelledby="DetailUserModalLabel"
				aria-hidden="true"
			>
				<DetailUserModal item={item?.patient} />
			</div>
			<div
				className="modal fade"
				id="PieceJointeModal"
				aria-labelledby="ListeDossiersModalLabel"
				aria-hidden="true"
			>
				<PieceJointeModal item={item} title="Justificatif" />
			</div>
		</div>
	);
};

export default DetailRemboursement;
