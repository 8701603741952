import React from "react";
import GestionMedecinsTable from "./GestionMedecinsTable";
import { ButtonAdd } from "../../../common/Button";
import GestionPharmaciesTable from "./GestionPharmaciesTable";

function GestionProfessionnels() {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<ul
						className="nav nav-tabs nav-tabs-setting"
						id="myTab"
						role="tablist"
					>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting active"
								id="pharmacie-tab"
								data-bs-toggle="tab"
								data-bs-target="#pharmacie"
								type="button"
								role="tab"
								aria-controls="pharmacie"
								aria-selected="true"
							>
								Pharmaciens
							</button>
						</li>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting"
								id="medecin-tab"
								data-bs-toggle="tab"
								data-bs-target="#medecin"
								type="button"
								role="tab"
								aria-controls="medecin"
								aria-selected="false"
							>
								Médecins
							</button>
						</li>
					</ul>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div
						className="tab-content mt-4"
						id="myTabContent"
					>
						<div
							className="tab-pane fade show active"
							id="pharmacie"
							role="tabpanel"
							aria-labelledby="pharmacie-tab"
						>
							<div className="">
								<div className="content-btn-add-theme flex-r mb-4">
									<ButtonAdd
										data-bs-toggle="modal"
										data-bs-target="#AddPharmacieModal"
										label="	Ajouter un pharmacien"
									/>
								</div>
								<GestionPharmaciesTable />
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="medecin"
							role="tabpanel"
							aria-labelledby="medecin-tab"
						>
							<div className="content-btn-add-theme flex-r mb-4">
								<ButtonAdd
									label="Ajouter un médecin"
									data-bs-toggle="modal"
									data-bs-target="#AddMedecinModal"
								/>
							</div>
							<GestionMedecinsTable />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GestionProfessionnels;
