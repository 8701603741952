import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { AdminType, IAdmin } from "../../../utils/api/admin/admin.type";
import { AdminInput, FormError, RequiredStar } from "../../common/Input";
import useCrudAdmin from "../../TableauDebord/Admin/ParametreAdmin/hooks/useCrudAdmin";
import PhoneInput from "react-phone-input-2";
import { BtnSubmit } from "../../common/Button";
import GoogleInput from "../../common/GoogleInput";
import { Authorisations } from "../../../utils/constantes";
import { SelectInput } from "../../common/SelectInput";

export const typesAdmins = [
	{
		label: "Administrateur",
		value: AdminType.admin,
	},
	{
		label: "Super administrateur",
		value: AdminType.superadmin,
	},
];

const AddAdminModal = ({ item, resetItem }: { item?: IAdmin; resetItem: () => void }) => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		phone,
		onChangePhone,
		setPhone,
		handleChangeAdress,
	} = useCrudAdmin(item, resetItem);
	const reset = () => {
		resetItem();
		setPhone("");
	};
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{item ? "Modifier l'" : "Ajouter un"}{" "}
						administrateur
					</h5>
					<CloseModalBtn />
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-12">
									<AdminInput
										label="Nom"
										id="nom"
										type="text"
										placeholder="Nom"
										{...register("nom")}
										error={errors?.nom}
										required
									/>
								</div>
								<div className="col-md-12">
									<AdminInput
										label="Prénom"
										id="prenom"
										type="text"
										placeholder="Prénom"
										{...register(
											"prenom"
										)}
										error={
											errors?.prenom
										}
										required
									/>
								</div>
								<div className="col-md-12">
									<AdminInput
										label="Email"
										required
										id="email"
										type="text"
										placeholder="Email"
										{...register(
											"email"
										)}
										error={
											errors?.email
										}
										inputMode="text"
									/>
								</div>
								<div className="col-md-12">
									<div className="mb-3">
										<label
											htmlFor="telephone"
											className="form-label form-label-modal-custom"
										>
											Numéro de
											téléphone
										</label>
										<PhoneInput
											defaultMask={
												".. ... .. .."
											}
											containerClass="form-control auth-form-control mb-3 p-0"
											inputClass="form-control form-control-modal-custom"
											country={"sn"}
											placeholder=""
											inputProps={{
												name: "telephone",
												required: true,
											}}
											prefix="+"
											value={phone}
											onChange={(
												phone
											) =>
												onChangePhone(
													phone
												)
											}
											inputStyle={{
												width: "100%",
											}}
										/>
										<FormError
											error={
												errors
													?.telephone
													?.message
											}
										/>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<label
										htmlFor="adress"
										className="form-label form-label-modal-custom"
									>
										Adresse
										<RequiredStar />
									</label>
									<GoogleInput
										value={
											item?.adresse ??
											""
										}
										onChange={
											handleChangeAdress
										}
										className="form-control form-control-modal-custom"
									/>
									<FormError
										error={
											errors?.adresse
										}
									/>
								</div>
								<div className="col-md-12">
									<SelectInput
										label="Type administrateur"
										id="admin_type"
										options={
											typesAdmins
										}
										placeholder="Type administrateur"
										{...register(
											"admin_type"
										)}
										error={
											errors?.admin_type
										}
										required
										hiddenFirstOption
									/>
								</div>
							</div>
							<div className="bg-white p-3 filter-and-table-container">
								<div className="content-title-section-detail-admin">
									<p className="admin-modal-title text-start">
										Permissions
									</p>
								</div>
								<div className="row">
									<div className="col-md-12 mt-3">
										<div className="form-group crud-form-group">
											<div className="container-radio-input">
												<div className="form-radio-input radio-input">
													{Authorisations?.map(
														(
															item
														) => (
															<label
																className="ml-0 mb-3"
																key={
																	item?.field
																}
															>
																<input
																	type="checkbox"
																	id="dashbboard"
																	defaultChecked={
																		item[
																			item
																				?.field
																		]
																	}
																	{...register(
																		item?.field
																	)}
																/>
																<span>
																	{
																		item?.label
																	}
																</span>
															</label>
														)
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="container-btn-modal d-flex justify-content-end gap-3 mt-4">
								<button
									type="button"
									className="btn btn-cancelled"
									data-bs-dismiss="modal"
									onClick={reset}
								>
									Fermer
								</button>
								<BtnSubmit
									isLoading={isLoading}
									label={
										item
											? "Modifier"
											: "Ajouter"
									}
									style={{
										width: "inherit",
									}}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddAdminModal;
