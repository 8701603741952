import React from "react";
import "./ListeUsers.css";
import ListePrecommandesTable from "./Table/ListePrecommandesTable";

const ListePrecommandes = () => {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="">
						<ListePrecommandesTable />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListePrecommandes;
