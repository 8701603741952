import React from "react";
import { AiTwotoneEdit } from "react-icons/ai";
import { createMarkup } from "../../../../utils/utils";
import AddContentMentionLegaleModal from "../../../modal/admin/AddContentMentionLegaleModal";
import { ConditionProps } from "./Cgu";

const TermesConditions = ({ content, type }: ConditionProps) => {
	return (
		<div className="container-termes-conditions-component">
			<div className="content-btn-add-theme flex-r mb-4">
				<button
					className="btn btn-export-csv"
					data-bs-toggle="modal"
					data-bs-target="#AddContentMentionLegaleModal"
				>
					<AiTwotoneEdit style={{ color: "#08BB90", fontSize: 20 }} />
					<span className="ps-2">Modifier</span>
				</button>
			</div>
			<div className="content-text-conditions-termes">
				<div
					className="text-conditions-termes"
					dangerouslySetInnerHTML={createMarkup(content?.text)}
				></div>
			</div>
			<div
				className="modal fade"
				id="AddContentMentionLegaleModal"
				aria-labelledby="AddContentMentionLegaleModalLabel"
				aria-hidden="true"
			>
				<AddContentMentionLegaleModal content={content} type={type} />
			</div>
		</div>
	);
};

export default TermesConditions;
