import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { getAvatar } from "../../../../utils/utils";
import ChangePasswordModal from "../../../modal/admin/ChangePasswordModal";
import EditNameModal from "../../../modal/admin/EditNameModal";
import { useChangeAvatar } from "../ParametreAdmin/hooks/useCrudAdmin";
import "./ProfilAdmin.css";

const ProfilAdmin = () => {
	const { user } = useAppSelector((s) => s?.user);
	const { handleChangeAvatar, avatar } = useChangeAvatar(user);
	return (
		<div className="profil-page-component">
			<div className="bg-white p-3 mt-4 filter-and-table-container">
				<div className="crypta-dash-locataire-requetes-header">
					<h4 className="crypta-dash-header-titre">Mon profil</h4>
				</div>
				<section className="mb-3">
					<div className="row">
						<div className="col-md-12">
							<div className="d-flex flex-column align-items-center py-4 mb-4 crypta-user-avatar-container">
								<div className="mb-auto profile-image-admin">
									<div className="profile-avatar-container">
										<img
											src={
												avatar
													? avatar
													: getAvatar(
															user?.avatar
													  )
											}
											alt="avatar"
										/>
										<span>
											<i className="fas fa-camera"></i>
											<p>Changer</p>
											<input
												type="file"
												name="avatar"
												id="file_up"
												accept="image/*"
												onChange={
													handleChangeAvatar
												}
											/>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="">
						<div className="py-2 row container-info-profil-admin">
							<div className="col-md-4">
								<p className="text-info-profil-admin-libelle">
									Prénom
								</p>
							</div>
							<div className="col-md-4">
								<p className="text-info-profil-admin-value">
									{user?.prenom}
								</p>
							</div>
							<div className="col-md-4 flex-r">
								<button
									className="btn btn-edit-info-profil"
									data-bs-toggle="modal"
									data-bs-target="#EditNameModal"
								>
									Modifier
								</button>
							</div>
						</div>
						<div className="py-2 row container-info-profil-admin">
							<div className="col-md-4">
								<p className="text-info-profil-admin-libelle">
									Nom
								</p>
							</div>
							<div className="col-md-4">
								<p className="text-info-profil-admin-value">
									{" "}
									{user?.nom}
								</p>
							</div>
							<div className="col-md-4 flex-r">
								<button
									className="btn btn-edit-info-profil"
									data-bs-toggle="modal"
									data-bs-target="#EditNameModal"
								>
									Modifier
								</button>
							</div>
						</div>
						<div className="py-2 row container-info-profil-admin">
							<div className="col-md-4">
								<p className="text-info-profil-admin-libelle">
									Adresse
								</p>
							</div>
							<div className="col-md-4">
								<p className="text-info-profil-admin-value">
									{user?.adresse}
								</p>
							</div>
							<div className="col-md-4 flex-r">
								<button
									className="btn btn-edit-info-profil"
									data-bs-toggle="modal"
									data-bs-target="#EditNameModal"
								>
									Modifier
								</button>
							</div>
						</div>
						<div className="py-2 row container-info-profil-admin">
							<div className="col-md-4">
								<p className="text-info-profil-admin-libelle">
									Adresse mail
								</p>
							</div>
							<div className="col-md-4">
								<p className="text-info-profil-admin-value">
									{user?.email}
								</p>
							</div>
							<div className="col-md-4 flex-r">
								<button
									className="btn btn-edit-info-profil"
									data-bs-toggle="modal"
									data-bs-target="#EditNameModal"
								>
									Modifier
								</button>
							</div>
						</div>
						<div className="py-2 row container-info-profil-admin">
							<div className="col-md-4">
								<p className="text-info-profil-admin-libelle">
									Téléphone
								</p>
							</div>
							<div className="col-md-4">
								<p className="text-info-profil-admin-value">
									{user?.telephone}
								</p>
							</div>
							<div className="col-md-4 flex-r">
								<button
									className="btn btn-edit-info-profil"
									data-bs-toggle="modal"
									data-bs-target="#EditNameModal"
								>
									Modifier
								</button>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div className="bg-white p-3 my-4 filter-and-table-container">
				<div className="row">
					<div className="col-md-4">
						<div className="password-btn-container">
							<h6 className="text-info-profil-admin-libelle mb-3">
								Mot de Passe
							</h6>
							<button
								className="btn auth-submit-btn"
								data-bs-toggle="modal"
								data-bs-target="#ChangePasswordModal"
							>
								Changer votre mot de passe
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="ChangePasswordModal"
				aria-labelledby="changePasswordLabel"
				aria-hidden="true"
			>
				<ChangePasswordModal />
			</div>
			<div
				className="modal fade"
				id="EditNameModal"
				aria-labelledby="EditNameModalLabel"
				aria-hidden="true"
			>
				<EditNameModal />
			</div>
		</div>
	);
};

export default ProfilAdmin;
