import React from "react";
import ListeSignalementsTable from "../Signalements/ListeSignalementsTable";

const Signalements = () => {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<h4 className="crypta-dash-header-titre">Signalements</h4>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<ListeSignalementsTable />
				</div>
			</div>
		</div>
	);
};

export default Signalements;