import React from "react";
import FooterLandingpage from "../FooterLandingpage/FooterLandingpage";
import HeaderLp from "../HeaderLp";
import "./ContactUs.css";
import { GoogleApiWrapper, Map } from "google-maps-react";
import ContactInfos from "./ContactInfos";
import ContactFormBlock from "./ContactFormBlock";
import { motion } from "framer-motion";
import ButtonScrollTop from "../ButtonScrollTop";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ContactUs = (props: { google: any }) => {
	return (
		<div className="contact-us-component overflow-x-hidden">
			<HeaderLp />
			{/* <BreadCrumb title="Nous contacter" pageName="Nous contacter" /> */}
			<section className="contact-us-section">
				<div className="row row-contact-us">
					<div className="col-lg-5 col-md-12 col-left-contact-us-section mb-3 d-flex">
						<ContactInfos />
					</div>
					<div className="col-lg-7 col-md-12 col-right-contact-us-section mb-3 d-flex">
						<ContactFormBlock />
					</div>
				</div>
			</section>
			<section className="contact-us-section bg-gradient-theme">
				<motion.div
					initial={{ opacity: 0, y: -100 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{
						duration: 2,
					}}
					style={{ height: "60vh", width: "100%" }}
					className="container-map mt-3 position-relative"
				>
					<Map
						google={props.google}
						initialCenter={{
							lat: parseFloat("14.78686"),
							lng: parseFloat("-17.36783"),
						}}
						// zoom={14}
					></Map>
				</motion.div>
			</section>
			<ButtonScrollTop />
			<FooterLandingpage />
		</div>
	);
};

export default GoogleApiWrapper({
	apiKey: "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ",
})(ContactUs);
