import React from "react";
import Login from "../components/auth/Login";
import AdminLayout from "../components/TableauDebord/AdminLayout/AdminLayout";
import DashboardAdmin from "../components/TableauDebord/Admin/Dashboard/DashboardAdmin";
import { Navigate } from "react-router-dom";
import ParametreAdmin from "../components/TableauDebord/Admin/ParametreAdmin/ParametreAdmin";
import ProfilAdmin from "../components/TableauDebord/Admin/Profil/ProfilAdmin";
import DetailUser from "../components/TableauDebord/Admin/ListeUsers/DetailUser";
import ForgetPassword from "../components/auth/ForgetPassword";
import MessageContact from "../components/TableauDebord/Admin/MessageContact/MessageContact";
import ListeTransaction from "../components/TableauDebord/Admin/ListeTransaction/ListeTransaction";
import ListeCoins from "../components/TableauDebord/Admin/ListeCoins/ListeCoins";
import GestionProfessionnels from "../components/TableauDebord/Admin/GestionProfessionnels/GestionProfessionnels";
import ListeDossiers from "../components/TableauDebord/Admin/ListeDossiers/ListeDossiers";
import Remboursements from "../components/TableauDebord/Admin/Remboursements/Remboursements";
import ForumMessage from "../components/TableauDebord/Admin/ForumMessage/ForumMessage";
import DetailForumMessage from "../components/TableauDebord/Admin/ForumMessage/DetailForumMessage";
import DetailAdmin from "../components/TableauDebord/Admin/ParametreAdmin/DetailAdmin";
import LandingPage from "../components/LandingPage/LandingPage";
import AboutUsKiwu from "../components/LandingPage/AboutUsKiwu/AboutUsKiwu";
import JoinUs from "../components/LandingPage/JoinUs/JoinUs";
import CategoryDetail from "../components/LandingPage/JoinUs/CategoryDetail";
import ContactUs from "../components/LandingPage/ContactUs/ContactUs";
import ComingSoonPage from "../components/shared/ComingSoonPage";
import {
  IsAdminAllow,
  ProtectedRoutes,
  RedirectAuthRoute,
} from "./routerUtils";
import { UserType } from "../utils/api/user/user.type";
import ResetPassword from "../components/auth/ResetPassword";
import { Page404 } from "../components/shared/ErrorsPage/Errors";
import ListeDemandes from "../components/TableauDebord/Admin/ListeDemandes/ListeDemandes";
import DetailTransaction from "../components/TableauDebord/Admin/ListeTransaction/DetailTransaction";
import DetailRemboursement from "../components/TableauDebord/Admin/Remboursements/DetailRemboursements";
import DetailDemande from "../components/TableauDebord/Admin/ListeDemandes/DetailDemande";
import ListeOffres from "../components/TableauDebord/Admin/Offres/Offres";
import DetailDossier from "../components/TableauDebord/Admin/ListeDossiers/DetailDossier";
import DetailMessage from "../components/TableauDebord/Admin/MessageContact/DetailMessage";
import GestionUsers from "../components/TableauDebord/Admin/ListeUsers/GestionUsers";
import Notifications from "../components/TableauDebord/Admin/Notifications/Notifications";
import ListeRendezVous from "../components/TableauDebord/Admin/ListeRendezVous/ListeRendezVous";
import DetailCandidature from "../components/TableauDebord/Admin/Candidatures/DetailCandidature";
import DetailPrecommande from "../components/TableauDebord/Admin/ListeUsers/DetailPrecommande";
import GestionPostes from "../components/TableauDebord/Admin/Candidatures/GestionPostes";
import Signalements from "../components/TableauDebord/Admin/Signalements/Signalements";
import GestionLandingPage from "../components/TableauDebord/Admin/Landingpage/GestionLandingPage";
import DetailPoste from "../components/LandingPage/JoinUs/DetailPoste";
import DetailPro from "../components/TableauDebord/Admin/GestionProfessionnels/DetailPro";
import Abonnements from "../components/TableauDebord/Admin/Abonnements/Abonnements";
import CguPage from "../components/shared/CguPage";
import PolicyPage from "../components/shared/PolicyPage";

export const AppRoutes = [
	{
		path: "/connexion",
		element: (
			<RedirectAuthRoute>
				<Login />
			</RedirectAuthRoute>
		),
	},
	{
		path: "/",
		element: <LandingPage />,
	},
	{
		path: "/coming-soon",
		element: <ComingSoonPage />,
	},
	{
		path: "/a-propos",
		element: <AboutUsKiwu />,
	},
	{
		path: "/nous-rejoindre",
		element: <JoinUs />,
	},
	{
		path: "/postes/:slug",
		element: <DetailPoste />,
	},
	{
		path: "/categorie/:slug",
		element: <CategoryDetail />,
	},
	{
		path: "/nous-contacter",
		element: <ContactUs />,
	},
	{
		path: "/mot-de-passe-oublie",
		element: <ForgetPassword />,
	},
	{
		path: "renitialiser-le-mot-de-passe",
		element: <ResetPassword />,
	},
	{
		path: "/condition-generale-d-utilisation",
		element: <CguPage />,
	},
	{
		path: "/politique-de-confidentialite",
		element: <PolicyPage />,
	  },
	{
		path: "*",
		element: <Page404 />,
	},
	{
		path: "admin/",
		element: (
			<ProtectedRoutes userType={[UserType.admin, UserType.superAdmin]}>
				<AdminLayout />
			</ProtectedRoutes>
		),
		children: [
			{ element: <Navigate to="/admin/dashboard" replace />, index: true },
			{
				path: "dashboard",
				element: (
					<IsAdminAllow field="dashboard">
						<DashboardAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "coins",
				element: <ListeCoins />,
			},
			{
				path: "patients",
				element: (
					<IsAdminAllow field="patients">
						<GestionUsers />
					</IsAdminAllow>
				),
			},
			{
				path: "transactions",
				element: (
					<IsAdminAllow field="transactions">
						<ListeTransaction />
					</IsAdminAllow>
				),
			},
			{
				path: "transactions/:slug",
				element: (
					<IsAdminAllow field="transactions">
						<DetailTransaction />
					</IsAdminAllow>
				),
			},
			{
				path: "utilisateur/:slug",
				element: (
					<IsAdminAllow field="patients">
						<DetailUser />
					</IsAdminAllow>
				),
			},
			{
				path: "precommandes/:slug",
				element: (
					<IsAdminAllow field="patients">
						<DetailPrecommande />
					</IsAdminAllow>
				),
			},
			{
				path: "parametres",
				element: (
					<IsAdminAllow field="parametres">
						<ParametreAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "profil",
				element: <ProfilAdmin />,
			},
			{
				path: "messages",
				element: (
					<IsAdminAllow field="messages">
						<MessageContact />
					</IsAdminAllow>
				),
			},
			{
				path: "messages/:slug",
				element: (
					<IsAdminAllow field="messages">
						<DetailMessage />
					</IsAdminAllow>
				),
			},
			{
				path: "professionnels",
				element: (
					<IsAdminAllow field="professionnels">
						<GestionProfessionnels />
					</IsAdminAllow>
				),
			},
			{
				path: "professionnels/:slug",
				element: (
					<IsAdminAllow field="professionnels">
						<DetailPro />
					</IsAdminAllow>
				),
			},
			{
				path: "dossiers",
				element: (
					<IsAdminAllow field="dossiers">
						<ListeDossiers />
					</IsAdminAllow>
				),
			},
			{
				path: "dossiers/:slug",
				element: (
					<IsAdminAllow field="dossiers">
						<DetailDossier />
					</IsAdminAllow>
				),
			},
			{
				path: "demandes",
				element: (
					<IsAdminAllow field="demandes">
						<ListeDemandes />
					</IsAdminAllow>
				),
			},
			{
				path: "demandes/:slug",
				element: (
					<IsAdminAllow field="demandes">
						<DetailDemande />
					</IsAdminAllow>
				),
			},
			{
				path: "remboursements",
				element: (
					<IsAdminAllow field="remboursements">
						<Remboursements />
					</IsAdminAllow>
				),
			},
			{
				path: "remboursements/:slug",
				element: (
					<IsAdminAllow field="remboursements">
						<DetailRemboursement />
					</IsAdminAllow>
				),
			},
			{
				path: "forum",
				element: (
					<IsAdminAllow field="forum">
						<ForumMessage />
					</IsAdminAllow>
				),
			},
			{
				path: "forum/:slug",
				element: (
					<IsAdminAllow field="forum">
						<DetailForumMessage />
					</IsAdminAllow>
				),
			},
			{
				path: "administrateur/:slug",
				element: (
					<IsAdminAllow field="parametres">
						<DetailAdmin />
					</IsAdminAllow>
				),
			},
			{
				path: "offres",
				element: (
					<IsAdminAllow field="offres">
						<ListeOffres />
					</IsAdminAllow>
				),
			},
			{
				path: "signalements",
				element: (
					<IsAdminAllow field="signalements">
						<Signalements />
					</IsAdminAllow>
				),
			},
			{
				path: "abonnements",
				element: (
					<IsAdminAllow field="abonnements">
						<Abonnements />
					</IsAdminAllow>
				),
			},
			{
				path: "landing_page",
				element: (
					<IsAdminAllow field="landing_page">
						<GestionLandingPage />
					</IsAdminAllow>
				),
			},
			{
				path: "landing_page/candidatures/:slug",
				element: (
					<IsAdminAllow field="landing_page">
						<DetailCandidature />
					</IsAdminAllow>
				),
			},
			{
				path: "landing_page/candidatures/postes",
				element: (
					<IsAdminAllow field="landing_page">
						<GestionPostes />
					</IsAdminAllow>
				),
			},
			{
				path: "notifications",
				element: <Notifications />,
			},
			{
				path: "rendez-vous",
				element: <ListeRendezVous />,
			},
		],
	},
];
