import React from "react";
import { IUser } from "../../../utils/api/user/user.type";
import { formattedDate, getAvatar, getName } from "../../../utils/utils";
import CloseModalBtn from "../../shared/CloseModalBtn";

const DetailDossierModal = ({ item, title }: { item?: IUser; title?: string }) => {
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="admin-modal-title text-start" id="changePasswordLabel">
						{title ? title : "Détail du dossier"}
					</h5>
					<CloseModalBtn />
				</div>
				<div className="container-dashboard-page">
					<div className="bg-white">
						<div className="row">
							<div className="col-md-12 pt-4">
								<div className="d-flex flex-column align-items-center crypta-user-avatar-container">
									<img
										src={getAvatar(item?.avatar)}
										alt="user avatar"
										className="crypta-dash-locataire-param-img-avatar"
									/>
									<div className="crypta-dash-param-title pt-2">
										{getName(item)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-page-tabs-user mb-3">
					<div className="bg-white p-3 filter-and-table-container">
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Prénom
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.prenom}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">Nom</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.nom}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Mail{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.email}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Adresse{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.adresse}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Code postal{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.code_postal}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Téléphone{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.telephone}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Date d'inscription
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{formattedDate(item?.created_at)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailDossierModal;
