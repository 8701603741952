/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import CaptureTop from "../../assets/landingpage/captureTop.png";
import PlayStore from "../../assets/landingpage/play_store.png";
import AppStore from "../../assets/landingpage/app_store.png";
import { useGetLandingDataQuery } from "../../utils/api/config/config.api";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { NavLink } from "react-router-dom";

const Banner = () => {
	const { data, isLoading } = useGetLandingDataQuery();

	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [endDate, setEndDate] = useState<Date>();
	console.log({ endDate });

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const timerId = useRef<any>(null);
	const [show, setShow] = useState(false);

	const getTwoDigitValue = (value) => {
		if (value < 10) {
			return "0" + value;
		}
		return "" + value;
	};

	const convertedDays = getTwoDigitValue(days);
	const convertedHours = getTwoDigitValue(hours);
	const convertedMins = getTwoDigitValue(minutes);

	const calculateCountdown = () => {
		const startDate = new Date();
		if (endDate) {
			const timeRemaining = endDate.getTime() - startDate.getTime();

			if (timeRemaining > 0) {
				const start_date = new Date(startDate);
				const end_date = new Date(endDate);
				const start_millis = start_date.getTime(); // Get timestamp of start date
				const end_millis = end_date.getTime(); // Get timestamp of end date

				// Convert to seconds, 1 second = 1000 milli seconds
				const old_sec = start_millis / 1000;
				const current_sec = end_millis / 1000;

				// Get remaining seconds
				let seconds = current_sec - old_sec;

				let days = Math.floor(seconds / (24 * 60 * 60)); // 1 day is equal to 24 hours, each hour has 60 mins and each minute has 60 seconds
				seconds -= days * 24 * 60 * 60; // Get remaining seconds

				let hours = Math.floor(seconds / (60 * 60)); // 1 hour has 60 mins and each minute has 60 seconds
				seconds -= hours * 60 * 60; // Get remaining seconds

				let minutes = Math.floor(seconds / 60); // 1 minute is equal to 60 seconds
				seconds -= minutes * 60; // Get remaining seconds

				days = Math.abs(days);
				hours = Math.abs(hours);
				minutes = Math.abs(minutes);
				seconds = Math.floor(Math.abs(seconds));
				setDays(days);
				setHours(hours);
				setMinutes(minutes);
				setSeconds(seconds);
				clearTimeout(timerId.current);
				timerId.current = setTimeout(calculateCountdown, 1000);
			} else {
				clearTimeout(timerId.current);
			}
		}
	};

	useEffect(() => {
		const startDate = new Date();
		if (endDate) {
			const timeRemaining = endDate.getTime() - startDate.getTime();
			if (timeRemaining > 0) {
				setShow(true);
			}
		}
	}, []);

	useEffect(() => {
		if (endDate) calculateCountdown();
		return () => {
			// clearTimeout(timer)
			clearTimeout(timerId.current);
			timerId.current = null;
		};
	}, [endDate]);

	useEffect(() => {
		if (data?.home) {
			console.log({ data });
			setEndDate(new Date(data.home.jours));
		}
	}, [data?.home]);
	return (
		<div className="row row-banner-landingpage align-items-center">
			<div className="col-lg-6 col-md-8 col-abnner-landingpage">
				<div className="content-col-abnner-landingpage">
					<div className="content-title-landingpage mb-md-3">
						<h1 className="title-landingpage animate__animated animate__backInLeft">
							{data?.home?.description}
						</h1>
					</div>
					<div className="content-text-chrono-landingpage mb-2 mb-md-4">
						<p className="text-chrono-landingpage">Lancement dans : </p>
						<div className="container-timer-landingpage animate__animated animate__pulse animate__infinite">
							<div className="container-text-chrono">
								<p className="text-chrono mb-0">
									{!isLoading && convertedDays}
								</p>
								<p className="libelle-text-chrono mb-0">Jours</p>
							</div>
							<div className="line-chrono"></div>
							<div className="container-text-chrono">
								<p className="text-chrono mb-0">{!isLoading && convertedHours}</p>
								<p className="libelle-text-chrono mb-0">Heures</p>
							</div>
							<div className="line-chrono"></div>
							<div className="container-text-chrono">
								<p className="text-chrono mb-0">{!isLoading && convertedMins}</p>
								<p className="libelle-text-chrono mb-0">Minutes</p>
							</div>
						</div>
					</div>
					<div className="container-element-download-app">
						<div className="pt-4">
							<p className="content-img-download-app-title">
								Téléchargez notre application
							</p>
						</div>
						<div className="content-img-download-app-item-container">
							<NavLink
								to={"https://apps.apple.com/us/app/kivu/id6476978861"}
								target="_blank"
								className="content-img-download-app-item">
								<img
									src={AppStore}
									alt="App store"
									className="img-download-app-item"
								/>
							</NavLink>
							<NavLink
								to={"https://play.google.com/store/apps/details?id=com.crypta.prod"}
								target="_blank"
								className="content-img-download-app-item">
								<img
									src={PlayStore}
									alt="Play store"
									className="img-download-app-item"
								/>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-6 col-right-banner-lp flex-r position-relative no-view-mobile">
				<div className="content-col-right-banner-lp animate__animated animate__bounceInRight p-0">
					<img
						src={CaptureTop}
						alt="bannière"
						className="img-content-col-right-banner-lp"
					/>
				</div>
			</div>
		</div>
	);
};

export default Banner;
