import React from "react";
import { Link } from "react-router-dom";
import IconCheck from "../../../../assets/landingpage/check.png";
import ErrorMessage from "../../../common/ErrorMessage";
import moment from "moment";

function ConfirmPreCommandeStep({
	offre,
	Index,
	dataForm,
	register,
	errors,
	isLoading,
	closeModal,
	onSubmit,
}) {
	return (
		<div className="custom-modal-confirm">
			<h3 className={`modal-title-admin`} id="ChangePasswordModalLabel">
				{offre?.option?.replace("_", " ")}
				<div className={`content-span-abonnement bg-span-abonnement${Index}`}>
					<span className="span-abonnement">
						{offre?.price} {offre?.devise}/an
					</span>
				</div>
			</h3>
			<form onSubmit={onSubmit}>
				<div className="body-modal">
					<div className="PreCommandeModal-text-paragraphe custom-container-abonnement-check-lists">
						<div className="container-abonnement-check-lists mb-2 mt-1">
							{offre?.avantages?.map((avantage) => (
								<div className="abonnement-check-list-item">
									<div className="content-icon-check-list">
										<img
											src={IconCheck}
											alt="check"
											className="icon-check-list-abonnement"
										/>
									</div>
									<div className="content-text-check-list">
										<p className="text-check-list mb-0">
											{avantage?.description}
										</p>
									</div>
								</div>
							))}
							{offre?.title !== "KC PREMIUM" && (
								<div className="abonnement-check-list-item">
									<div className="content-text-check-list ps-4">
										<p className="text-check-list-last mb-0">
											+70% Appareils (dentition, vue,
											motricité etc.)
										</p>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="text-warning-pre-commande-container">
						<div className={`text-warning-pre-commande-attention${Index}`}>
							Attention
						</div>
						<ul className="text-warning-pre-commande">
							<li>Les pré-commandes sont non remboursables</li>
							<li>
								En validant le payement, vous attestez sur l'honneur
								avoir signalé par recommandé avec justificatifs aux
								contacts de Kivu (FP) tout soucis de santé majeur
								s'il y'a lieu, avant l'inscription
							</li>
						</ul>
					</div>
					<div className="resume-infos-container">
						<div className="row">
							<div className="col-md-6 custom-resum-preorder-infos-user">
								Prénom <span>{dataForm?.prenom}</span>
							</div>
							<div className="col-md-6 custom-resum-preorder-infos-user">
								Civilité <span>{dataForm?.civilite}</span>
							</div>
							<div className="col-md-6 custom-resum-preorder-infos-user">
								Nom <span>{dataForm?.nom}</span>
							</div>
							<div className="col-md-6 custom-resum-preorder-infos-user">
								Nombre d’enfant{" "}
								<span>{dataForm?.nombre_enfants_moins_5_ans}</span>
							</div>
							<div className="col-md-6 custom-resum-preorder-infos-user">
								Adresse mail <span>{dataForm?.email}</span>
							</div>
							<div className="col-md-6 custom-resum-preorder-infos-user">
								Date de naissance{" "}
								<span>
									{moment(dataForm?.date_de_naissance).format(
										"L"
									)}
								</span>
							</div>
							<div className="col-md-6 custom-resum-preorder-infos-user">
								Téléphone <span>{dataForm?.telephone}</span>
							</div>
							<div className="col-md-6 custom-resum-preorder-infos-user">
								Leu de naissance{" "}
								<span>{dataForm?.lieu_de_naissance}</span>
							</div>
						</div>
					</div>
					<div className="btn-payer-pre-commande-container justify-content-between gap-2 my-4">
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								value=""
								id="flexCheckDefault"
								{...register("condition")}
							/>
							<label
								className="form-check-label conf-pre-order-label-cgu"
								htmlFor="flexCheckDefault"
							>
								J’accepte les{" "}
								<Link className="conf-pre-order-link-cgu" to={"#"}>
									conditions générales d’utilisation
								</Link>{" "}
								de KIVU
							</label>
							{errors?.condition && (
								<ErrorMessage
									message={errors?.condition?.message}
								/>
							)}
						</div>

						<div className="btn-confirm-pre-order-container d-flex gap-2">
							<button
								className={`btn bg-span-abonnement-annuler`}
								type="button"
								onClick={closeModal}
							>
								<span className="span-abonnement">Annuler</span>
							</button>
							{!isLoading ? (
								<button
									className="btn btn-payer-pre-commande"
									title="Payer"
									type="submit"
								>
									Je confirme
								</button>
							) : (
								<button
									type="submit"
									className="btn btn-payer-pre-commande"
									disabled={isLoading}
								>
									<span
										className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
										role="status"
									></span>
									<span>Chargement...</span>
								</button>
							)}
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}

export default ConfirmPreCommandeStep;
