import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppDispatch } from "../../../redux/hooks";
import { onSetUserToken } from "../../../redux/slice/User.slice";
import { useLazyMeAdminQuery } from "../../../utils/api/admin/admin.api";
import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";
import { LoginFormData } from "../../../utils/api/auth/auth.type";
import { UserType } from "../../../utils/api/user/user.type";
import { AppStorage } from "../../../utils/storage";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/utils";

function useLogin() {
	const validationSchema = yup.object().shape({
		email: yup.string().email().required().label("Email"),
		password: yup.string().required().label("Mot de passe"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<LoginFormData>({
		resolver: yupResolver(validationSchema),
	});
	const dispatch = useAppDispatch();

	const [loginUser, { isLoading }] = useLoginUserMutation();
	const [fetchMe] = useLazyMeAdminQuery();

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	const onSubmit = async (data: LoginFormData) => {
		const res = await loginUser(data);
		if ("data" in res) {
			const { data } = res;
			AppStorage.setItem("user", {
				token: data?.token,
			});
			if (data?.data?.user_type === UserType.admin) {
				const res = await fetchMe();
				if ("data" in res) {
					const { data: adminData } = res;
					console.log("admin", adminData);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const user = AppStorage.getItem("user") as any;
					dispatch(onSetUserToken({ user: adminData, token: user?.token }));
				}
			}
			Swal.fire({
				icon: "success",
				title: "Connexion reussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				if (data?.data?.user_type !== UserType.admin) {
					dispatch(onSetUserToken({ user: data?.data, token: data?.token }));
				}
			});
		}
		if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			console.log("err", err.data);
			const { message = "Une erreur a survenue lors de la connexion" } = err.data || {};
			setError("email", { message: message });
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useLogin;
