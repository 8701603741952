import React from "react";
import "./ForumMessage.css";
import ListeForum from "./ListeForum";
import ListeThemesTable from "./ListeThemesTable";
import { IoMdAdd } from "react-icons/io";
import { useActive } from "../../../../utils/hooks";

const tabs = ["Liste", "thèmes"];

const ForumMessage = () => {
	const { isActive, handleClick } = useActive(0);
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header">
						<h4 className="crypta-dash-header-titre">Forum</h4>
					</div>
				</div>
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<ul
						className="nav nav-tabs nav-tabs-setting"
						id="myTab"
						role="tablist"
					>
						{tabs?.map((tab, index) => (
							<li
								className="nav-item nav-item-setting"
								role="button"
								key={tab}
							>
								<button
									className={`nav-link nav-link-setting ${
										index === isActive ? "active" : ""
									}`}
									id={tab + "-tab"}
									data-bs-toggle="tab"
									data-bs-target={"#" + tab}
									type="button"
									role="tab"
									aria-controls={tab}
									aria-selected="true"
									onClick={() => handleClick(index)}
								>
									{tab}
								</button>
							</li>
						))}
					</ul>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					{isActive === 0 ? (
						<div id="forum" role="tabpanel" aria-labelledby="forum-tab">
							<ListeForum />
						</div>
					) : (
						<div id="theme" role="tabpanel" aria-labelledby="theme-tab">
							<div className="content-btn-add-theme flex-r mb-4">
								<button
									className="btn btn-add-theme"
									data-bs-toggle="modal"
									data-bs-target="#AddThemeModal"
								>
									<IoMdAdd />
									<span className="ps-2">Ajouter un thème</span>
								</button>
							</div>
							<ListeThemesTable />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ForumMessage;
