import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { AdminInput, InputFile } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import useCrudModePaiement from "../../TableauDebord/Admin/ParametreAdmin/hooks/useCrudModePaiement";
import { PreviewImage, ViewUniqueImage } from "../../common/PreviewImage";
import { checkIfIsImage } from "../../../utils/utils";
import { ModePaiment } from "../../../utils/api/config/config.type";
import { SelectInput } from "../../common/SelectInput";

const AddMoyenPaiementModal = ({
	item,
	resetItem,
}: {
	item?: ModePaiment;
	resetItem: () => void;
}) => {
	const { register, onSubmit, errors, isLoading, image, handleChangeImage, setImage, reset } =
		useCrudModePaiement(item, resetItem);

	const resetAll = () => {
		resetItem();
		setImage(undefined);
		reset();
	};

	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{item ? "Modifier la" : "Ajouter un"} mode de paiement
					</h5>
					<CloseModalBtn onClick={resetAll} />
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-12">
									<InputFile
										label="Logo"
										id="logo"
										type="file"
										error={errors?.logo}
										required
										accept="image/*"
										onChange={handleChangeImage}
										file={image}
									/>
									{image && <PreviewImage image={image} />}
									{item?.logo && checkIfIsImage(item.logo) && (
										<ViewUniqueImage image={item?.logo} />
									)}
								</div>

								<div className="col-md-12">
									<AdminInput
										label="Nom"
										id="nom"
										type="text"
										placeholder="Nom"
										{...register("titre")}
										error={errors?.titre}
										required
									/>
								</div>
								<div className="col-md-12">
									<SelectInput
										label="Type"
										id="type"
										options={[
											{
												label: "Paydunya",
												value: "paydunya",
											},
											{
												label: "Wave",
												value: "wave",
											},
											{
												label: "Cash",
												value: "cash",
											},
										]}
										placeholder="Type"
										{...register("nom")}
										error={errors?.nom}
										required
										hiddenFirstOption
									/>
								</div>
							</div>
							<div className="container-btn-modal d-flex justify-content-end gap-3 mt-4">
								<button
									type="button"
									className="btn btn-cancelled"
									data-bs-dismiss="modal"
									onClick={resetAll}
								>
									Fermer
								</button>
								<BtnSubmit
									isLoading={isLoading}
									label={item ? "Modifier" : "Ajouter"}
									style={{ width: "inherit" }}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddMoyenPaiementModal;
