import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
	useAddOrEditConditionMutation,
	useLazySendEditConditionCodeQuery,
} from "../../../../../utils/api/condition/condition.api";
import {
	ConditionUpdateFormData,
	ICondition,
} from "../../../../../utils/api/condition/condition.type";
import { QueryError } from "../../../../../utils/type";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { toast } from "react-hot-toast";
import { Color } from "../../../../../utils/theme";
import { useCountdownTimer } from "../../../../../utils/hooks";

function UseAddOrEditConditionForm(modalId: string, content?: ICondition) {
	const [text, setText] = React.useState<string>("");
	const validationSchema = yup.object().shape({
    text: yup
      .string()
      .required("Le contenu est requis.")
      .transform((val) => (val === "<p><br></p>" ? "" : val)),
    type: yup.string().required(),
  });

	const {
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm<ConditionUpdateFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [sendCode, { isLoading: isLoad }] = useLazySendEditConditionCodeQuery();

	const [editData, { isLoading, isSuccess, isError, error }] = useAddOrEditConditionMutation();

	const { isTimeActive, stopTimer, startTimer } = useCountdownTimer(2);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (content) {
			console.log({ content });
			setText(content?.text);
			setValue("slug", content?.slug);
			setValue("text", content?.text);
		}
	}, [content]);

	useEffect(() => {
		if (isSuccess) {
			setText("");
			toast.success("Modifié avec succès!");
			onHide(modalId);
			stopTimer();
		}
		if (isError) {
			const err = error as QueryError;
			let mes = err?.data?.message
				? err?.data?.message
				: `Erreur de statut ${err?.status}`;
			toast.error(mes);
		}
	}, [isLoading]);

	const handleChange = (value) => {
		setText(value);
		setValue("text", value);
	};

	function Popup(data: ConditionUpdateFormData) {
		onHide(modalId);
		Swal.fire({
			title: "Entrez le code que vous avez reçu par mail",
			icon: "info",
			showCancelButton: true,
			confirmButtonText: "Modifier",
			cancelButtonText: "Fermer",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.blue,
			confirmButtonColor: Color.success,
			input: "text",
			inputAttributes: {
				autocapitalize: "off",
				autocorrect: "off",
				autocomplete: "off"
			},
			allowOutsideClick: false,
			preConfirm: (inputValue) => {
				if (inputValue) {
					return editData({
						slug: content?.slug,
						data: { ...data, code: inputValue },
					});
				}
			},
		});
	}
	const onSubmit = async (data: ConditionUpdateFormData) => {
		console.log({ content });
		if (content) {
			if (isTimeActive) {
				Popup(data);
			} else {
				const res = await sendCode();
				if ("data" in res) {
					Popup(data);
					startTimer();
				} else {
					toast.error("erreur d'envoie de de code");
				}
			}
		} else {
			return editData({ data: data });
		}
	};
	return {
		errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading: isLoad ? isLoad : isLoading,
		text,
		onchange: handleChange,
	};
}

export default UseAddOrEditConditionForm;
