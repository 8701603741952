import React from "react";
import { IUser } from "../../../../utils/api/user/user.type";
import { formattedDate } from "../../../../utils/utils";

const InfoPersonelles = ({ user }: { user?: IUser }) => {
	return (
		<>
			<div className="py-2 row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Prénom</p>
				</div>
				<div className="col-md-8">
					<p className="text-info-profil-admin-value">{user?.prenom}</p>
				</div>
			</div>
			<div className="py-2 row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Nom</p>
				</div>
				<div className="col-md-8">
					<p className="text-info-profil-admin-value">{user?.nom}</p>
				</div>
			</div>
			<div className="py-2 row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Adresse</p>
				</div>
				<div className="col-md-8">
					<p className="text-info-profil-admin-value">{user?.adresse}</p>
				</div>
			</div>
			<div className="py-2 row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Code postal</p>
				</div>
				<div className="col-md-8">
					<p className="text-info-profil-admin-value">{user?.code_postal}</p>
				</div>
			</div>
			<div className="py-2  row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Adresse mail</p>
				</div>
				<div className="col-md-8">
					<p className="text-info-profil-admin-value">{user?.email}</p>
				</div>
			</div>
			<div className="py-2 row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Téléphone</p>
				</div>
				<div className="col-md-8">
					<p className="text-info-profil-admin-value">{user?.telephone}</p>
				</div>
			</div>
			<div className="py-2 row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Date d'inscription</p>
				</div>
				<div className="col-md-8">
					<p className="text-info-profil-admin-value">
						{formattedDate(user?.created_at)}
					</p>
				</div>
			</div>
		</>
	);
};

export default InfoPersonelles;
