import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useEditDossierMutation } from "../../../../utils/api/dossier/dossier.api";
import { DossierFormData, IDossier } from "../../../../utils/api/dossier/dossier.type";
import { QueryError } from "../../../../utils/type";
import { cleannerError, onHide } from "../../../../utils/utils";
import { BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { toast } from "react-hot-toast";

function ChangeStatusDossierModal({ item }: { item?: IDossier }) {
	const validationSchema = yup.object().shape({
		statut: yup.string().required().label("Status"),
	});
	const [changeStatus, { isLoading }] = useEditDossierMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<DossierFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
			setValue("statut", item?.statut);
		}
	}, [item]);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const onSubmit = async (data: DossierFormData) => {
		const res = await changeStatus(data);
		if ("data" in res) {
			toast.success("Statut changé avec  succès!");
			reset();
			onHide("ChangeStatusDossierModal");
		} else if ("error" in res) {
			console.log("error", res.error);
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	return (
		<div
			className="modal fade"
			id="ChangeStatusDossierModal"
			aria-labelledby="ChangeStatusDossierModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="ChangeStatusDossierModal"
						>
							Changer le statut du dossier
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form className="w-100" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Statut du dossier
											<span className="text-danger">
												*
											</span>
										</label>
										<select
											className="form-control form-control-input-admin py-3"
											id="name"
											{...register("statut")}
										>
											{/* <option value="">
												Choisir un statut
											</option> */}
											<option value="nouveau">
												Nouveau
											</option>
											<option value="en_pause">
												En pause
											</option>{" "}
											<option value="cloturer">
												Clôturé
											</option>
										</select>
										<FormError error={errors?.statut} />
									</div>
								</div>
							</div>
							<div className="flex-end mt-5 gap-4">
								<BtnSubmit
									label="Enregistrer"
									isLoading={isLoading}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStatusDossierModal;
