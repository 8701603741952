import React from "react";
import { Outlet } from "react-router-dom";
import HeaderAdmin from "../navBar/HeaderAdmin/HeaderAdmin";
import SidebarAdmin from "../navBar/SidebarAdmin/SidebarAdmin";
import "./AdminLayout.css";
import { Toaster } from "react-hot-toast";


const AdminLayout = () => {
	return (
		<div className="container-fluid body-background-dashboard">
			<HeaderAdmin />
			<div className="row">
				<div className="nav-container no-view-mobile">
					<SidebarAdmin />
				</div>
				<div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-0 h-90vh position-relative page-content-base-container">
					<div className="admin-page-container">
						<Outlet />
					</div>
				</div>
			</div>
			<Toaster />
		</div>
	);
};

export default AdminLayout;
