import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import TableSkeleton from "../../../common/TableSkeleton";
import { useItem, usePagination, useSearch } from "../../../../utils/hooks";
import { useGetPharmaciensQuery } from "../../../../utils/api/user/user.api";
import { getAvatar, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { IUser } from "../../../../utils/api/user/user.type";
import { ButtonEdit, ButtonViewLink } from "../../../common/Button";
import { DeleteUser } from "../ListeUsers/Table/ListeUsersTable";
import AddPharmacieModal from "../../../modal/admin/AddPharmacieModal";
import { ToggleArchiveUser, ToggleStatutUser } from "./GestionMedecinsTable";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import { InputFilter } from "../../../common/Input";

function GestionPharmaciesTable() {
	const { search: pharmacie, handlefilter } = useSearch();
	const { search: adresse, handlefilter: handlefilterAdresse } = useSearch();
	const { search: telephone, handlefilter: handlefilterPhone } = useSearch();
	const { search: email, handlefilter: handlefilterByEmail } = useSearch();
	const { search: prenom, handlefilter: handlefilterPrenomPhar } = useSearch();
	const { search: nom, handlefilter: handlefilterNomPhar } = useSearch();

	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetPharmaciensQuery({
		page,
		limit,
		pharmacie,
		prenom,
		email,
		telephone,
		adresse,
		nom,
	});

	console.log("pharma", data)
	const { item, getItem, resetItem } = useItem<IUser>();

	const imageFormatter = (cell, row: IUser) => {
		return (
			<div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
				<img src={getAvatar(row?.avatar)} alt="jeton" className="img-pharmacie-table" />{" "}
			</div>
		);
	};
	const actionFormatter = (cell: string, row: IUser) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ToggleStatutUser item={row} />
					<ButtonViewLink to={`${row?.slug}`} state={row} />
					<ButtonEdit data-bs-toggle="modal" data-bs-target="#AddPharmacieModal" onClick={() => getItem(row)} />
					<DeleteUser item={row} />
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "avatar",
			text: "Photo",
			formatter: (cell: string, row: IUser) => imageFormatter(cell, row),
		},
		{
			dataField: "prenom",
			text: "Prénom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="prenom" placeholder="Prénom" onChange={handlefilterPrenomPhar} />,
		},
		{
			dataField: "nom",
			text: "Nom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="nom" placeholder="Nom" onChange={handlefilterNomPhar} />,
		},
		{
			dataField: "nom_pharmacie",
			text: "Pharmacie",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="pharmacie" placeholder="Pharmacie" onChange={handlefilter} />,
		},
		{
			dataField: "email",
			text: "Email",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="nom" placeholder="Nom" onChange={handlefilterByEmail} />,
		},
		{
			dataField: "telephone",
			text: "Téléphone",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="tel" placeholder="Téléphone" onChange={handlefilterPhone} />,
		},
		{
			dataField: "adresse",
			text: "Adresse",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="adresse" placeholder="Adresse" onChange={handlefilterAdresse} />,
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IUser) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="AddPharmacieModal"
				data-bs-backdrop="static"
				aria-labelledby="AddPharmacieModalLabel"
				aria-hidden="true"
			>
				<AddPharmacieModal item={item} resetItem={resetItem} />
			</div>
		</>
	);
}

export default GestionPharmaciesTable;
