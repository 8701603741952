import { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import {
	useLazySendCodeQuery,
	useValidRemboursementMutation,
} from "../../../../utils/api/remboursement/remboursement.api";
import { Color } from "../../../../utils/theme";
import toast from "react-hot-toast";
import { IRemboursement } from "../../../../utils/api/remboursement/remboursement.type";
import { QueryError } from "../../../../utils/type";
import { formatMontant } from "../../../../utils/utils";

export function useValidRemboursement(item: IRemboursement) {
	const [changeStatus, { isLoading, isSuccess, isError, error }] =
		useValidRemboursementMutation();
	const [sendCode, { isLoading: isLoadSendCode }] = useLazySendCodeQuery();

	const onValid = useCallback(async () => {
		const res = await sendCode();
		if ("data" in res) {
			Swal.fire({
				title: `Récapitulatif du remboursement`,
				icon: "info",
				html: `<div><span>Type </span><span>${
					item?.type
				}</span></div><div><span>Montant </span><span>${formatMontant(
					item?.montant
				)}</span></div> <div><span>Montant à rembourser </span><span>${formatMontant(
					item?.montant_a_payer
				)}</span></div>	`,
				showCancelButton: true,
				confirmButtonText: "Envoyer",
				cancelButtonText: "Fermer",
				showLoaderOnConfirm: true,
				focusCancel: true,
				iconColor: Color.blue,
				confirmButtonColor: Color.blue,
				input: "password",
				inputLabel: "Entrez le code que vous avez reçu par mail",
				inputAttributes: {
					autocapitalize: "off",
				},
				preConfirm: (inputValue) => {
					if (inputValue) {
						return changeStatus({ slug: item?.slug, code: inputValue });
					}
				},
			});
		} else {
			toast.error("Erreur d'envoie de code");
		}
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success(`Validé avec succès!`);
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as QueryError;
			Swal.fire({
				icon: "error",
				title:
					err?.data?.message ??
					`Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: true,
				confirmButtonColor: "#1468cc",
			});
		}
	}, [isError]);

	return {
		isLoading,
		onValid,
		sendCode,
		isLoadSendCode,
	};
}
