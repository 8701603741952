import React from "react";
// import { FiEdit } from "react-icons/fi";
import { IAdmin } from "../../../../utils/api/admin/admin.type";
import { Authorisations } from "../../../../utils/constantes";
import { useLocationState } from "../../../../utils/hooks";
import { getAvatar, getName } from "../../../../utils/utils";
import { BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import useCrudAdmin, { useChangeAvatar } from "./hooks/useCrudAdmin";
import { useNavigate } from "react-router-dom";

interface DetailProps {
	item: IAdmin;
	disabled: boolean;
}
const DetailAdmin = () => {
	const { item, disabled } = useLocationState<DetailProps>(undefined);
	console.log({ item });
	const { register, onSubmit, errors, isLoading } = useCrudAdmin(item);
	const { handleChangeAvatar, avatar } = useChangeAvatar(item);
	const navigate = useNavigate();

	return (
		<div className="profil-page-component">
			<div className="bg-white p-3 mt-4 filter-and-table-container">
				<div className="crypta-dash-locataire-requetes-header">
					<h4 className="crypta-dash-header-titre">
						Détails de l’administrateur
					</h4>
				</div>
				<section className="mb-3">
					<div className="row">
						<div className="col-md-12">
							<div className="d-flex flex-column align-items-center py-4 mb-4 crypta-user-avatar-container">
								<div className="mb-auto profile-image-admin">
									<div className="profile-avatar-container">
										<img
											src={
												avatar
													? avatar
													: getAvatar(
															item?.avatar
													  )
											}
											alt="avatar"
										/>
										<span>
											<i className="fas fa-camera"></i>
											<p>Changer</p>
											<input
												type="file"
												name="avatar"
												id="file_up"
												onChange={
													handleChangeAvatar
												}
												disabled={
													disabled
												}
											/>
										</span>
									</div>
									<div className="crypta-dash-param-title pt-2 text-center">
										{getName(item)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<form onSubmit={onSubmit}>
				<div className="bg-white p-3 my-4 filter-and-table-container">
					<div className="content-title-section-detail-admin">
						<p className="title-section-detail-admin">
							Informations personnelles
						</p>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="mb-3 position-relative">
								<label
									htmlFor="prenom"
									className="form-label form-label-modal-custom"
								>
									Prénom
								</label>
								<input
									type="text"
									placeholder="Prénom"
									className="form-control form-detail-admin-profil"
									id="prenom"
									defaultValue={
										item?.prenom
									}
									{...register("prenom")}
									disabled={disabled}
								/>
								{/* <div className="content-icon-edit-info-profil">
									<FiEdit />
								</div> */}
							</div>
							<FormError error={errors?.prenom} />
						</div>
						<div className="col-md-6">
							<div className="mb-3 position-relative">
								<label
									htmlFor="nom"
									className="form-label form-label-modal-custom"
								>
									Nom
								</label>
								<input
									type="text"
									placeholder="Nom"
									className="form-control form-detail-admin-profil"
									id="nom"
									defaultValue={item?.nom}
									{...register("nom")}
									disabled={disabled}
								/>
								{/* <div className="content-icon-edit-info-profil">
									<FiEdit />
								</div> */}
							</div>
							<FormError error={errors?.nom} />
						</div>
						<div className="col-md-6">
							<div className="mb-3 position-relative">
								<label
									htmlFor="email"
									className="form-label form-label-modal-custom"
								>
									Adresse mail
								</label>
								<input
									type="text"
									placeholder="mail@gmail.com"
									className="form-control form-detail-admin-profil"
									id="email"
									defaultValue={item?.email}
									{...register("email")}
									disabled={disabled}
									inputMode="text"
								/>
								{/* <div className="content-icon-edit-info-profil">
									<FiEdit />
								</div> */}
							</div>
							<FormError error={errors?.email} />
						</div>
						<div className="col-md-6">
							<div className="mb-3 position-relative">
								<label
									htmlFor="adress"
									className="form-label form-label-modal-custom"
								>
									Adresse
								</label>
								<input
									type="text"
									placeholder="Adresse"
									className="form-control form-detail-admin-profil"
									id="adress"
									defaultValue={
										item?.adresse
									}
									{...register("adresse")}
									disabled={disabled}
								/>
								{/* <div className="content-icon-edit-info-profil">
									<FiEdit />
								</div> */}
							</div>
						</div>
						<div className="col-md-6">
							<div className="mb-3 position-relative">
								<label
									htmlFor="telephone"
									className="form-label form-label-modal-custom"
								>
									Numéro de téléphone
								</label>
								<input
									type="text"
									placeholder="+22477777777"
									className="form-control form-detail-admin-profil"
									id="telephone"
									defaultValue={
										item?.telephone
									}
									{...register("telephone")}
									disabled={disabled}
								/>
								{/* <div className="content-icon-edit-info-profil">
									<FiEdit />
								</div> */}
							</div>
							<FormError
								error={errors?.telephone}
							/>
						</div>
					</div>
				</div>
				<div className="bg-white p-3 my-4 filter-and-table-container">
					<div className="content-title-section-detail-admin">
						<p className="title-section-detail-admin">
							Permissions
						</p>
					</div>
					<div className="row">
						<div className="col-md-12 mt-3">
							<div className="form-group crud-form-group">
								<div className="container-radio-input">
									<div className="form-radio-input radio-input">
										{Authorisations?.map(
											(item) => (
												<label
													className="ml-0 mb-3"
													key={
														item?.field
													}
												>
													<input
														type="checkbox"
														id="dashbboard"
														checked={
															item[
																item
																	?.field
															]
														}
														{...register(
															item?.field
														)}
														disabled={
															disabled
														}
													/>
													<span
														style={{
															cursor: disabled
																? "default"
																: "pointer",
														}}
													>
														{
															item?.label
														}
													</span>
												</label>
											)
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-btn-parametre flex-r my-4 gap-3">
					<div className="content-btn-cancelled">
						<button
							className="btn btn-cancelled"
							type="button"
							onClick={() => navigate(-1)}
						>
							Retour
						</button>
					</div>
					<div className="content-btn-submit">
						<BtnSubmit
							label="Enregistrer"
							isLoading={isLoading}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default DetailAdmin;
