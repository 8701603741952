import React from "react";
import { formattedDate, getAvatar, getName } from "../../../../utils/utils";
import { ButtonGoBack } from "../../../common/Button";
import { useUserFromLocation } from "../../../../utils/api/user/user.api";
import { Specialite } from '../../../../utils/api/config/config.type';

const DetailPro = () => {
	const [item] = useUserFromLocation();

	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page">
				<div className="col-left-dashboard col-left-dashboard-responsive">
					<div className="bg-white p-3">
						<div className="d-flex align-items-center m-0">
							<ButtonGoBack />{" "}
							<h5 className="m-0">
								Détail{" "}
								{item?.user_type === "pharmacien"
									? "pharmacie"
									: "médecin"}
							</h5>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="d-flex flex-column align-items-center py-4 mb-4 crypta-user-avatar-container">
									<img
										src={getAvatar(item?.avatar)}
										alt="user avatar"
										className="crypta-dash-locataire-param-img-avatar"
									/>
									<div className="crypta-dash-param-title pt-2">
										{getName(item)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-page-tabs-user mb-3">
					<div className="bg-white p-3 filter-and-table-container">
						{item?.nom_pharmacie && (
							<div className="row row-info-perso-user">
								<div className="col-md-4">
									<div className="content-text-info-user">
										<p className="text-info-user-libelle">
											Nom de la pharmacie
										</p>
									</div>
								</div>
								<div className="col-md-4">
									<div className="content-text-info-user">
										<p className="text-info-user-value">
											{item?.nom_pharmacie}
										</p>
									</div>
								</div>
							</div>
						)}
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Prénom
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.prenom}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">Nom</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.nom}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Mail{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.email}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Téléphone{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.telephone}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Adresse{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.adresse}
									</p>
								</div>
							</div>
						</div>
						{item?.specialite && (
							<div className="row row-info-perso-user">
								<div className="col-md-4">
									<div className="content-text-info-user">
										<p className="text-info-user-libelle">
											Specialite
										</p>
									</div>
								</div>
								<div className="col-md-4">
									<div className="content-text-info-user">
										<p className="text-info-user-value">
											{item?.specialite?.nom}
										</p>
									</div>
								</div>
							</div>
						)}
						{item?.diplome && (
							<div className="row row-info-perso-user">
								<div className="col-md-4">
									<div className="content-text-info-user">
										<p className="text-info-user-libelle">
											Diplôme
										</p>
									</div>
								</div>
								<div className="col-md-4">
									<div className="content-text-info-user">
										<p className="text-info-user-value">
											{item?.diplome?.nom}
										</p>
									</div>
								</div>
							</div>
						)}
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Pays{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.pays}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Ville{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.ville}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">Rue </p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.rue}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Code postal
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.code_postal}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Date d'inscription
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{formattedDate(item?.created_at)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailPro;
