import { IUser, TypeQuery } from "../user/user.type";

interface Actor {
	id: number;
	prenom: string;
	nom: string;
}

export enum TranMotifType {
	paiement = "paiement",
	remboursement = "remboursement",
	retrait = "retrait",
	depot = "depot",
}

export enum TransactionType {
	in = "IN",
	out = "OUT",
}
export interface ITransaction {
	id: number;
	slug: string;
	value: number;
	type: string;
	paid: boolean;
	solde_restant: string;
	transaction_id: string;
	archiver: boolean;
	paiement: string;
	user: IUser;
	description: string;
	created_at: string;
	receiver: Actor;
	sender: Actor;
	motif: string;
}

export type TransactionQuery = TypeQuery & {
	motif?: string;
	expediteur?: string;
	beneficiaire?: string;
	type?: string;
	value?: string;
	date?: string | null;
	transaction_id?: string;
};
