import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { NewsletterFormData } from "./newsletter.type";

export const NewsletterApi = createApi({
  tagTypes: ["newsletter", "allNewsletter", "desabonner_user"],
  reducerPath: "newsletterApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
  }),
  endpoints: (builder) => ({
    subscribeNewsletter: builder.mutation<NewsletterFormData, any>({
      query: (data) => ({
        url: "visitor/newsletters/",
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["newsletter"],
    }),
  }),
});

export const { useSubscribeNewsletterMutation } = NewsletterApi;
