import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { MdOutlineAddCircle, MdOutlineRemoveCircle } from "react-icons/md";
import { useItem, usePagination, useSearch } from "../../../../utils/hooks";
import { useGetMedicinsQuery } from "../../../../utils/api/user/user.api";
import TableSkeleton from "../../../common/TableSkeleton";
import { getAvatar, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { IUser } from "../../../../utils/api/user/user.type";
import { ButtonEdit, ButtonViewLink } from "../../../common/Button";
import { DeleteUser } from "../ListeUsers/Table/ListeUsersTable";
import AddMedecinModal from "./AddMedecinModal";
import { useToggleArchiveUser, useToggleStatutUser } from "../ListeUsers/hooks/useAddOrEditUser";
import { Color } from "../../../../utils/theme";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import { InputFilter } from "../../../common/Input";

export function ToggleArchiveUser({ item }: { item: IUser }) {
	const toggleArchive = useToggleArchiveUser(item);
	return (
		<button
			className={`btn btn-action-modal-icon with-tooltip`}
			data-tooltip-content={!item?.is_archive ? "Actif" : "Inactif"}
			onClick={toggleArchive}
		>
			{!item?.is_archive ? <MdOutlineAddCircle color={Color.success} /> : <MdOutlineRemoveCircle color="#d62912" />}
		</button>
	);
}
export function ToggleStatutUser({ item }: { item: IUser }) {
	const toggleStatut = useToggleStatutUser(item);
	return (
		<button
			className={`btn btn-action-modal-icon with-tooltip`}
			data-tooltip-content={item?.is_active ? "Actif" : "Inactif"}
			onClick={toggleStatut}
		>
			{item?.is_active ? <MdOutlineAddCircle color={Color.success} /> : <MdOutlineRemoveCircle color="#d62912" />}
		</button>
	);
}

function GestionMedecinsTable() {
	const { search: adresse, handlefilter: handlefilterAdresse } = useSearch();
	const { search: telephone, handlefilter: handlefilterPhone } = useSearch();
	const { search: email, handlefilter: handlefilterByEmail } = useSearch();
	const { search: prenom, handlefilter: handlefilterPrenom } = useSearch();
	const { search: nom, handlefilter: handlefilterNom } = useSearch();
	const { search: specialite, handlefilter: handlefilterSpeci } = useSearch();

	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetMedicinsQuery({
		page,
		limit,
		nom,
		prenom,
		specialite,
		email,
		telephone,
		adresse,
	});

	console.log({ data });

	const { item, getItem, resetItem } = useItem<IUser>();

	const imageFormatter = (cell, row: IUser) => {
		return (
			<div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
				<img
					src={getAvatar(row?.avatar)}
					alt="jeton"
					className="img-pharmacie-table"
				/>{" "}
			</div>
		);
	};
	const actionFormatter = (cell: string, row: IUser) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ToggleStatutUser item={row} />
					<ButtonViewLink to={`${row?.slug}`} state={row} />
					<ButtonEdit
						data-bs-toggle="modal"
						data-bs-target="#AddMedecinModal"
						onClick={() => getItem(row)}
					/>
					<DeleteUser item={row} />
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "avatar",
			text: "Photo",
			formatter: (cell: string, row: IUser) =>
				imageFormatter(cell, row),
		},
		{
			dataField: "prenom",
			text: "Prénom",
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter
					id="prenom"
					placeholder="Prénom"
					onChange={handlefilterPrenom}
				/>
			),
		},
		{
			dataField: "nom",
			text: "Nom",
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter
					id="nom"
					placeholder="Nom"
					onChange={handlefilterNom}
				/>
			),
		},
		{
			dataField: "email",
			text: "Email",
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter
					id="nom"
					placeholder="Nom"
					onChange={handlefilterByEmail}
				/>
			),
		},
		{
			dataField: "specialite.nom",
			text: "Spécialité",
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter
					id="specialite"
					placeholder="Spécialité"
					onChange={handlefilterSpeci}
				/>
			),
		},
		{
			dataField: "telephone",
			text: "Téléphone",
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter
					id="tel"
					placeholder="Téléphone"
					onChange={handlefilterPhone}
				/>
			),
		},
		{
			dataField: "adresse",
			text: "Adresse",
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter
					id="adresse"
					placeholder="Adresse"
					onChange={handlefilterAdresse}
				/>
			),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IUser) =>
				actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="AddMedecinModal"
				data-bs-backdrop="static"
				aria-labelledby="AddMedecinModalLabel"
				aria-hidden="true"
			>
				<AddMedecinModal resetItem={resetItem} item={item} />
			</div>
		</>
	);
}

export default GestionMedecinsTable;
