import React from "react";
import { useItem, usePagination } from "../../../../utils/hooks";
import { useGetObservationsByDossierQuery } from "../../../../utils/api/dossier/dossier.api";
import TableSkeleton from "../../../common/TableSkeleton";
import { CustomPagination } from "../../../common/CustomPagination";
import { formattedDate, getName, isShowPagination } from "../../../../utils/utils";
import BootstrapTable from "react-bootstrap-table-next";
import { IObservation } from "../../../../utils/api/dossier/dossier.type";
import { HiDocumentText } from "react-icons/hi";
import PieceJointeModal from "../../../modal/admin/PieceJointeModal";

const Observations = ({ slug }: { slug?: string }) => {
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetObservationsByDossierQuery({
		slug,
		page,
		limit,
	});
	const { item, getItem } = useItem<IObservation>();
	console.log(data);

	const docFormatter = (cell: string, row: IObservation) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<button
							className="btn with-tooltip btn-action-modal-icon"
							data-tooltip-content="Document"
							data-bs-toggle="modal"
							data-bs-target="#PieceJointeModal"
							style={{ color: "rgb(22 173 139)", fontSize: 26 }}
							onClick={() => getItem(row)}
						>
							<HiDocumentText />
						</button>
					</div>
				</div>
			</>
		);
	};
	const columns = [
		{
			dataField: "created_at",
			text: "Date ",
			formatter: (cell: string) => formattedDate(cell),
		},
		{
			dataField: "description",
			text: "Description",
		},
		{
			dataField: "professionnel",
			text: "Professionnel",
			formatter: (cell: string, row: IObservation) => getName(row?.rdv?.professionnel),
		},
		{
			dataField: "fichier",
			text: "Document",
			formatter: (cell: string, row: IObservation) => docFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="PieceJointeModal"
				aria-labelledby="ListeDossiersModalLabel"
				aria-hidden="true"
			>
				<PieceJointeModal item={item} title="Document" />
			</div>
		</>
	);
};

export default Observations;
