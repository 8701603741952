import { IOffres } from "../offres/offres.type";

export enum OptionCivite {
	monsieur = "M",
	madame = "Mme",
	mademoiselle = "Mlle",
}
export enum OptionGenre {
	homme = "homme",
	femme = "femme",
}
export interface IOrder {
	step: number;
	id?: number;
	slug?: string;
	nom: string;
	prenom: string;
	telephone: string;
	email: string;
	date_debut: string;
	date_fin: string;
	paid?: boolean;
	env: string;
	page: string;
	price: number;
	offre: IOffres;
	moyen_paiement: string;
	condition: boolean;
	lieu_de_naissance: string;
	date_de_naissance: string;
	created_at: string;
	code_postal: string;
}

export type OrderFormData = {
	step?: number;
	id?: number;
	slug?: string;
	nom: string;
	prenom: string;
	civilite: string;
	genre: string;
	telephone: string;
	email: string;
	nombre_personnes_en_charge: string;
	nombre_enfants_moins_5_ans: string;
	paid?: boolean;
	price: number;
	offre: number;
	env: string;
	page: string;
	moyen_paiement: number;
	condition: boolean;
	lieu_de_naissance: string;
	date_de_naissance: string;
	enfant_moins_de_5_ans: boolean;
	code_postal: string;
};

export interface PaymentUrl {
	url: string;
}

export type PaymentMethod = {
	id: number;
	slug: string;
	cardCVC: string;
	cardExpireMonth: string;
	cardExpireYear: string;
	cardNumber: string;
	cardType: string;
	customerFirstName: string;
	customerLastName: string;
};
export type PaymentMethodFormData = Partial<Omit<PaymentMethod, "id" | "slug">>;
