import React from "react";
import { useSearch, useSelectSearch } from "../../../../utils/hooks";
import { InputFilter } from "../../../common/Input";
import { SelectInputFilter } from "../../../common/SelectInput";
import { TranMotifType, TransactionType } from "../../../../utils/api/transaction/transaction.type";
import ListeRendezVousTable from "./ListeRendezVousTable";
const motifs = [
	{
		label: "Dépôt",
		value: TranMotifType.depot,
	},
	{
		label: "Paiement",
		value: TranMotifType.paiement,
	},
	{
		label: "Retrait",
		value: TranMotifType.retrait,
	},
	{
		label: "Remboursement",
		value: TranMotifType.remboursement,
	},
];
const types = [
	{
		label: "Débit",
		value: TransactionType.in,
	},
	{
		label: "Crédit",
		value: TransactionType.out,
	},
];
const ListeRendezVous = () => {
	const { search, handlefilter } = useSearch();
	const { search: beneficiare, handlefilter: handlefilterBeneficiaire } = useSearch();
	const { search: motif, handlefilter: handlefilterMotif } = useSelectSearch();
	const { search: type, handlefilter: handlefilterType } = useSelectSearch();

	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header">
						<h4 className="crypta-dash-header-titre">Rendez vous</h4>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="">
						<div className="flex-sb align-items-center mb-4">
							<div className="tab-dash-admin-filter-container">
								<div className="">
									<label
										htmlFor="filtreLogement"
										className="tab-dash-admin-filter-label"
									>
										Filtrer
									</label>
								</div>
								<div className="d-flex flex-wrap gap-4">
									<InputFilter
										id="expediteur"
										placeholder="Entrer un expéditeur"
										onChange={handlefilter}
									/>
									<InputFilter
										id="beneficiaire"
										placeholder="Entrer un bénéficiaire"
										onChange={handlefilterBeneficiaire}
									/>
									<SelectInputFilter
										id="motif"
										placeholder="Tous les motifs"
										onChange={handlefilterMotif}
										options={motifs}
									/>
									<SelectInputFilter
										id="motif"
										placeholder="Tous les types"
										onChange={handlefilterType}
										options={types}
									/>
								</div>
							</div>
						</div>
						<ListeRendezVousTable
							expediteur={search}
							beneficiaire={beneficiare}
							type={type}
							motif={motif}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListeRendezVous;
