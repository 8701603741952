import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { IUser } from "../user/user.type";
import {
	ForgetPasswordFormData,
	LoginFormData,
	LoginResult,
	RegisterFormData,
	RegisterResponse,
	ResetPasswordFormData,
} from "./auth.type";

export const AuthApi = createApi({
	reducerPath: "auth",
	tagTypes: ["auth"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
	}),
	endpoints: (build) => ({
		// register user mutation
		registerUser: build.mutation<RegisterResponse, RegisterFormData>({
			query: (data) => ({
				url: "auth/register/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
		// login user mutation
		loginUser: build.mutation<LoginResult, LoginFormData>({
			query: (data) => ({
				url: "auth/login/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
		// request to reset user password mutation
		forgetPassword: build.mutation<IUser, ForgetPasswordFormData>({
			query: (data) => ({
				url: `auth/request-password-reset/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
		//reset user password mutation
		resetUserPassword: build.mutation<IUser, ResetPasswordFormData>({
			query: (data) => ({
				url: `auth/reset-email-password/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
	}),
});

export const {
	useRegisterUserMutation,
	useLoginUserMutation,
	useForgetPasswordMutation,
	useResetUserPasswordMutation,
} = AuthApi;
