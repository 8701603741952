import React from "react";
import Logo from "../../assets/appImages/logo.png";
import { BtnSubmit } from "../common/Button";
import { AuthInput } from "../common/Input";
import { usePasswordRule } from "./hooks/useRegister";
import { useResetPassword } from "./hooks/useResetPassword";
import { PassWordRuleView } from "./PasswordRuleView";

const ResetPassword = () => {
	const { register, onSubmit, errors, isLoading, setValue } = useResetPassword();
	const {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
	} = usePasswordRule(setValue, "new_password");
	const props = {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
	};
	return (
		<div>
			<div className="">
				<div className="row login-page-row">
					<div className="col-md-6 offset-md-3 right-side-col">
						<div className="login-form-container">
							<div className="login-form-introduction d-flex flex-column align-items-center">
								<div className="mb-4">
									<img
										src={Logo}
										alt="logo"
										className="logo-auth-login-img"
									/>
								</div>
								<h1 className="auth-form-title">
									Modification du mot de
									passe
								</h1>
								<div className="p-4">
									<h4 className="auth-form-message">
										Créer un nouveau mot
										de passe
									</h4>
								</div>
							</div>
							<div className="mt-4">
								<form
									id="auth-form"
									onSubmit={onSubmit}
								>
									<div className="row auth-form-row">
										<div className="row auth-form-row">
											<div className="col-md-12 auth-input-col mb-3">
												<AuthInput
													label="Code*"
													type="text"
													id="code"
													placeholder="Code"
													{...register(
														"code"
													)}
													error={
														errors?.code
													}
													data-testid="codeId"
													autoComplete="off"
												/>
											</div>
											<div className="col-md-12 auth-input-col mb-2">
												<AuthInput
													label="Nouveau Mot de passe*"
													type="password"
													id="password"
													inputMode="text"
													placeholder="Nouveau Mot de passe"
													onChange={
														handleChangePassword
													}
													onFocus={
														showPasswordRule
													}
													onBlur={
														showPasswordRule
													}
													error={
														errors?.new_password
													}
													data-testid="newPasswordId"
												/>
											</div>
											{isShowPasswordRules && (
												<PassWordRuleView
													{...props}
												/>
											)}
											<div className="col-md-12 auth-input-col mb-2">
												<AuthInput
													label="Confimer le mot de passe*"
													type="password"
													inputMode="text"
													id="new_password_confirm"
													placeholder="Confimer le mot de passe"
													{...register(
														"new_password_confirm"
													)}
													error={
														errors?.new_password_confirm
													}
													data-testid="confirmNewPasswordId"
												/>
											</div>
										</div>
										<div className="col-md-6 offset-md-3 auth-submit-btn-container mt-5">
											<BtnSubmit
												label="	Reinitialiser le mot de
												passe"
												isLoading={
													isLoading
												}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
