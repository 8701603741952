import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import { useItem, usePagination, useSearch } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { formatRemboursementStatus, formatRemboursementStatusClass, formattedDate, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { useGetDemandeRenitCompteursQuery } from "../../../../utils/api/user/user.api";
import { ICompteur } from "../../../../utils/api/user/user.type";
import ChangeStatusDemandeCompteurModal from "./ChangeStatusDemandeCompteurModal";
import { InputFilter } from "../../../common/Input";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
registerLocale("fr", fr);

function ListeDemandesCompteurTable() {
	const [date, setDate] = useState<Date | null>(null);

	const { search, handlefilter } = useSearch();
	const { search: prenom, handlefilter: handleFilterByPrenom } = useSearch();
	const { search: email, handlefilter: handleFilterByEmail } = useSearch();
	const { search: telephone, handlefilter: handleFilterByPhone } = useSearch();
	const { search: matricule, handlefilter: handlefilterMatricule } = useSearch();
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetDemandeRenitCompteursQuery({
		page,
		limit,
		nom: search,
		matricule,
		prenom,
		email,
		telephone,
		date: date !== null ? moment(date).format("YYYY-MM-DD") : null,
	});

	const { item, getItem } = useItem<ICompteur>();

	const statutFormatter = (cell, row) => {
		return (
			<div className="table-actions-btn-container-img d-flex align-items-center">
				<span
					className={`statut-dossier-item text-capitalize ${formatRemboursementStatusClass(cell)}`}
					role="button"
					onClick={() => getItem(row)}
					data-bs-toggle="modal"
					data-bs-target={`#ChangeStatusDemandeCompteur`}
				>
					{formatRemboursementStatus(cell)}
				</span>
			</div>
		);
	};
	const columns = [
		{
			dataField: "patient.matricule",
			text: "Matricule",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="matricule" placeholder="Matricule" onChange={handlefilterMatricule} />,
		},
		{
			dataField: "patient.prenom",
			text: "Prénom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="prenom" placeholder="Prénom" onChange={handleFilterByPrenom} />,
		},
		{
			dataField: "patient.nom",
			text: "Nom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="nom" placeholder="Nom" onChange={handlefilter} />,
		},
		{
			dataField: "patient.email",
			text: "Email",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="email" placeholder="Email" onChange={handleFilterByEmail} />,
		},
		{
			dataField: "patient.telephone",
			text: "Téléphone",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="tel" placeholder="Téléphone" onChange={handleFilterByPhone} />,
		},
		{
			dataField: "raisons",
			text: "Motif",
		},
		{
			dataField: "created_at",
			text: "Date",
			formatter: (cell: string) => formattedDate(cell),
			filter: customFilter(),
			filterRenderer: () => (
				<div className="date-picker-container">
					<DatePicker
						className="form-control tab-dash-admin-filter-input"
						selected={date}
						onChange={(date: Date) => setDate(date)}
						maxDate={new Date()}
						dateFormat="dd/MM/yyyy"
						placeholderText="Choisir une date"
						isClearable
						locale="fr"
					/>
				</div>
			),
		},
		{
			dataField: "status",
			text: "Statut",
			formatter: (cell: string, row: ICompteur) => statutFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<ChangeStatusDemandeCompteurModal modalId="ChangeStatusDemandeCompteur" item={item} />
		</>
	);
}

export default ListeDemandesCompteurTable;
