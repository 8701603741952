import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDelete, useItem, usePagination, useSearch } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { formatMontant, getName, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { Link } from "react-router-dom";
import { useDeleteAbonnementMutation, useGetAbonnementsQuery } from "../../../../utils/api/abonnement/abonnement.api";
import { IAbonnements } from "../../../../utils/api/abonnement/abonnement.type";
import { IOffres } from "../../../../utils/api/offres/offres.type";
import DetailOffreModal from "../../../modal/admin/DetailOffreModal";
import AddAbonnementModal from "../../../modal/admin/AddAbonnementModal";
import { ModePaiment } from "../../../../utils/api/config/config.type";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import { InputFilter } from "../../../common/Input";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";

export function DeleteAbonnement({ item }: { item: IAbonnements }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteAbonnementMutation();
	const onDelete = useDelete<IAbonnements>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet abonnement",
		successMessage: "Abonnement supprimé avec succès !",
	});

	return <ButtonDelete onClick={onDelete} />;
}

function ListeAbonnementsTable() {
	const { page, setPage, limit, setPerPage } = usePagination(10);

	const { item: offer, getItem: getOffer } = useItem<IOffres>();
	const { item, getItem, resetItem } = useItem<IAbonnements>();

	const { search: patient, handlefilter } = useSearch();
	const { search: offre, handlefilter: handlefilterOffre } = useSearch();
	const { search: moyen_paiement, handlefilter: handlefilterMode } = useSearch();

	const { data, isLoading } = useGetAbonnementsQuery({
		page,
		limit,
		patient,
		offre,
		moyen_paiement,
	});

	const actionFormatter = (cell: string, row: IAbonnements) => {
		if (row?.created_by) {
			return (
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonEdit data-bs-toggle="modal" data-bs-target="#AddMoyenPaiementModal" onClick={() => getItem(row)} />
					<DeleteAbonnement item={row} />
				</div>
			);
		}
		return null;
	};

	const modePaiementFormatter = (cell: ModePaiment) => {
		return <span className="text-capitalize">{cell?.titre}</span>;
	};

	const offreFormatter = (cell: IOffres) => {
		return (
			<span
				className="text-capitalize underline"
				role="button"
				data-bs-toggle="modal"
				data-bs-target="#DetailOffreModal"
				onClick={() => getOffer(cell)}
			>
				{cell?.title}
			</span>
		);
	};

	const userFormatter = (cell: string, row: IAbonnements) => {
		return (
			<Link to={`/admin/utilisateur/${row?.user?.slug}`} state={row?.user} className="redirect-link">
				{getName(row.user)}
			</Link>
		);
	};

	const columns = [
		{
			dataField: "user",
			text: "Patient",
			formatter: (cell: string, row: IAbonnements) => userFormatter(cell, row),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="Patient" placeholder="Patient" onChange={handlefilter} />,
		},
		{
			dataField: "offre",
			text: "Offre",
			formatter: (cell: IOffres) => offreFormatter(cell),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="offre" placeholder="Offre" onChange={handlefilterOffre} />,
		},
		{
			dataField: "moyen_paiement",
			text: "Mode de paiement",
			formatter: (cell: ModePaiment) => modePaiementFormatter(cell),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="modedepaiement" placeholder="Mode de paiement" onChange={handlefilterMode} />,
		},
		{
			dataField: "price",
			text: "Montant",
			formatter: (cell: string) => formatMontant(cell),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IAbonnements) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			<div className="flex-sb align-items-center mb-4">
				<div className="tab-dash-admin-filter-container">
					<div className="">
						<label htmlFor="filtreLogement" className="tab-dash-admin-filter-label">
							Filtrer par
						</label>
					</div>
					<div className="d-flex flex-wrap gap-4"></div>
				</div>
			</div>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div className="modal fade" id="DetailOffreModal" aria-labelledby="DetailOffreModalLabel" aria-hidden="true">
				<DetailOffreModal item={offer} />
			</div>
			<div className="modal fade" id="AddAbonnementModal" aria-labelledby="AddAbonnementModalLabel" aria-hidden="true">
				<AddAbonnementModal item={item} resetItem={resetItem} />
			</div>
		</>
	);
}
export default ListeAbonnementsTable;
