import React, { useEffect } from "react";
import Modal from "react-modal";
import CloseModalBtn from "../../shared/CloseModalBtn";
import usePreOrderForm from "../requestForm/usePreOrderForm";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import UserInfosStep from "./steps/UserInfosStep";
import ConfirmPreCommandeStep from "./steps/ConfirmPreCommandeStep";
import ModePaiementStep from "./steps/ModePaiementStep";

const steps = [
	{
		id: "user-infos",
		Component: UserInfosStep,
	},
	{
		id: "mode-paiement",
		Component: ModePaiementStep,
	},
	{
		id: "confirm-pre-commande",
		Component: ConfirmPreCommandeStep,
	},
];

export const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
		zIndex: 99999,
	},
};

function InfosUserForOrderModal({ modalInfosUserIsOpen, setInfosUserIsOpen, offre, Index }) {
	const { step, navigation, index }: useStepType = useStep({
		initialStep: 0,
		steps,
	});
	const {
		register,
		errors,
		onSubmit,
		setValue,
		phone,
		setPhone,
		reset,
		handleReset,
		isLoading,
		level,
		dataForm,
		setLevel,
		setDataForm,
		checked,
		handleChecked,
		setCode
	} = usePreOrderForm(navigation, offre, setInfosUserIsOpen);

	function closeModal() {
		handleReset();
		setLevel(0);
		setDataForm(undefined);
		setInfosUserIsOpen(false);
	}

	const { Component } = step;
	const props = {
		navigation,
		register,
		onSubmit,
		setValue,
		errors,
		isLoading,
		level,
		dataForm,
		phone,
		setPhone,
		reset,
		offre,
		Index,
		closeModal,
		checked,
		handleChecked,
		setCode
	};

	useEffect(() => {
		setValue("step", index + 1);
	}, [index]);

	// useEffect(() => {
	// 	if (level) {
	// 		console.log("level", level);
	// 		if (level === 1) {
	// 			navigation.go("mode-paiement");
	// 		}
	// 		if (level === 2) {
	// 			navigation.go("confirm-pre-commande");
	// 		}
	// 	}
	// }, [level, dataForm]);

	return (
		<Modal
			isOpen={modalInfosUserIsOpen}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div
				className={`modal-header pe-2 py-1 ${
					index === 1 && "custom-modal-header-border"
				}`}
			>
				<h5 className="modal-title-admin" id="ChangePasswordModalLabel">
					{index === 0
						? "Informations personnelles"
						: index === 1
						? "Choisissez un mode de paiement"
						: "Confirmer votre pré commande"}
				</h5>
				<CloseModalBtn onClick={closeModal} />
			</div>
			<div className="body-modal">
				<div className="container-modal-postuler">
					<Component {...props} />
				</div>
			</div>
		</Modal>
	);
}

export default InfosUserForOrderModal;
