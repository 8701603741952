import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useResetUserPasswordMutation } from "../../../utils/api/auth/auth.api";
import { ForgetPasswordFormData, ResetPasswordFormData } from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import { QueryError } from "../../../utils/type";
import { cleannerError } from "../../../utils/utils";
import { validatePassword } from "./useRegister";
export function useResetPassword() {
	const [resetPassword, { error, isSuccess, isLoading }] = useResetUserPasswordMutation();

	const state = useLocation().state as ForgetPasswordFormData;

	const navigate = useNavigate();

	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				email: yup.string().email().required().label("Email"),
				code: yup.string().required().label("Code"),
				new_password: validatePassword(yup, "Le mot de passe est un champ obligatoire"),
				new_password_confirm: yup
					.string()
					.oneOf([yup.ref("new_password"), null], "Les mots de passe ne correspond pas"),
			}),
		[]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		setError,
	} = useForm<ResetPasswordFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (state?.email) {
			setValue("email", state.email);
		} else {
			setTimeout(() => navigate("/mot-de-passe-oublie"), 1);
		}
	}, [state?.email]);

	useEffect(() => {
		if (error) {
			const err = error as QueryError;
			const { message = "Une erreur a survenue" } = err.data || {};
			setError("code", { message: message });
		}
	}, [error]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: "Mot de passe modifié avec succès!",
			});
			navigate("/connexion");
		}
	}, [isSuccess]);

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors);
		}
	}, [errors]);
	return {
		register,
		errors,
		onSubmit: handleSubmit(resetPassword),
		clearErrors,
		setValue,
		isLoading,
	};
}
