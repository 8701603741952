import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ConditionFormData, ICondition } from "./condition.type";
import { prepareHeaders } from "../user/user.api";
import { ApiBaseUrl } from "../../http";

const tags = {
  cnds: "conditionsList",
  cgu: "cgu",
  ml: "ml",
  condition: "condition",
};

export const ConditionApi = createApi({
  reducerPath: "conditionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  tagTypes: [tags.cnds],
  endpoints: (build) => ({
    getConditions: build.query<ICondition[], void>({
      query: () => `conditions/`,
      transformResponse: ({ results }) => results,
      providesTags: [tags.cnds],
    }),
    addOrEditCondition: build.mutation<
      ICondition,
      { slug?: string; data: ConditionFormData }
    >({
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `conditions/${slug}/`,
            method: "PUT",
            body: data,
          };
        } else {
          return {
            url: `conditions/`,
            method: "POST",
            body: data,
          };
        }
      },
      invalidatesTags: [tags.cnds],
    }),
    getPolicy: build.query<ICondition[], void>({
      query: () => `pc/`,
      transformResponse: ({ results }) => results[0],
      providesTags: [tags.cnds, tags.ml],
    }),
    getCgu: build.query<ICondition[], void>({
      query: () => `cgu/`,
      providesTags: [tags.cnds, tags.cgu],
    //   transformResponse: (data:ICondition[]) => console.log(data,"cgu"),
    }),
    getAide: build.query<ICondition, void>({
      query: () => `aides/`,
      providesTags: [tags.cnds, tags.cgu],
      transformResponse: ({ results }) => results[0],
    }),
    sendEditConditionCode: build.query<ICondition, void>({
      query: () => ({
        url: `modification_condition/request_code/`,
        method: "GET",
      }),
      providesTags: [tags.condition],
    }),
  }),
});

export const {
  useAddOrEditConditionMutation,
  useGetConditionsQuery,
  useGetCguQuery,
  useGetPolicyQuery,
  useGetAideQuery,
  useLazySendEditConditionCodeQuery,
} = ConditionApi;
