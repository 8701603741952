import React from "react";
import "./ListeUsers.css";
import { AiOutlinePlusSquare } from "react-icons/ai";
import ListeUsersTable from "./Table/ListeUsersTable";

const ListeUsers = () => {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-r align-items-center">
					<div className="content-btn-add-theme">
						<button className="btn btn-add-theme" data-bs-toggle="modal" data-bs-target="#addUserModal">
							<AiOutlinePlusSquare style={{ color: "#08BB90", fontSize: 20 }} />
							<span className="ps-2">Ajouter un patient</span>
						</button>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="">
						<ListeUsersTable />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListeUsers;
