import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import { useDelete, usePagination } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { formattedDate, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonViewLink } from "../../../common/Button";
import { useDeleteCandidatureMutation, useGetCandidaturesQuery } from "../../../../utils/api/candidature/candidature.api";
import { ICandidature, IPoste } from "../../../../utils/api/candidature/candidature.type";
import { InputFilter } from "../../../common/Input";
import { useSearch } from "../../../../utils/hooks";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
registerLocale("fr", fr);

export function DeleteCandidature({ item }: { item: ICandidature }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteCandidatureMutation();
	const onDelete = useDelete<ICandidature>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette candidature",
		successMessage: "Candidature supprimée avec succès !",
	});
	return <ButtonDelete onClick={onDelete} />;
}

function ListeCandidaturesTable() {
	const [date, setDate] = useState<Date | undefined>(undefined);

	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { search, handlefilter } = useSearch();
	const { search: nom, handlefilter: handleFilterByNom } = useSearch();
	const { search: email, handlefilter: handleFilterByEmail } = useSearch();
	const { search: poste, handlefilter: handleFilterByPoste } = useSearch();

	const { data, isLoading } = useGetCandidaturesQuery({
		page,
		limit,
		prenom: search,
		nom,
		date_disponibilite: date
			? moment(date).format("YYYY-MM-DD")
			: undefined,
		email,
		poste,
	});

	const actionFormatter = (cell: string, row: ICandidature) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonViewLink
						to={`candidatures/${row.slug}`}
						state={row}
					/>
					<DeleteCandidature item={row} />
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "prenom",
			text: "Prénom",
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter
					id="prenom"
					placeholder="Prénom"
					onChange={handlefilter}
				/>
			),
		},
		{
			dataField: "nom",
			text: "Nom",
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter
					id="nom"
					placeholder="Nom"
					onChange={handleFilterByNom}
				/>
			),
		},
		{
			dataField: "email",
			text: "Email",
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter
					id="email"
					placeholder="Email"
					onChange={handleFilterByEmail}
				/>
			),
		},
		{
			dataField: "poste",
			text: "Poste",
			formatter: (cell: IPoste) => cell?.nom,
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter
					id="poste"
					placeholder="Poste"
					onChange={handleFilterByPoste}
				/>
			),
		},
		{
			dataField: "date_disponibilite",
			text: "Date de disponibilité",
			formatter: (cell: string) => formattedDate(cell),
			filter: customFilter(),
			filterRenderer: () => (
				<div className="date-picker-container">
					<DatePicker
						className="form-control tab-dash-admin-filter-input"
						selected={date}
						onChange={(date: Date) => setDate(date)}
						maxDate={new Date()}
						dateFormat="dd/MM/yyyy"
						placeholderText="Choisir une date"
						isClearable
						locale="fr"
					/>
				</div>
			),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: ICandidature) =>
				actionFormatter(cell, row),
		},
	];

	return (
		<>
			<div className="flex-sb align-items-center mb-4">
				<div className="tab-dash-admin-filter-container">
					<div className="">
						<label
							htmlFor="filtreLogement"
							className="tab-dash-admin-filter-label"
						>
							Filtrer par
						</label>
					</div>
					<div className="d-flex flex-wrap gap-4">
						<div className="date-picker-container">
							<DatePicker
								className="form-control tab-dash-admin-filter-input"
								selected={date}
								onChange={(date: Date) =>
									setDate(date)
								}
								maxDate={new Date()}
								dateFormat="dd/MM/yyyy"
								placeholderText="Choisir une date"
								isClearable
								locale="fr"
							/>
						</div>
					</div>
				</div>
			</div>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() =>
							"Aucune donnée trouvée"
						}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
		</>
	);
}
export default ListeCandidaturesTable;
