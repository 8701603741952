import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
	cleannerError,
	formatMontant,
	getName,
	onHide,
} from "../../../../../utils/utils";
import toast from "react-hot-toast";
import {
	IRemboursement,
	RemboursementFormData,
	RemboursementType,
} from "../../../../../utils/api/remboursement/remboursement.type";
import {
	useAddOrUpdateRemboursementMutation,
	useDeleteRemboursemntMutation,
} from "../../../../../utils/api/remboursement/remboursement.api";
import { useGetPatientsQuery } from "../../../../../utils/api/user/user.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { isSuperAdmin } from "../../../../../routes/routerUtils";
import { maxAMountRemboursement } from "../../../../../utils/constantes";
import { Color } from "../../../../../utils/theme";
import { QueryError } from "../../../../../utils/type";

function useAddOrEditRemboursement(
	resetItem: () => void,
	currentType: RemboursementType,
	modalId: string,
	item?: IRemboursement
) {
	const issuperadmin = useAppSelector((s) => isSuperAdmin(s?.user?.user));
	const validationSchema = yup.object().shape({
		issuperadmin: yup.boolean().default(issuperadmin),
		type: yup.string().label("Type de remboursement"),
		montant: yup.number().when("issuperadmin", {
			is: true,
			then: yup
				.number()
				.required()
				.label("Montant")
				.typeError("Montant est un champ obligatoire"),
			otherwise: yup
				.number()
				.required()
				.label("Montant")
				.max(
					maxAMountRemboursement,
					"Le montant ne doit peut pas être plus grand qu'un million"
				)
				.typeError("Montant est un champ obligatoire"),
		}),
		appareils: yup.string().when("type", {
			is: RemboursementType.appareils,
			then: yup.string().required().label("Appareils"),
		}),
		hopital: yup.string().when("type", {
			is: RemboursementType.hospitalisation,
			then: yup.string().required().label("Hôpital"),
		}),
		fichier: yup.mixed().required().label("Justificatif"),
		patient: yup.string().required().label("Patient"),
	});

	const [type, setType] = useState<string>(currentType);
	const [justificatif, setJustificatif] = useState<File>();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<RemboursementFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrEditRemboursement, { isLoading }] =
		useAddOrUpdateRemboursementMutation();
	const { data = { results: [] } } = useGetPatientsQuery({});

	const handleChangeJustifcatif = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		if (e.currentTarget.files) {
			const file = e.currentTarget.files[0];
			setJustificatif(file);
			setValue("fichier", file);
		}
	};
	const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setType(e.currentTarget.value);
	};

	const [inputValue, setInputValue] = useState("");
	const [option, setOption] = useState<any>();
	const [options, setOptions] = useState<
		{ label: string; value: number }[]
	>([]);

	const handleChange = (selectedOption) => {
		setValue("patient", selectedOption?.value);
		setOption(selectedOption);
	};

	const handleInputChange = (newValue) => {
		setInputValue(newValue);
	};

	const filterOptions = (option, inputValue) => {
		return option.label
			.toLowerCase()
			.includes(inputValue.toLowerCase());
	};

	const loadOptions = (inputValue) => {
		if (inputValue.length < 2) {
			let options = data?.results?.map((item) => {
				return {
					label: getName(item),
					value: item?.id,
				};
			});
			setOptions(options);
			return;
		}

		setTimeout(() => {
			const filteredOptions = options.filter((option) =>
				filterOptions(option, inputValue)
			);
			setOptions(filteredOptions);
		}, 300);
	};
	useEffect(() => {
		cleannerError(errors, clearErrors);
		console.log(errors);
	}, [errors]);

	useEffect(() => {
		setValue("montant", item?.montant);
		if (item?.appareils) setValue("appareils", item?.appareils);

		if (item?.hopital) setValue("hopital", item?.hopital);
		setValue("type", currentType);
		setValue("patient", item?.patient?.id);
		setOption({ label: getName(item?.patient), value: item?.id });
	}, [item]);

	useEffect(() => {
		if (currentType) {
			setType(currentType);
			setValue("type", currentType);
		}
	}, [currentType]);

	const onSubmit = async (data: RemboursementFormData) => {
		// console.log({ data });
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}
		const res = await addOrEditRemboursement({
			slug: item?.slug,
			data: fd,
		});
		if ("data" in res) {
			toast.success(
				`Demande ${
					!item ? "ajoutée" : "modifiée"
				} avec succès !`
			);
			reset();
			resetItem();
			setJustificatif(undefined);
			onHide(modalId);
		} else if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${
						item ? "la modification" : "l'ajout"
				  }`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		type,
		justificatif,
		handleChangeJustifcatif,
		handleChangeType,
		patients: data?.results?.map((item) => {
			return {
				label: getName(item),
				value: item?.id,
			};
		}),
		handleChange,
		filterOptions,
		inputValue,
		handleInputChange,
		loadOptions,
		options,
		option,
	};
}

export function useDeleteRemboursement(item: IRemboursement) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteRemboursemntMutation();

	const onDelete = useCallback(async () => {
		Swal.fire({
			titleText: `Êtes vous sûr de vouloir supprimer ce remboursement de ${getName(
				item?.patient
			)}`,
			icon: "question",
			html: `<div><span>Type </span><span>${
				item?.type
			}</span></div><div><span>Montant </span><span>${formatMontant(
				item?.montant
			)}</span></div> <div><span>Montant à rembourser </span><span>${formatMontant(
				item?.montant_a_payer
			)}</span></div>	`,
			width: 600,
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.danger,
			confirmButtonColor: Color.danger,
			preConfirm: () => {
				deleteItem(item?.slug);
			},
		});
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success(`Remboursement avec succès!`);
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as QueryError;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}

export default useAddOrEditRemboursement;
