import React from "react";
import { MdOutlineFilePresent } from "react-icons/md";
import { IUser } from "../../../../utils/api/user/user.type";
import { Color } from "../../../../utils/theme";
import { formattedDate, getAvatar, getName } from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import ListeParticipantTable from "./Table/ListeParticipantTable";
import { ButtonGoBack } from "../../../common/Button";
import { useReinitCompteur } from "./hooks/useRenitCompteur";
import { useUserFromLocation } from "../../../../utils/api/user/user.api";
import DetailOffreModal from "../../../modal/admin/DetailOffreModal";
import { IOffres } from "../../../../utils/api/offres/offres.type";
import { useItem } from "../../../../utils/hooks";

function ReinitCompteur({ item }: { item: IUser }) {
	const reinitCompteur = useReinitCompteur(item);

	return (
		<button
			className="btn"
			style={{
				background: Color.blue,
				color: "#FFFF",
			}}
			onClick={reinitCompteur}
		>
			Reinitialiser
		</button>
	);
}
const DetailEntreprise = () => {
	const [item] = useUserFromLocation();
	const { item: offre, getItem } = useItem<IOffres>();

	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page">
				<div className="col-left-dashboard col-left-dashboard-responsive">
					<div className="bg-white p-3">
						<div>
							<ButtonGoBack />
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="d-flex flex-column align-items-center py-4 mb-4 crypta-user-avatar-container">
									<img
										src={getAvatar(item?.avatar)}
										alt="user avatar"
										className="crypta-dash-locataire-param-img-avatar"
									/>
									<div className="crypta-dash-param-title pt-2">
										{getName(item)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-page-tabs-user mb-3">
					<div className="bg-white p-3 filter-and-table-container">
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Prénom
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.prenom}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">Nom</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.nom}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Mail{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.email}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Téléphone{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.telephone}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Adresse{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.adresse}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Pays{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.pays}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Ville{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.ville}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">Rue </p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.rue}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Code postal
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.code_postal}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Date d'inscription
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{formattedDate(item?.created_at)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white p-3 mt-4">
				<h3 className="crypta-dash-param-title">Abonnement</h3>
				{item?.abonnement ? (
					<>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Date de début{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{formattedDate(
											item?.abonnement?.date_debut
										)}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Date de fin{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{formattedDate(
											item?.abonnement?.date_fin
										)}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Offre
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p
										className="text-info-user-value underline"
										role="button"
										data-bs-toggle="modal"
										data-bs-target="#DetailOffreModal"
										onClick={() =>
											getItem(item?.abonnement?.offre)
										}
									>
										{item?.abonnement?.offre?.title}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Fréquence
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.abonnement?.offre?.frequence ===
										"MONTH"
											? "Mensuelle"
											: "Annuelle"}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Facture
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									{item?.abonnement?.facture ? (
										<a href={item?.abonnement?.facture}>
											<MdOutlineFilePresent
												fontSize={24}
												color={Color.success}
												title="Cliquer pour télécharger la facture"
												role="button"
											/>
										</a>
									) : (
										<AlertInfo message="Aucun fichier trouvé" />
									)}
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user align-items-center">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Compteur
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.abonnement?.offre?.frequence ===
										"MONTH"
											? item?.compteur?.mensuel
											: item?.compteur?.annuel}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<ReinitCompteur item={item} />
							</div>
						</div>
					</>
				) : (
					<AlertInfo message="Aucun abonnement trouvé pour ce patient" />
				)}
			</div>
			<div className="bg-white p-3 mt-4">
				<h3>Liste des participants</h3>
				<ListeParticipantTable userSlug={item?.slug} />
			</div>
			<div
				className="modal fade"
				id="DetailOffreModal"
				aria-labelledby="DetailOffreModalLabel"
				aria-hidden="true"
			>
				<DetailOffreModal item={offre} />
			</div>
		</div>
	);
};

export default DetailEntreprise;
