import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDelete, useItem, usePagination } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { createMarkup, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import AddDiplomeModal from "../../../modal/admin/AddDiplomeModal";
import { Diplome } from "../../../../utils/api/config/config.type";
import {
	useDeleteDiplomeMutation,
	useGetDiplomeQuery,
} from "../../../../utils/api/config/config.api";

export function DeleteDiplome({ item }: { item: Diplome }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteDiplomeMutation();
	const onDelete = useDelete<Diplome>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "ce diplôme",
		successMessage: "Diplôme supprimé avec succès !",
	});
	return <ButtonDelete onClick={onDelete} />;
}

function ListeDiplomesTable() {
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetDiplomeQuery({
		page,
		limit,
	});

	const { item, resetItem, getItem } = useItem<Diplome>();

	const actionFormatter = (cell: string, row: Diplome) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonEdit
						data-bs-toggle="modal"
						data-bs-target="#AddDiplomeModal"
						onClick={() => getItem(row)}
					/>
					<DeleteDiplome item={row} />
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: "Titre",
		},
		{
			dataField: "desription",
			text: "Description",
			formatter: (cell: string) =>
				cell ? (
					<div
						dangerouslySetInnerHTML={createMarkup(
							cell?.slice(0, 200)
						)}
					/>
				) : (
					""
				),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: Diplome) =>
				actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() =>
							"Aucune donnée trouvée"
						}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="AddDiplomeModal"
				aria-labelledby="AddDiplomeModalLabel"
				aria-hidden="true"
			>
				<AddDiplomeModal item={item} resetItem={resetItem} />
			</div>
		</>
	);
}

export default ListeDiplomesTable;
