import React from "react";
import FormEchange from "./Gains/FormEchange";
import FormGain from "./Gains/FormGain";

const Gains = () => {
	return (
		<div className="container-gains pt-5">
			<div className="row">
				<div className="col-lg-6 gain-bloc pb-4">
					<h3 className="gain-bloc_title">Gains</h3>
					<FormGain />
				</div>
				<div className="col-lg-6">
					<h3 className="gain-bloc_title">Echange</h3>
					<FormEchange />
				</div>
			</div>
		</div>
	);
};

export default Gains;
