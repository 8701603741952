import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { cleannerError, getName, onHide } from "../../../../../utils/utils";

import { QueryError } from "../../../../../utils/type";
import { toast } from "react-hot-toast";
import {
	AbonnementFormData,
	IAbonnements,
} from "../../../../../utils/api/abonnement/abonnement.type";
import { useGetPatientsQuery } from "../../../../../utils/api/user/user.api";
import { useAddOrEditAbonnementMutation } from "../../../../../utils/api/abonnement/abonnement.api";
import { useGetOffresQuery } from "../../../../../utils/api/offres/offres.api";
import moment from "moment";
import { useGetModePaiementQuery } from "../../../../../utils/api/config/config.api";
import { useAppSelector } from "../../../../../redux/hooks";

function useCrudAbonnement(resetItem: () => void, item?: IAbonnements) {
	const validationSchema = yup.object().shape({
		user: yup
			.number()
			.required()
			.label("Patient")
			.typeError("Patient est un champ obligatoire"),
		offre: yup
			.number()
			.required()
			.label("Offre")
			.typeError("Offre est un champ obligatoire"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<AbonnementFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addSubscription, { isLoading }] = useAddOrEditAbonnementMutation();
	const { data = { results: [] } } = useGetPatientsQuery({});
	const { data: Offres = { results: [] } } = useGetOffresQuery({});
	const { data: modes = { results: [] } } = useGetModePaiementQuery({});

	const { user } = useAppSelector((s) => s?.user);
	const [inputValue, setInputValue] = useState("");
	const [options, setOptions] = useState<{ label: string; value: number }[]>([]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	// useEffect(() => {
	// 	if (item?.id) {
	// 		let fields: (keyof AbonnementFormData)[] = [
	// 			"title",
	// 			"price",
	// 			"description",
	// 			"details",
	// 			"frequence",
	// 			"option",
	// 			"prix_rattachement_adulte",
	// 			"prix_rattachement_enfant",
	// 			"taux_couverture_soins_esthetique",
	// 			"taux_couverture_soins_ordonnance",
	// 			"taux_hospitalisation",
	// 		];
	// 		for (let field of fields) {
	// 			register(field);
	// 			setValue(field, item[field]);
	// 		}
	// 		setDescription(item?.details);
	// 	}
	// }, [item]);

	const handleChange = (selectedOption) => {
		setValue("user", selectedOption?.value);
	};
	const handleSelectOffer = (selectedOption) => {
		setValue("offre", selectedOption?.value);
	};
	const handleInputChange = (newValue) => {
		setInputValue(newValue);
	};

	const filterOptions = (option, inputValue) => {
		return option.label.toLowerCase().includes(inputValue.toLowerCase());
	};

	const loadOptions = (inputValue) => {
		if (inputValue.length < 2) {
			let options = data?.results?.map((item) => {
				return {
					label: getName(item),
					value: item?.id,
				};
			});
			setOptions(options);
			return;
		}

		setTimeout(() => {
			const filteredOptions = options.filter((option) =>
				filterOptions(option, inputValue)
			);
			setOptions(filteredOptions);
		}, 300);
	};

	const onSubmit = async (data: AbonnementFormData) => {
		const mode = modes?.results?.filter((item) => item?.nom === "cash")[0];

		const newData: AbonnementFormData = {
			...data,
			date_debut: moment(new Date()).format("YYYY-MM-DDThh:mm"),
			moyen_paiement: mode?.id,
			created_by: user?.id,
		};
		console.log({ newData });

		const res = await addSubscription({
			data: newData,
		});

		if ("data" in res) {
			const msg = `Abonnement ${!item ? "ajouté" : "modifié"} avec succès`;
			console.log("data", data);
			toast.success(msg);
			reset();
			resetItem();
			onHide("AddAbonnementModal");
			setInputValue("");
			setOptions([]);
		}
		if ("error" in res) {
			const err = res.error as QueryError;
			let mes = err?.data?.message
				? err?.data?.message
				: `Erreur de statut ${err?.status}`;
			toast.error(mes);
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading: isLoading,
		patients: data?.results?.map((item) => {
			return {
				label: getName(item),
				value: item?.id,
			};
		}),
		handleChange,
		filterOptions,
		inputValue,
		handleInputChange,
		loadOptions,
		options,
		offres: Offres?.results?.map((item) => {
			return {
				label: item?.title,
				value: item?.id,
			};
		}),
		modes: modes?.results?.map((item) => {
			return {
				label: item?.titre,
				value: item?.id,
			};
		}),
		modeValue: modes?.results?.map((item) => {
			if (item?.nom === "cash") {
				return {
					label: item.titre,
					value: item.id,
				};
			}
		}),
		handleSelectOffer,
	};
}

export default useCrudAbonnement;
