/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Color } from "./theme";
import { IQuery, QueryError } from "./type";
import toast from "react-hot-toast";

export function useLocationState<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: T | any,
  redirectToWhenNull: string | null = null
): T {
  const state = useLocation().state as T;
  const navigate = useNavigate();
  useEffect(() => {
    if (!state && redirectToWhenNull) {
      navigate(redirectToWhenNull);
    }
  }, [state]);
  return state || defaultValue;
}

export function useModal<T>() {
  const [isShowModal, setIsShowModal] = useState(false);
  const toggle = () => setIsShowModal((isShowModal) => !isShowModal);
  const [item, setItem] = useState<T>();
  const openModal = () => {
    setItem(undefined);
    toggle();
  };
  const openEditModal = (item?: T) => {
    setItem(item);
    toggle();
  };
  const getItem = (item: T) => {
    setItem(item);
  };
  const resetItem = () => {
    setItem(undefined);
  };
  return {
    isShowModal,
    toggle,
    openModal,
    openEditModal,
    closeModal: openModal,
    item,
    getItem,
    resetItem,
  };
}

export function useItem<T>() {
  const [item, setItem] = useState<T>();
  const getItem = (item?: T) => {
    setItem(item);
  };
  const resetItem = () => {
    setItem(undefined);
  };
  return {
    item,
    getItem,
    resetItem,
  };
}

export function useSearch() {
  const [search, setSearch] = useState<string>();
  const handlefilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    let target = e.currentTarget.value;
    if (target?.length > 2) {
      setSearch(e.currentTarget.value);
    } else {
      setSearch("");
    }
  };
  const reset = () => {
    setSearch("");
  };
  return {
    search,
    handlefilter,
    reset,
  };
}
export function useSelectSearch() {
  const [search, setSearch] = useState<string>();
  const handlefilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearch(e.currentTarget.value);
  };
  return {
    search,
    handlefilter,
  };
}

export function usePagination(initialPerPageValue: number) {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(initialPerPageValue);
  return {
    page,
    setPage,
    limit: perPage,
    setPerPage,
    perPage,
  };
}

export function useDelete<T>(props: IQuery<T>) {
  const {
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question,
    successMessage,
    redirectUrl,
  } = props;
  const navigate = useNavigate();
  const onDelete = useCallback(async () => {
    Swal.fire({
      title: `Êtes vous sûr de vouloir supprimer ${question}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: true,
      iconColor: Color.danger,
      confirmButtonColor: Color.danger,
      preConfirm: () => {
        deleteItem(item?.slug);
      },
    });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`${successMessage} avec succès!`);
      if (redirectUrl) {
        navigate(redirectUrl);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as QueryError;
      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);

  return onDelete;
}
export function useDeleteConfirme<T>(props: {
  item: T | any;
  deleteItem: any;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  question: string;
  successMessage: string;
}) {
  const { item, deleteItem, isSuccess, isError, error, question } = props;
  const onDelete = useCallback(() => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ${question} `,
      icon: "question",
      input: "password",
      inputLabel: "Mettre votre mot de passe pour confirmer .",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: true,
      iconColor: Color.danger,
      confirmButtonColor: Color.danger,
      inputPlaceholder: "Mot de passe",
      preConfirm: (inputValue) => {
        console.log("dee", inputValue);
        if (inputValue) {
          // let fd = new FormData();
          // fd.append("password", inputValue);
          return deleteItem({
            slug: item.slug,
            data: { password: inputValue },
          });
        } else {
          Swal.showValidationMessage(
            `le mot de passe est obligatoire pour la suppression`
          );
        }
      },
    });
  }, []);
  useEffect(() => {
    if (isSuccess) {
      toast.success(`Supprimé avec succès!`);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as any;
      if (err?.status == 400) {
        Swal.fire({
          icon: "error",
          title: `Votre mot de passe est incorrecte !`,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: `Une erreur de status ${err?.status} est survenue!`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  }, [isError]);

  return onDelete;
}

export const useToggle = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleMenu = () => setIsOpen((isOpen) => !isOpen);

  return {
    isOpen,
    toggleMenu,
  };
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useCountdownTimer(minutes: number) {
  const [timeLeft, setTimeLeft] = useState(minutes * 60); // Convert minutes to seconds
  const [isTimeActive, setIsTimeActive] = useState(false);

  function startTimer() {
    setIsTimeActive(true);
  }

  function stopTimer() {
    setIsTimeActive(false);
    setTimeLeft(minutes * 60);
  }

  useEffect(() => {
    let intervalId;
    if (isTimeActive) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isTimeActive]);

  useEffect(() => {
    if (timeLeft === 0) {
      stopTimer();
    }
  }, [timeLeft]);

  const minutesLeft = Math.floor(timeLeft / 60);
  const secondsLeft = timeLeft % 60;

  return {
    minutesLeft,
    secondsLeft,
    timeLeft,
    stopTimer,
    startTimer,
    isTimeActive,
  };
}

export function useDeleteWithCode<T>(props: {
  item: T | any;
  deleteItem: any;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  title: string;
  successMessage: string;
  sendCode: any;
}) {
  const {
    item,
    sendCode,
    deleteItem,
    isSuccess,
    isError,
    error,
    successMessage,
  } = props;
  const { isTimeActive, stopTimer, startTimer } = useCountdownTimer(2);

  function Popup() {
    Swal.fire({
      title: "Entrez le code que vous avez reçu par mail",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Fermer",
      showLoaderOnConfirm: true,
      focusCancel: true,
      iconColor: Color.blue,
      confirmButtonColor: Color.danger,
      input: "password",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off",
      },
      allowOutsideClick: false,
      preConfirm: (inputValue) => {
        if (inputValue) {
          return deleteItem({
            slug: item.slug,
            data: { code: inputValue },
          });
        }
      },
    });
  }
  const onDelete = useCallback(async () => {
    if (isTimeActive) {
      Popup();
    } else {
      const res = await sendCode();
      if ("data" in res) {
        startTimer();
        Popup();
      } else {
        toast.error("Erreur d'envoie de code");
      }
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success(successMessage);
      stopTimer();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as QueryError;
      const message =
        err?.data?.message ??
        `Une erreur de status ${err?.status} est survenue!`;
      toast.error(message);
    }
  }, [isError]);

  return onDelete;
}

export const useActive = (active: number) => {
  const [isActive, setIsActive] = useState<number>(active);
  const handleClick = (active: number) => setIsActive(active);

  return {
    isActive,
    handleClick,
  };
};

export function useTogglePassword() {
  const [showPassword, setShowPassword] = useState<boolean>();
  const toggleShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };
  return {
    showPassword,
    toggleShowPassword,
  };
}
