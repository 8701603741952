import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { validatePassword } from "../../../../auth/hooks/useRegister";
import { ChangePasswordData } from "../../../../../utils/api/auth/auth.type";
import { useChangePasswordMutation } from "../../../../../utils/api/user/user.api";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { toast } from "react-hot-toast";

function useChangePassword() {
	const validationSchema = yup.object().shape({
		old_password: yup.string().required().label("L'ancien mot de passe"),
		new_password: validatePassword(yup, "Le mot de passe est un champ obligatoire"),
		confirm_password: yup
			.string()
			.oneOf(
				[yup.ref("new_password"), null],
				"veuillez entrer des mots de passe identiques."
			),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setError,
		reset,
		setValue,
	} = useForm<ChangePasswordData>({
		resolver: yupResolver(validationSchema),
	});

	const [changePassword, { isLoading }] = useChangePasswordMutation();

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	const onSubmit = async (data: ChangePasswordData) => {
		const res = await changePassword(data);
		if ("data" in res) {
			toast.success("Mot de passe modifiée avec succès!");
			reset();
			onHide("ChangePasswordModal");
		} else if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			console.log({ err });
			if (err?.data?.old_password?.includes("Wrong password.")) {
				setError("old_password", {
					message: "Le mot de passe est incorrect!",
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur a survenue lors de la modification",
					showConfirmButton: false,
					timer: 3000,
				});
			}
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		setValue,
	};
}

export default useChangePassword;
