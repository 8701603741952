import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Table.css";
import { useDeleteWithCode, useItem, usePagination, useSearch } from "../../../../../utils/hooks";
import {
	useDeleteUserMutation,
	useGetPatientsQuery,
	useLazySendDeleteUserCodeQuery,
	useToggleActiveUserMutation,
} from "../../../../../utils/api/user/user.api";
import { CustomPagination } from "../../../../common/CustomPagination";
import { formattedDate, isShowPagination } from "../../../../../utils/utils";
import { IUser } from "../../../../../utils/api/user/user.type";
import { ButtonDelete, ButtonEdit, ButtonViewLink, Spinner } from "../../../../common/Button";
import TableSkeleton from "../../../../common/TableSkeleton";
import AddUserModal from "../../../../modal/admin/AddUserModal";
import { FaLock, FaUnlock } from "react-icons/fa";
import { Color } from "../../../../../utils/theme";
import { useDeleteMultipleUsers, useToggleArchiveUser } from "../hooks/useAddOrEditUser";
import Switch from "react-switch";
import Swal from "sweetalert2";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
import { InputFilter } from "../../../../common/Input";
registerLocale("fr", fr);

export function DeleteUser({ item }: { item: IUser }) {
	const [sendCode, { isLoading }] = useLazySendDeleteUserCodeQuery();

	const [deleteItem, { isSuccess, isError, error }] = useDeleteUserMutation();
	const onDelete = useDeleteWithCode<IUser>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		sendCode,
		title: "Suppression",
		successMessage: "Supprimé avec succès !",
	});
	if (isLoading) return <Spinner />;
	return <ButtonDelete onClick={onDelete} />;
}

export function ToggleArchiveUser({ item }: { item: IUser }) {
	const toggleArchive = useToggleArchiveUser(item);
	return (
		<button
			className={`btn with-tooltip btn-action-modal-icon ${item?.is_archive ? "tooltip-red" : ""}`}
			data-tooltip-content={item?.is_archive ? "Désarchiver" : "Archiver"}
			onClick={toggleArchive}
			style={{ color: !item?.is_archive ? Color.success : Color.danger }}
		>
			{!item?.is_archive ? <FaUnlock /> : <FaLock />}
		</button>
	);
}

export function DeleteMultiple({ users, reset }: { users: number[]; reset: () => void }) {
	const { onDelete, isLoading } = useDeleteMultipleUsers(users, reset);
	if (isLoading) return <Spinner />;
	return (
		<button onClick={onDelete} className="btn btn-danger px-2 py-1" style={{ fontWeight: 500 }}>
			Supprimer
		</button>
	);
}

function ListeUsersTable() {
	const [date, setDate] = useState<Date | null>(null);
	const [selected, setSelected] = useState<number[]>([]);
	const [disableUser] = useToggleActiveUserMutation();
	const { page, setPage, perPage, setPerPage } = usePagination(10);
	const { search: nom, handlefilter } = useSearch();
	const { search: matricule, handlefilter: handlefilterMatricule } = useSearch();
	const { search: prenom, handlefilter: handleFilterByPrenom } = useSearch();
	const { search: email, handlefilter: handleFilterByEmail } = useSearch();
	const { search: telephone, handlefilter: handleFilterByPhone } = useSearch();

	const { data = { results: [] }, isLoading } = useGetPatientsQuery({
		page,
		limit: perPage,
		matricule,
		nom,
		prenom,
		email,
		telephone,
		date: date !== null ? moment(date).format("YYYY-MM-DD") : null,
	});

	const { item, getItem, resetItem } = useItem<IUser>();

	const actionFormatter = (cell: string, row: IUser) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonViewLink to={`/admin/utilisateur/${row?.slug}`} state={row} />
					<ButtonEdit data-bs-toggle="modal" data-bs-target="#addUserModal" onClick={() => getItem(row)} />
					<DeleteUser item={row} />
					<ToggleArchiveUser item={row} />
				</div>
			</>
		);
	};

	const onChange = async (item: IUser) => {
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${item?.is_active === true ? "désactivé" : "activé"} ce patient ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: !item?.is_active ? Color.success : Color.danger,
			confirmButtonColor: !item?.is_active ? Color.success : Color.danger,
			preConfirm: () => {
				return disableUser({ slug: item?.slug, is_active: item?.is_active });
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			console.log(result, "valider");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Patient ${result?.value?.data?.is_active ? "activé " : "désactivé"} avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};

	const statutFormatter = (cell, row) => {
		return (
			<Switch
				checked={cell}
				handleDiameter={18}
				height={18}
				width={42}
				offHandleColor="#C4C4C4"
				onHandleColor="#C4C4C4"
				borderRadius={2}
				uncheckedIcon={<div className="toggle-style-off toggle-style">OFF</div>}
				checkedIcon={<div className="toggle-style-on toggle-style">ON</div>}
				className="react-switch"
				id="small-radius-switch"
				onChange={() => onChange(row)}
			/>
		);
	};

	const columns = [
		{
			dataField: "matricule",
			text: "Matricule",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="matricule" placeholder="Matricule" onChange={handlefilterMatricule} />,
		},
		{
			dataField: "prenom",
			text: "Prénom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="prenom" placeholder="Prénom" onChange={handleFilterByPrenom} />,
		},
		{
			dataField: "nom",
			text: "Nom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="nom" placeholder="Nom" onChange={handlefilter} />,
		},
		{
			dataField: "email",
			text: "Email",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="email" placeholder="Email" onChange={handleFilterByEmail} />,
		},
		{
			dataField: "telephone",
			text: "Téléphone",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="tel" placeholder="Téléphone" onChange={handleFilterByPhone} />,
		},
		{
			dataField: "created_at",
			text: "Date d'inscription",
			formatter: (cell: string) => formattedDate(cell),
			filter: customFilter(),
			filterRenderer: () => (
				<div className="date-picker-container">
					<DatePicker
						className="form-control tab-dash-admin-filter-input"
						selected={date}
						onChange={(date: Date) => setDate(date)}
						maxDate={new Date()}
						dateFormat="dd/MM/yyyy"
						placeholderText="Choisir une date"
						isClearable
						locale="fr"
					/>
				</div>
			),
		},
		{
			dataField: "is_active",
			text: "Statut",
			formatter: (cell: string, row: IUser) => statutFormatter(cell, row),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IUser) => actionFormatter(cell, row),
		},
	];

	const handleOnSelect = (row, isSelect) => {
		if (isSelect) {
			setSelected([...selected, row.id]);
		} else {
			setSelected(selected.filter((x) => x !== row.id));
		}
	};

	const handleOnSelectAll = (isSelect, rows) => {
		const ids = rows.map((r) => r.id);
		if (isSelect) {
			setSelected(ids);
		} else {
			setSelected([]);
		}
	};

	const selectRow = {
		mode: "checkbox",
		clickToSelect: false,
		onSelect: handleOnSelect,
		onSelectAll: handleOnSelectAll,
	};

	const reset = () => setSelected([]);

	return (
		<>
			{selected?.length > 1 && (
				<div className="flex-r">
					<DeleteMultiple users={selected} reset={reset} />
				</div>
			)}
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						selectRow={selectRow}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, perPage) => {
								setPerPage(perPage);
								setPage(page);
							}}
							perPage={perPage}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="addUserModal"
				data-bs-backdrop="static"
				aria-labelledby="addUserModalLabel"
				aria-hidden="true"
			>
				<AddUserModal resetItem={resetItem} item={item} />
			</div>
		</>
	);
}

export default ListeUsersTable;
