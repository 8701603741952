import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DetailTransactionModal from "../../../modal/admin/DetailTransactionModal";
import { useItem, usePagination, useSearch, useSelectSearch } from "../../../../utils/hooks";
import { useGetTransactionQuery } from "../../../../utils/api/transaction/transaction.api";
import TableSkeleton from "../../../common/TableSkeleton";
import { formatMontant, formattedDateTime, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ITransaction, TranMotifType, TransactionType } from "../../../../utils/api/transaction/transaction.type";
import { ButtonViewLink } from "../../../common/Button";
import { HiDocumentText } from "react-icons/hi";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
import { InputFilter } from "../../../common/Input";
import { SelectInputFilter } from "../../../common/SelectInput";
registerLocale("fr", fr);

const motifs = [
	{
		label: "Dépôt",
		value: TranMotifType.depot,
	},
	{
		label: "Paiement",
		value: TranMotifType.paiement,
	},
	{
		label: "Retrait",
		value: TranMotifType.retrait,
	},
	{
		label: "Remboursement",
		value: TranMotifType.remboursement,
	},
];

const types = [
	{
		label: "Débit",
		value: TransactionType.in,
	},
	{
		label: "Crédit",
		value: TransactionType.out,
	},
];

function ListeTransactionsTable() {
	const [date, setDate] = useState<Date | null>(null);
	const { search: expediteur, handlefilter } = useSearch();
	const { search: beneficiaire, handlefilter: handlefilterBeneficiaire } = useSearch();
	const { search: transaction_id, handlefilter: handlefilterByRef } = useSearch();
	const { search: montant, handlefilter: handlefilterByMontant } = useSearch();
	const { search: motif, handlefilter: handlefilterMotif } = useSelectSearch();
	const { search: type, handlefilter: handlefilterType } = useSelectSearch();
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetTransactionQuery({
		page,
		limit,
		expediteur,
		beneficiaire,
		transaction_id,
		value: montant,
		motif,
		type,
		date: date !== null ? moment(date).format("YYYY-MM-DD") : null,
	});

	const { item, getItem } = useItem<ITransaction>();
	// console.log({ data });

	const actionFormatter = (cell: string, row: ITransaction) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonViewLink to={`${row?.slug}`} state={row} />
					<div className="container-btn-action-icon-modal">
						<button
							className="btn with-tooltip btn-action-modal-icon"
							data-tooltip-content="Pièce jointe"
							data-bs-toggle="modal"
							data-bs-target="#DetailTransactionModal"
							style={{ color: "rgb(22 173 139)", fontSize: 26 }}
							onClick={() => getItem(row)}
						>
							<HiDocumentText />
						</button>
					</div>
				</div>
			</>
		);
	};
	const motifFormatter = (cell: string) => {
		return <span className="text-capitalize">{cell === TranMotifType.depot ? "dépôt" : cell}</span>;
	};
	const expediteurFormatter = (cell: string, row: ITransaction) => {
		if (row?.sender) return row?.sender?.prenom + " " + row?.sender?.nom;
		return <span className="text-impremerie">&#10077;from service</span>;
	};

	const columns = [
		{
			dataField: "transaction_id",
			text: "Référence",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="transaction_id" placeholder="Référence" onChange={handlefilterByRef} />,
		},
		{
			dataField: "sender",
			text: "Expéditeur",
			formatter: (cell: string, row: ITransaction) => expediteurFormatter(cell, row),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="sender" placeholder="Expéditeur" onChange={handlefilter} />,
		},
		{
			dataField: "receiver",
			text: "Bénéficiaire",
			formatter: (cell: string, row: ITransaction) => row?.receiver?.prenom + " " + row?.receiver?.nom,
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="receiver" placeholder="Bénéficiaire" onChange={handlefilterBeneficiaire} />,
		},
		{
			dataField: "created_at",
			text: "Date ",
			formatter: (cell: string) => formattedDateTime(cell),
			filter: customFilter(),
			filterRenderer: () => (
				<div className="date-picker-container">
					<DatePicker
						className="form-control tab-dash-admin-filter-input"
						selected={date}
						onChange={(date: Date) => setDate(date)}
						maxDate={new Date()}
						dateFormat="dd/MM/yyyy"
						placeholderText="Choisir une date"
						isClearable
						locale="fr"
					/>
				</div>
			),
		},
		{
			dataField: "value",
			text: "Montant total",
			formatter: (cell: string) => formatMontant(cell),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="matricule" placeholder="Matricule" onChange={handlefilterByMontant} />,
		},
		{
			dataField: "motif",
			text: "Motif",
			formatter: (cell: string) => motifFormatter(cell),
			filter: customFilter(),
			filterRenderer: () => (
				<SelectInputFilter id="motif" placeholder="Tous les motifs" onChange={handlefilterMotif} options={motifs} />
			),
		},
		{
			dataField: "type",
			text: "Type",
			formatter: (cell: string) => (cell === "IN" ? "Débit" : "Crédit"),
			filter: customFilter(),
			filterRenderer: () => (
				<SelectInputFilter id="motif" placeholder="Tous les types" onChange={handlefilterType} options={types} />
			),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: ITransaction) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div className="modal fade" id="DetailTransactionModal" aria-labelledby="DetailTransactionModalLabel" aria-hidden="true">
				<DetailTransactionModal item={item} />
			</div>
		</>
	);
}

export default ListeTransactionsTable;
