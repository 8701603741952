import React from "react";
import { IoMdAdd } from "react-icons/io";
import SocialMedia from "../ParametreAdmin/SocialMedia";
import FaqAdmin from "../ParametreAdmin/Faq/FaqAdmin";
import Candidatures from "../Candidatures/Candidatures";
import GestionBanner from "./GestionBanner";
import GestionAbout from "./GestionAbout";
import GestionJoinUs from "./GestionJoinUs";

const GestionLandingPage = () => {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="crypta-dash-locataire-requetes-header mb-4">
					<h4 className="crypta-dash-header-titre">Landing page</h4>
				</div>
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<ul
						className="nav nav-tabs nav-tabs-setting"
						id="myTab"
						role="tablist"
					>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting active"
								id="admins-tab"
								data-bs-toggle="tab"
								data-bs-target="#admins"
								type="button"
								role="tab"
								aria-controls="admins"
								aria-selected="true"
							>
								Candidatures
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="faq-tab"
								data-bs-toggle="tab"
								data-bs-target="#faq"
								type="button"
								role="tab"
								aria-controls="faq"
								aria-selected="false"
							>
								Faqs
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="social-tab"
								data-bs-toggle="tab"
								data-bs-target="#social"
								type="button"
								role="tab"
								aria-controls="social"
								aria-selected="false"
							>
								Contacts
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="banner-tab"
								data-bs-toggle="tab"
								data-bs-target="#banner"
								type="button"
								role="tab"
								aria-controls="banner"
								aria-selected="false"
							>
								Bannière
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="about-tab"
								data-bs-toggle="tab"
								data-bs-target="#about"
								type="button"
								role="tab"
								aria-controls="about"
								aria-selected="false"
							>
								À propos
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="joinUs-tab"
								data-bs-toggle="tab"
								data-bs-target="#joinUs"
								type="button"
								role="tab"
								aria-controls="joinUs"
								aria-selected="false"
							>
								Nous rejoindre
							</button>
						</li>
					</ul>
				</div>
				<div className="col-left-dashboard-responsive">
					<div className="tab-content " id="myTabContent">
						<div
							className="tab-pane fade show active"
							id="admins"
							role="tabpanel"
							aria-labelledby="admins-tab"
						>
							<div className="">
								<Candidatures />
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="faq"
							role="tabpanel"
							aria-labelledby="faq-tab"
						>
							<div className="content-btn-add-theme flex-r mb-4">
								<button
									className="btn btn-add-theme"
									data-bs-toggle="modal"
									data-bs-target="#AddEditFaqModal"
								>
									<IoMdAdd />
									<span className="ps-2">Ajouter</span>
								</button>
							</div>
							<FaqAdmin />
						</div>
						<div
							className="tab-pane fade"
							id="social"
							role="tabpanel"
							aria-labelledby="social-tab"
						>
							<SocialMedia />
						</div>
						<div
							className="tab-pane fade"
							id="banner"
							role="tabpanel"
							aria-labelledby="banner-tab"
						>
							<GestionBanner />
						</div>
						<div
							className="tab-pane fade"
							id="about"
							role="tabpanel"
							aria-labelledby="about-tab"
						>
							<GestionAbout />
						</div>
						<div
							className="tab-pane fade"
							id="joinUs"
							role="tabpanel"
							aria-labelledby="joinUs-tab"
						>
							<GestionJoinUs />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GestionLandingPage;
