/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCreateOrderMutation } from "../../../utils/api/order/order.api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { OrderFormData } from "../../../utils/api/order/order.type";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { cleannerError, validatePhone } from "../../../utils/utils";

function usePreOrderForm(navigation, offre, setInfosUserIsOpen) {
	const [code, setCode] = useState<string>("")
  const validationSchema = yup.object().shape({
    step: yup.number().nullable(),
    nom: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Nom"),
    }),
    prenom: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Prenom"),
    }),
    telephone: yup.string().when("step", {
      is: 1,
      //   then: yup.string().required().label("Téléphone"),
      then: validatePhone(yup, code).required(),
    }),
    email: yup.string().when("step", {
      is: 1,
      then: yup.string().email().required().label("Email"),
    }),
    lieu_de_naissance: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Lieu de naissance"),
    }),
    moyen_paiement: yup.number().when("step", {
      is: 2,
      then: yup
        .number()
        .required("Vous devez choisir un mode de paiement")
        .typeError("Vous devez choisir un mode de paiement"),
    }),
    condition: yup.boolean().when("step", {
      is: 3,
      then: yup
        .boolean()
        .required()
        .label("Cgu")
        .typeError("Vous devez accepter les conditions d'utilisations"),
    }),
    date_de_naissance: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Date de naissance"),
    }),
    nombre_enfants_moins_5_ans: yup
      .number()
      .typeError("Nombre d'enfant de moins de 5 ans doit être de type number")
      .label("Nombre d'enfants"),
    enfant_moins_de_5_ans: yup
      .boolean()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire"),
    civilite: yup.string().label("Civilite"),
    genre: yup.string().label("Genre"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm<OrderFormData>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors, 3000);
    }
  }, [errors]);

  const [addOrder, { isLoading }] = useCreateOrderMutation();

  const [level, setLevel] = useState<number>(0);
  const [dataForm, setDataForm] = useState<OrderFormData>();
  const [phone, setPhone] = useState<string>();
  const [checked, setChecked] = useState<boolean>();

  const handleReset = () => {
    setPhone("");
    reset();
    setLevel(0);
    setDataForm(undefined);
    navigation.go("user-infos");
  };
  const handleChecked = (value: boolean) => {
    setChecked(Boolean(value));
    setValue("enfant_moins_de_5_ans", value);
  };
  const onSubmit = async (data) => {
    setDataForm(data);
    // console.log({ data });
    console.log(data?.step);
    const firstName = data.prenom;
    const lastName = data.nom;
    const regex = /^[a-zA-Z{é, è, â, ä, ô, ö, _, ï, î} ]{2,}$/;

    switch (data?.step) {
      case 1:
        if (!regex.test(firstName)) {
          return setError("prenom", {
            message:
              "Prénom doit être au minimum 2 lettres et ne doit contenir que des lettres",
          });
        } else if (!regex.test(lastName)) {
          return setError("nom", {
            message:
              "Nom doit être au minimum 2 lettes et ne doit contenir que des lettres",
          });
        } else if (data.civilite === "") {
          return setError("civilite", {
            message: "Civilité ne doit pas être vide",
          });
        } else if (data.genre === "") {
          return setError("genre", {
            message: "Genre ne doit pas être vide",
          });
        } else if (data.condition === false) {
          return setError("condition", {
            message: "Vous devez accepter les conditions d'utilisations.",
          });
        } else {
          setDataForm(data);
          return navigation.go("mode-paiement");
        }
        break;
      case 2:
        setDataForm(data);
        return navigation.go("confirm-pre-commande");
        break;

      default:
        if (!data?.condition) {
          return setError("condition", {
            message: "Vous devez accepter les conditions d'utilisations",
          });
        }
        setTimeout(() => setInfosUserIsOpen(false));

        // const order: OrderFormData = {
        // 	email: data.email,
        // 	nom: data.nom,
        // 	prenom: data.prenom,
        // 	telephone: data.telephone,
        // 	civilite: data.civilite,
        // 	genre: data.genre,
        // 	nombre_personnes_en_charge: data.nombre_personnes_en_charge,
        // 	nombre_enfants_moins_5_ans: data.nombre_enfants_moins_5_ans,
        // 	enfant_moins_de_5_ans: data?.enfant_moins_de_5_ans,
        // 	env: window.origin,
        // 	page: "/",
        // 	price: offre?.price,
        // 	offre: offre?.id,
        // 	moyen_paiement: Number(data?.moyen_paiement),
        // 	date_de_naissance: data.date_de_naissance,
        // 	lieu_de_naissance: data.lieu_de_naissance,
        // 	condition: data.condition,
        // };
        // const res: any = await addOrder(order);
        // if (res?.data) {
        // 	window.location.replace(`${res?.data.url}`);
        // } else {
        // 	const err = res.error as any;
        // 	Swal.fire({
        // 		icon: "error",
        // 		title: err?.data?.email
        // 			? err?.data?.email
        // 			: err?.data?.telephone
        // 			? err?.data?.telephone
        // 			: `Erreur de statut ${err?.status}`,
        // 		confirmButtonText: "OK",
        // 		confirmButtonColor: "#0FBF56",
        // 	}).then(() => handleReset());
        // }

        Swal.fire({
          width: "22rem",
          icon: "success",
          iconColor: "#0FBF56",
          html: "Confirmer votre pré commande",
          confirmButtonText: "Oui je confirme",
          confirmButtonColor: "#0FBF56",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const order: OrderFormData = {
              email: data.email,
              nom: data.nom,
              prenom: data.prenom,
              telephone: data.telephone,
              civilite: data.civilite,
              genre: data.genre,
              nombre_personnes_en_charge: data.nombre_personnes_en_charge,
              nombre_enfants_moins_5_ans: data.nombre_enfants_moins_5_ans,
              enfant_moins_de_5_ans: data?.enfant_moins_de_5_ans,
              env: window.origin,
              page: "/",
              price: offre?.price,
              offre: offre?.id,
              moyen_paiement: Number(data?.moyen_paiement),
              date_de_naissance: data.date_de_naissance,
              lieu_de_naissance: data.lieu_de_naissance,
              condition: data.condition,
              code_postal: data?.code_postal,
            };
            const res: any = await addOrder(order);

            if (res?.data?.url) {
              console.log(res);
              window.location.replace(`${res?.data.url}`);
            } else if (res?.data?.wave_launch_url) {
              window.location.replace(`${res?.data?.wave_launch_url}`);
            } else {
              const err = res.error as any;
              Swal.fire({
                icon: "error",
                title: err?.data?.email
                  ? err?.data?.email
                  : err?.data?.telephone
                  ? err?.data?.telephone
                  : `Erreur de statut ${err?.status}`,
                confirmButtonText: "OK",
                confirmButtonColor: "#0FBF56",
              }).then(() => handleReset());
            }
          },
          showCancelButton: true,
          cancelButtonText: "Annuler",
          cancelButtonColor: "#D90000",
          reverseButtons: true,
          allowOutsideClick: () => !Swal.isLoading(),
        });
    }
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone,
    setPhone,
    reset,
    handleReset,
    level,
    dataForm,
    setLevel,
    setDataForm,
    checked,
    handleChecked,
	setCode
  };
}

export default usePreOrderForm;
