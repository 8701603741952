import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import {
	IContact,
	IResponseContact,
	MessageQuery,
	TContactForm,
	TEditContactForm,
	TResponseContact,
} from "./contact.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl } from "../../utils";
import { PaginationResults, TypeQuery } from "../user/user.type";

export const ContactApi = createApi({
	reducerPath: "contactApi",
	tagTypes: ["contact", "response"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		addContact: builder.mutation<IContact, TContactForm>({
			invalidatesTags: ["contact"],
			query: (data) => {
				return {
					url: `contact/add/`,
					method: "POST",
					body: data,
				};
			},
		}),
		getContactMessages: builder.query<IContact[], MessageQuery>({
			query: (query) => QueryUrl(`contacts/`, query),
			providesTags: ["contact"],
			transformResponse: ({ results }) => results,
		}),
		getResponsesContactMessages: builder.query<
			PaginationResults<IResponseContact>,
			TypeQuery
		>({
			query: ({ slug, ...query }) => QueryUrl(`contact/${slug}/responses`, query),
			providesTags: ["contact", "response"],
		}),
		updateContact: builder.mutation<
			IContact,
			{ slug?: string; data: TEditContactForm | FormData }
		>({
			invalidatesTags: ["contact"],
			query: ({ slug, data }) => {
				return {
					url: `contacts/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		deleteContact: builder.mutation<IContact, string>({
			query: (slug) => ({
				url: `contact/add/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["contact"],
		}),
		responseContact: builder.mutation<IContact, TResponseContact>({
			invalidatesTags: ["response", "contact"],
			query: (data) => {
				return {
					url: `contact/responses/`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useGetContactMessagesQuery,
	useAddContactMutation,
	useDeleteContactMutation,
	useUpdateContactMutation,
	useResponseContactMutation,
	useGetResponsesContactMessagesQuery,
} = ContactApi;
