import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/utils";
import {
	IOffres,
	OffreFormData,
} from "../../../../../utils/api/offres/offres.type";
import {
	useAddOrEditOffreMutation,
	useLazySendEditOfferCodeQuery,
} from "../../../../../utils/api/offres/offres.api";
import { QueryError } from "../../../../../utils/type";
import { toast } from "react-hot-toast";
import { Color } from "../../../../../utils/theme";
import { useCountdownTimer } from "../../../../../utils/hooks";

function useCrudOffer(resetItem: () => void, item?: IOffres) {
	const [description, setDescription] = React.useState<string>("");
	const validationSchema = yup.object().shape({
		title: yup.string().required().label("Nom  de l’abonnement"),
		price: yup.string().required().label("Prix"),
		option: yup.string().required().label("Type d'abonnement"),
		frequence: yup
			.string()
			.required()
			.label("Fréquence")
			.typeError("Fréquence est un champ obligatoire"),
		details: yup
			.string()
			.required()
			.label("Details")
			.transform((val) => (val === "<p><br></p>" ? "" : val)),
		prix_rattachement_adulte: yup
			.string()
			.required()
			.label("Prix rattachement adulte"),
		prix_rattachement_enfant: yup
			.string()
			.required()
			.label("Prix rattachement enfant"),
		taux_couverture_soins_esthetique: yup
			.number()
			.required()
			.label("Taux appareils")
			.typeError("Ce champ doit être un entier")
			.max(100, "Valeur maximale 100"),
		taux_couverture_soins_ordonnance: yup
			.number()
			.required()
			.label("Taux couverture soins ordonnance")
			.typeError("Ce champ doit être un entier")
			.max(100, "Valeur maximale 100"),
		taux_hospitalisation: yup
			.number()
			.required()
			.label("Taux hospitalisation")
			.typeError("Ce champ doit être un entier")
			.max(100, "Valeur maximale 100"),
		plafond_sans_ordonnance: yup
			.number()
			.required()
			.label("Plafond sans ordonnance")
			.typeError("Ce champ doit être un entier"),
		plafond_avec_ordonnance: yup
			.number()
			.required()
			.label("Taux hospitalisation")
			.typeError("Ce champ doit être un entier"),
		position: yup.number().required().label("Position").typeError("Ce champ doit être un entier"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<OffreFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [sendCode, { isLoading: isLoad }] = useLazySendEditOfferCodeQuery();
	const [addOrEditOffre, { isLoading, isSuccess, error, isError, data }] =
		useAddOrEditOffreMutation();
	const { isTimeActive, stopTimer, startTimer } = useCountdownTimer(2);

	useEffect(() => {
		if (isSuccess) {
			const msg = `Offre ${!item ? "ajoutée" : "modifiée"
				} avec succès`;
			console.log("data", data);
			toast.success(msg);
			reset();
			resetItem();
			onHide("AddOffreModal");
			setDescription("");
			stopTimer();
		}
		const err = error as QueryError;
		if (isError) {
			let mes = err?.data?.message
				? err?.data?.message
				: `Erreur de statut ${err?.status}`;
			toast.error(mes);
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			let fields: (keyof OffreFormData)[] = [
				"title",
				"price",
				"description",
				"details",
				"frequence",
				"option",
				"prix_rattachement_adulte",
				"prix_rattachement_enfant",
				"taux_couverture_soins_esthetique",
				"taux_couverture_soins_ordonnance",
				"taux_hospitalisation",
				"plafond_avec_ordonnance",
				"plafond_sans_ordonnance",
				"position",
			];
			for (let field of fields) {
				register(field);
				setValue(field, item[field]);
			}
			setDescription(item?.details);
		}
	}, [item]);

	const handleChange = (value: string) => {
		setDescription(value);
		setValue("details", value);
	};
	function Popup(data: OffreFormData) {
		Swal.fire({
			title: "Entrez le code que vous avez reçu par mail",
			icon: "info",
			showCancelButton: true,
			confirmButtonText: "Modifier",
			cancelButtonText: "Fermer",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.blue,
			confirmButtonColor: Color.success,
			input: "password",
			inputAutoFocus: true,
			inputAttributes: {
				autocapitalize: "off",
			},
			allowOutsideClick: false,
			preConfirm: (inputValue) => {
				if (inputValue) {
					return addOrEditOffre({
						slug: item?.slug,
						data: { ...data, code: inputValue },
					});
				}
			},
		});
	}
	const onSubmit = async (data: OffreFormData) => {
		console.log("data", data);
		if (item) {
			if (isTimeActive) {
				Popup(data);
			} else {
				const res = await sendCode();
				if ("data" in res) {
					onHide("AddOffreModal");
					startTimer();
					Popup(data);
				} else {
					toast.error("erreur d'envoie de de code");
				}
			}
		} else {
			return addOrEditOffre({
				data: data,
			});
		}
		// const newData = { ...data, devise: DeviseType.euro };
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading: isLoad ? isLoad : isLoading,
		description,
		handleChange,
	};
}

export default useCrudOffer;
