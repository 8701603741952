import React from "react";
import ReactQuill from "react-quill";
import { IOffres } from "../../../utils/api/offres/offres.type";
import { BtnSubmit } from "../../common/Button";
import { AdminInput, FormError, RequiredStar } from "../../common/Input";
import CloseModalBtn from "../../shared/CloseModalBtn";
import useCrudOffer from "../../TableauDebord/Admin/Offres/hooks/UseCrudOffer";

const AddOffreModal = ({
	item,
	resetItem,
}: {
	item?: IOffres;
	resetItem: () => void;
}) => {
	const {
		onSubmit,
		register,
		isLoading,
		errors,
		description,
		handleChange,
	} = useCrudOffer(resetItem, item);
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5
						className="admin-modal-title text-start"
						id="changePasswordLabel"
					>
						{item ? "Modifier l'" : "Ajouter une"} offre
					</h5>
					<CloseModalBtn onClick={resetItem} />
				</div>
				<div className="form-container p-3">
					<form onSubmit={onSubmit}>
						<div className="row">
							<div className="col-12 col-md-6">
								<AdminInput
									label="Titre"
									id="title"
									type="text"
									placeholder="Titre"
									{...register("title")}
									error={errors?.title}
									required
								/>
							</div>
							<div className="col-12 col-md-6">
								<AdminInput
									label="Position"
									id="position"
									type="number"
									placeholder="Position"
									{...register("position")}
									error={errors?.position}
									required
								/>
							</div>
							<div className="col-12 col-md-6">
								<AdminInput
									label="Prix"
									id="price"
									type="number"
									placeholder="Prix"
									{...register("price")}
									error={errors?.price}
									required
								/>
							</div>
							<div className="col-12 col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Fréquence
										<span className="text-danger">
											*
										</span>
									</label>
									<select
										className="form-control form-control-modal-custom"
										id="name"
										{...register(
											"frequence"
										)}
									>
										<option value="">
											Choisir la
											fréquence
										</option>
										<option value="DAY">
											Jour
										</option>
										<option value="MONTH">
											Mois
										</option>
										<option value="YEAR">
											Année
										</option>
										<option value="ILLIMITE">
											Illimité
										</option>
									</select>
									<FormError
										error={
											errors?.frequence
										}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Devise
										<span className="text-danger">
											*
										</span>
									</label>
									<select
										className="form-control form-control-modal-custom"
										id="name"
										{...register(
											"devise"
										)}
									>
										<option value="XOF">
											XOF
										</option>
										<option value="EUR">
											EUR
										</option>
										<option value="USD">
											USD
										</option>
									</select>
									<FormError
										error={
											errors?.devise
										}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Option
										<span className="text-danger">
											*
										</span>
									</label>
									<select
										className="form-control form-control-modal-custom"
										id="name"
										{...register(
											"option"
										)}
									>
										<option value="">
											Choisir une
											option
										</option>
										<option value="KIVU_TOP">
											KIVU TOP
										</option>
										<option value="KC1">
											KC1
										</option>
										<option value="KC_BUSINESS">
											KC BUSINNESS
										</option>
										<option value="KC_GOLD">
											KC GOLD
										</option>
										<option value="KC_PREMIUM">
											KC PREMIUM
										</option>
									</select>
									<FormError
										error={
											errors?.option
										}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<AdminInput
									label="Prix rattachement adulte"
									id="price"
									type="number"
									placeholder="Prix rattachement adulte"
									{...register(
										"prix_rattachement_adulte"
									)}
									error={
										errors?.prix_rattachement_adulte
									}
									required
								/>
							</div>
							<div className="col-12 col-md-6">
								<AdminInput
									label="Prix rattachement enfant"
									id="price"
									type="number"
									placeholder="Prix rattachement enfant"
									{...register(
										"prix_rattachement_enfant"
									)}
									error={
										errors?.prix_rattachement_enfant
									}
									required
								/>
							</div>
							<div className="col-12 col-md-6">
								<AdminInput
									label="Taux hospitalisation"
									id="price"
									type="number"
									placeholder="Taux hospitalisation"
									{...register(
										"taux_hospitalisation"
									)}
									error={
										errors?.taux_hospitalisation
									}
									required
								/>
							</div>
							<div className="col-12 col-md-6">
								<AdminInput
									label="Taux appareils"
									id="price"
									type="number"
									placeholder="Taux appareils"
									{...register(
										"taux_couverture_soins_esthetique"
									)}
									error={
										errors?.taux_couverture_soins_esthetique
									}
									required
								/>
							</div>
							<div className="col-12 col-md-6">
								<AdminInput
									label="Taux couverture soins ordonnance"
									id="tauxcouverture"
									type="number"
									placeholder="Taux couverture soins ordonnance"
									{...register(
										"taux_couverture_soins_ordonnance"
									)}
									error={
										errors?.taux_couverture_soins_ordonnance
									}
									required
								/>
							</div>
							<div className="col-12 col-md-6">
								<AdminInput
									label="Plafond sans ordonnance"
									id="plafond_sans_ordonnance"
									type="number"
									placeholder="Plafond sans ordonnance"
									{...register(
										"plafond_sans_ordonnance"
									)}
									error={
										errors?.plafond_sans_ordonnance
									}
									required
								/>
							</div>
							<div className="col-12 col-md-6">
								<AdminInput
									label="Plafond avec ordonnance"
									id="plafond_avec_ordonnance"
									type="number"
									placeholder="Plafond avec ordonnance"
									{...register(
										"plafond_avec_ordonnance"
									)}
									error={
										errors?.plafond_avec_ordonnance
									}
									required
								/>
							</div>
							<div className="col-12">
								<label
									htmlFor="advantages"
									className="form-label form-label-modal-custom"
								>
									Détails
									<RequiredStar />
								</label>
								<ReactQuill
									className="editor-cgu"
									value={description}
									onChange={handleChange}
								/>
								<FormError
									error={errors?.details}
								/>
							</div>
						</div>
						<div className="container-btn-modal d-flex justify-content-end gap-3 mt-4">
							<button
								type="button"
								className="btn btn-cancelled"
								data-bs-dismiss="modal"
							// onClick={reset}
							>
								Fermer
							</button>
							<BtnSubmit
								isLoading={isLoading}
								label={
									item
										? "Modifier"
										: "Ajouter"
								}
								style={{ width: "inherit" }}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddOffreModal;
