import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoMdAttach } from "react-icons/io";
import { usePagination, useSearch } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { formatMontant, formattedDate, getName, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { useEditDemandeMutation, useGetDemandeQuery } from "../../../../utils/api/demande/demande.api";
import { DemandeFormData, IDemande } from "../../../../utils/api/demande/demande.type";
import PieceJointeDemandeModal from "./PieceJointeModal";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { ButtonViewLink } from "../../../common/Button";
import { HiDocumentText } from "react-icons/hi";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import { InputFilter } from "../../../common/Input";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
import moment from "moment";

function ListeDossiersTable() {
	const [date, setDate] = useState<Date | undefined>(undefined);
	const { search: patient, handlefilter: handlefilterPatient } = useSearch();
	const { search: montant_total, handlefilter: handlefilterByMontant } = useSearch();
	const { search: professionnel, handlefilter: handlefilterPro } = useSearch();

	const { page, setPage, limit, setPerPage } = usePagination(10);
	// const { patient, professionnel, date, montant_total } = props;
	const { data = { results: [] }, isLoading } = useGetDemandeQuery({
		page,
		limit,
		patient,
		professionnel,
		date: date ? moment(date).format("DD-MM-YYYY") : undefined,
		montant_total,
	});
	// console.log({ data });

	const [item, setItem] = useState<string | null>();
	const getItem = (item: string | null) => {
		setItem(item);
	};

	const [editData] = useEditDemandeMutation();

	const onChange = async (item: IDemande) => {
		let data: DemandeFormData = {
			slug: item?.slug,
			paid: !item?.paid,
		};
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  changer le statut`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.blue,
			confirmButtonColor: Color.blue,
			preConfirm: () => {
				return editData(data);
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			console.log(result, "paid");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Statut changé  avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};

	const actionFormatter = (cell: string, row: IDemande) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonViewLink to={`${row?.slug}`} state={row} />
					<button
						className="btn btn-action-modal-icon with-tooltip"
						data-tooltip-content="Voir la facture"
						data-bs-toggle="modal"
						data-bs-target="#PieceJointeDemandeModal"
						onClick={() => getItem(row?.facture)}
					>
						<HiDocumentText />
					</button>
					<button
						className="btn btn-action-modal-icon with-tooltip"
						data-tooltip-content="Voir l'ordonnance"
						data-bs-toggle="modal"
						data-bs-target="#PieceJointeDemandeModal"
						onClick={() => getItem(row?.ordonnance)}
					>
						<IoMdAttach />
					</button>
				</div>
			</>
		);
	};
	const professionelFormatter = (cell: string, row: IDemande) => {
		if (row?.medecin) {
			return `Dr ${getName(row?.medecin)}`;
		}
		return `Dr ${getName(row?.pharmacien)}`;
	};

	const statutFormatter = (cell, row) => {
		return (
			<Switch
				checked={cell}
				handleDiameter={18}
				height={18}
				width={42}
				offHandleColor="#C4C4C4"
				onHandleColor="#C4C4C4"
				borderRadius={2}
				uncheckedIcon={<div className="toggle-style-off toggle-style">Non</div>}
				checkedIcon={<div className="toggle-style-on toggle-style">Oui</div>}
				className="react-switch"
				id="small-radius-switch"
				onChange={() => onChange(row)}
				disabled={true}
			/>
		);
	};

	const columns = [
		{
			dataField: "patient",
			text: "Patient",
			formatter: (cell: string, row: IDemande) => getName(row?.patient),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="patient" placeholder="Patient" onChange={handlefilterPatient} />,
		},
		{
			dataField: "professionnel",
			text: "Professionnel",
			formatter: (cell: string, row: IDemande) => professionelFormatter(cell, row),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="professionnel" placeholder="Professionnel" onChange={handlefilterPro} />,
		},
		{
			dataField: "created_at",
			text: "Date",
			formatter: (cell: string) => formattedDate(cell),
			filter: customFilter(),
			filterRenderer: () => (
				<div className="date-picker-container">
					<DatePicker
						className="form-control tab-dash-admin-filter-input"
						selected={date}
						onChange={(date: Date) => setDate(date)}
						maxDate={new Date()}
						dateFormat="dd/MM/yyyy"
						placeholderText="Choisir une date"
						isClearable
						locale="fr"
					/>
				</div>
			),
		},
		{
			dataField: "montant_total",
			text: "Montant total",
			formatter: (cell: string) => formatMontant(cell),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="montant_total" placeholder="Montant" onChange={handlefilterByMontant} />,
		},
		{
			dataField: "paid",
			text: "Payé",
			formatter: (cell: string, row: IDemande) => statutFormatter(cell, row),
		},

		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IDemande) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div className="modal fade" id="PieceJointeDemandeModal" aria-labelledby="ListeDossiersModalLabel" aria-hidden="true">
				<PieceJointeDemandeModal item={item} />
			</div>
		</>
	);
}

export default ListeDossiersTable;
