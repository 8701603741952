import React from "react";
import { FiMail } from "react-icons/fi";
import Logo from "../../assets/appImages/logo.png";
import { BtnSubmit } from "../common/Button";
import { FormError } from "../common/Input";
import { useForgetPassword } from "./hooks/useForgetPassword";

const ForgetPassword = () => {
	const { register, onSubmit, errors, isLoading } = useForgetPassword();
	return (
		<div>
			<div className="">
				<div className="row login-page-row">
					<div className="col-md-6 offset-md-3 right-side-col">
						<div className="login-form-container">
							<div className="login-form-introduction d-flex flex-column align-items-center">
								<div className="mb-4">
									<img
										src={Logo}
										alt="logo"
										className="logo-auth-login-img"
									/>
								</div>
								<h1 className="auth-form-title">
									Récupération du Mot de Passe
								</h1>
								<div className="p-4">
									<h4 className="auth-form-message">
										Une demande de réinitialisation de mot
										de passe va être envoyé par e-mail
									</h4>
								</div>
							</div>
							<div className="mt-4">
								<form id="auth-form" onSubmit={onSubmit}>
									<div className="row auth-form-row">
										<div className="col-md-12 auth-input-col mb-3">
											<div className="input-group auth-form-group auth-form-group-login">
												<span
													className="input-group-text input-group-text-custom"
													id="basic-addon1"
												>
													<FiMail />
												</span>
												<input
													type={`email`}
													className="form-control auth-form-control"
													id="email-or-username"
													placeholder="Identifiant"
													aria-label="Username"
													aria-describedby="basic-addon1"
													{...register("email")}
													data-testid="emailId"
												/>
											</div>
											<FormError
												error={errors?.email}
											/>
										</div>
										<div className="col-md-6 offset-md-3 auth-submit-btn-container mt-5">
											<BtnSubmit
												label="	Reinitialiser le mot de
												passe"
												isLoading={isLoading}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgetPassword;
