import React, { useState } from "react";
import "./DashboardAdmin.css";
import Activity from "../../../../assets/icons/Activity.png";
import Users from "../../../../assets/icons/users.png";
import Paper from "../../../../assets/icons/Paper.png";
import LineChart from "./LineChart/LineChart";
import {
	useGetAdminDashboardDataQuery,
	useGetDashboardGraphDataQuery,
} from "../../../../utils/api/admin/admin.api";
import { Link } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
import moment from "moment";
import { MdSms } from "react-icons/md";
import DynamicDatePicker from './DynamicDatePicker';

const DashboardAdmin = () => {
	const [date, setDate] = useState<Date>(new Date());
	const [query, setQuery] = useState<string>("week");
	const [queryData, setQueryData] = useState<string>("day");

	const { data } = useGetAdminDashboardDataQuery({
		date: moment(date).format("DD-MM-YYYY"),
		q: queryData,
	});

	const { data: graphData } = useGetDashboardGraphDataQuery({
		date: moment(date).format("DD-MM-YYYY"),
		q: query,
	});
	// console.log(graphData);

	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page">
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="form-group col-md-6 col-xl-4 mb-4">
						<label htmlFor="filterVariations" style={{ fontSize: "14px" }}>
							Afficher par:
						</label>
						<select
							name="filter"
							id="filterVariations"
							className="form-select form-select-date-graph-farmer"
							onChange={(e) => setQueryData(e.currentTarget.value)}
						>
							<option value="day">Jour</option>
							<option value="week">Semaine</option>
							<option value="month">Mois</option>
							<option value="year">Année</option>
						</select>
					</div>
					<div className="row">
						<div className="col-md-3 mb-3 dis-flex px-md-2">
							<div className="item-stat-dashboad-view bgcolor1">
								<div className="content-item-icon-stat-dashboard-view">
									<img src={Users} alt="icon" className="" />
								</div>
								<div className="content-stat-dashboard-view">
									<div className="content-text-item-stat-dashboad-view">
										<div className="content-item-stat-dashboad-view">
											<p className="titre-item-stat-dashboad-view">
												Utilisateurs{" "}
											</p>
										</div>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view">
											{data?.utilisateurs}
										</p>
									</div>
								</div>
								{/* <div className="icon-item-stat-dashboad-view">
									<p className="info-item-stat-dashboad-view">
										Cette semaine
									</p>
								</div> */}
							</div>
						</div>
						<div className="col-md-3 mb-3 dis-flex px-md-2">
							<div className="item-stat-dashboad-view bgcolor2">
								<div className="content-item-icon-stat-dashboard-view">
									<img src={Paper} alt="icon" className="" />
								</div>
								<Link
									to="/admin/transactions"
									className="no-underline"
								>
									<div className="content-stat-dashboard-view">
										<div className="content-text-item-stat-dashboad-view">
											<div className="content-item-stat-dashboad-view">
												<p className="titre-item-stat-dashboad-view">
													Transactions
												</p>
											</div>
										</div>
										<div className="icon-item-stat-dashboad-view">
											<p className="chiffre-item-stat-dashboad-view">
												{data?.transactions}
											</p>
										</div>
									</div>
								</Link>
							</div>
						</div>
						<div className="col-md-3 mb-3 dis-flex px-md-2">
							<div className="item-stat-dashboad-view bgcolor1">
								<div className="content-item-icon-stat-dashboard-view">
									<img src={Activity} alt="icon" className="" />
								</div>
								<div className="content-stat-dashboard-view">
									<div className="content-text-item-stat-dashboad-view">
										<div className="content-item-stat-dashboad-view">
											<p className="titre-item-stat-dashboad-view">
												Augmentation
											</p>
										</div>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view">
											{data?.progression}%
										</p>
									</div>
								</div>
								{/* <div className="icon-item-stat-dashboad-view">
									<p className="info-item-stat-dashboad-view">
										Cette semaine
									</p>
								</div> */}
							</div>
						</div>
						<div className="col-md-3 mb-3 dis-flex px-md-2">
							<div className="item-stat-dashboad-view bgcolor2">
								<div className="content-item-icon-stat-dashboard-view">
									<MdSms color="#fff"/>
								</div>
								<div className="content-stat-dashboard-view">
									<div className="content-text-item-stat-dashboad-view">
										<div className="content-item-stat-dashboad-view">
											<p className="titre-item-stat-dashboad-view">
												Nb. SMS restant
											</p>
										</div>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view">
											{data?.nb_sms_restant}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="bg-white p-3 mt-4">
						<div className="chart-item-container">
							<div className="row mb-4">
								<div className="col-md-6 mb-2">
									<h2 className="chart-item-title">
										Utilisateurs
									</h2>
								</div>
								<div
									className="dis-flex col-md-6"
									style={{
										justifyContent: "flex-end",
										gap: "10px",
									}}
								>
									<div className="form-group">
										<select
											name="filter"
											id="filterByTime"
											className="form-select form-select-date-graph-farmer"
											onChange={(e) =>
												setQuery(
													e.currentTarget.value
												)
											}
										>
											<option value="week">
												Semaine
											</option>
											<option value="month">
												Mois
											</option>
											<option value="year">
												Année
											</option>
										</select>
									</div>
									<div className="date-picker-container">
										{/* <DatePicker
											className="form-select form-select-date-graph-farmer"
											selected={date}
											onChange={(date: Date) =>
												setDate(date)
											}
											maxDate={new Date()}
											dateFormat="dd/MM/yyyy"
											locale="fr"
										/> */}
										<DynamicDatePicker date={date} setDate={setDate} query={query} />
									</div>
								</div>
							</div>
							<LineChart graph={graphData} query={query} />
						</div>
					</div>
					{/* <div className="row">
						<div className="col-lg-4 col-md-4">
							<div className="bg-white p-3 mt-4">
								<GenreChart values={series} />
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default DashboardAdmin;
