import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useCallback, useEffect, useState } from "react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { INotification, NotificationFormData } from "./notification.type";
import { usePagination } from "../../hooks";
import { QueryUrl } from "../../utils";
import { useAppSelector } from "../../../redux/hooks";

export const NotificationApi = createApi({
	reducerPath: "notificationApi",
	tagTypes: ["notification"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getNotifByUser: builder.query<PaginationResults<INotification>, TypeQuery>({
			query: ({ slug, ...query }) => QueryUrl(`user/${slug}/notifications`, query),
			providesTags: ["notification"],
		}),
		readNotification: builder.mutation<INotification, NotificationFormData>({
			query: ({ slug, ...data }) => ({
				url: `notifications/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: ["notification"],
		}),
		deleteNotification: builder.mutation<INotification, NotificationFormData>({
			query: (slug) => ({
				url: `notifications/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["notification"],
		}),
	}),
});

export const {
	useLazyGetNotifByUserQuery,
	useReadNotificationMutation,
	useGetNotifByUserQuery,
	useDeleteNotificationMutation,
} = NotificationApi;

export function useNotification() {
	const { user } = useAppSelector((s) => s?.user);
	const { page, setPage, limit, setPerPage } = usePagination(20);
	const [count, setCount] = useState(0);
	const { data, isLoading } = useGetNotifByUserQuery({ page, limit, slug: user?.slug });
	const [readMessage] = useReadNotificationMutation();

	const functionCountunreadNotif = useCallback(() => {
		let count = 0;
		data?.results?.map((notif) => {
			if (!notif?.read) {
				count++;
			}
			return count;
		});
		setCount(count);
	}, [data]);

	useEffect(() => {
		functionCountunreadNotif();
	});

	const readAll = () => {
		data?.results?.map((item) => {
			return readMessage({ slug: item?.slug, read: true });
		});
	};
	const readNotif = (slug: string) => {
		console.log("call");
		return readMessage({ slug: slug, read: true });
	};

	return {
		count,
		data: data?.results,
		isLoading,
		readAll,
		readNotif,
		notifications: data?.results?.filter((item) => !item?.read),
		page,
		limit,
		setPage,
		setPerPage,
		total: data?.count,
	};
}
