import React from "react";
import { CgComment } from "react-icons/cg";
import { FiTrash2 } from "react-icons/fi";
import { useDelete, useItem, useLocationState, useSearch } from "../../../../utils/hooks";
import { IForum, IResponseForum } from "../../../../utils/api/forum/forum.type";
import { createMarkup, getAvatar, getName } from "../../../../utils/utils";
import moment from "moment";
import {
	useDeleteResponseForumMutation,
	useGetForumResponseQuery,
} from "../../../../utils/api/forum/forum.api";
import { AlertInfo } from "../../../common/Alert";
import AddResponseForumModal from "./AddResponseForumModal";
import DetailUserModal from "../../../modal/admin/DetailUserModal";
import { IUser } from "../../../../utils/api/user/user.type";
import { ButtonGoBack } from "../../../common/Button";
import { DeleteForumItem } from "./ListeForum";

export function DeleteResponseForumItem({ item }: { item: IResponseForum }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteResponseForumMutation();
	const onDelete = useDelete<IResponseForum>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `cette réponse?`,
		successMessage: `Réponse supprimée`,
	});
	return (
		<button className="btn btn-delete-forum" onClick={onDelete}>
			<FiTrash2 />
		</button>
	);
}

const DetailForumMessage = () => {
	const itemState = useLocationState<IForum>(undefined);
	const { search, handlefilter } = useSearch();
	const { data, isLoading } = useGetForumResponseQuery({
		slug: itemState?.slug,
		author: search,
	});
	const { item: user, getItem } = useItem<IUser>();

	console.log({ itemState });
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="crypta-dash-locataire-requetes-header d-flex align-items-center">
					<ButtonGoBack />
				</div>
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header">
						<div className="d-flex align-items-center gap-3">
							<div>
								<img
									src={getAvatar(itemState?.author?.avatar)}
									alt="profil"
									className="img-pp-forum"
								/>
							</div>
							<div>
								<div className="admin-message-contact-card-description mb-2">
									<span className="modal-title admin-modal-title text-start fs-18 m-0 lh-28 me-2">
										Titre:
									</span>
									{itemState?.title}
								</div>
								<p
									className="text-notif-type-message-forum"
									style={{ textAlign: "left" }}
								>
									Par{" "}
									<span
										style={{
											fontWeight: 600,
											paddingLeft: 5,
											paddingRight: 5,
										}}
									>
										{getName(itemState?.author)}
									</span>
									Le{" "}
									{moment(itemState?.created_at)?.format(
										"DD-MM-YYYY"
									)}
								</p>
							</div>
						</div>
					</div>
					<div className="content-btn-add-theme position-relative">
						<button
							className="btn btn-add-theme btn-response"
							data-bs-toggle="modal"
							data-bs-target="#AddResponseForumModal"
						>
							<CgComment style={{ color: "#08BB90", fontSize: 20 }} />
							<span className="ps-2">Ajouter une réponse</span>
						</button>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="mb-3">
						<h5 className="modal-title admin-modal-title text-start fs-18 m-0 lh-28">
							Question:
						</h5>
						<div className="px-2 py-3 d-flex justify-content-between align-items-center gap-3 admin-message-contact-card  w-100 border-theme">
							<div>
								<div className="d-flex gap-3 align-items-center mb-3">
									<p
										className="name-text-profil-forum m-0 hover__underline"
										data-bs-toggle="modal"
										data-bs-target="#DetailUserModal"
										role="button"
										onClick={() =>
											getItem(itemState?.author)
										}
									>
										{itemState?.author?.prenom}
									</p>
									<p className="forum-created_at">
										Posté le{" "}
										{moment(itemState?.created_at)?.format(
											"DD/MM/YYYY à HH:MM"
										)}
									</p>
								</div>
								<div className="d-flex gap-3 align-items-center">
									<div>
										<img
											src={getAvatar(
												itemState?.author?.avatar
											)}
											alt="profil"
											className="img-pp-forum-detail"
										/>
									</div>
									<div
										className="admin-message-forum-text mb-3"
										dangerouslySetInnerHTML={createMarkup(
											itemState?.content
										)}
									/>
								</div>
							</div>
							<div>
								<DeleteForumItem
									item={itemState}
									redirectUrl="/admin/forum"
								/>
							</div>
						</div>
					</div>
					<div className="responses my-5 px-3">
						<div className="row align-items-center mb-3 pr-4">
							<div className="col-md-6 col-lg-7">
								<h5 className="modal-title admin-modal-title text-start fs-18 m-0 lh-28">
									Réponses:
								</h5>
							</div>
							<div className="col-md-6 col-lg-5  gap-4">
								<input
									id="search"
									placeholder="Réchercher par auteur"
									onChange={handlefilter}
									className="form-control tab-dash-admin-filter-input"
								/>
							</div>
						</div>
						<div className="responses-messages-forum">
							{!isLoading ? (
								data && data?.results?.length > 0 ? (
									data.results.map((item) => (
										<div className="mb-3" key={item?.slug}>
											<div className="px-2 py-3 d-flex justify-content-between align-items-center gap-3 admin-message-contact-card  w-100">
												<div>
													<div className="d-flex gap-3 align-items-center mb-3">
														<p
															className="name-text-profil-forum m-0 hover__underline"
															data-bs-toggle="modal"
															data-bs-target="#DetailUserModal"
															role="button"
															onClick={() =>
																getItem(
																	item?.author
																)
															}
														>
															{item
																?.author
																?.prenom ??
																"Admin"}
														</p>
														<p className="forum-created_at">
															Posté le{" "}
															{moment(
																item?.created_at
															)?.format(
																"DD/MM/YYYY à HH:MM"
															)}
														</p>
													</div>
													<div className="d-flex gap-3 align-items-center">
														<div>
															<img
																src={getAvatar(
																	item
																		?.author
																		?.avatar
																)}
																alt="profil"
																className="img-pp-forum-detail"
															/>
														</div>
														<div
															className="admin-message-forum-text mb-3"
															dangerouslySetInnerHTML={createMarkup(
																item?.content
															)}
														/>
													</div>
												</div>
												<div>
													<DeleteResponseForumItem
														item={item}
													/>
												</div>
											</div>
										</div>
									))
								) : (
									<AlertInfo message="Aucune donnée trouvée" />
								)
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</div>
			<AddResponseForumModal item={itemState} />
			<div
				className="modal fade"
				id="DetailUserModal"
				aria-labelledby="DetailUserModalLabel"
				aria-hidden="true"
			>
				<DetailUserModal item={user} title="Informations de l'auteur" />
			</div>
		</div>
	);
};

export default DetailForumMessage;
