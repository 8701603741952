import { BsInfoCircle } from "react-icons/bs";

type sizeType = "small" | "medium" | "large";

type PropsType = {
	message: string;
	size?: sizeType;
};
function addSizeValue(size: sizeType) {
	if (size === "large") {
		return "1rem 1%";
	} else if (size === "medium") {
		return ".7rem 1rem";
	} else {
		return ".5rem 1rem";
	}
}
export function AlertInfo({ message, size = "large" }: PropsType) {
	return (
		<div
			className="alert-kiwu-container"
			style={{
				padding: addSizeValue(size),
			}}
		>
			<BsInfoCircle />
			<h4 className="text-alert-kiwu mb-0">{message}</h4>
		</div>
	);
}
