import { HttpCodePage } from "page-http-code";
import "page-http-code/dist/index.css";
import image403 from "../../../assets/appImages/errors/403.png";
import image404 from "../../../assets/appImages/errors/404.png";

export function Page403() {
	return (
		<HttpCodePage
			text="Vous n'avez pas accès au contenu de cette page"
			image={image403}
			typePage={403}
			redirectLink="/admin/dashboard"
		/>
	);
}
export function Page404() {
	return (
		<HttpCodePage
			text="Page non trouvée"
			image={image404}
			typePage={403}
			redirectLink="/"
		/>
	);
}
