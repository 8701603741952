import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { ConditionProps } from "../../TableauDebord/Admin/ParametreAdmin/Cgu";
import { ConditionForm } from "./AddContentCguModal";

const AddContentMentionLegaleModal = ({ content, type }: ConditionProps) => {
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5
						className="modal-title admin-modal-title text-start"
						id="AddContentMentionLegaleModal"
					>
						Modifier
					</h5>
					<CloseModalBtn />
				</div>
				<div className="form-container px-3">
					<ConditionForm
						content={content}
						type={type}
						modalId="AddContentMentionLegaleModal"
					/>
				</div>
			</div>
		</div>
	);
};

export default AddContentMentionLegaleModal;
