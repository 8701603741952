import React from "react";
import ListeAbonnementsTable from "./ListeAbonnementsTable";
import { AiOutlinePlusSquare } from "react-icons/ai";

const Abonnements = () => {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<h4 className="crypta-dash-header-titre">Abonnements</h4>
					<div className="content-btn-add-theme">
						<button
							className="btn btn-add-theme"
							data-bs-toggle="modal"
							data-bs-target="#AddAbonnementModal"
						>
							<AiOutlinePlusSquare
								style={{ color: "#08BB90", fontSize: 20 }}
							/>
							<span className="ps-2">Ajouter</span>
						</button>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<ListeAbonnementsTable />
				</div>
			</div>
		</div>
	);
};

export default Abonnements;
