import { Diplome } from "../config/config.type";
import { IOffres } from "../offres/offres.type";

export enum UserType {
	admin = "admin",
	superAdmin = "superadmin",
	medecin = "medecin",
	pharmacien = "pharmacien",
	patient = "patient",
}

export interface IAbonnement {
	id: number;
	slug: string;
	date_debut: string;
	date_fin: string;
	facture: string;
	nb_renew: number;
	offre: IOffres;
	paid: boolean;
	price: string;
	moyen_paiement: {
		nom: string;
		titre: string;
	};
}

export interface ICompteur {
	id: number;
	slug: string;
	status: string;
	motif: string;
	raisons: string;
	patient: IUser;
}
export interface IUser {
	id: number;
	slug: string;
	email: string;
	nom: string;
	prenom: string;
	password: string;
	date_naissance: string;
	telephone: string;
	avatar: string;
	adresse: string;
	password_reset_count: number;
	created_at: string;
	last_login: string;
	is_active: boolean;
	is_archive: boolean;
	username: string;
	user_type: UserType;
	longitude: string;
	latitude: string;
	abonnement: IAbonnement;
	compteur: {
		mensuel: number;
		patient: number;
		annuel: number;
		id: number;
	};
	code_postal: string;
	ville: string;
	pays: string;
	quartier: string;
	rue: string;
	nom_pharmacie: string;
	specialite: any;
	diplome: Diplome
}

export type PaginationResults<T> = {
	count?: number;
	next?: string | null;
	previous?: string | null;
	results: T[];
};

export type TypeQuery = Partial<{
	page?: number;
	limit?: number;
	word?: string | null;
	slug?: string;
	q?: string;
}>;

export type UserQuery = TypeQuery & {
	matricule?: string;
	prenom?: string;
	nom?: string;
	email?: string;
	telephone?: string;
	is_active?: boolean;
	date?: string | null;
};

export type PharmacienQuery = TypeQuery & {
	nom?: string;
	prenom?: string;
	pharmacie?: string;
	adresse?: string;
	telephone?: string;
	email?: string;
};
export type MedecinQuery = TypeQuery & {
	nom?: string;
	prenom?: string;
	adresse?: string;
	specialite?: string;
	telephone?: string;
	email?: string;
};

export interface AuthState {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	user: IUser | any;
	token?: string | null;
}
export type UserFormData = {
	slug: string;
	nom: string;
	prenom: string;
	email: string;
	telephone: string;
	adresse: string;
	user_type: UserType;
	avatar: File;
	is_archive: boolean;
	nom_pharmacie: string;
	longitude: string;
	latitude: string;
	specialite: any;
	code_postal: string;
	ville: string;
	pays: string;
	quartier: string;
	rue: string;
	diplome: number;
};

export interface IParticipant {
	id: number;
	slug: string;
	fullname: string;
	lien_parente: string;
	date_naissance: string;
	avatar: string;
	justificatif: string;
	valider: boolean;
	date_debut: string;
	date_fin: string;
	user: IUser;
}

export interface IModePaiement {
	id: number;
	slug: string;
	titre: string;
	nom: string;
	logo: string;
	description: string;
	created_at: string;
	active: boolean;
}

export interface DemandeSupAccount {
	user: IUser;
	motif: string;
	status: string;
	created_at: string;
}
