import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
	ForumQuery,
	ForumResponseFormData,
	IForum,
	IResponseForum,
	ITheme,
	ThemeFormData,
} from "./forum.type";

export const ForumApi = createApi({
	reducerPath: "forumAPi",
	tagTypes: ["forum", "forumResponse", "themes"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getForum: builder.query<PaginationResults<IForum>, ForumQuery>({
			query: (query) => QueryUrl(`forums/`, query),
			providesTags: ["forum"],
		}),
		getForumResponse: builder.query<
			PaginationResults<IResponseForum>,
			TypeQuery & { author?: string }
		>({
			query: ({ slug, ...query }) => QueryUrl(`forum/${slug}/comments/`, query),
			providesTags: ["forumResponse"],
		}),
		deleteForum: builder.mutation<IForum, string>({
			query: (slug) => ({
				url: `forums/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["forum"],
		}),
		addResponseForum: builder.mutation<IResponseForum, ForumResponseFormData>({
			invalidatesTags: ["forumResponse"],
			query: (data) => {
				return {
					url: `comments/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteResponseForum: builder.mutation<IResponseForum, string>({
			query: (slug) => ({
				url: `comments/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["forumResponse"],
		}),
		getThemes: builder.query<PaginationResults<ITheme>, TypeQuery & { name?: string }>({
			query: (query) => QueryUrl("/themes/", query),
			providesTags: ["themes"],
		}),
		deleteTheme: builder.mutation<ITheme, string>({
			query: (slug) => ({
				url: `/themes/${slug}/`,
				method: "Delete",
			}),
			invalidatesTags: ["themes"],
		}),
		addOrUpdateTheme: builder.mutation<
			ITheme,
			{
				slug?: string;
				data: ThemeFormData;
			}
		>({
			invalidatesTags: ["themes"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `themes/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `themes/`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useGetForumQuery,
	useLazyGetForumQuery,
	useDeleteForumMutation,
	useGetForumResponseQuery,
	useAddResponseForumMutation,
	useDeleteResponseForumMutation,
	useGetThemesQuery,
	useAddOrUpdateThemeMutation,
	useDeleteThemeMutation,
} = ForumApi;
