import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults } from "../user/user.type";
import { IScreen, ScreenFormData, ScreenQuery } from "./screen.type";
import { prepareHeaders } from "../user/user.api";

export const ScreenApi = createApi({
	reducerPath: "screenApi",
	tagTypes: ["screens"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getScreens: builder.query<PaginationResults<IScreen>, ScreenQuery>({
			query: (query) => QueryUrl("/ecrans/", query),
			providesTags: ["screens"],
		}),
        addOrUpdateScreen: builder.mutation<
			IScreen,
			{
				slug?: string;
				data: ScreenFormData | FormData;
			}
		>({
			invalidatesTags: ["screens"],
			query: ({ slug, data }) => {
				return {
                    url: !!slug ? `ecrans/${slug}/` : `ecrans/`,
                    method: !!slug ? 'PUT' : 'POST',
                    body: data,
                };
			},
		}),
        deleteScreen: builder.mutation<IScreen, string>({
			query: (slug) => ({
				url: `/ecrans/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["screens"],
		}),

	}),
});

export const {
	useGetScreensQuery,
    useAddOrUpdateScreenMutation,
    useDeleteScreenMutation,
} = ScreenApi;
