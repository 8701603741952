import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { useToggle } from "../../../../utils/hooks";
import useAbout from "./hooks/useAbout";
import { AdminInput, FormError, InputFile, RequiredStar } from "../../../common/Input";
import { PreviewImage, ViewUniqueImage } from "../../../common/PreviewImage";
import { BtnSubmit } from "../../../common/Button";
import { FieldErrors, UseFormRegister, UseFormSetError, UseFormSetValue } from "react-hook-form";
import { AboutData } from "../../../../utils/api/config/config.type";
import ReactQuill from "react-quill";

export type KeyType = "one" | "two" | "three" | "four";
type SectionProps = {
	sectionKey: KeyType;
	order: number;
	title?: string;
	description?: string;
	image?: string;
	register: UseFormRegister<AboutData>;
	setValue: UseFormSetValue<AboutData>;
	errors: FieldErrors<AboutData>;
	setError: UseFormSetError<AboutData>;
	onSubmit: () => void;
	isSuccess: boolean;
};

export const SectionItem = (props: SectionProps) => {
	const [newimage, setNewImage] = useState<File>();
	const { sectionKey, order, title, description, image, register, setValue, errors, onSubmit, isSuccess } = props;
	const [text, setText] = useState<string>(description ?? "");

	const { isOpen, toggleMenu: toggleActive } = useToggle();

	const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		let keyField = e.currentTarget.id as KeyType;
		if (e.target.files) {
			let file = e.target.files[0];
			console.log({ file, id: e.target.id });
			setNewImage(file);
			setValue(`about.section_${keyField}_image`, file);
		}
	};

	useEffect(() => {
		if (isSuccess) {
			setNewImage(undefined);
		}
	}, [isSuccess]);

	return (
		<details className="card questioncard  mb-3">
			<summary className="card__header d-flex align-items-center justify-content-between px-3 py-2" onClick={toggleActive}>
				<h3 className="title-collapse-section mb-0">
					Section {order} {title && `(${title})`}
				</h3>
				<div className="d-flex align-items-center gap-2" onClick={toggleActive}>
					<div onClick={toggleActive} role="button" style={{ color: "#7c8087", fontSize: "20px" }}>
						{isOpen ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
					</div>
				</div>
			</summary>
			<div className="card__body">
				<div className="accordion-body text-dark mb-0 px-2 py-0">
					<form onSubmit={onSubmit}>
						<div className="col-md-12 mb-3">
							<InputFile
								label="Image de la section"
								id={sectionKey}
								type="file"
								required
								accept="image/*"
								onChange={handleChangeImage}
								file={newimage}
							/>
							<div className="d-md-flex align-items-center gap-2">
								{newimage && <PreviewImage image={newimage} />}
								{image && <ViewUniqueImage image={image} isLink />}
							</div>
						</div>
						<div className="col-12">
							<AdminInput
								label="Titre de la section"
								id="titre"
								type="text"
								placeholder="Titre"
								defaultValue={title}
								{...register(`about.section_${sectionKey}_title`)}
								error={errors && errors.about && errors.about[`section_${sectionKey}_title`]}
								required={order !== 1}
							/>
						</div>
						<div className="col-md-12">
							<label htmlFor="description" className="form-label form-label-modal-custom">
								Description de la section
								<RequiredStar />
							</label>
							<ReactQuill
								value={text}
								onChange={(value) => {
									setText(value);
									setValue(`about.section_${sectionKey}_description`, value);
								}}
							/>
							{/* <textarea
								id="description"
								className="form-control"
								defaultValue={description}
								{...register(
									`about.section_${sectionKey}_description`
								)}
								style={{
									minHeight: "150px",
									maxHeight: "150px",
								}}
							/> */}
							<FormError error={errors && errors.about && errors.about[`section_${sectionKey}_description`]} />
						</div>
						<div className="px-2 flex-r col-md-4 col-xl-3 offset-md-8 offset-xl-9 pt-3">
							<BtnSubmit label="Modifier" isLoading={false} />
						</div>
					</form>
				</div>
			</div>
		</details>
	);
};

const GestionAbout = () => {
	const { register, errors, onSubmit, setValue, data, setError, isSuccess } = useAbout();
	// console.log({ data });
	return (
		<div className="tab-content-admin px-3 py-3">
			<SectionItem
				sectionKey="one"
				order={1}
				title={data?.section_one_title}
				description={data?.section_one_description}
				image={data?.section_one_image}
				register={register}
				errors={errors}
				onSubmit={onSubmit}
				setValue={setValue}
				setError={setError}
				isSuccess={isSuccess}
			/>
			<SectionItem
				sectionKey="two"
				order={2}
				title={data?.section_two_title}
				description={data?.section_two_description}
				image={data?.section_two_image}
				register={register}
				errors={errors}
				onSubmit={onSubmit}
				setValue={setValue}
				setError={setError}
				isSuccess={isSuccess}
			/>
			<SectionItem
				sectionKey="three"
				order={3}
				title={data?.section_three_title}
				description={data?.section_three_description}
				image={data?.section_three_image}
				register={register}
				errors={errors}
				onSubmit={onSubmit}
				setValue={setValue}
				setError={setError}
				isSuccess={isSuccess}
			/>
			<SectionItem
				sectionKey="four"
				order={4}
				title={data?.section_four_title}
				description={data?.section_four_description}
				image={data?.section_four_image}
				register={register}
				errors={errors}
				onSubmit={onSubmit}
				setValue={setValue}
				setError={setError}
				isSuccess={isSuccess}
			/>
		</div>
	);
};

export default GestionAbout;
