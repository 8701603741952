import React from "react";
import "./SidebarAdmin.css";
import SidebarAdminContent, { MenuAdmin } from "./SidebarAdminContent";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";

export const variants = {
	open: { opacity: 1, x: 0, transition: { duration: 0.5 } },
	closed: { opacity: 0, x: "-100%", transition: { duration: 0.7 } },
};

const SidebarAdmin = () => {
	return (
		<div className="navbar-vertical-customisation no-view-mobile">
			<SidebarAdminContent />
		</div>
	);
};

export function MobileMenu({ isOpen, toggleMenu }: { isOpen: boolean; toggleMenu: () => void }) {
	return (
		<motion.div
			animate={isOpen ? "open" : "closed"}
			variants={variants}
			className="menu-mobile__admin no-view-desktop"
		>
			<div className="d-flex justify-content-end mb-3">
				<button className="navbar-toggler" type="button" onClick={toggleMenu}>
					<span className="navbar-toggler-icon">
						<IoClose style={{ color: "#fff", fontSize: 24 }} />
					</span>
				</button>
			</div>
			<ul className="menu-mobile-list">
				<MenuAdmin toggleMenu={toggleMenu} />
			</ul>
		</motion.div>
	);
}

export default SidebarAdmin;
