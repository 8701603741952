import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAddContactMutation } from "../../../utils/api/contact/contact.api";
import { TContactForm } from "../../../utils/api/contact/contact.type";
import { QueryError } from "../../../utils/type";
import { cleannerError } from "../../../utils/utils";
import { toast } from "react-hot-toast";
import { alphaSpaceRegex } from "../../../utils/constantes";

const useContactForm = () => {
	const validationSchema = yup.object().shape({
		name: yup
			.string()
			.required()
			.matches(alphaSpaceRegex, "Nom doit contenir que des lettres")
			.label("Le nom"),
		email: yup.string().email().required().label("Email"),
		message: yup.string().required().label("Le message").nullable(),
		subject: yup.string().required().label("Le sujet"),
	});

	const [sendMessage, { isLoading, isError, isSuccess, error }] = useAddContactMutation();
	const [text, setText] = useState<string>("");

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setError,
		setValue,
	} = useForm<TContactForm>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (isSuccess) {
			toast.success("Message envoyé avec succés!");
			reset();
			setText("");
		}
		const err = error as QueryError;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isLoading]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const handleChangeMessage = (value: string) => {
		setValue("message", value);
		setText(value);
	};

	const onsubmit = async (data: TContactForm) => {
		console.log(data);

		const name = data.name;
		const regex = /^[a-zA-Z ]{2,}$/;

		if (!regex.test(name)) {
			return setError("name", {
				message: "Nom doit être au minimum 2 lettes et ne doit contenir que des lettres",
			});
		}

		await sendMessage(data);
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		handleChangeMessage,
		text,
	};
};

export default useContactForm;
