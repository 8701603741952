import { AiOutlineEye, AiOutlinePlusSquare } from "react-icons/ai";
import { BsArrowLeft, BsTrashFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { LinkProps } from "react-router-dom";

type BtnPropsType = {
	label: string;
	type?: "submit" | "button" | "reset";
	isLoading?: boolean;
	disabled?: boolean;
};

type ButtonProps = React.HtmlHTMLAttributes<HTMLButtonElement>;

export function BtnSubmit({
	isLoading,
	label,
	disabled = false,
	...rest
}: BtnPropsType & React.HtmlHTMLAttributes<HTMLButtonElement>) {
	return !isLoading ? (
		<button
			type="submit"
			className="btn auth-submit-btn"
			{...rest}
			disabled={disabled}
			data-testid="submitBtnId"
		>
			{label}
		</button>
	) : (
		<button
			type="submit"
			className="btn auth-submit-btn trans-0-2"
			disabled={isLoading}
			{...rest}
			data-testid="submitBtnId"
		>
			<span
				className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
				role="status"
			></span>
			<span>Chargement...</span>
		</button>
	);
}

export function ButtonAdd({
	label,
	...rest
}: BtnPropsType & React.HtmlHTMLAttributes<HTMLButtonElement>) {
	return (
		<button className="btn btn-add-theme" {...rest}>
			<AiOutlinePlusSquare style={{ color: "#08BB90", fontSize: 20 }} />
			<span className="ps-2">{label}</span>
		</button>
	);
}

export const ButtonDelete: React.FC<ButtonProps> = ({ ...rest }) => {
	return (
		<button
			className="btn with-tooltip btn-action-modal-icon"
			data-tooltip-content="Supprimer"
			{...rest}
			type="button"
		>
			<BsTrashFill />
		</button>
	);
};

export const ButtonEdit: React.FC<ButtonProps> = ({ ...rest }) => {
	return (
		<button
			className="btn btn-action-modal-icon with-tooltip"
			data-tooltip-content="Modifier"
			type="button"
			{...rest}
		>
			<MdModeEdit />
		</button>
	);
};
export const ButtonView: React.FC<ButtonProps> = ({ ...rest }) => {
	return (
		<button
			className="btn btn-action-modal-icon with-tooltip"
			data-tooltip-content="Voir"
			type="button"
			{...rest}
		>
			<AiOutlineEye />
		</button>
	);
};

export const ButtonEditLink: React.FC<LinkProps> = ({ ...rest }) => {
	return (
		<Link
			{...rest}
			className="btn btn-action-modal-icon with-tooltip"
			data-tooltip-content="Modifier"
		>
			<MdModeEdit />
		</Link>
	);
};
export const ButtonViewLink: React.FC<LinkProps> = ({ ...rest }) => {
	return (
		<Link
			className="btn btn-action-modal-icon with-tooltip"
			data-tooltip-content="Voir"
			{...rest}
		>
			<AiOutlineEye />
		</Link>
	);
};

export const ButtonGoBack: React.FC<ButtonProps> = ({ ...rest }) => {
	const navigate = useNavigate();
	return (
		<button
			className="btn"
			{...rest}
			type="button"
			onClick={() => navigate(-1)}
			style={{ fontSize: 26 }}
		>
			<BsArrowLeft />
		</button>
	);
};

export function Spinner() {
	return (
		<span
			className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
			role="status"
		></span>
	);
}
