import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useEditRemboursementMutation } from "../../../../utils/api/remboursement/remboursement.api";
import {
	IRemboursement,
	RemboursementFormData,
} from "../../../../utils/api/remboursement/remboursement.type";
import { cleannerError, onHide } from "../../../../utils/utils";
import { BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { toast } from "react-hot-toast";

function ChangeStatusRemboursementModal({
	modalId,
	item,
}: {
	modalId: string;
	item?: IRemboursement;
}) {
	const [isShowMotifField, setIsShowMotifField] = useState<boolean>(false);
	const validationSchema = yup.object().shape({
		status: yup.string().required().label("Status"),
		motif: yup.string().when("status", {
			is: "rejetee",
			then: yup.string().required().label("Motif"),
		}),
	});
	const [changeStatus, { isLoading }] = useEditRemboursementMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<RemboursementFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
			setValue("status", item?.status);
		}
	}, [item]);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const handleShowMotifField = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e.currentTarget.value === "rejetee") {
			setIsShowMotifField(true);
		} else {
			setIsShowMotifField(false);
		}
	};

	const onSubmit = async (data: RemboursementFormData) => {
		let newData: Partial<RemboursementFormData> = { ...data };
		if (!data?.motif) newData = { slug: data?.slug, status: data?.status };

		const res = await changeStatus(newData);
		if ("data" in res) {
			toast.success("Statut changé avec  succès!");
			reset();
			onHide(modalId);
		} else if ("error" in res) {
			console.log("error", res.error);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			if (err?.data?.motif[0]?.includes("This field may not be blank.")) {
				return Swal.fire({
					icon: "error",
					title: `Motif est un champ obligatoire`,
					showConfirmButton: false,
					timer: 2000,
				});
			}
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	return (
		<div
			className="modal fade"
			id={modalId}
			aria-labelledby="ChangeStatusRemboursementModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="ChangeStatusRemboursementModal"
						>
							Changer le status
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form className="w-100" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Statut du remboursement
											<span className="text-danger">
												*
											</span>
										</label>
										<select
											className="form-control form-control-input-admin py-3"
											id="name"
											{...register("status")}
											onChange={handleShowMotifField}
										>
											{/* <option value="">
												Choisir un status
											</option> */}
											<option value="en_attente">
												En attente
											</option>
											{/* <option value="acceptee">
												Accepté
											</option>{" "} */}
											<option value="annulee">
												Annulé
											</option>
											<option value="rejetee">
												Rejeté
											</option>
										</select>
										<FormError error={errors?.status} />
									</div>
								</div>
								{isShowMotifField && (
									<div className="col-12">
										<label className="form-label label-form-admin">
											Motif
											<span className="text-danger">
												*
											</span>
										</label>
										<textarea
											className="form-control form-control-modal"
											rows={4}
											{...register("motif")}
											placeholder="Motif de refus"
										/>
										<FormError error={errors?.motif} />
									</div>
								)}
							</div>
							<div className="flex-end mt-5 gap-4">
								<BtnSubmit
									label="Enregistrer"
									isLoading={isLoading}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStatusRemboursementModal;
