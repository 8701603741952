import React from "react";
import { usePagination } from "../../../../utils/hooks";
import { useGetAbonnementsByUserQuery } from "../../../../utils/api/user/user.api";
import TableSkeleton from "../../../common/TableSkeleton";
import { CustomPagination } from "../../../common/CustomPagination";
import { formatMontant, formattedDate, isShowPagination } from "../../../../utils/utils";
import BootstrapTable from "react-bootstrap-table-next";
import { FiDownload } from "react-icons/fi";
import moment from "moment";

const AbonnementUsers = ({ slug }: { slug?: string }) => {
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetAbonnementsByUserQuery({
		slug,
		page,
		limit,
	});
	const count = (data?.count as number) - 1;
	const lastAbonnement = data?.results[count];
	console.log({ lastAbonnement });

	const FactureFormatter = (cell: string) => {
		if (cell) {
			return (
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<a
							className="btn with-tooltip btn-action-modal-icon"
							href={cell}
							data-tooltip-content="Télecharger"
						>
							<FiDownload />
						</a>
					</div>
				</div>
			);
		}
		return (
			<button
				className="btn with-tooltip btn-action-modal-icon"
				data-tooltip-content="Aucune facture"
			>
				-
			</button>
		);
	};
	const StatutFormatter = (cell: string) => {
		return (
			<span
				className={`statut-dossier-item text-capitalize ${
					cell ? "stat_valide" : "stat_rejet"
				}`}
			>
				{cell ? "Payé " : "Non payé"}
			</span>
		);
	};
	const columns = [
		{
			dataField: "id",
			text: "Numéro",
		},
		{
			dataField: "created_at",
			text: "Date ",
			formatter: (cell: string) => formattedDate(cell),
		},
		{
			dataField: "offre.title",
			text: "Offre",
		},
		{
			dataField: "offre.price",
			text: "Montant",
			formatter: (cell: string) => formatMontant(cell),
		},
		{
			dataField: "moyen_paiement.titre",
			text: "Mode de paiement",
		},
		{
			dataField: "facture",
			text: "Facture",
			formatter: (cell: string) => FactureFormatter(cell),
		},
		{
			dataField: "paid",
			text: "Statut",
			formatter: (cell: string) => StatutFormatter(cell),
		},
	];

	return (
		<>
			<div className="row mb-5 pt-2">
				<div className="col-lg-9 col-xl-6">
					<div className="last-abonnement p-3 d-lg-flex gap-3 justify-content-between">
						<div>
							<div className="d-flex gap-1">
								<h5>Type d’abonnement : </h5>
								<h6>{lastAbonnement?.offre?.title}</h6>
							</div>
							<div className="d-flex gap-1">
								<h5>Montant : </h5>
								<h6>
									{formatMontant(lastAbonnement?.offre?.price)}
								</h6>
							</div>
						</div>
						<div>
							<div className="d-flex gap-1">
								<h5>Fin du contrat dans : </h5>
								<h6>
									{moment(lastAbonnement?.date_fin).fromNow(
										true
									)}
								</h6>
							</div>
							<div className="d-flex gap-1">
								<h5>Prochain paiement le : </h5>
								<h6>
									{moment(lastAbonnement?.date_fin).format(
										"DD MMMM YYYY"
									)}
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
		</>
	);
};

export default AbonnementUsers;
