import { IAdmin } from "../admin/admin.type";
import { IUser, TypeQuery } from "../user/user.type";
export enum RemboursementType {
	appareils = "appareils",
	hospitalisation = "hospitalisation",
}
export interface IRemboursement {
	id: number;
	slug: string;
	fichier: string;
	matricule: string;
	type: RemboursementType;
	status: string;
	montant: number;
	montant_a_payer: number;
	motif: string;
	hopital: string;
	patient: IUser;
	appareils: number;
	created_at: string;
	auteur: IUser | IAdmin;
}
export type RemboursementQuery = TypeQuery & {
	type_remboursement?: string;
	nom?: string;
	prenom?: string;
	date?: string;
	matricule?: string;
	hopital?: string;
	appareils?: string;
	status?: string;
};
export type RemboursementFormData = Partial<
	Pick<
		IRemboursement,
		"slug" | "status" | "motif" | "montant" | "type" | "appareils" | "hopital"
	> & { fichier: File; patient: number }
>;
export type CompteurFormData = Partial<Pick<IRemboursement, "slug" | "status" | "motif">>;
