import React from "react";
import HeaderLp from "../LandingPage/HeaderLp";
import ButtonScrollTop from "../LandingPage/ButtonScrollTop";
import FooterLandingpage from "../LandingPage/FooterLandingpage/FooterLandingpage";
import { useGetCguQuery } from "../../utils/api/condition/condition.api"
import Skeleton from "react-loading-skeleton"
import { AlertInfo } from "../common/Alert";
import { createMarkup } from "../../utils/utils";

export const ConditionSkeleton = () => {
    return (
      <>
        <Skeleton count={100} />
      </>
    );
  };
function CguPage() {
  const { data = [], isLoading } = useGetCguQuery();
  return (
    <div className="contact-us-component overflow-x-hidden">
      <HeaderLp />
      {/* <BreadCrumb title="Nous contacter" pageName="Nous contacter" /> */}
      <section
        className="contact-us-section gradient-background h-auto"
        style={{ minHeight: "100vh" }}
      >
        <div className="row row-contact-us mb-5">
          <h1 className="title-login-page pb-5 text-uppercase">
            Conditions Générales d'Utilisation
          </h1>
          <div className="container-form-login cgu-content mb-5">
            {!!isLoading && <ConditionSkeleton />}
            {!isLoading &&
              (!data?.length ? (
                <div>
                  <AlertInfo message="Aucun contenu trouvé" />
                </div>
              ) : (
                <>
                  <p
                    className="mb-3"
                    dangerouslySetInnerHTML={createMarkup(data[0]?.text)}
                  />
                </>
              ))}
          </div>
        </div>
      </section>

      <ButtonScrollTop />
      <FooterLandingpage />
    </div>
  );
}

export default CguPage;
