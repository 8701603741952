import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import { useDeleteWithCode, useItem, usePagination, useSearch, useSelectSearch } from "../../../../utils/hooks";
import { useDeleteAdminMutation, useGetAdminsListQuery } from "../../../../utils/api/admin/admin.api";
import { IAdmin } from "../../../../utils/api/admin/admin.type";
import TableSkeleton from "../../../common/TableSkeleton";
import { isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonEditLink, ButtonViewLink, Spinner } from "../../../common/Button";
import AddAdminModal, { typesAdmins } from "../../../modal/admin/AddAdminModal";
import { useToggleArchiveAdmin } from "./hooks/useCrudAdmin";
import { FaLock, FaUnlock } from "react-icons/fa";
import { Color } from "../../../../utils/theme";
import { useLazySendDeleteUserCodeQuery } from "../../../../utils/api/user/user.api";
import { isSuperSuperAdmin } from "../../../../routes/routerUtils";
import { useAppSelector } from "../../../../redux/hooks";
import { InputFilter } from "../../../common/Input";
import { SelectInputFilter } from "../../../common/SelectInput";

function ToggleArchiveAdmin({ item }: { item: IAdmin }) {
	const toggleArchive = useToggleArchiveAdmin(item);

	return (
		<button
			className={`btn with-tooltip btn-action-modal-icon ${item?.is_archive ? "tooltip-red" : ""}`}
			data-tooltip-content={item?.is_archive ? "Désarchiver" : "Archiver"}
			onClick={toggleArchive}
			style={{ color: !item?.is_archive ? Color.success : Color.danger }}
		>
			{!item?.is_archive ? <FaUnlock /> : <FaLock />}
		</button>
	);
}

export function DeleteAdmin({ item }: { item: IAdmin }) {
	const [sendCode, { isLoading }] = useLazySendDeleteUserCodeQuery();

	const [deleteItem, { isSuccess, isError, error }] = useDeleteAdminMutation();
	const onDelete = useDeleteWithCode<IAdmin>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		sendCode,
		title: "Suppression d'un administrateur",
		successMessage: "Administrateur supprimé avec succès !",
	});
	if (isLoading) return <Spinner />;
	return <ButtonDelete onClick={onDelete} />;
}

function ListeAdminsTable() {
	const { search, handlefilter } = useSearch();
	const { search: type, handlefilter: handlefilterType } = useSelectSearch();
	const { search: prenom, handlefilter: handlefilterPrenom } = useSearch();
	const { search: telephone, handlefilter: handlefilterTelephone } = useSearch();
	const { search: email, handlefilter: handleFilterByEmail } = useSearch();
	const { search: adresse, handlefilter: handleFilterByAdresse } = useSearch();

	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetAdminsListQuery({
		page,
		limit,
		nom: search,
		type,
		prenom,
		telephone,
		email,
		adresse,
	});

	const { user } = useAppSelector((s) => s?.user);
	const { item, resetItem } = useItem<IAdmin>();

	const actionFormatter = (cell: string, row: IAdmin) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonViewLink
						to={`/admin/administrateur/${row?.slug}`}
						state={{
							item: row,
							disabled: true,
						}}
					/>
					{isSuperSuperAdmin(user) && (
						<>
							<ButtonEditLink
								to={`/admin/administrateur/${row?.slug}`}
								state={{
									item: row,
									disabled: false,
								}}
							/>
							<DeleteAdmin item={row} />
							<ToggleArchiveAdmin item={row} />
						</>
					)}
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "prenom",
			text: "Prénom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="prenom" placeholder="Prénom" onChange={handlefilterPrenom} />,
		},
		{
			dataField: "nom",
			text: "Nom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="nom" placeholder="Nom" onChange={handlefilter} />,
		},
		{
			dataField: "email",
			text: "Email",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="email" placeholder="Email" onChange={handleFilterByEmail} />,
		},
		{
			dataField: "telephone",
			text: "Téléphone",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="tel" placeholder="Téléphone" onChange={handlefilterTelephone} />,
		},
		{
			dataField: "adresse",
			text: "Adresse",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="adresse" placeholder="Adresse" onChange={handleFilterByAdresse} />,
		},
		{
			dataField: "user_type",
			text: "Type d'utilisateur",
			filter: customFilter(),
			filterRenderer: () => (
				<SelectInputFilter id="motif" placeholder="Tous" onChange={handlefilterType} options={typesAdmins} />
			),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IAdmin) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div className="modal fade" id="AddAdminModal" aria-labelledby="AddAdminModalLabel" aria-hidden="true">
				<AddAdminModal item={item} resetItem={resetItem} />
			</div>
		</>
	);
}

export default ListeAdminsTable;
