import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDelete, useItem, usePagination } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { getImage, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import { Color } from "../../../../utils/theme";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
import { QueryError } from "../../../../utils/type";
import {
	useAddOrUpdateModePaiementMutation,
	useDeleteModePaiementMutation,
	useGetModePaiementQuery,
} from "../../../../utils/api/config/config.api";
import { ModePaiment } from "../../../../utils/api/config/config.type";
import AddMoyenPaiementModal from "../../../modal/admin/AddMoyenPaiementModal";
import { useModePaiementListener } from "../../../../utils/pusher";

export function DeleteMode({ item }: { item: ModePaiment }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteModePaiementMutation();
	const onDelete = useDelete<ModePaiment>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette Moyen de paiement",
		successMessage: "Moyen de paiement supprimé avec succès !",
	});
	return <ButtonDelete onClick={onDelete} />;
}

function ListeModePaiementTable() {
	const { page, setPage, limit, setPerPage } = usePagination(10);
	useModePaiementListener();
	const { data = { results: [] }, isLoading } = useGetModePaiementQuery({
		page,
		limit,
	});

	const { item, resetItem, getItem } = useItem<ModePaiment>();
	const [editData, { isError, error }] =
		useAddOrUpdateModePaiementMutation();

	const actionFormatter = (cell: string, row: ModePaiment) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonEdit
						data-bs-toggle="modal"
						data-bs-target="#AddMoyenPaiementModal"
						onClick={() => getItem(row)}
					/>
					<DeleteMode item={row} />
				</div>
			</>
		);
	};
	useEffect(() => {
		if (isError) {
			const err = error as QueryError;
			const message =
				err?.data?.message ??
				`Une erreur de status ${err?.status} est survenue!`;
			toast.error(message);
		}
	}, [isError]);
	const toggleActive = async (item: ModePaiment) => {
		let data = {
			active: !item.active,
		};
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${
				!item?.active === true ? "activer" : "désactiver"
			} ce moyen de paiement?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.blue,
			confirmButtonColor: Color.blue,
			preConfirm: () => {
				return editData({ slug: item.slug, data: data });
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				toast.success(
					`Mode de paiement ${
						result?.value?.data?.active
							? "activé "
							: "désactivé"
					} avec succèss!`
				);
			}
		});
	};
	const statutFormatter = (cell, row) => {
		return (
			<Switch
				checked={cell}
				handleDiameter={18}
				height={18}
				width={42}
				offHandleColor="#C4C4C4"
				onHandleColor="#C4C4C4"
				borderRadius={2}
				uncheckedIcon={
					<div className="toggle-style-off toggle-style">
						Non
					</div>
				}
				checkedIcon={
					<div className="toggle-style-on toggle-style">
						Oui
					</div>
				}
				className="react-switch"
				id="small-radius-switch"
				onChange={() => toggleActive(row)}
			/>
		);
	};
	const imageFormatter = (cell: string) => (
		<img
			src={getImage(cell)}
			alt="Image du mode de paiement"
			width={100}
			height={80}
		/>
	);

	const columns = [
		{
			dataField: "titre",
			text: "Nom",
			formatter: (cell: string) => (
				<span className="text-capitalize">{cell}</span>
			),
		},

		{
			dataField: "logo",
			text: "Logo",
			formatter: (cell: string) => imageFormatter(cell),
		},
		{
			dataField: "nom",
			text: "Type",
			formatter: (cell: string) => (
				<span className="text-capitalize">{cell}</span>
			),
		},
		{
			dataField: "active",
			text: "Actif",
			formatter: (cell: string, row: ModePaiment) =>
				statutFormatter(cell, row),
			headerStyle: () => {
				return { width: "160px" };
			},
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: ModePaiment) =>
				actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() =>
							"Aucune donnée trouvée"
						}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="AddMoyenPaiementModal"
				aria-labelledby="AddMoyenPaiementModalLabel"
				aria-hidden="true"
			>
				<AddMoyenPaiementModal
					item={item}
					resetItem={resetItem}
				/>
			</div>
		</>
	);
}

export default ListeModePaiementTable;
