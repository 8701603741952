import React from "react";
import { formattedDate } from "../../../../utils/utils";
import { ButtonGoBack } from "../../../common/Button";
import DetailOffreModal from "../../../modal/admin/DetailOffreModal";
import { useItem, useLocationState } from "../../../../utils/hooks";
import { IOrder } from "../../../../utils/api/order/order.type";
import { IOffres } from "../../../../utils/api/offres/offres.type";

const DetailPrecommande = () => {
	const item = useLocationState<IOrder>(undefined);
	const { item: offre, getItem } = useItem<IOffres>();

	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page">
				<div className="col-left-dashboard col-left-dashboard-responsive">
					<div className="bg-white p-3">
						<div className="d-flex gap-2 align-items-center">
							<ButtonGoBack />
							<h4 className="crypta-dash-header-titre m-0">
								Détail de la précommande{" "}
							</h4>
						</div>
					</div>
				</div>
				<div className="container-page-tabs-user mb-3">
					<div className="bg-white p-3 filter-and-table-container">
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Prénom
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.prenom}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">Nom</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.nom}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Mail{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.email}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Date de naissance
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.date_de_naissance}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Lieu de naissance
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.lieu_de_naissance}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Téléphone{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.telephone}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Date de début{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{formattedDate(item?.date_debut)}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Date de fin{" "}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{formattedDate(item?.date_fin)}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Date d'inscription
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{formattedDate(item?.created_at)}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Code postal
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-value">
										{item?.code_postal}
									</p>
								</div>
							</div>
						</div>
						<div className="row row-info-perso-user">
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p className="text-info-user-libelle">
										Offre choisi
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="content-text-info-user">
									<p
										className="text-info-user-value underline"
										role="button"
										data-bs-toggle="modal"
										data-bs-target="#DetailOffreModal"
										onClick={() => getItem(item?.offre)}
									>
										{item?.offre?.title}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="DetailOffreModal"
				aria-labelledby="DetailOffreModalLabel"
				aria-hidden="true"
			>
				<DetailOffreModal item={offre} />
			</div>
		</div>
	);
};

export default DetailPrecommande;
