import React from "react";
import FooterLandingpage from "../FooterLandingpage/FooterLandingpage";
import HeaderLp from "../HeaderLp";
import ButtonScrollTop from "../ButtonScrollTop";
import { useLocationState, useModal } from "../../../utils/hooks";
import { IPoste } from "../../../utils/api/candidature/candidature.type";
import { createMarkup } from "../../../utils/utils";
import moment from "moment";
import PostulerModal from "./modal/PostulerModal";

const DetailPoste = () => {
	const itemState = useLocationState<IPoste>(undefined);
	const { item, closeModal, openEditModal, isShowModal } =
		useModal<IPoste>();
	return (
		<div className="join-us-component overflow-x-hidden">
			<HeaderLp />
			<section className="container-section-about-us-page bg-gradient-theme mb-5 container-section-join-us-page">
				<div className="container-text-describe-kiwu-abot-us">
					<div className="detail-poste">
						<div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
							<div>
								<h1>{itemState?.nom}</h1>
								<p>
									{itemState?.type}{" "}
									{itemState?.lieu && (
										<>
											{" "}
											à{" "}
											{
												itemState?.lieu
											}
										</>
									)}
								</p>
							</div>
							<p className="align-self-end">
								Publié{" "}
								{moment(
									itemState.created_at
								).fromNow()}
							</p>
							<button
								className="btn-postuler"
								onClick={() =>
									openEditModal(itemState)
								}
							>
								Postuler maintenant
							</button>
						</div>
						<div className="px-1 px-md-3 px-xl-5 mt-5">
							<div
								className="detail-poste-text"
								dangerouslySetInnerHTML={createMarkup(
									itemState?.description
								)}
							/>
						</div>
					</div>
				</div>
			</section>
			<PostulerModal
				item={item}
				close={closeModal}
				isOpen={isShowModal}
			/>
			<ButtonScrollTop />
			<FooterLandingpage />
		</div>
	);
};

export default DetailPoste;
