import { useState } from "react";

export function validatePassword(Yup, message) {
	return Yup.string()
		.required(message)
		.min(8)
		.matches(/[a-z]+/, "Le mot de passe doit contenir au moins une lettre minuscule.")
		.matches(/[A-Z]+/, "Le mot de passe doit contenir au moins une lettre majuscule.")
		.matches(
			/[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
			"Le mot de passe doit contenir au moins un caractère spécial."
		)
		.matches(/\d+/, "Le mot de passe doit contenir au moins un chiffre.");
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePasswordRule = (setValue: any, field?: string) => {
	const [haveMinUppercase, setHaveMinUppercase] = useState<boolean>(false);
	const [haveMinLowercase, setHaveMinLowercase] = useState<boolean>(false);
	const [haveMinNumber, setHaveMinNumber] = useState<boolean>(false);
	const [haveMinCharacter, setHaveMinCharacter] = useState<boolean>(false);
	const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState<boolean>(false);
	const [isShowPasswordRules, setIsShowPasswordRules] = useState<boolean>(false);

	const countUppercase = (str: string) => {
		if (str.replace(/[^A-Z]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countLowercase = (str: string) => {
		if (str.replace(/[^a-z]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countNumber = (str: string) => {
		if (str.replace(/[^0-9]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countCharacters = (str: string) => {
		if (str.length >= 8) {
			return true;
		}
		return false;
	};
	const countSpecial = (str: string) => {
		const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
		let count = 0;
		for (let i = 0; i < str.length; i++) {
			if (!punct.includes(str[i])) {
				continue;
			}
			count++;
		}
		if (count >= 1) {
			return true;
		}
		return false;
	};
	const handleChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
		var password = e.currentTarget.value;
		if (countUppercase(password)) {
			setHaveMinUppercase(true);
		} else {
			setHaveMinUppercase(false);
		}
		if (countLowercase(password)) {
			setHaveMinLowercase(true);
		} else {
			setHaveMinLowercase(false);
		}
		if (countNumber(password)) {
			setHaveMinNumber(true);
		} else {
			setHaveMinNumber(false);
		}
		if (countCharacters(password)) {
			setHaveMinCharacter(true);
		} else {
			setHaveMinCharacter(false);
		}
		if (countSpecial(password)) {
			setHaveMinSpecialCharacter(true);
		} else {
			setHaveMinSpecialCharacter(false);
		}
		setValue(field ? field : "password", e.currentTarget.value);
	};
	const showPasswordRule = () => {
		setIsShowPasswordRules((isShowPasswordRules) => !isShowPasswordRules);
	};
	return {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
	};
};
