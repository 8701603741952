import React from "react";
import { FaqItem, FaqItemSkeleton } from "./FaqItems";
import AddEditFaqModal from "./AddEditFaqModal";
import { useItem, usePagination } from "../../../../../utils/hooks";
import { useGetFaqsQuery } from "../../../../../utils/api/config/config.api";
import { Faq } from "../../../../../utils/api/config/config.type";
import { CustomPagination } from "../../../../common/CustomPagination";
import { AlertInfo } from "../../../../common/Alert";

const FaqAdmin = () => {
	const { limit, page, setPerPage, setPage } = usePagination(10);
	const { data, isLoading } = useGetFaqsQuery({ limit, page });
	const { item, getItem, resetItem } = useItem<Faq>();

	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="dashboard-praticien-table-recent-patient-container bg-white my-4">
					<div className="container-coolapse-questions mt-5">
						{!isLoading ? (
							data && data?.results?.length > 0 ? (
								<>
									{data?.results?.map((item) => (
										<FaqItem
											item={item}
											key={item?.slug}
											openEditModal={getItem}
										/>
									))}
									<CustomPagination
										nbPages={data?.count}
										page={page}
										onChange={(page, limit) => {
											setPerPage(limit);
											setPage(page);
										}}
										perPage={limit}
									/>
								</>
							) : (
								<AlertInfo message="Aucune donnée trouvée" />
							)
						) : (
							<>
								{[...Array(5)].map((item, i) => (
									<FaqItemSkeleton key={i} />
								))}
							</>
						)}
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="AddEditFaqModal"
				aria-labelledby="AddEditFaqModalLabel"
				aria-hidden="true"
			>
				<AddEditFaqModal item={item} reset={resetItem} />
			</div>
		</div>
	);
};
export default FaqAdmin;
