import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { IoLocationOutline, IoMailOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { useGetSocialMediaVisitorQuery } from "../../../utils/api/config/config.api";

const ContactInfos = () => {
	const { data } = useGetSocialMediaVisitorQuery();
	return (
		<motion.div
			initial={{
				x: -500,
			}}
			animate={{
				x: 0,
			}}
			transition={{
				duration: 1,
			}}
			className="content-col-left-contact-us-section w-100"
		>
			<h2 className="title-section-how-it-work-kiwu text-center">Contact</h2>
			<div className="container-list-section-contact mt-5">
				<div className="container-info-contact-us-section-item">
					<div className="content-icon-contact">
						<IoLocationOutline
							style={{
								fontSize: 50,
								color: "#D2F1FD",
							}}
						/>
					</div>
					<div className="content-text-info-contact-us">
						<p className="title-text-info-contact">Adresse </p>
						<p className="text-value-info-contact-us">
							{data?.adresse}
							{/* GADAYE, Prolongement VDN, Lot N 1, Cité
							<br />
							Dakar 15000, Sénégal */}
						</p>
					</div>
				</div>
				<div className="container-info-contact-us-section-item">
					<div className="content-icon-contact">
						<IoMailOutline
							style={{
								fontSize: 50,
								color: "#D2F1FD",
							}}
						/>
					</div>
					<div className="content-text-info-contact-us">
						<p className="title-text-info-contact">Adresse mail </p>
						<p className="text-value-info-contact-us">
							{data?.email}
							{/* contact@kivu.sn */}
						</p>
					</div>
				</div>
				<div className="container-info-contact-us-section-item">
					<div className="content-icon-contact">
						<FiPhoneCall
							style={{
								fontSize: 50,
								color: "#D2F1FD",
							}}
						/>
					</div>
					<div className="content-text-info-contact-us">
						<p className="title-text-info-contact">Téléphone </p>
						<p className="text-value-info-contact-us">
							{data?.telephone}
							{/* +221 77 299 0065 */}
						</p>
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default ContactInfos;
