import React from "react";
import { AiTwotoneEdit } from "react-icons/ai";
import { conditionType, ICondition } from "../../../../utils/api/condition/condition.type";
import { createMarkup } from "../../../../utils/utils";
import AddContentCguModal from "../../../modal/admin/AddContentCguModal";
export interface ConditionProps {
	content?: ICondition;
	type?: conditionType;
}
const Cgu = ({ content, type }: ConditionProps) => {
	return (
		<div className="container-termes-conditions-component">
			<div className="content-btn-add-theme flex-r mb-4">
				<button
					className="btn btn-export-csv"
					data-bs-toggle="modal"
					data-bs-target="#AddContentCguModal"
				>
					<AiTwotoneEdit style={{ color: "#08BB90", fontSize: 20 }} />
					<span className="ps-2">Modifier</span>
				</button>
			</div>
			<div className="content-text-conditions-termes">
				<div
					className="text-conditions-termes"
					dangerouslySetInnerHTML={createMarkup(content?.text)}
				></div>
			</div>
			<div
				className="modal fade"
				id="AddContentCguModal"
				aria-labelledby="AddContentCguModalLabel"
				aria-hidden="true"
			>
				<AddContentCguModal content={content} type={type} />
			</div>
		</div>
	);
};

export default Cgu;
