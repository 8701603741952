import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/Input";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { ConditionProps } from "../../TableauDebord/Admin/ParametreAdmin/Cgu";
import UseAddOrEditConditionForm from "../../TableauDebord/Admin/ParametreAdmin/hooks/UseAddOrEditConditionForm";

export function ConditionForm({ content, type, modalId }: ConditionProps & { modalId: string }) {
	const { onSubmit, errors, isLoading, text, onchange, setValue } = UseAddOrEditConditionForm(
		modalId,
		content
	);
	useEffect(() => {
		if (type) {
			setValue("type", type);
		}
	}, [type]);
	return (
		<form className="row" onSubmit={onSubmit}>
			<div className="col-12 mb-5">
				<div className="mb-4 form-group pt-3">
					<label className="form-label label-form-admin">Contenu</label>
					<span className="text-danger" style={{ fontSize: 24 }}>
						*
					</span>
					<ReactQuill
						className="editor-cgu"
						value={text}
						onChange={(value) => onchange(value)}
						data-testid="quillId"
						theme="snow"
					/>
				</div>

				{<FormError error={errors?.text} />}
			</div>
			<div className="gap-3 flex-r align-center row my-4">
				<div className="col-md-3">
					<button
						type="button"
						className="btn btn-cancelled"
						data-bs-dismiss="modal"
					>
						Fermer
					</button>
				</div>
				<div className="col-md-3 ">
					<BtnSubmit label="Modifier" isLoading={isLoading} />
				</div>
			</div>
		</form>
	);
}

const AddContentCguModal = ({ content, type }: ConditionProps) => {
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5
						className="modal-title admin-modal-title text-start"
						id="AddContentCguModal"
					>
						Modifier
					</h5>
					<CloseModalBtn />
				</div>
				<div className="form-container px-3">
					<ConditionForm
						content={content}
						type={type}
						modalId="AddContentCguModal"
					/>
				</div>
			</div>
		</div>
	);
};

export default AddContentCguModal;
