import React from "react";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import useCandidatureForm from "../useCandidatureForm";
import ErrorMessage from "../../../common/ErrorMessage";
import moment from "moment";
import { Modal, ModalBody } from "reactstrap";
import { IPoste } from "../../../../utils/api/candidature/candidature.type";
import ReactQuill from "react-quill";

const PostulerModal = ({ isOpen, close, item }: { isOpen: boolean; close: () => void; item?: IPoste }) => {
	const { register, onSubmit, errors, isLoading, handleChangeCv, handleChangeLettre, motivation, handleChangeMotivation } =
		useCandidatureForm(close, item);

	return (
		<div>
			<Modal isOpen={isOpen} size="lg" centered className="" role="dialog">
				<div className="modal-header pe-2 py-1 flex-r">
					<h2 className="modal-title">Postuler</h2>
					<CloseModalBtn onClick={close} />
				</div>
				<ModalBody>
					<div className="container-modal-postuler">
						<form onSubmit={onSubmit}>
							<div className="row row-form my-4">
								<div className="col-md-6 col-form-modal mb-3">
									<div className="content-col-form-modal">
										<label htmlFor="prenom" className="form-label form-label-modal">
											Prénom
											<span
												className="text-danger"
												style={{
													fontSize: 22,
												}}
											>
												*
											</span>
										</label>
										<input
											type="text"
											className="form-control form-control-modal"
											placeholder="Prénom"
											id="prenom"
											{...register("prenom")}
										/>

										{!!errors?.prenom && <ErrorMessage message={errors?.prenom?.message} />}
									</div>
								</div>
								<div className="col-md-6 col-form-modal mb-3">
									<div className="content-col-form-modal">
										<label htmlFor="nom" className="form-label form-label-modal">
											Nom
											<span
												className="text-danger"
												style={{
													fontSize: 22,
												}}
											>
												*
											</span>
										</label>
										<input
											type="text"
											className="form-control form-control-modal"
											placeholder="Nom"
											id="nom"
											{...register("nom")}
										/>

										{!!errors?.nom && <ErrorMessage message={errors?.nom?.message} />}
									</div>
								</div>
								<div className="col-md-6 col-form-modal mb-3">
									<div className="content-col-form-modal">
										<label htmlFor="email" className="form-label form-label-modal">
											Adresse mail
											<span
												className="text-danger"
												style={{
													fontSize: 22,
												}}
											>
												*
											</span>
										</label>
										<input
											type="text"
											className="form-control form-control-modal"
											placeholder="Adresse mail"
											id="email"
											{...register("email")}
										/>
										{!!errors?.email && <ErrorMessage message={errors?.email?.message} />}
									</div>
								</div>
								<div className="col-md-6 col-form-modal mb-3">
									<div className="content-col-form-modal">
										<label htmlFor="poste" className="form-label form-label-modal">
											Date de disponibilté
											<span
												className="text-danger"
												style={{
													fontSize: 22,
												}}
											>
												*
											</span>
										</label>
										<input
											type="date"
											className="form-control form-control-modal"
											min={moment(new Date()).format("YYYY-MM-DD")}
											defaultValue={moment(new Date()).format("YYYY-MM-DD")}
											{...register("date_disponibilite")}
										/>
										{!!errors?.date_disponibilite && (
											<ErrorMessage message={errors?.date_disponibilite?.message} />
										)}
									</div>
								</div>
								<div className="col-md-6 col-form-modal mb-3">
									<div className="content-col-form-modal">
										<label htmlFor="cv" className="form-label form-label-modal">
											Joindre un cv
											<span
												className="text-danger"
												style={{
													fontSize: 22,
												}}
											>
												*
											</span>
										</label>
										<input
											type="file"
											className="form-control form-control-modal"
											placeholder="Joindre un cv"
											id="cv"
											onChange={handleChangeCv}
											accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
										/>
										{!!errors?.cv && <ErrorMessage message={errors?.cv?.message} />}
									</div>
								</div>
								<div className="col-md-6 col-form-modal mb-3">
									<div className="content-col-form-modal">
										<label htmlFor="lettre" className="form-label form-label-modal">
											Joindre une lettre de motivation
										</label>
										<input
											type="file"
											className="form-control form-control-modal"
											placeholder="Joindre une lettre de motivation"
											id="lettre"
											onChange={handleChangeLettre}
											accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
										/>
										{!!errors?.lettre_de_motivation && (
											<ErrorMessage message={errors?.lettre_de_motivation?.message} />
										)}
									</div>
								</div>
								{/* <div className="col-md-6 col-form-modal mb-3">
									<div className="content-col-form-modal">
										<label
											htmlFor="poste"
											className="form-label form-label-modal"
										>
											Offre de poste
											<span
												className="text-danger"
												style={{
													fontSize: 22,
												}}
											>
												*
											</span>
										</label>
										<select
											className="form-control form-control-modal"
											style={{
												appearance: "auto",
											}}
											{...register("poste")}
										>
											<option value="">
												Choisir un poste
											</option>
											{postes?.map((item) => (
												<option value={item?.value}>
													{item?.label}
												</option>
											))}
										</select>

										{!!errors?.poste && (
											<ErrorMessage
												message={
													errors?.poste?.message
												}
											/>
										)}
									</div>
								</div> */}

								<div className="col-md-12 col-form-modal mb-3">
									<div className="content-col-form-modal">
										<label htmlFor="motivation" className="form-label form-label-modal">
											Motivation
										</label>
										{/* <textarea
											className="form-control form-control-modal"
											rows={4}
											{...register("motivation")}
											placeholder="Vos motivations"
											style={{
												minHeight: "170px",
												maxHeight: "170px",
											}}
										></textarea> */}
										<ReactQuill value={motivation} onChange={handleChangeMotivation} />
										{!!errors?.motivation && <ErrorMessage message={errors?.motivation?.message} />}
									</div>
								</div>
							</div>
							<div className="content-col-form-modal flex-r">
								<button className="btn btn-postuler" disabled={isLoading}>
									{isLoading ? (
										<span>
											En cours &nbsp;
											<i className="fas fa-spin fa-spinner"></i>
										</span>
									) : (
										"Envoyer"
									)}
								</button>
							</div>
						</form>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default PostulerModal;
