import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import logoHeader from "../../assets/landingpage/logo3.png";
import { motion } from "framer-motion";
import { useToggle } from "../../utils/hooks";
import { IoMdClose } from "react-icons/io";

export const variants = {
	open: { opacity: 1, x: 0, transition: { duration: 0.5 } },
	closed: { opacity: 0, x: "100%", transition: { duration: 0.7 } },
};

export function LinksList() {
	return (
		<>
			<li className="nav-item nav-item-li">
				<NavLink to="/" className="nav-link nav-link-linkv1">
					Accueil
				</NavLink>
			</li>
			<li className="nav-item nav-item-li">
				<NavLink to="/a-propos" className="nav-link nav-link-linkv1">
					À propos
				</NavLink>
			</li>
			<li className="nav-item nav-item-li">
				<NavLink className="nav-link nav-link-linkv1" to="/nous-rejoindre">
					Nous rejoindre
				</NavLink>
			</li>
			<li className="nav-item nav-item-li">
				<NavLink to="/nous-contacter" className="nav-link nav-link-linkv1">
					Nous contacter
				</NavLink>
			</li>
		</>
	);
}

const Navbar = () => {
	const { isOpen, toggleMenu } = useToggle();

	return (
		<nav className="navbar navbar-expand-lg navbar-light crypta-container mx-auto">
			<div className="container-fluid">
				<NavLink className="navbar-brand" to="/">
					<img
						src={logoHeader}
						alt="KanImmo"
						className="app-logo-admin m-0 app-logo-landingpage"
					/>
				</NavLink>
				<button className="navbar-toggler" type="button" onClick={toggleMenu}>
					<span className="navbar-toggler-icon">
						<AiOutlineMenuUnfold style={{ color: "#fff" }} />
					</span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNavLp">
					<div className="menu-landingpage-container ms-auto">
						<ul className="navbar-nav navbar-nav navbar-nav-header-landing-page me-auto mb-lg-0">
							<LinksList />
						</ul>
					</div>
				</div>
			</div>
			<motion.div
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				className="menu-mobile no-view-desktop"
			>
				<div className="d-flex justify-content-end mb-3">
					<button className="navbar-toggler" type="button" onClick={toggleMenu}>
						<span className="navbar-toggler-icon">
							<IoMdClose style={{ color: "#fff", fontSize: 24 }} />
						</span>
					</button>
				</div>
				<ul className="menu-mobile-list">
					<LinksList />
				</ul>
			</motion.div>
		</nav>
	);
};

export default Navbar;
