import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IScreen } from "../../../../../utils/api/screen/screen.type";
import { useDeleteScreenMutation, useGetScreensQuery } from "../../../../../utils/api/screen/screen.api";
import { useDelete, useItem, usePagination } from "../../../../../utils/hooks";
import { ButtonDelete, ButtonEdit } from "../../../../common/Button";
import { createMarkup, getImage, isShowPagination } from "../../../../../utils/utils";
import TableSkeleton from "../../../../common/TableSkeleton";
import { CustomPagination } from "../../../../common/CustomPagination";
import AddOrEditSreenModal from "./AddOrEditSreenModal";
import ChangePositionScreenModal from "../../../../modal/admin/ChangePositionScreenModal";


export function DeleteScreen({ item }: { item: IScreen }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteScreenMutation();
	const onDelete = useDelete<IScreen>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet écran",
		successMessage: "Écran supprimé avec succès !",
	});
	return <ButtonDelete onClick={onDelete} />;
}

function ScreensTable() {
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetScreensQuery({
		page,
		limit,
	});

	const { item, resetItem, getItem } = useItem<IScreen>();
    const imageFormatter = (cell: string, row: IScreen) => {
		return (
			<div className="screen_image__wrapper">
                <img src={getImage(cell)} alt={`screen-${row?.id}`} />
            </div>
		);
	};
	const actionFormatter = (cell: string, row: IScreen) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonEdit
						data-bs-toggle="modal"
						data-bs-target="#AddOrEditScreenModal"
						onClick={() => getItem(row)}
					/>
					<DeleteScreen item={row} />
				</div>
			</>
		);
	};
	const positionFormatter = (cell: string, row: IScreen) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<button
							className={`btn custon-btn-position px-4`}
							data-bs-toggle="modal"
							data-bs-target="#ChangePositionScreenModal"
							aria-expanded="false"
							onClick={() => getItem(row)}
						>
							{cell}
					</button>
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "image",
			text: "Image",
            formatter: (cell: string, row: IScreen) => imageFormatter(cell, row)
		},
        {
			dataField: "titre",
			text: "Titre",
		},
		{
			dataField: "desription",
			text: "Description",
			formatter: (cell: string) =>
				cell ? (
					<div
						dangerouslySetInnerHTML={createMarkup(
							cell?.slice(0, 200)
						)}
					/>
				) : (
					""
				),
		},
		{
			dataField: "position",
			text: "Position",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IScreen) =>
				positionFormatter(cell, row),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IScreen) =>
				actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() =>
							"Aucune donnée trouvée"
						}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="AddOrEditScreenModal"
				aria-labelledby="AddOrEditScreenModalLabel"
				aria-hidden="true"
			>
				<AddOrEditSreenModal item={item} resetItem={resetItem} />
			</div>
			<ChangePositionScreenModal item={item} resetItem={resetItem} />
		</>
	);
}

export default ScreensTable;
