import React from "react";
import { useGetModePaiementsForVisitorsQuery } from "../../../../utils/api/user/user.api";
import { getImage } from "../../../../utils/utils";
import { FormError } from "../../../common/Input";
import { useModePaiementListener } from "../../../../utils/pusher";

function ModePaiementStep({ register, errors, isLoading, onSubmit }) {
	useModePaiementListener();
	const { data } = useGetModePaiementsForVisitorsQuery();
	return (
		<form onSubmit={onSubmit}>
			<div className="d-flex  justify-content-center  gap-5 my-4">
				{data?.map((item) => (
					<div className="col-form-modal mb-3" key={item?.slug}>
						<label htmlFor={item?.nom} className="mode-paiement-item">
							<img
								src={getImage(item?.logo)}
								alt={item?.titre}
								className="w-100"
							/>
							<input
								type="radio"
								id={item?.nom}
								defaultValue={item?.id}
								{...register("moyen_paiement")}
								// hidden={true}
							/>
						</label>
					</div>
				))}
			</div>
			<FormError error={errors?.moyen_paiement} />
			<div className="content-col-form-modal flex-r">
				{!isLoading ? (
					<button
						className="btn btn-payer-pre-commande"
						title="Payer"
						type="submit"
					>
						Payer
					</button>
				) : (
					<button
						type="submit"
						className="btn btn-payer-pre-commande"
						disabled={isLoading}
					>
						<span
							className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
							role="status"
						></span>
						<span>Chargement...</span>
					</button>
				)}
			</div>
		</form>
	);
}

export default ModePaiementStep;
