import React from "react";
import { Link } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import { useDeleteForumMutation, useGetForumQuery, useGetThemesQuery } from "../../../../utils/api/forum/forum.api";
import { useDelete, usePagination, useSearch, useSelectSearch } from "../../../../utils/hooks";
import { AlertInfo } from "../../../common/Alert";
import moment from "moment";
import { getAvatar, getName, isShowPagination } from "../../../../utils/utils";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { IForum } from "../../../../utils/api/forum/forum.type";
import { CustomPagination } from "../../../common/CustomPagination";
import { InputFilter } from "../../../common/Input";
import { SelectInputFilter } from "../../../common/SelectInput";

export function ForumItemSkeleton() {
	return (
		<div className="mb-3">
			<div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
				<div className="row w-100 align-items-center">
					<div className="col-md-7">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
					</div>
					<div className="col-md-2">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
					</div>
					<div className="col-md-3">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
					</div>
				</div>
			</div>
		</div>
	);
}
export function DeleteForumItem({ item, redirectUrl }: { item: IForum; redirectUrl?: string }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteForumMutation();
	const onDelete = useDelete<IForum>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `cette discussion?`,
		successMessage: `Discussion supprimée`,
		redirectUrl: redirectUrl,
	});
	return (
		<button className="btn btn-delete-forum" onClick={onDelete}>
			<FiTrash2 />
		</button>
	);
}

const ListeForum = () => {
	const { search, handlefilter } = useSearch();
	const { search: theme, handlefilter: handleFilterThematique } = useSelectSearch();
	const { search: author, handlefilter: handleFilterUser } = useSearch();
	const { data: themes = { results: [] } } = useGetThemesQuery({});

	const { limit, page, setPage, setPerPage } = usePagination(10);
	const { data, isLoading } = useGetForumQuery({
		limit,
		page,
		author,
		title: search,
		theme,
	});
	return (
		<div className="tab-dash-admin-filter-container">
			<div className="mb-4">
				<div className="">
					<label htmlFor="filtreLogement" className="tab-dash-admin-filter-label">
						Filtrer
					</label>
				</div>
				<div className="d-flex flex-wrap gap-4">
					<InputFilter id="subject" placeholder="Entrer un sujet" onChange={handlefilter} />
					<SelectInputFilter
						id="theme"
						placeholder="Choisir un thème"
						onChange={handleFilterThematique}
						options={themes?.results?.map((item) => {
							return {
								label: item?.name,
								value: item?.slug,
							};
						})}
					/>
					<InputFilter id="auteur" placeholder="Auteur" onChange={handleFilterUser} />
				</div>
			</div>
			{!isLoading ? (
				data && data?.results?.length > 0 ? (
					data.results.map((item) => (
						<div className="mb-3 flex-m gap-3" key={item?.slug}>
							<div className="px-2 py-3 admin-message-contact-card w-100">
								<div>
									<Link to={`/admin/forum/${item?.slug}`} className="no-link" state={item}>
										<div className="row w-100 align-items-center">
											<div className="col-md-5">
												<h5
													className="forum-title d-flex btn p-0 mb-2 m-0"
													style={{
														textOverflow: "ellipsis",
													}}
													role="button"
												>
													{item?.title}
												</h5>
												<p className="forum-created_at m-0">
													{moment(item?.created_at)?.format("DD/MM/YYYY à HH:MM")}
												</p>
											</div>
											<div className="col-md-4">
												<div className="flex-sb align-items-center">
													<div>
														<span className="badge text-bg-primary my-2 py-2">
															{item?.theme?.name}
														</span>
														<p className="text-notif-type-message-forum">thème</p>
													</div>
													<div>
														<p className="text-nombre-message-forum">
															{item?.comments}
														</p>
														<p className="text-notif-type-message-forum">
															messages
														</p>
													</div>
													<div>
														<p className="text-nombre-message-forum">
															{item?.likes}
														</p>
														<p className="text-notif-type-message-forum">Likes</p>
													</div>
													<div>
														<p className="text-nombre-message-forum">
															{item?.dislikes}
														</p>
														<p className="text-notif-type-message-forum">Unlikes</p>
													</div>
												</div>
											</div>
											<div className="col-md-3 col-xl-2 offset-xl-1">
												<div className="d-flex align-items-center gap-3">
													<div>
														<img
															src={getAvatar(item?.author?.avatar)}
															alt="profil"
															className="img-pp-forum"
														/>
													</div>
													<div>
														<p className="text-notif-type-message-forum">
															Par{" "}
															<span
																style={{
																	fontWeight: 600,
																}}
															>
																{getName(item?.author)}
															</span>
														</p>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
							</div>
							<div>
								<DeleteForumItem item={item} />
							</div>
						</div>
					))
				) : (
					<AlertInfo message="Aucune donnée trouvée" />
				)
			) : (
				<>
					{[...Array(5)].map((item, i) => (
						<ForumItemSkeleton key={i} />
					))}
				</>
			)}
			{isShowPagination(data?.count || 0) && (
				<CustomPagination
					nbPages={data?.count}
					page={page}
					onChange={(page, limit) => {
						setPerPage(limit);
						setPage(page);
					}}
					perPage={limit}
				/>
			)}
		</div>
	);
};

export default ListeForum;
