import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDelete, useItem, usePagination } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonEdit, ButtonView } from "../../../common/Button";
import {
	useAddOrUpdatePosteMutation,
	useDeletePosteMutation,
	useGetPostesQuery,
} from "../../../../utils/api/candidature/candidature.api";
import AddPosteModal from "../../../modal/admin/AddPosteModal";
import { IPoste } from "../../../../utils/api/candidature/candidature.type";
import DetailsPosteModal from "../../../modal/admin/DetailsPosteModal";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";

export function DeletePoste({ item }: { item: IPoste }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeletePosteMutation();
	const onDelete = useDelete<IPoste>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet poste",
		successMessage: "Poste supprimée avec succès !",
	});
	return <ButtonDelete onClick={onDelete} />;
}

function ListePostesTable() {
	const { page, setPage, limit, setPerPage } = usePagination(10);

	const { data, isLoading } = useGetPostesQuery({
		page,
		limit,
	});
	const { item, getItem, resetItem } = useItem<IPoste>();
	const [toggleActif] = useAddOrUpdatePosteMutation();

	const onChange = async (item: IPoste) => {
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${
				item?.status === true ? "désactivé" : "activé"
			} ce poste ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: !item?.status ? Color.success : Color.danger,
			confirmButtonColor: !item?.status
				? Color.success
				: Color.danger,
			preConfirm: () => {
				return toggleActif({
					slug: item?.slug,
					data: { status: !item?.status },
				});
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			console.log(result, "valider");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Poste ${
						result?.value?.data?.is_active
							? "activée "
							: "désactivée"
					} avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};

	console.log({ data });

	const statutFormatter = (cell, row) => {
		return (
			<Switch
				checked={cell}
				handleDiameter={18}
				height={18}
				width={42}
				offHandleColor="#C4C4C4"
				onHandleColor="#C4C4C4"
				borderRadius={2}
				uncheckedIcon={
					<div className="toggle-style-off toggle-style">
						OFF
					</div>
				}
				checkedIcon={
					<div className="toggle-style-on toggle-style">
						ON
					</div>
				}
				className="react-switch"
				id="small-radius-switch"
				onChange={() => onChange(row)}
			/>
		);
	};

	const actionFormatter = (cell: string, row: IPoste) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonView
						data-bs-toggle="modal"
						data-bs-target={`#DetailPosteModal${cell}`}
					/>
					<ButtonEdit
						data-bs-toggle="modal"
						data-bs-target="#AddPosteModal"
						onClick={() => getItem(row)}
					/>

					<DeletePoste item={row} />
				</div>
				<div
					className="modal fade"
					id={`DetailPosteModal${cell}`}
					aria-labelledby="DetailPosteModalLabel"
					aria-hidden="true"
				>
					<DetailsPosteModal item={row} />
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: "Intitulé de poste",
		},
		{
			dataField: "type",
			text: "Type",
		},
		{
			dataField: "lieu",
			text: "Lieu",
		},
		{
			dataField: "status",
			text: "Statut",
			formatter: (cell: string, row: IPoste) =>
				statutFormatter(cell, row),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IPoste) =>
				actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() =>
							"Aucune donnée trouvée"
						}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="AddPosteModal"
				data-bs-backdrop="static"
				aria-labelledby="AddPosteModalLabel"
				aria-hidden="true"
			>
				<AddPosteModal item={item} resetItem={resetItem} />
			</div>
		</>
	);
}
export default ListePostesTable;
