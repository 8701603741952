import React from "react";
import ListeOffresTable from "./ListeOffresTable";
import { AiOutlinePlusSquare } from "react-icons/ai";

const ListeOffres = () => {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<h4 className="crypta-dash-header-titre">
						Offres
					</h4>
					<div className="content-btn-add-theme">
						<button
							className="btn btn-add-theme"
							data-bs-toggle="modal"
							data-bs-target="#AddOffreModal"
						>
							<AiOutlinePlusSquare
								style={{
									color: "#08BB90",
									fontSize: 20,
								}}
							/>
							<span className="ps-2">
								Ajouter une offre
							</span>
						</button>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<ListeOffresTable />
				</div>
			</div>
		</div>
	);
};

export default ListeOffres;
