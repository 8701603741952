/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { toast } from "react-hot-toast";
import { ITheme, ThemeFormData } from "../../../../../utils/api/forum/forum.type";
import { useAddOrUpdateThemeMutation } from "../../../../../utils/api/forum/forum.api";

function useCrudTheme(item?: ITheme, resetItem?: () => void) {
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Nom"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<ThemeFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdateTheme, { isLoading }] = useAddOrUpdateThemeMutation();

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("name", item?.name);
			setValue("description", item?.description);
		}
	}, [item]);

	const onSubmit = async (data: ThemeFormData) => {
		console.log("data", data);

		const res = await addOrUpdateTheme({
			slug: item?.slug,
			data: data,
		});
		if ("data" in res) {
			toast.success(
				!item ? "Thème ajouté avec succès" : "Le thème a été modifié avec succès !"
			);
			reset();
			onHide("AddThemeModal");
			resetItem && resetItem();
		}
		if ("error" in res) {
			const err = res.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useCrudTheme;
