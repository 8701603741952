import React from "react";
import ReactQuill from "react-quill";
import { IContact } from "../../../utils/api/contact/contact.type";
import { createMarkup, getName } from "../../../utils/utils";
import { AlertInfo } from "../../common/Alert";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/Input";
import CloseModalBtn from "../../shared/CloseModalBtn";
import useResponseContactForm from "../../TableauDebord/Admin/MessageContact/hooks/useResponseContactForm";
import moment from "moment";

const DetailsMessageModal = ({ item }: { item?: IContact }) => {
	console.log({ item });
	const { errors, onSubmit, response, handleChangeResponse, isLoading } =
		useResponseContactForm(item);
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
				<div className="modal-header b-b-0">
					<h5
						className="modal-title admin-modal-title text-start"
						id="DetailsMessageModal"
					>
						Réponse à ({item?.name})
					</h5>
					<CloseModalBtn />
				</div>
				<div className="form-container px-3">
					<div>
						<h5 className="modal-title admin-modal-title text-start fs-18 m-0 lh-28">
							Sujet:
						</h5>
						<p className="admin-content-message">{item?.subject}</p>
					</div>
					<div className="row border-bottom pb-2">
						<h5 className="modal-title admin-modal-title text-start fs-18 m-0 lh-28">
							Message:
						</h5>
						<div className="col-md-12 form-group">
							<div
								className="admin-content-message"
								dangerouslySetInnerHTML={createMarkup(
									item?.message
								)}
							/>
						</div>
					</div>
					<div className="my-2">
						<h5 className="modal-title admin-modal-title text-start fs-18 m-0">
							Réponses
						</h5>
						<div className="responses-container">
							{item?.responses && item?.responses?.length > 0 ? (
								item?.responses?.map((item) => (
									<div className="b-t-1 pt-3" key={item?.id}>
										<div
											className="admin-response-message"
											dangerouslySetInnerHTML={createMarkup(
												item?.response
											)}
										/>
										<p
											className="text-notif-type-message-forum mb-2"
											style={{ textAlign: "left" }}
										>
											Par{" "}
											<span
												style={{
													fontWeight: 600,
													paddingLeft: 5,
													paddingRight: 5,
												}}
											>
												{item?.user?.prenom
													? getName(item?.user)
													: "Admin"}
											</span>
											Le{" "}
											{moment(item?.created_at)?.format(
												"DD-MM-YYYY"
											)}
										</p>
									</div>
								))
							) : (
								<AlertInfo message="Aucune réponse" size="small" />
							)}
						</div>
					</div>
					<form onSubmit={onSubmit}>
						<div className="row mb-4 mt-4">
							<div className="col-md-12 mb-3">
								<div className="mb-4 form-group">
									<label className="form-label label-form-admin">
										Réponse
									</label>
									<ReactQuill
										value={response}
										onChange={handleChangeResponse}
									/>
									<FormError error={errors?.response} />
								</div>
							</div>
						</div>
						<div className="gap-3 flex-r align-center row my-4">
							<div className="col-md-3">
								<button
									type="button"
									className="btn btn-cancelled"
									data-bs-dismiss="modal"
								>
									Fermer
								</button>
							</div>
							<div className="col-md-3">
								<BtnSubmit label="Envoyer" isLoading={isLoading} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default DetailsMessageModal;
