import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl } from "../../utils";
import { IDossier, DossierQuery, DossierFormData, IConsultation, IRdv } from "./dossier.type";
import { useLocationState } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const DossierApi = createApi({
	reducerPath: "DossierApi",
	tagTypes: ["dossiers", "dossier"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getDossier: build.query<PaginationResults<IDossier>, DossierQuery>({
			query: (query) => QueryUrl("dossiers/", query),
			providesTags: ["dossiers"],
		}),
		editDossier: build.mutation<IDossier, DossierFormData>({
			invalidatesTags: ["dossiers"],
			query: ({ slug, ...data }) => {
				return {
					url: `dossiers/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		exportDossier: build.query<IDossier, void>({
			query: () => "export_dossier/",
		}),
		getConsultationsByDossier: build.query<PaginationResults<IConsultation>, DossierQuery>({
			query: ({ slug, ...query }) => QueryUrl(`dossiers/${slug}/consultations`, query),
			providesTags: ["dossiers"],
		}),
		getObservationsByDossier: build.query<PaginationResults<IConsultation>, DossierQuery>({
			query: ({ slug, ...query }) => QueryUrl(`dossiers/${slug}/observations`, query),
			providesTags: ["dossiers"],
		}),
		getRdvByDossier: build.query<IRdv, DossierQuery>({
			query: ({ slug, ...query }) => QueryUrl(`dossiers/${slug}/rdv`, query),
			providesTags: ["dossiers"],
		}),
		getDossierBySlug: build.query<IDossier, string>({
			query: (slug) => `dossiers/${slug}`,
			providesTags: ["dossier"],
		}),
	}),
});

export const {
	useGetDossierQuery,
	useEditDossierMutation,
	useExportDossierQuery,
	useLazyExportDossierQuery,
	useGetConsultationsByDossierQuery,
	useGetObservationsByDossierQuery,
	useGetRdvByDossierQuery,
	useLazyGetDossierBySlugQuery,
} = DossierApi;

export function useDossierFromLocation(): [IDossier, boolean, string, (id: string) => void] {
	const itemState = useLocationState<IDossier | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyGetDossierBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [findBySlug, itemState, slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError, itemState, navigate]);
	useEffect(() => {
		if (data) {
			// console.log("data",data)
			setItem(data);
		}
	}, [data]);

	return [item as IDossier, isLoading, slug as string, findBySlug];
}