import React from "react";
import ListeDemandesTable from "./ListeDemandesTable";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

const ListeDemandes = () => {
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="crypta-dash-locataire-requetes-header">
            <h4 className="crypta-dash-header-titre">Demandes de paiements</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <ListeDemandesTable />
        </div>
      </div>
    </div>
  );
};

export default ListeDemandes;
