import React from "react";
import { IImage } from "../../utils/type";
import { getImage } from "../../utils/utils";

interface Props {
	image?: File;
}
interface ImagesListProps {
	images?: (File | undefined)[];
}
interface ViewProps {
	image?: IImage;
}
interface ViewImagesListProps {
	images?: IImage[];
}
export const PreviewImage: React.FC<Props> = ({ image }) => {
	return (
		<div className="image-preview-contianer mt-1 mb-2" key={image?.name}>
			{image && (
				<img
					src={URL.createObjectURL(image)}
					alt={image?.name}
					className="w-100 img-thumbnail"
					style={{
						width: "200px",
						height: "150px",
						maxWidth: "200px",
					}}
				/>
			)}
		</div>
	);
};
export const PreviewImageList: React.FC<ImagesListProps> = ({ images }) => {
	return (
		<>
			{images && images?.length > 0 && (
				<div className="d-flex align-items-center gap-3 mt-3">
					{images?.map((item) => (
						<PreviewImage image={item} key={item?.name} />
					))}
				</div>
			)}
		</>
	);
};

export const ViewImage: React.FC<ViewProps> = ({ image }) => {
	return (
		<div className="image-preview-contianer" key={image?.slug}>
			{image && (
				<img
					src={getImage(image.picture)}
					alt={image.slug}
					className="w-100 img-thumbnail"
					style={{
						width: "200px",
						height: "150px",
						maxWidth: "200px",
					}}
				/>
			)}
		</div>
	);
};
export const ViewUniqueImage: React.FC<{ image?: string; isLink?: boolean }> = ({
	image,
	isLink,
}) => {
	return (
		<div className="image-preview-contianer mt-1 mb-1">
			{image && (
				<img
					src={isLink ? image : getImage(image)}
					alt={image}
					className="w-100 img-thumbnail"
					style={{
						width: "200px",
						height: "150px",
						maxWidth: "200px",
					}}
				/>
			)}
		</div>
	);
};
export const ViewImageList: React.FC<ViewImagesListProps> = ({ images }) => {
	return (
		<>
			{images && images?.length > 0 && (
				<div className="d-flex align-items-center gap-3 mt-3">
					{images?.map((item) => (
						<ViewImage image={item} key={item?.slug} />
					))}
				</div>
			)}
		</>
	);
};
