import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";

const AddCoinModal = () => {
  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header py-2 flex-m">
          <h5 className="admin-modal-title text-start">Ajouter un coin</h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="key"
                      className="form-label form-label-modal-custom"
                    >
                      API KEY
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control form-control-modal-custom"
                      id="key"
                    />
                  </div>
                </div>
              </div>
              <div className="container-btn-modal d-flex gap-3 mt-4">
                <button
                  type="button"
                  className="btn btn-cancelled"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </button>
                <button className="btn auth-submit-btn">Ajouter</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCoinModal;
