import React, { InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { useTogglePassword } from "../../utils/hooks";
import { GoFileMedia } from "react-icons/go";

type InputPropsType = InputHTMLAttributes<HTMLInputElement> & {
	label: string;
	error?: string | FieldError;
};

export function RequiredStar() {
	return (
		<span className="text-danger" style={{ fontSize: 24 }}>
			*
		</span>
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FormError({ error }: { error?: string | FieldError | any }) {
	const message = typeof error === "string" ? error : error ? error.message : error;
	if (!message) return null;

	return <ErrorMessage message={message} />;
}

export const AuthInput = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { name, error, id, label, required, type, readOnly, ...rest } = props;
	const { showPassword, toggleShowPassword } = useTogglePassword();
	if (type === "password") {
		return (
			<>
				<label htmlFor={id} className="form-label-login" aria-labelledby={id}>
					{label}
					{required && (
						<span className="text-danger" style={{ fontSize: 24 }}>
							*
						</span>
					)}
				</label>
				<div className="position-relative">
					<input
						className="form-control auth-form-control"
						name={name}
						id={id}
						ref={ref}
						readOnly={readOnly || false}
						{...rest}
						onWheel={(event) => event.currentTarget.blur()}
						type={showPassword ? "text" : "password"}
					/>
					<button
						style={{
							position: "absolute",
							backgroundColor: "transparent",
							border: "none",
							top: 13,
							right: 0,
						}}
						onClick={toggleShowPassword}
						type="button"
					>
						{!showPassword ? (
							<HiOutlineEye
								style={{
									fontSize: "26px",
									color: "#44415080",
								}}
							/>
						) : (
							<HiOutlineEyeOff
								style={{
									fontSize: "26px",
									color: "#44415080",
								}}
							/>
						)}
					</button>
				</div>
				{<FormError error={error} />}
			</>
		);
	}
	return (
		<>
			<label htmlFor={id} className="form-label-login" aria-labelledby={id}>
				{label}
				{required && (
					<span className="text-danger" style={{ fontSize: 24 }}>
						*
					</span>
				)}
			</label>
			<input
				className="form-control auth-form-control"
				name={name}
				id={id}
				ref={ref}
				readOnly={readOnly || false}
				{...rest}
				onWheel={(event) => event.currentTarget.blur()}
				type={type}
			/>
			{<FormError error={error} />}
		</>
	);
});

export const Input = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { name, error, id, label, required, readOnly, ...rest } = props;
	return (
		<>
			<div className="mb-3">
				<label
					htmlFor={id}
					className="form-label form-label-modal-custom"
					aria-labelledby={id}
				>
					{label}
					{required && (
						<span className="text-danger" style={{ fontSize: 24 }}>
							*
						</span>
					)}
				</label>
				<input
					className="form-control form-control-modal"
					name={name}
					id={id}
					ref={ref}
					readOnly={readOnly || false}
					{...rest}
					onWheel={(event) => event.currentTarget.blur()}
				/>
				{<FormError error={error} />}
			</div>
		</>
	);
});

export const AdminInput = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { name, error, id, label, required, type, readOnly, ...rest } = props;
	const { showPassword, toggleShowPassword } = useTogglePassword();
	if (type === "password") {
		return (
			<>
				<label
					htmlFor={id}
					className="form-label form-label-modal-custom"
					aria-labelledby={id}
				>
					{label}
					{required && (
						<span className="text-danger" style={{ fontSize: 24 }}>
							*
						</span>
					)}
				</label>
				<div className="position-relative">
					<input
						className="form-control form-control-modal-custom"
						name={name}
						id={id}
						ref={ref}
						readOnly={readOnly || false}
						{...rest}
						onWheel={(event) => event.currentTarget.blur()}
						type={showPassword ? "text" : "password"}
					/>
					<button
						style={{
							position: "absolute",
							backgroundColor: "transparent",
							border: "none",
							top: 13,
							right: 0,
						}}
						onClick={toggleShowPassword}
						type="button"
					>
						{!showPassword ? (
							<HiOutlineEye
								style={{
									fontSize: "26px",
									color: "#44415080",
								}}
							/>
						) : (
							<HiOutlineEyeOff
								style={{
									fontSize: "26px",
									color: "#44415080",
								}}
							/>
						)}
					</button>
				</div>
				{<FormError error={error} />}
			</>
		);
	}
	return (
		<>
			<div className="mb-3">
				<label
					htmlFor={id}
					className="form-label form-label-modal-custom"
					aria-labelledby={id}
				>
					{label}
					{required && (
						<span className="text-danger" style={{ fontSize: 24 }}>
							*
						</span>
					)}
				</label>
				<input
					className="form-control form-control-modal-custom"
					name={name}
					id={id}
					ref={ref}
					readOnly={readOnly || false}
					{...rest}
					onWheel={(event) => event.currentTarget.blur()}
					type={type}
				/>
				{<FormError error={error} />}
			</div>
		</>
	);
});

export const InputFilter = React.forwardRef<
	HTMLInputElement,
	InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
	return (
		<div className="col-auto">
			<input
				type="search"
				className="form-control tab-dash-admin-filter-input"
				{...props}
				ref={ref}
			/>
		</div>
	);
});

export const InputFile = React.forwardRef<HTMLInputElement, InputPropsType & { file?: File }>(
	(props, ref) => {
		const { label, id, required, error, file, ...rest } = props;
		return (
			<div className="mb-3">
				<label htmlFor={id} className="form-label">
					{label}
					{required && (
						<span className="text-danger" style={{ fontSize: 24 }}>
							*
						</span>
					)}
				</label>
				<label className="position-relative input-hidden-file" htmlFor={id}>
					<input
						className="form-control form-control-modal-custom"
						placeholder={label}
						id={id}
						{...rest}
						ref={ref}
						hidden={true}
					/>
					<div className="d-flex align-items-center gap-3">
						<GoFileMedia fontSize={20} color="06152b" />
						<span>
							{file ? file.name : "Choisir un fichier (maximum 10Mo)"}
						</span>
					</div>
				</label>
				{<FormError error={error} />}
			</div>
		);
	}
);
