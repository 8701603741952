import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Table.css";
import { useDelete, useItem } from "../../../../../utils/hooks";
import {
	useDeleteUserAccountMutation,
	useEditParticipantMutation,
	useGetParticipantsByUserQuery,
} from "../../../../../utils/api/user/user.api";
import { formatUserType, formattedDate } from "../../../../../utils/utils";
import { IParticipant, IUser } from "../../../../../utils/api/user/user.type";
import { ButtonDelete } from "../../../../common/Button";
import TableSkeleton from "../../../../common/TableSkeleton";
import { IoMdAttach } from "react-icons/io";
import { JustificatifModal } from "../../../../modal/admin/PieceJointeModal";
import Switch from "react-switch";
import { Color } from "../../../../../utils/theme";
import Swal from "sweetalert2";

export function DeleteUser({ item }: { item: IUser }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteUserAccountMutation();
	const typeUser = formatUserType(item?.user_type);
	const onDelete = useDelete<IUser>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: `ce ${typeUser}?`,
		successMessage: `${typeUser} supprimé`,
	});
	return <ButtonDelete onClick={onDelete} />;
}

function ListeParticipantTable({ userSlug }: { userSlug?: string }) {
	// const { page, setPage, perPage, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetParticipantsByUserQuery({
		slug: userSlug,
	});
	const { item, getItem } = useItem<IParticipant>();

	const actionFormatter = (cell: string, row: IParticipant) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<button
						className="btn btn-action-modal-icon with-tooltip"
						data-tooltip-content="Voir"
						data-bs-toggle="modal"
						data-bs-target="#PieceJointeModal"
						onClick={() => getItem(row)}
					>
						<IoMdAttach />
					</button>
					{/* <DeleteUser item={row} /> */}
				</div>
			</>
		);
	};
	const [editData] = useEditParticipantMutation();

	const onChange = async (item: IParticipant) => {
		let data: Partial<IParticipant> = {
			valider: !item?.valider,
		};
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${
				item?.valider === true ? "invalider" : "valider"
			} ce participant ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: !item?.valider ? Color.success : Color.danger,
			confirmButtonColor: !item?.valider ? Color.success : Color.danger,
			preConfirm: () => {
				return editData({ slug: item.slug, data: data });
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			console.log(result, "valider");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Participant ${
						result?.value?.data?.valider ? "validé " : "invalidé"
					} avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};
	const statutFormatter = (cell, row) => {
		return (
			<Switch
				checked={cell}
				handleDiameter={18}
				height={18}
				width={42}
				offHandleColor="#C4C4C4"
				onHandleColor="#C4C4C4"
				borderRadius={2}
				uncheckedIcon={<div className="toggle-style-off toggle-style">OFF</div>}
				checkedIcon={<div className="toggle-style-on toggle-style">ON</div>}
				className="react-switch"
				id="small-radius-switch"
				onChange={() => onChange(row)}
			/>
		);
	};

	const columns = [
		{
			dataField: "prenom",
			text: "Prénom",
		},
		{
			dataField: "nom",
			text: "Nom",
		},
		{
			dataField: "date_naissance",
			text: "Date de naissance",
		},
		{
			dataField: "lien_parente",
			text: "Lien de parenté",
		},
		{
			dataField: "adresse",
			text: "Adresse",
		},
		{
			dataField: "created_at",
			text: "Date d'inscription",
			formatter: (cell: string) => formattedDate(cell),
		},
		{
			dataField: "valider",
			text: "Statut",
			formatter: (cell: string, row: IParticipant) => statutFormatter(cell, row),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IParticipant) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucun participant trouvée"}
					/>
					{/* {isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, perPage) => {
								setPerPage(perPage);
								setPage(page);
							}}
							perPage={perPage}
						/>
					)} */}
				</>
			)}
			<div
				className="modal fade"
				id="PieceJointeModal"
				aria-labelledby="ListeDossiersModalLabel"
				aria-hidden="true"
			>
				<JustificatifModal item={item} />
			</div>
		</>
	);
}

export default ListeParticipantTable;
