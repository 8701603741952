/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, convertFileSizeByteToMegaByte, onHide } from "../../../../../utils/utils";
import { toast } from "react-hot-toast";
import { ModePaiment, ModePaimentFormData } from "../../../../../utils/api/config/config.type";
import { useAddOrUpdateModePaiementMutation } from "../../../../../utils/api/config/config.api";

function useCrudModePaiement(item?: ModePaiment, resetItem?: () => void) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("nom"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		setError,
	} = useForm<ModePaimentFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [image, setImage] = useState<File>();
	const [addOrUpdateitem, { isLoading }] = useAddOrUpdateModePaiementMutation();

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("titre", item?.titre);
			setValue("nom", item?.nom);
		}
	}, [item]);

	const handleChangeImage = (e: any) => {
		if (e.currentTarget.files) {
			console.log(e.currentTarget.files[0]);
			let file = e.currentTarget.files[0];
			console.log({ file });
			const sizeInBytes = file.size;
			const size = convertFileSizeByteToMegaByte(sizeInBytes);
			if (size > 10) {
				return setError("logo", { message: "Fichier trop volumineux" });
			}
			setImage(file);
			setValue("logo", file);
		}
	};

	const onSubmit = async (data: ModePaimentFormData) => {
		if (!item && !data?.logo) {
			return setError("logo", { message: "Image est un champ obligatoire" });
		}
		console.log("data", data);
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}
		fd.append("active", "true");
		const res = await addOrUpdateitem({
			slug: item?.slug,
			data: fd,
		});
		if ("data" in res) {
			toast.success(
				!item
					? "Mode de paiement ajouté avec succès"
					: "Le Mode de paiement a été modifié avec succès !"
			);
			reset();
			onHide("AddMoyenPaiementModal");
			setImage(undefined);
			resetItem && resetItem();
		}
		if ("error" in res) {
			const err = res.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		image,
		handleChangeImage,
		setImage,
		reset,
	};
}

export default useCrudModePaiement;
