import React from "react";
import ListeCandidaturesTable from "./ListeCandidaturesTable";
import { Link } from "react-router-dom";


const Candidatures = () => {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white">
				<div className="admin-page-title-header-container flex-r align-items-center">
					<div className="content-btn-add-theme">
						<Link
							to={"candidatures/postes"}
							className="btn btn-export-csv btn-candidate"
						>
							<span className="ps-2">Gestions des postes</span>
						</Link>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<ListeCandidaturesTable />
				</div>
			</div>
		</div>
	);
};

export default Candidatures;
