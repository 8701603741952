import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import {
	CandidatureQuery,
	ICandidature,
	IPoste,
	IResponseCandidature,
	PosteFormData,
	ResponseCandidatureFormData,
	TCandidatureForm,
} from "./candidature.type";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../utils";
import { prepareHeaders } from "../user/user.api";

export const CandidatureApi = createApi({
	reducerPath: "candidatureApi",
	tagTypes: ["candidature", "postes"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getCandidatures: builder.query<PaginationResults<ICandidature>, CandidatureQuery>({
			query: (query) => QueryUrl("candidatures/", query),
			providesTags: ["candidature"],
		}),
		addCandidature: builder.mutation<ICandidature, TCandidatureForm | FormData>({
			invalidatesTags: ["candidature"],
			query: (data) => {
				return {
					url: `visitor/candidatures/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteCandidature: builder.mutation<ICandidature, string>({
			query: (slug) => ({
				url: `/candidatures/${slug}/`,
				method: "Delete",
			}),
			invalidatesTags: ["candidature"],
		}),
		getPostes: builder.query<PaginationResults<IPoste>, TypeQuery>({
			query: (query) => QueryUrl("offres_de_poste/", query),
			providesTags: ["postes"],
		}),
		getVisitorPostes: builder.query<PaginationResults<IPoste>, TypeQuery>({
			query: (query) => QueryUrl("visitor/offres_de_poste/", query),
			providesTags: ["postes"],
		}),
		deletePoste: builder.mutation<ICandidature, string>({
			query: (slug) => ({
				url: `/offres_de_poste/${slug}/`,
				method: "Delete",
			}),
			invalidatesTags: ["postes"],
		}),
		addOrUpdatePoste: builder.mutation<
			IPoste,
			{
				slug?: string;
				data: PosteFormData;
			}
		>({
			invalidatesTags: ["postes"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `offres_de_poste/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `offres_de_poste/`,
					method: "POST",
					body: data,
				};
			},
		}),
		getResponsesByCandidature: builder.query<
			PaginationResults<IResponseCandidature>,
			TypeQuery
		>({
			query: ({ slug, ...query }) => QueryUrl(`candidature/${slug}/responses/`, query),
			providesTags: ["candidature"],
		}),
		responseCandidature: builder.mutation<
			IResponseCandidature,
			ResponseCandidatureFormData
		>({
			invalidatesTags: ["candidature"],
			query: (data) => {
				return {
					url: `response_candidatures/`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useAddCandidatureMutation,
	useGetCandidaturesQuery,
	useDeleteCandidatureMutation,
	useAddOrUpdatePosteMutation,
	useGetPostesQuery,
	useDeletePosteMutation,
	useGetVisitorPostesQuery,
	useGetResponsesByCandidatureQuery,
	useResponseCandidatureMutation,
} = CandidatureApi;
