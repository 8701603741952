import React from "react";
import { BtnSubmit } from "../../common/Button";
import CloseModalBtn from "../../shared/CloseModalBtn";
import useCrudAbonnement from "../../TableauDebord/Admin/Abonnements/hooks/useCrudAbonnement";
import { IAbonnements } from "../../../utils/api/abonnement/abonnement.type";
import Select from "react-select";
import { FormError } from "../../common/Input";

const AddAbonnementModal = ({
	item,
	resetItem,
}: {
	item?: IAbonnements;
	resetItem: () => void;
}) => {
	const {
		onSubmit,
		isLoading,
		handleChange,
		handleInputChange,
		filterOptions,
		loadOptions,
		inputValue,
		options,
		offres,
		handleSelectOffer,
		errors,
		modes,
		modeValue,
	} = useCrudAbonnement(resetItem, item);

	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="admin-modal-title text-start" id="changePasswordLabel">
						{item ? "Modifier l'" : "Ajouter une"} offre
					</h5>
					<CloseModalBtn onClick={resetItem} />
				</div>
				<div className="form-container p-3">
					<form onSubmit={onSubmit}>
						<div className="row">
							<div className="col-12">
								<div className="mb-3 react-custom-select-input">
									<label
										htmlFor="nombre_stock"
										className="form-label form-label-modal-custom"
									>
										Patient
										<span
											className="text-danger"
											style={{ fontSize: 17 }}
										>
											*
										</span>
									</label>
									<Select
										options={options}
										className="react-select-container c-react-select"
										classNamePrefix="react-select"
										inputValue={inputValue}
										onInputChange={handleInputChange}
										onChange={handleChange}
										filterOption={filterOptions}
										onMenuOpen={() =>
											loadOptions(inputValue)
										}
										menuIsOpen={inputValue.length >= 2}
										placeholder="Entrer le nom d'un patient"
									/>
									<FormError error={errors?.user} />
								</div>
							</div>
							<div className="col-12">
								<div className="mb-3 react-custom-select-input">
									<label
										htmlFor="nombre_stock"
										className="form-label form-label-modal-custom"
									>
										Offre
										<span
											className="text-danger"
											style={{ fontSize: 17 }}
										>
											*
										</span>
									</label>
									<Select
										options={offres}
										className="react-select-container c-react-select"
										classNamePrefix="react-select"
										onChange={handleSelectOffer}
										placeholder="Choisir une offre"
									/>
									<FormError error={errors?.offre} />
								</div>
							</div>
							<div className="col-12">
								<div className="mb-3 react-custom-select-input">
									<label
										htmlFor="nombre_stock"
										className="form-label form-label-modal-custom"
									>
										Mode de paiement
										<span
											className="text-danger"
											style={{ fontSize: 17 }}
										>
											*
										</span>
									</label>
									<Select
										options={modes}
										className="react-select-container c-react-select"
										classNamePrefix="react-select"
										isDisabled
										value={modeValue}
									/>
									<FormError error={errors?.moyen_paiement} />
								</div>
							</div>
						</div>
						<div className="container-btn-modal d-flex justify-content-end gap-3 mt-4">
							<button
								type="button"
								className="btn btn-cancelled"
								data-bs-dismiss="modal"
								// onClick={reset}
							>
								Fermer
							</button>
							<BtnSubmit
								isLoading={isLoading}
								label={item ? "Modifier" : "Ajouter"}
								style={{ width: "inherit" }}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddAbonnementModal;
