import React from "react";
import { TiExport } from "react-icons/ti";
import ListeDossiersTable from "./ListeDossiersTable";
import { ApiBaseUrl } from "../../../../utils/http";
import { useAppSelector } from "../../../../redux/hooks";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

const ListeDossiers = () => {
	const { token } = useAppSelector((s) => s?.user);

	const handleExportClick = () => {
		fetch(`${ApiBaseUrl}/api/export_dossier/`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "dossiers.xlxs");
				document.body.appendChild(link);
				link.click();
			});
	};

	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header">
						<h4 className="crypta-dash-header-titre">Dossiers</h4>
					</div>
					<div className="content-btn-add-theme">
						<button className="btn btn-export-csv" onClick={handleExportClick}>
							<TiExport style={{ color: "#08BB90", fontSize: 20 }} />
							<span className="ps-2">Export EN Xls</span>
						</button>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<ListeDossiersTable />
				</div>
			</div>
		</div>
	);
};

export default ListeDossiers;
