import React from "react";
import useContactForm from "./useContactForm";
import ErrorMessage from "../../common/ErrorMessage";
import { motion } from "framer-motion";
import ReactQuill from "react-quill";

const ContactFormBlock = () => {
	const { register, onSubmit, errors, isLoading, text, handleChangeMessage } = useContactForm();

	return (
		<motion.div
			initial={{
				x: 500,
			}}
			animate={{
				x: 0,
			}}
			transition={{
				duration: 1,
			}}
			className="content-col-right-contact-us-section w-100"
		>
			<h2 className="modal-title text-center">Écrivez-nous</h2>
			<div className="container-form-contact-us mt-5">
				<form onSubmit={onSubmit}>
					<div className="row row-form my-4">
						<div className="col-md-6 col-form-modal mb-3">
							<div className="content-col-form-modal">
								<label htmlFor="name" className="form-label form-label-modal">
									Votre nom
									<span
										className="text-danger"
										style={{
											fontSize: 24,
										}}
									>
										*
									</span>
								</label>
								<input
									type="text"
									className="form-control form-control-modal"
									placeholder="Votre nom"
									id="name"
									{...register("name")}
								/>

								{!!errors?.name && <ErrorMessage message={errors?.name?.message} />}
							</div>
						</div>
						<div className="col-md-6 col-form-modal mb-3">
							<div className="content-col-form-modal">
								<label htmlFor="email" className="form-label form-label-modal">
									Votre adresse mail
									<span
										className="text-danger"
										style={{
											fontSize: 24,
										}}
									>
										*
									</span>
								</label>
								<input
									type="text"
									className="form-control form-control-modal"
									placeholder="Votre adresse mail"
									id="email"
									{...register("email")}
								/>
								{!!errors?.email && <ErrorMessage message={errors?.email?.message} />}
							</div>
						</div>
						<div className="col-12 col-form-modal mb-3">
							<div className="content-col-form-modal">
								<label htmlFor="email" className="form-label form-label-modal">
									Sujet
									<span
										className="text-danger"
										style={{
											fontSize: 24,
										}}
									>
										*
									</span>
								</label>
								<input
									type="text"
									className="form-control form-control-modal"
									placeholder="Sujet"
									id="subject"
									{...register("subject")}
								/>
								{!!errors?.subject && <ErrorMessage message={errors?.subject?.message} />}
							</div>
						</div>
						<div className="col-md-12 col-form-modal mb-3">
							<div className="content-col-form-modal">
								<label htmlFor="message" className="form-label form-label-modal">
									Votre message
									<span
										className="text-danger"
										style={{
											fontSize: 24,
										}}
									>
										*
									</span>
								</label>
								{/* <textarea
									className="form-control form-control-modal"
									rows={4}
									{...register("message")}
									placeholder="Ecrivez votre message"
									style={{
										minHeight: "170px",
										maxHeight: "170px",
									}}
								></textarea> */}
								<ReactQuill
									value={text}
									onChange={handleChangeMessage}
									placeholder="Ecrivez votre message"
								/>
								{!!errors?.message && <ErrorMessage message={errors?.message?.message} />}
							</div>
						</div>
					</div>
					<div className="content-col-form-modal flex-r">
						<button className="btn btn-postuler" disabled={isLoading}>
							{isLoading ? (
								<span>
									En cours &nbsp;
									<i className="fas fa-spin fa-spinner"></i>
								</span>
							) : (
								"Envoyer"
							)}
						</button>
					</div>
				</form>
			</div>
		</motion.div>
	);
};

export default ContactFormBlock;
