/* eslint-disable @typescript-eslint/no-explicit-any */
import Pagination from "./Pagination";

type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-end">
			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}
