import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NewsletterFormData } from "../../../utils/api/newsletter/newsletter.type";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { useSubscribeNewsletterMutation } from "../../../utils/api/newsletter/newsletter.api";
import { Env, ApiBaseUrl } from "../../../utils/http";
import { cleannerError } from "../../../utils/utils";

export const useNewsLetter = () => {
  const validationSchema = yup.object().shape({
    email: yup.string().required().email().label("Adresse mail"),
  });
  const [subscribeNewsLetter, { isError, error, isSuccess, isLoading }] =
    useSubscribeNewsletterMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    clearErrors,
  } = useForm<NewsletterFormData>({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors, 3000);
    }
  }, [errors]);
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Souscription reussi!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      });
      reset();
    }
    if (isError) {
      const err = error as any;
      console.log(err.data);
      if (
        err?.data?.email?.includes("newsletter with this email already exists.")
      ) {
        setError("email", { message: "Cet email existe déjà!" });
      } else {
        const { message = "An error occured" } = err.data || {};
        setError("email", { message: message });
      }
    }
  }, [isLoading]);

  const onSubmit = (data: NewsletterFormData) => {
    data.env = window.origin;

    subscribeNewsLetter(data);
  };
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    errors,
    isLoading,
  };
};
