import React from "react";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import BreadCrumb from "../../shared/BreadCrumb";
import FooterLandingpage from "../FooterLandingpage/FooterLandingpage";
import HeaderLp from "../HeaderLp";
import PostulerModal from "./modal/PostulerModal";
import ButtonScrollTop from "../ButtonScrollTop";

const CategoryDetail = () => {
  const [modalPostuler, setModalPostuler] = React.useState(false);

  function openModalPostuler() {
    setModalPostuler(true);
  }
  return (
		<div className="join-us-component">
			<HeaderLp />
			<BreadCrumb title="Nous rejoindre" pageName="Nous rejoindre" />
			<section className="join-us-section">
				<div className="row row-join-us">
					<div className="col-md-9 col-left-category-detail mb-3">
						<div className="content-col-left-category-detail">
							<h2 className="title-category-detail">
								Lorem ipsum dolor sit amet, consectetur adipiscing
								elit. Quis.
							</h2>
							<div className="content-text-category-item-join-us pt-4">
								<p className="text-category-detail">
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Faucibus orci vitae sed arcu.
									Neque, ipsum urna pretium posuere viverra
									pellentesque leo urna leo. Posuere enim a
									accumsan quam dui ullamcorper sit. Ut aliquet
									nisl elementum faucibus. Maecenas dignissim
									nulla mauris urna, aliquet faucibus lobortis.
									Augue vitae nisl gravida urna nibh. Varius
									nulla dapibus hac curabitur dictumst senectus
									elit pharetra, et. Sit ac in ac dui ornare
									quis nullam sed. Dictum proin vitae et velit
									tempor leo nisi, diam habitant. Velit odio
									dolor pellentesque purus interdum at. Morbi
									vel phasellus non parturient molestie. Vel
									diam pellentesque non fringilla tristique
									dignissim dictum et est. Vulputate justo,
									aliquet tellus commodo pellentesque pretium
									pellentesque. A commodo, netus at proin
									commodo vestibulum. Habitant dignissim tellus
									purus vitae magna quam sed. Porttitor faucibus
									tellus metus lacus pellentesque convallis
									velit id. Odio ullamcorper ut porta
									sollicitudin. Diam cursus ultrices morbi vel,
									magnis. Sed montes, vivamus habitasse amet,
									proin. Faucibus senectus aliquam cursus
									convallis lacus dui faucibus. Dictum.
								</p>
								<p className="text-category-detail">
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Faucibus orci vitae sed arcu.
									Neque, ipsum urna pretium posuere viverra
									pellentesque leo urna leo. Posuere enim a
									accumsan quam dui ullamcorper sit. Ut aliquet
									nisl elementum faucibus. Maecenas dignissim
									nulla mauris urna, aliquet faucibus lobortis.
									Augue vitae nisl gravida urna nibh. Varius
									nulla dapibus hac curabitur dictumst senectus
									elit pharetra, et. Sit ac in ac dui ornare
									quis nullam sed. Dictum proin vitae et velit
									tempor leo nisi, diam habitant. Velit odio
									dolor pellentesque purus interdum at. Morbi
									vel phasellus non parturient molestie. Vel
									diam pellentesque non fringilla tristique
									dignissim dictum et est. Vulputate justo,
									aliquet tellus commodo pellentesque pretium
									pellentesque. A commodo, netus at proin
									commodo vestibulum. Habitant dignissim tellus
									purus vitae magna quam sed. Porttitor faucibus
									tellus metus lacus pellentesque convallis
									velit id. Odio ullamcorper ut porta
									sollicitudin. Diam cursus ultrices morbi vel,
									magnis. Sed montes, vivamus habitasse amet,
									proin. Faucibus senectus aliquam cursus
									convallis lacus dui faucibus. Dictum.
								</p>
							</div>
							<div className="share-info-container mt-4">
								<p className="text-share-info m-0">
									Partager l’info :
								</p>
								<a
									href="#"
									className="social-media-share"
									style={{ color: "#0678B6" }}
								>
									<AiFillLinkedin />
								</a>
								<a
									href="#"
									className="social-media-share"
									style={{ color: "#4B69B1" }}
								>
									<AiFillFacebook />
								</a>
								<a
									href="#"
									className="social-media-share"
									style={{ color: "#37B1E2" }}
								>
									<BsTwitter />
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-left-join-us mb-3">
						<div className="content-col-left-join-us">
							<div className="content-btn-postuler">
								<button
									className="btn btn-postuler"
									onClick={openModalPostuler}
								>
									Postuler
								</button>
								{/* <PostulerModal
                  modalPostuler={modalPostuler}
                  setModalPostuler={setModalPostuler}
                /> */}
							</div>
							<div className="container-category-detail-col-right mt-4">
								<h2 className="title-col-left-join-us">
									Autres offres
								</h2>
								<div className="content-category-detail-item mb-2">
									<p className="category-detail-title mb-1">
										Malesuada eget semper.
									</p>
									<p className="text-date-others-category mb-0">
										2 days ago
									</p>
								</div>
								<div className="content-category-detail-item mb-2">
									<p className="category-detail-title mb-1">
										Malesuada eget semper.
									</p>
									<p className="text-date-others-category mb-0">
										2 days ago
									</p>
								</div>
								<div className="content-category-detail-item mb-2">
									<p className="category-detail-title mb-1">
										Malesuada eget semper.
									</p>
									<p className="text-date-others-category mb-0">
										2 days ago
									</p>
								</div>
								<div className="content-category-detail-item mb-2">
									<p className="category-detail-title mb-1">
										Malesuada eget semper.
									</p>
									<p className="text-date-others-category mb-0">
										2 days ago
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<ButtonScrollTop />
			<FooterLandingpage />
		</div>
  );
};

export default CategoryDetail;
