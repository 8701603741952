import React from "react";
import { IOffres } from "../../../utils/api/offres/offres.type";
import {
	createMarkup,
	formatMontant,
	formatOffreFrequence,
	formatTaux,
} from "../../../utils/utils";
import CloseModalBtn from "../../shared/CloseModalBtn";

const DetailOffreModal = ({ item }: { item?: IOffres }) => {
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="admin-modal-title text-start" id="changePasswordLabel">
						Détail de l'offre #{item?.option}
					</h5>
					<CloseModalBtn />
				</div>
				<div className="form-container p-3">
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Titre</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{item?.title}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Fréquence</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{formatOffreFrequence(item?.frequence)}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Option</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{item?.option}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Prix</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{formatMontant(item?.price)}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">
								Prix rattachement adulte
							</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{formatMontant(item?.prix_rattachement_adulte)}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">
								Prix rattachement enfant
							</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{formatMontant(item?.prix_rattachement_enfant)}
							</p>
						</div>
					</div>

					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">
								Taux hospitalisation
							</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{formatTaux(item?.taux_hospitalisation)}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">
								Taux appareils
							</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{formatTaux(item?.taux_couverture_soins_esthetique)}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">
								Taux soins ordonnance
							</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{formatTaux(item?.taux_couverture_soins_ordonnance)}
							</p>
						</div>
					</div>
					<div className="align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Avantages</p>
						</div>
						<div className="content-text-info-transaction-value">
							<div
								dangerouslySetInnerHTML={createMarkup(
									item?.details
								)}
								className="text-info-transaction-value"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailOffreModal;
