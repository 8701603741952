import React from "react";
import PhoneInput from "react-phone-input-2";
import { BtnSubmit } from "../../common/Button";
import { AdminInput, FormError, RequiredStar } from "../../common/Input";
import CloseModalBtn from "../../shared/CloseModalBtn";
import useEditProfil from "../../TableauDebord/Admin/ParametreAdmin/hooks/useEditProfil";
import GoogleInput from "../../common/GoogleInput";

const EditNameModal: React.FC = () => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		phone,
		setPhone,
		onChangePhone,
		user,
		handleChangeAdress,
	} = useEditProfil();
	const reset = () => {
		setPhone("");
	};
	return (
		<div className="modal-dialog modal-dialog-centered">
			<div className="modal-content">
				<div className="modal-header">
					<h5
						className="admin-modal-title text-start"
						id="changePasswordLabel"
					>
						Modifier
					</h5>
					<CloseModalBtn />
				</div>
				<div className="modal-body">
					<form onSubmit={onSubmit}>
						<div className="row">
							<div className="col-md-12">
								<AdminInput
									label="Nom"
									id="nom"
									type="text"
									placeholder="Nom"
									{...register("nom")}
									error={errors?.nom}
									required
								/>
							</div>
							<div className="col-md-12">
								<AdminInput
									label="Prénom"
									id="prenom"
									type="text"
									placeholder="Prénom"
									{...register("prenom")}
									error={errors?.prenom}
									required
								/>
							</div>
							<div className="col-md-12">
								<AdminInput
									label="Email"
									required
									id="email"
									type="text"
									placeholder="Email"
									{...register("email")}
									error={errors?.email}
									inputMode="text"
								/>
							</div>
							<div className="col-md-12">
								<div className="col-md-12">
									<label
										htmlFor="adress"
										className="form-label form-label-modal-custom"
									>
										Adresse
										<RequiredStar />
									</label>
									<GoogleInput
										value={
											user?.adresse ??
											""
										}
										onChange={
											handleChangeAdress
										}
										className="form-control form-control-modal-custom"
									/>
									<FormError
										error={
											errors?.adresse
										}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="mb-3">
									<label
										htmlFor="telephone"
										className="form-label form-label-modal-custom"
									>
										Numéro de téléphone
										<RequiredStar />
									</label>
									<PhoneInput
										defaultMask={
											".. ... .. .."
										}
										containerClass="form-control auth-form-control mb-3 p-0"
										inputClass="form-control form-control-modal-custom"
										country={"sn"}
										placeholder=""
										inputProps={{
											name: "telephone",
											required: true,
										}}
										prefix="+"
										value={phone}
										onChange={(phone) =>
											onChangePhone(
												phone
											)
										}
										inputStyle={{
											width: "100%",
										}}
									/>
									<FormError
										error={
											errors
												?.telephone
												?.message
										}
									/>
								</div>
							</div>
						</div>
						<div className="gap-3 flex-r align-center mt-4">
							<button
								type="button"
								className="btn btn-cancelled"
								data-bs-dismiss="modal"
								onClick={reset}
							>
								Fermer
							</button>
							<BtnSubmit
								isLoading={isLoading}
								label="Modifier"
								style={{ width: "inherit" }}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
export default EditNameModal;
