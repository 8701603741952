import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useUpdateContactMutation } from "../../../utils/api/contact/contact.api";
import { IContact, TEditContactForm } from "../../../utils/api/contact/contact.type";
import { ContactMessageStatus } from "../../../utils/constantes";
import { QueryError } from "../../../utils/type";
import { cleannerError, onHide } from "../../../utils/utils";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/Input";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { toast } from "react-hot-toast";

function ChangeStatusMessageModal({ item }: { item?: IContact }) {
	const validationSchema = yup.object().shape({
		status: yup.string().required().label("Status"),
	});
	const [changeStatus, { isLoading }] = useUpdateContactMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<TEditContactForm>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
			setValue("status", item?.status);
		}
	}, [item]);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const onSubmit = async (data: TEditContactForm) => {
		const res = await changeStatus({ slug: data?.slug, data: data });
		if ("data" in res) {
			toast.success("Statut changé avec  succès!");
			reset();
			onHide("ChangeStatusMessageModal");
		} else if ("error" in res) {
			console.log("error", res.error);
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	return (
		<div
			className="modal fade"
			id="ChangeStatusMessageModal"
			aria-labelledby="ChangeStatusMessageModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="ChangeStatusMessageModal"
						>
							Changer le statut du message
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form className="w-100" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Statut du message
											<span className="text-danger">
												*
											</span>
										</label>
										<select
											className="form-control form-control-input-admin py-3"
											id="name"
											{...register("status")}
										>
											{/* <option value="">
												Choisir un statut
											</option> */}
											{ContactMessageStatus?.map(
												(option) => (
													<option
														value={
															option?.value
														}
														key={
															option.value
														}
													>
														{option?.label}
													</option>
												)
											)}
										</select>
										{errors?.status && (
											<FormError
												error={errors?.status}
											/>
										)}
									</div>
								</div>
							</div>
							<div className="flex-end mt-5 gap-4">
								<BtnSubmit
									label="Enregistrer"
									isLoading={isLoading}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStatusMessageModal;
