import React from "react";
import { IPoste } from "../../../utils/api/candidature/candidature.type";
import { createMarkup } from "../../../utils/utils";
import CloseModalBtn from "../../shared/CloseModalBtn";

const DetailsPosteModal = ({ item }: { item: IPoste }) => {
  return (
		<div className="modal-dialog modal-lg modal-dialog-centered modal-md">
			<div className="modal-content text-start">
				<div className="modal-header">
					<h5 className="admin-modal-title text-start" id="changePasswordLabel">
						Détail du poste
					</h5>
					<CloseModalBtn />
				</div>
				<div className="form-container p-3">
					<div className=" align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Titre</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">{item?.nom}</p>
						</div>
					</div>
					<div className=" align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">
								Type et Lieu
							</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{item?.details}
							</p>
						</div>
					</div>
					<div className="align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">
								Description
							</p>
						</div>
						<div className="content-text-info-transaction-value">
							<div
								dangerouslySetInnerHTML={createMarkup(
									item?.description
								)}
								className="text-info-transaction-value"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
  );
};

export default DetailsPosteModal;
