import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDelete, useItem, usePagination } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import {
	checkIfIsImage,
	getFileExtension,
	getImage,
	isShowPagination,
} from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import {
	useAddOrUpdateSlideMutation,
	useDeleteSlideMutation,
	useGetSlidesQuery,
} from "../../../../utils/api/slide/slide.api";
import { ISlide } from "../../../../utils/api/slide/slide.type";
import AddSlideModal from "../../../modal/admin/AddSlideModal";
import { ApiBaseUrl } from "../../../../utils/http";
import { Color } from "../../../../utils/theme";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
import { QueryError } from "../../../../utils/type";

export function DeleteSlide({ item }: { item: ISlide }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteSlideMutation();
	const onDelete = useDelete<ISlide>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette slide",
		successMessage: "Slide supprimée avec succès !",
	});
	return <ButtonDelete onClick={onDelete} />;
}

function ListeSlidesTable() {
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetSlidesQuery({
		page,
		limit,
	});

	const { item, resetItem, getItem } = useItem<ISlide>();
	const [editData, { isError, error }] = useAddOrUpdateSlideMutation();

	const actionFormatter = (cell: string, row: ISlide) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonEdit
						data-bs-toggle="modal"
						data-bs-target="#AddSlideModal"
						onClick={() => getItem(row)}
					/>
					<DeleteSlide item={row} />
				</div>
			</>
		);
	};
	const toggleActive = async (item: ISlide) => {
		let data = {
			active: !item.active,
		};
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${
				!item?.active === true ? "activer" : "désactiver"
			} cette slide?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.blue,
			confirmButtonColor: Color.blue,
			preConfirm: () => {
				return editData({ slug: item.slug, data: data });
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				toast.success(
					`Slide ${
						result?.value?.data?.active ? "activé " : "désactivé"
					} avec succèss!`
				);
			}
		});
		useEffect(() => {
			if (isError) {
				const err = error as QueryError;
				const message =
					err?.data?.message ??
					`Une erreur de status ${err?.status} est survenue!`;
				toast.error(message);
			}
		}, [isError]);
	};
	const statutFormatter = (cell, row) => {
		return (
			<Switch
				checked={cell}
				handleDiameter={18}
				height={18}
				width={42}
				offHandleColor="#C4C4C4"
				onHandleColor="#C4C4C4"
				borderRadius={2}
				uncheckedIcon={<div className="toggle-style-off toggle-style">Non</div>}
				checkedIcon={<div className="toggle-style-on toggle-style">Oui</div>}
				className="react-switch"
				id="small-radius-switch"
				onChange={() => toggleActive(row)}
			/>
		);
	};
	const imageFormatter = (cell: string) => {
		let type = getFileExtension(cell);
		let isImage = checkIfIsImage(cell);
		let isWebImage = cell?.includes('.webp');
		if (isImage || isWebImage) {
			return <img src={getImage(cell)} alt="Image du slide" width={200} height={130} />;
		} else {
			return (
				<video width="240" height="130" controls>
					<source src={ApiBaseUrl + cell} type={`video/${type}`} />
				</video>
			);
		}
	};
	const linkFormatter = (cell: string) => {
		return (
			<a href={cell} target="_blank" className="redirect-link">
				{cell}
			</a>
		);
	};
	const columns = [
		{
			dataField: "image",
			text: "Media",
			formatter: (cell: string) => imageFormatter(cell),
		},
		{
			dataField: "lien",
			text: "Lien",
			formatter: (cell: string) => linkFormatter(cell),
		},
		{
			dataField: "contenu",
			text: "Contenue",
		},
		{
			dataField: "active",
			text: "Statut",
			formatter: (cell: string, row: ISlide) => statutFormatter(cell, row),
			headerStyle: () => {
				return { width: "160px" };
			},
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: ISlide) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="AddSlideModal"
				aria-labelledby="AddSlideModalLabel"
				aria-hidden="true"
			>
				<AddSlideModal item={item} resetItem={resetItem} />
			</div>
		</>
	);
}

export default ListeSlidesTable;
