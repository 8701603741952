/* eslint-disable @typescript-eslint/no-explicit-any */
import Pusher from "pusher-js";
import React from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { OffresApi, useGetOffresQuery } from "./api/offres/offres.api";
import {
  ConfigApi,
  useGetGainsQuery,
  useGetModePaiementQuery,
} from "./api/config/config.api";
import { AdminApi, useGetAdminsListQuery } from "./api/admin/admin.api";
import { UserApi } from "./api/user/user.api";

// Enable pusher logging - don't include this in production
Pusher.logToConsole = false;

const pusher = new Pusher("a232c41131a6c1f26d22", {
  cluster: "eu",
});

const usePusher = (
  eventName: string,
  cb: (data: any) => any,
  userId: number
) => {
  React.useEffect(() => {
    const channel = pusher.subscribe("my-channel");
    const subscription = channel.bind(eventName, (data: any) => {
      cb(data);
    });
    return () => {
      try {
        if (subscription.subscribed) {
          subscription.unsubscribe();
        } else if (subscription.subscriptionPending) {
          subscription.cancelSubscription();
        }
      } catch (ex) {
        console.log("erorr ", ex);
      }
    };
  }, [userId]);
};

export const useGainListener = () => {
  const dispatch = useAppDispatch();
  useGetGainsQuery();
  const user = useAppSelector((s) => s.user.user);
  usePusher(
    "gain",
    ({ data }: { data: any }) => {
      if (data) {
        dispatch(ConfigApi.util.invalidateTags(["gains"]));
      }
    },
    user?.id
  );
};

export const useEchangeListener = () => {
  const dispatch = useAppDispatch();
  useGetGainsQuery();
  const user = useAppSelector((s) => s.user.user);
  usePusher(
    "echange",
    ({ data }: { data: any }) => {
      if (data) {
        dispatch(ConfigApi.util.invalidateTags(["echanges"]));
      }
    },
    user?.id
  );
};

export const useModePaiementListener = () => {
  const dispatch = useAppDispatch();
  useGetModePaiementQuery({});
  const user = useAppSelector((s) => s.user.user);
  usePusher(
    "mode_de_paiement",
    ({ data }: { data: any }) => {
      if (data) {
        dispatch(UserApi.util.invalidateTags(["modepaiement"]));
        dispatch(ConfigApi.util.invalidateTags(["modepaiements"]));
      }
    },
    user?.id
  );
};

export const useUserListener = () => {
  const dispatch = useAppDispatch();
  useGetAdminsListQuery({});
  const user = useAppSelector((s) => s.user.user);
  usePusher(
    "user",
    ({ data }: { data: any }) => {
      if (data) {
        dispatch(AdminApi.util.invalidateTags(["admin"]));
      }
    },
    user?.id
  );
};

export const useOffresListener = () => {
  const dispatch = useAppDispatch();
  useGetOffresQuery({});
  const user = useAppSelector((s) => s.user.user);
  usePusher(
    "offre",
    ({ data }: { data: any }) => {
      if (data) {
        dispatch(OffresApi.util.invalidateTags(["offres", "visitorsOffers"]));
        console.log("fetched");
      } else {
        console.log("error fetched");
      }
    },
    user?.id
  );
};
