import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../redux/hooks";
import { useAddResponseForumMutation } from "../../../../utils/api/forum/forum.api";
import { ForumResponseFormData, IForum } from "../../../../utils/api/forum/forum.type";
import { QueryError } from "../../../../utils/type";
import { cleannerError, onHide } from "../../../../utils/utils";
import { BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { toast } from "react-hot-toast";

function AddResponseForumModal({ item }: { item?: IForum }) {
	const validationSchema = yup.object().shape({
		content: yup.string().required().label("Réponse"),
	});
	const [sendResponse, { isLoading }] = useAddResponseForumMutation();
	const {
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
		setError,
		register,
	} = useForm<ForumResponseFormData>({
		resolver: yupResolver(validationSchema),
	});
	const { user } = useAppSelector((s) => s?.user);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const onSubmit = async (data: ForumResponseFormData) => {
		let regex = /(<([^>]+)>)/gi;
		let hasText = !!data?.content.replace(regex, "").trim().length;
		if (!hasText) {
			return setError("content", { message: "Réponse est un champ obligatoire" });
		}
		const newData: ForumResponseFormData = {
			...data,
			author: user?.id,
			forum: item?.id as number,
		};
		const res = await sendResponse(newData);
		if ("data" in res) {
			toast.success("Réponse envoyée avec succès");
			reset();
			onHide("AddResponseForumModal");
		} else if ("error" in res) {
			console.log("error", res.error);
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};

	return (
		<div
			className="modal fade"
			id="AddResponseForumModal"
			aria-labelledby="AddResponseForumModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="AddResponseForumModal"
						>
							Ajouter une réponse
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form className="w-100" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Réponse
											<span className="text-danger">
												*
											</span>
										</label>
										<textarea
											id="contenu"
											className="form-control"
											{...register("content")}
											style={{
												minHeight: "150px",
												maxHeight: "150px",
											}}
										/>
										<FormError error={errors?.content} />
									</div>
								</div>
							</div>
							<div className="flex-end mt-5 gap-4">
								<BtnSubmit
									label="Enregistrer"
									isLoading={isLoading}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddResponseForumModal;
