import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl } from "../../utils";
import { ITransaction, TransactionQuery } from "./transaction.type";

export const TransactionApi = createApi({
	reducerPath: "TransactionApi",
	tagTypes: ["transactions", "transactions"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getTransaction: build.query<PaginationResults<ITransaction>, TransactionQuery>({
			query: (query) => QueryUrl("transactions/", query),
			providesTags: ["transactions"],
		}),
	}),
});

export const { useGetTransactionQuery } = TransactionApi;
