import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";
import "./LineChart.css";

const LineChart = ({ graph, query }) => {
	if (!graph || graph.length === 0) {
		return <div>Aucune donnée disponible pour le graphique</div>;
	}
	const series = [
		{
			name: "Utilisateurs",
			data:
				query === "week"
					? graph?.map((item) => item?.nb_user || 0)
					: graph?.map((item) => item?.users_count || 0),
		},
	];
	const labels =
		query === "week"
			? graph?.map((item) => moment(item?.day).format("dddd"))
			: query === "month"
			? graph?.map((item) => moment(item?.start_day).format("DD MMMM"))
			: graph?.map((item) => moment(item?.month).format("MMMM"));

	const options = {
		chart: {
			height: 350,
			background: "#FFFFFF",
			type: "line",
			zoom: {
				enabled: true,
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: 2,
			curve: "smooth",
		},
		tooltip: {
			enabled: true,
			enabledOnSeries: undefined,
			shared: true,
			followCursor: false,
			intersect: false,
			inverseOrder: false,
			custom: undefined,
			fillSeriesColor: false,
			theme: false,
			style: {
				fontSize: "12px",
				fontFamily: undefined,
			},
			x: {
				show: true,
				formatter: undefined,
			},
			y: {
				formatter: undefined,
				title: {
					formatter: (seriesName) => seriesName,
				},
			},
			z: {
				formatter: undefined,
				title: "Size: 20px",
			},
			marker: {
				show: true,
			},
		},
		colors: ["#08BB90"],

		fill: {
			colors: ["#42922E"],
		},

		grid: {
			show: true,

			xaxis: {
				lines: {
					show: false,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
		xaxis: {
			categories: labels,
		},

		yaxis: {
			show: true,
		},

		legend: {
			show: false,
		},
	};

	return (
		<Chart
			className="chart-dashboard-marketplace"
			options={options}
			series={series}
			data="Soins"
			type="area"
			width="100%"
			height="350"
		/>
	);
};

export default LineChart;
