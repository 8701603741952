import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import logoHeader from "../../assets/landingpage/logo-footer.png";
import { motion } from "framer-motion";
import { useToggle } from "../../utils/hooks";
import { LinksList, variants } from "./Navbar";
import { IoClose } from "react-icons/io5";

const HeaderLp = () => {
	const { isOpen, toggleMenu } = useToggle();

	return (
		<div className="header-kiwu-theme">
			<nav className="navbar navbar-expand-lg navbar-light crypta-container bg-header-kiwu">
				<div className="container-fluid">
					<NavLink className="navbar-brand" to="/">
						<img
							src={logoHeader}
							alt="KanImmo"
							className="logo-lp-header"
						/>
					</NavLink>
					<button className="navbar-toggler" type="button" onClick={toggleMenu}>
						<span className="navbar-toggler-icon">
							<AiOutlineMenuUnfold style={{ color: "#fff" }} />
						</span>
					</button>
					<div className="collapse navbar-collapse" id="navbarNavLp">
						<div className="menu-landingpage-container ms-auto">
							<ul className="navbar-nav navbar-nav navbar-nav-header-landing-page me-auto mb-lg-0">
								<li className="nav-item nav-item-li">
									<NavLink
										to="/"
										className="nav-link nav-link-linkv1 nav-link-linkv2"
									>
										Accueil
									</NavLink>
								</li>
								<li className="nav-item nav-item-li">
									<NavLink
										to="/a-propos"
										className="nav-link nav-link-linkv1 nav-link-linkv2"
									>
										À propos
									</NavLink>
								</li>
								<li className="nav-item nav-item-li">
									<NavLink
										className="nav-link nav-link-linkv1 nav-link-linkv2"
										to="/nous-rejoindre"
									>
										Nous rejoindre
									</NavLink>
								</li>
								<li className="nav-item nav-item-li">
									<NavLink
										to="/nous-contacter"
										className="nav-link nav-link-linkv1 nav-link-linkv2"
									>
										Nous contacter
									</NavLink>
								</li>
							</ul>
						</div>
						{/* <div className="auth-btn-contact-us-container">
              <NavLink
                to="/nous-contacter"
                className="nav-link nav-link-linkv1 nav-link-linkv2"
              >
                Nous contacter
              </NavLink>
            </div> */}
					</div>
				</div>
				<motion.div
					animate={isOpen ? "open" : "closed"}
					variants={variants}
					className="menu-mobile no-view-desktop"
				>
					<div className="d-flex justify-content-end mb-3">
						<button
							className="navbar-toggler"
							type="button"
							onClick={toggleMenu}
						>
							<span className="navbar-toggler-icon">
								<IoClose style={{ color: "#fff", fontSize: 24 }} />
							</span>
						</button>
					</div>
					<ul className="menu-mobile-list">
						<LinksList />
					</ul>
				</motion.div>
			</nav>
		</div>
	);
};

export default HeaderLp;
