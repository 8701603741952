import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { IRemboursement } from "../../../utils/api/remboursement/remboursement.type";
import { ApiBaseUrl } from "../../../utils/http";
import { AlertInfo } from "../../common/Alert";
import { IDossier, IObservation } from "../../../utils/api/dossier/dossier.type";
import { IAbonnement, IParticipant } from "../../../utils/api/user/user.type";
import { ICandidature } from "../../../utils/api/candidature/candidature.type";

const PieceJointeModal = ({
	item,
	title,
}: {
	item?: IRemboursement | IDossier | IObservation;
	title?: string;
}) => {
	// console.log({ item });
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{title ?? "Pièces jointes"}
					</h5>
					<CloseModalBtn />
				</div>
				<div className="modal-body">
					<div className="container-form">
						{item?.fichier ? (
							<iframe
								height={500}
								width="100%"
								src={ApiBaseUrl + item?.fichier}
							/>
						) : (
							<AlertInfo message="Aucun fichier trouvé" />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export const JustificatifModal = ({ item }: { item?: IParticipant }) => {
	// console.log({ item });
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">Justifcatif</h5>
					<CloseModalBtn />
				</div>
				<div className="modal-body">
					<div className="container-form">
						{item?.justificatif ? (
							<iframe
								height={500}
								width="100%"
								src={ApiBaseUrl + item?.justificatif}
							/>
						) : (
							<AlertInfo message="Aucun fichier trouvé" />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};


export const FactureModal = ({ item }: { item?: IAbonnement }) => {
	// console.log({ item });
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">Facture</h5>
					<CloseModalBtn />
				</div>
				<div className="modal-body">
					<div className="container-form">
						{item?.facture ? (
							<iframe height={500} width="100%" src={item?.facture} />
						) : (
							<AlertInfo message="Aucun fichier trouvé" />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export const CandidatureFileView = ({
	item,
	type,
}: {
	item?: ICandidature;
	type: "cv" | "letter";
}) => {
	// console.log({ item });
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{type === "cv" ? "CV" : "Lettre de motivation"}
						{" de: "}
						{item?.prenom}
					</h5>
					<CloseModalBtn />
				</div>
				<div className="modal-body">
					{type === "cv" ? (
						<div className="container-form">
							{item?.cv ? (
								<iframe
									height={500}
									width="100%"
									src={ApiBaseUrl + item?.cv}
								/>
							) : (
								<AlertInfo message="Aucun fichier trouvé" />
							)}
						</div>
					) : (
						<div className="container-form">
							{item?.lettre_de_motivation ? (
								<iframe
									height={500}
									width="100%"
									src={ApiBaseUrl + item?.lettre_de_motivation}
								/>
							) : (
								<AlertInfo message="Aucun fichier trouvé" />
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default PieceJointeModal;
