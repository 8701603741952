import React from "react";
import { FormError, RequiredStar } from "../../../../common/Input";
import { BtnSubmit } from "../../../../common/Button";
import useGainForm from "../hooks/useGainForm";

const FormGain = () => {
	const { register, errors, onSubmit, isLoading, handleChecked, data } =
		useGainForm();

	return (
		<form onSubmit={onSubmit}>
			<div className="gain-level_list">
				<div className="gain-level_item">
					<div className="input">
						<input
							type="radio"
							id="eleve_is_active"
							className="form-check-input gain-form-check-input"
							name="gain"
							onChange={handleChecked}
							defaultChecked={data?.eleve_is_active}
						/>
						<label
							htmlFor="eleve_is_active"
							className="ms-2 gain-form_label"
						>
							Elevé
						</label>
					</div>
					<div className="col-md-12">
						<div className="item-c gap-2">
							<label
								htmlFor="nb_points_g1"
								className="gain-form_label"
							>
								Nombre de points
								<RequiredStar />:
							</label>
							<input
								className="form-control form-control-modal-custom"
								id="radio"
								type="text"
								placeholder="Nombre de points"
								{...register(
									"point_gain_eleve"
								)}
							/>
						</div>
						<FormError
							error={errors.point_gain_eleve}
						/>
					</div>
				</div>
				<div className="gain-level_item">
					<div className="input">
						<input
							type="radio"
							id="moyen_is_active"
							className="form-check-input gain-form-check-input"
							name="gain"
							onChange={handleChecked}
							defaultChecked={data?.moyen_is_active}
						/>
						<label
							htmlFor="moyen_is_active"
							className="ms-2 gain-form_label"
						>
							Moyen
						</label>
					</div>
					<div className="col-md-12">
						<div className="item-c gap-2">
							<label
								htmlFor="nb_points_g2"
								className="gain-form_label"
							>
								Nombre de points
								<RequiredStar />:
							</label>
							<input
								className="form-control form-control-modal-custom"
								id="nb_points_g2"
								type="text"
								placeholder="Nombre de points"
								{...register(
									"point_gain_moyen"
								)}
							/>
						</div>
						<FormError
							error={errors.point_gain_moyen}
						/>
					</div>
				</div>
				<div className="gain-level_item">
					<div className="input">
						<input
							type="radio"
							id="faible_is_active"
							className="form-check-input gain-form-check-input"
							name="gain"
							onChange={handleChecked}
							defaultChecked={
								data?.faible_is_active
							}
						/>
						<label
							htmlFor="faible_is_active"
							className="ms-2 gain-form_label"
						>
							Faible
						</label>
					</div>
					<div className="col-md-12">
						<div className="item-c gap-2">
							<label
								htmlFor="nb_points_g3"
								className="gain-form_label"
							>
								Nombre de points
								<RequiredStar />:
							</label>
							<input
								className="form-control form-control-modal-custom"
								id="nb_points_g3"
								type="text"
								placeholder="Nombre de points"
								{...register(
									"point_gain_faible"
								)}
							/>
						</div>
						<FormError
							error={errors.point_gain_faible}
						/>
					</div>
				</div>
			</div>
			<div className="flex-r">
				<BtnSubmit
					isLoading={isLoading}
					label="Enregistrer"
					style={{
						width: "inherit",
					}}
				/>
			</div>
		</form>
	);
};

export default FormGain;
