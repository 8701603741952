import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useItem, usePagination, useSearch } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { getName, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonView } from "../../../common/Button";
import { useGetSignalementsQuery } from "../../../../utils/api/signalements/signalements.api";
import { ISignalements } from "../../../../utils/api/signalements/signalements.type";
import DetailSignalementModal from "../../../modal/admin/DetailSignalementModal";
import { Link } from "react-router-dom";
import { InputFilter } from "../../../common/Input";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";

function ListeSignalementsTable() {
	const { page, setPage, limit, setPerPage } = usePagination(10);

	const { item, getItem } = useItem<ISignalements>();
	const { search, handlefilter } = useSearch();
	const { search: forum, handlefilter: handlefilterForum } = useSearch();
	const { search: comment, handlefilter: handlefilterComment } = useSearch();
	const { search: author, handlefilter: handlefilterByName } = useSearch();
	const { search: user, handlefilter: handlefilterByUser } = useSearch();

	const { data, isLoading } = useGetSignalementsQuery({
		page,
		limit,
		q: search,
		forum,
		comment,
		author,
		user,
	});

	const actionFormatter = (cell: string, row: ISignalements) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonView data-bs-toggle="modal" data-bs-target="#DetailSignalementModal" onClick={() => getItem(row)} />
				</div>
			</>
		);
	};

	const forumFormatter = (cell: string, row: ISignalements) => {
		if (row?.forum?.title) {
			return row?.forum?.title;
		} else {
			return "--";
		}
	};
	const commentFormatter = (cell: string, row: ISignalements) => {
		if (row?.comment?.content) {
			return row?.comment?.content?.slice(0, 50);
		} else {
			return "--";
		}
	};

	const authorFormatter = (cell: string, row: ISignalements) => {
		return (
			<Link to={`/admin/utilisateur/${row?.author?.slug}`} state={row?.author} className="redirect-link">
				{getName(row.author)}
			</Link>
		);
	};
	const userFormatter = (cell: string, row: ISignalements) => {
		return (
			<Link to={`/admin/utilisateur/${row?.user?.slug}`} state={row?.user} className="redirect-link">
				{getName(row.user)}
			</Link>
		);
	};

	const columns = [
		{
			dataField: "motif",
			text: "Motif",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="motif" placeholder="Motif" onChange={handlefilter} />,
		},
		{
			dataField: "forum",
			text: "Forum",
			formatter: (cell: string, row: ISignalements) => forumFormatter(cell, row),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="forum" placeholder="Forum" onChange={handlefilterForum} />,
		},
		{
			dataField: "comment",
			text: "Commentaire",
			formatter: (cell: string, row: ISignalements) => commentFormatter(cell, row),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="comment" placeholder="Commentaire" onChange={handlefilterComment} />,
		},
		{
			dataField: "author",
			text: "Utilisateur signalé",
			formatter: (cell: string, row: ISignalements) => userFormatter(cell, row),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="user" placeholder="Utilisateur" onChange={handlefilterByUser} />,
		},
		{
			dataField: "author",
			text: "Auteur",
			formatter: (cell: string, row: ISignalements) => authorFormatter(cell, row),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="author" placeholder="Auteur" onChange={handlefilterByName} />,
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: ISignalements) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div className="modal fade" id="DetailSignalementModal" aria-labelledby="DetailSignalementModal" aria-hidden="true">
				<DetailSignalementModal item={item} />
			</div>
		</>
	);
}
export default ListeSignalementsTable;
