import React, { useState } from "react";
import "./Remboursements.css";
import ListeAppareilsTable from "./ListeAppareilsTable";
import ListeHospitalisationsTable from "./ListeHospitalisationsTable";
import { ButtonAdd } from "../../../common/Button";

export function formatRemboursementStatus(status: string) {
	if (status === "en_attente") return "En attente";
	if (status === "acceptee") return "Validé";
	if (status === "rejetee") return "Rejeté";
	if (status === "annulee") return "Annulé";
	return "";
}

export const statusList = [
	{
		label: "En attente",
		value: "en_attente",
	},
	{
		label: "Accepté",
		value: "acceptee",
	},
	{
		label: "Réjeté",
		value: "rejetee",
	},
	{
		label: "Annulé",
		value: "annulee",
	},
];

const Remboursements = () => {
	const [activeTab, setActiveTab] = useState<string>("hospital");

	const onClickTab = (tab: string) => {
		setActiveTab(tab);
	};
	return (
		<div className="dashboard-admin-component pt-5">
			<div className="container-dashboard-page bg-white p-3">
				<ul className="nav nav-tabs nav-tabs-setting" id="myTab" role="tablist">
					<li className="nav-item nav-item-setting" role="presentation">
						<button
							className="nav-link nav-link-setting active"
							id="hospitalisation-tab"
							data-bs-toggle="tab"
							data-bs-target="#hospitalisation"
							type="button"
							role="tab"
							aria-controls="hospitalisation"
							aria-selected="true"
							onClick={() => onClickTab("hospital")}
						>
							Hospitalisation
						</button>
					</li>
					<li className="nav-item nav-item-setting" role="presentation">
						<button
							className="nav-link nav-link-setting"
							id="appareil-tab"
							data-bs-toggle="tab"
							data-bs-target="#appareil"
							type="button"
							role="tab"
							aria-controls="appareil"
							aria-selected="false"
							onClick={() => onClickTab("appareils")}
						>
							Appareils
						</button>
					</li>
				</ul>
			</div>

			<div className="tab-content bg-white" id="myTabContent">
				<div className="p-3">
					<div className="content-btn-add-theme flex-r mb-4">
						{activeTab === "hospital" ? (
							<ButtonAdd
								data-bs-toggle="modal"
								data-bs-target="#AddRemboursementModal"
								label="Ajouter une demande"
							/>
						) : (
							<ButtonAdd
								data-bs-toggle="modal"
								data-bs-target="#AddRemboursementAppareilModal"
								label="	Ajouter une demande"
							/>
						)}
					</div>
				</div>
				<div
					className="tab-pane fade show active"
					id="hospitalisation"
					role="tabpanel"
					aria-labelledby="hospitalisation-tab"
				>
					<div className="">
						<div className="container-dashboard-page bg-white px-3 mb-3">
							<div className="mb-3">
								<div className="">
									<ListeHospitalisationsTable />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="tab-pane fade" id="appareil" role="tabpanel" aria-labelledby="appareil-tab">
					<div className="container-dashboard-page bg-white p-3">
						<ListeAppareilsTable />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Remboursements;
