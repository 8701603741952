import React, { useState } from "react";
import { useLocationState } from "../../../../utils/hooks";
import { createMarkup, formattedDate } from "../../../../utils/utils";
import { BtnSubmit, ButtonGoBack } from "../../../common/Button";
import { ICandidature } from "../../../../utils/api/candidature/candidature.type";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { Color } from "../../../../utils/theme";
import { CandidatureFileView } from "../../../modal/admin/PieceJointeModal";
import useResponseCandidature from "./hooks/useResponseCandidature";
import ReactQuill from "react-quill";
import { FormError } from "../../../common/Input";
import { useGetResponsesByCandidatureQuery } from "../../../../utils/api/candidature/candidature.api";
import { AlertInfo } from "../../../common/Alert";

const DetailCandidature = () => {
	const item = useLocationState<ICandidature>(undefined);
	const [type, setType] = useState<"cv" | "letter">("cv");
	const getType = (type: "cv" | "letter") => {
		setType(type);
	};
	const { errors, onSubmit, response, handleChangeResponse, isLoading } = useResponseCandidature(item);

	const { data, isLoading: isLoad } = useGetResponsesByCandidatureQuery({ slug: item?.slug });

	console.log({ item });
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header d-flex align-items-center">
						<ButtonGoBack />
						<h4 className="crypta-dash-header-titre m-0">Détail candidature </h4>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="detail-transaction">
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">Prénoms et nom du candidat</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{item?.prenom} {item?.nom}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">Email du candidat</p>
							</div>
							<div className="content-text-info-transaction-value">
								<a href={`mailto:${item?.email}`} className="text-info-transaction-value">
									{item?.email}
								</a>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">Poste</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">{item?.poste?.nom}</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">Téléphone</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">{item?.telephone}</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">Date de disponibilité</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">{formattedDate(item?.date_disponibilite)}</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">Envoyée le</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">{formattedDate(item?.created_at)}</p>
							</div>
						</div>
						{item?.motivation && (
							<div className="mb-2">
								<div className="content-text-info-transaction-libelle">
									<p className="text-info-transaction-libelle">Motivation</p>
								</div>
								<div className="content-text-info-transaction-value">
									<div dangerouslySetInnerHTML={createMarkup(item?.motivation)} />
								</div>
							</div>
						)}
						<div className="mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">Documents</p>
							</div>
							<div className="content-btn-add-theme">
								<button
									className="btn btn-export-csv btn-candidate me-3"
									data-bs-toggle="modal"
									data-bs-target="#candidatureModal"
									onClick={() => getType("cv")}
								>
									<BsFileEarmarkPdf color={Color.danger} fontSize={24} />
									<span className="ps-2">Voir le CV</span>
								</button>
								{item?.lettre_de_motivation && (
									<button
										className="btn btn-export-csv btn-candidate"
										data-bs-toggle="modal"
										data-bs-target="#candidatureModal"
										onClick={() => getType("letter")}
									>
										<BsFileEarmarkPdf color={Color.danger} fontSize={24} />
										<span className="ps-2">Voir la lettre de motivation</span>
									</button>
								)}
							</div>
						</div>
						<div className="my-2">
							<h5 className="modal-title admin-modal-title text-start fs-18 m-0">Réponses</h5>
							<div className="responses-container">
								{!isLoad ? (
									data?.results && data?.results?.length > 0 ? (
										data?.results?.map((item) => (
											<div className="b-t-1 pt-3" key={item?.id}>
												<div
													className="admin-response-message"
													dangerouslySetInnerHTML={createMarkup(item?.response)}
												/>
											</div>
										))
									) : (
										<AlertInfo message="Aucune réponse" size="small" />
									)
								) : (
									<></>
								)}
							</div>
						</div>
						<form onSubmit={onSubmit}>
							<div className="row mb-4 mt-4">
								<div className="col-md-12 mb-3">
									<div className="mb-4 form-group">
										<label className="form-label label-form-admin">Réponse</label>
										<ReactQuill value={response} onChange={handleChangeResponse} />
										<FormError error={errors?.response} />
									</div>
								</div>
							</div>
							<div className="gap-3 flex-r align-center row my-4">
								<div className="col-md-3 col-xl-2">
									<BtnSubmit label="Envoyer" isLoading={isLoading} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div className="modal fade" id="candidatureModal" aria-labelledby="CandidatureModalLabel" aria-hidden="true">
				<CandidatureFileView item={item} type={type} />
			</div>
		</div>
	);
};

export default DetailCandidature;
