import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import { JoinUsData } from "../../../../../utils/api/config/config.type";
import {
	useGetJoinUsQuery,
	useUpdateJoinUsMutation,
} from "../../../../../utils/api/config/config.api";
import { QueryError } from "../../../../../utils/type";

function useJoinUs() {
	const validationSchema = yup.object().shape({
		join_us: yup.object({
			title: yup.string().required().label("Titre"),
		}),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<JoinUsData>({
		resolver: yupResolver(validationSchema),
	});

	const { data } = useGetJoinUsQuery();
	console.log({ data });
	const [updateBanner, { isLoading, isSuccess, error, isError }] = useUpdateJoinUsMutation();

	useEffect(() => {
		if (data) {
			setValue("join_us.title", data?.join_us.title);
		}
	}, [data]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Modifié avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			});
		}
		const err = error as QueryError;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : "Une erreur a survenu",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: JoinUsData) => {
		console.log(JSON.stringify(data));
		updateBanner(data);
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading: isLoading,
	};
}
export default useJoinUs;
