import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Faq, FaqFormData } from "../../../../../../utils/api/config/config.type";
import { useAddOrUpdateFaqMutation } from "../../../../../../utils/api/config/config.api";
import { cleannerError, onHide } from "../../../../../../utils/utils";
import { Color } from "../../../../../../utils/theme";
import { QueryError } from "../../../../../../utils/type";

function useFaqForm(item?: Faq) {
	const validationSchema = yup.object().shape({
		titre: yup.string().required().label("titre"),
		description: yup.string().required().label("Description"),
	});

	const [response, setResponse] = useState<string>();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<FaqFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdate, { isLoading }] = useAddOrUpdateFaqMutation();

	const onChangeResponse = (data: string) => {
		setResponse(data);
		setValue("description", data);
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.titre) {
			setValue("titre", item?.titre);
			setResponse(item?.description);
			setValue("description", item?.description);
		} else {
			setValue("titre", "");
			setResponse("");
			setValue("description", "");
		}
	}, [item]);

	const onSubmit = async (data: FaqFormData) => {
		const res = await addOrUpdate({ slug: item?.slug, data: data });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: item
					? "Question modifiée avec succès!"
					: "Question ajoutée avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				setResponse("");
				onHide("AddEditFaqModal");
			});
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${
							item ? "la modification" : "l'ajout"
					  }`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		onChangeResponse,
		response,
	};
}

export default useFaqForm;
