import Requette from "../../../../assets/icons/requette1.png";
import Requette2 from "../../../../assets/icons/requette2.png";
import ImgCoin1 from "../../../../assets/icons/coin1.png";
import ImgCoin2 from "../../../../assets/icons/coin2.png";
import ImgCoin3 from "../../../../assets/icons/coin3.png";
import ImgCoin4 from "../../../../assets/icons/coin4.png";

const GestionCoinsTableData = [
  {
    id: 1,
    requette: Requette,
    nom: "Bitcoin",
    image: ImgCoin1,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 2,
    requette: Requette2,
    nom: "Stellar",
    image: ImgCoin2,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: -5.65,
    notification: "inactif",
  },
  {
    id: 3,
    requette: Requette,
    nom: "Litecoin",
    image: ImgCoin3,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +1.44,
    notification: "actif",
  },
  {
    id: 4,
    requette: Requette2,
    nom: "Binance Coin",
    image: ImgCoin4,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: -1.36,
    notification: "actif",
  },
  {
    id: 5,
    requette: Requette,
    nom: "Bitcoin",
    image: ImgCoin1,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 6,
    requette: Requette,
    nom: "Bitcoin",
    image: ImgCoin2,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 7,
    requette: Requette2,
    nom: "Bitcoin",
    image: ImgCoin3,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 8,
    requette: Requette,
    nom: "Bitcoin",
    image: ImgCoin4,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 9,
    requette: Requette2,
    nom: "Bitcoin",
    image: ImgCoin1,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 10,
    requette: Requette,
    nom: "Bitcoin",
    image: ImgCoin1,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 11,
    requette: Requette2,
    nom: "Bitcoin",
    image: ImgCoin1,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 12,
    requette: Requette,
    nom: "Bitcoin",
    image: ImgCoin1,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 13,
    requette: Requette,
    nom: "Bitcoin",
    image: ImgCoin1,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 14,
    requette: Requette,
    nom: "Bitcoin",
    image: ImgCoin1,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
  {
    id: 15,
    requette: Requette,
    nom: "Bitcoin",
    image: ImgCoin1,
    prix: "$49,487.00",
    sousTitle: "BTC",
    commentPrix: +3.47,
    notification: "actif",
  },
];

export default GestionCoinsTableData;
