import React from "react";
import ListeUsers from "./ListeUsers";
import ListeDemandesCompteur from "./ListeDemandesCompteur";
import ListeDemandesSuppresionCompte from "./ListeDemandesSuppresionCompte";
import ListePrecommandes from "./ListePrecommandes";

function GestionUsers() {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<ul
						className="nav nav-tabs nav-tabs-setting"
						id="myTab"
						role="tablist"
					>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting active"
								id="pharmacie-tab"
								data-bs-toggle="tab"
								data-bs-target="#pharmacie"
								type="button"
								role="tab"
								aria-controls="pharmacie"
								aria-selected="true"
							>
								Liste des patients
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="preorder-tab"
								data-bs-toggle="tab"
								data-bs-target="#preorder"
								type="button"
								role="tab"
								aria-controls="preorder"
								aria-selected="true"
							>
								Liste des précommandes
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="medecin-tab"
								data-bs-toggle="tab"
								data-bs-target="#medecin"
								type="button"
								role="tab"
								aria-controls="medecin"
								aria-selected="false"
							>
								Demandes de réinitialisation de compteur
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="delAccount-tab"
								data-bs-toggle="tab"
								data-bs-target="#delAccount"
								type="button"
								role="tab"
								aria-controls="delAccount"
								aria-selected="false"
							>
								Demandes de suppresion de compte
							</button>
						</li>
					</ul>
				</div>
				<div className="mb-3">
					<div className="tab-content" id="myTabContent">
						<div
							className="tab-pane fade show active"
							id="pharmacie"
							role="tabpanel"
							aria-labelledby="pharmacie-tab"
						>
							<ListeUsers />
						</div>
						<div
							className="tab-pane fade show"
							id="preorder"
							role="tabpanel"
							aria-labelledby="preorder-tab"
						>
							<ListePrecommandes />
						</div>
						<div
							className="tab-pane fade"
							id="medecin"
							role="tabpanel"
							aria-labelledby="medecin-tab"
						>
							<ListeDemandesCompteur />
						</div>
						<div
							className="tab-pane fade"
							id="delAccount"
							role="tabpanel"
							aria-labelledby="del-account-tab"
						>
							<ListeDemandesSuppresionCompte />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GestionUsers;
