import React from "react";
import FooterLandingpage from "../FooterLandingpage/FooterLandingpage";
import HeaderLp from "../HeaderLp";
import "./JoinUs.css";
import { motion } from "framer-motion";
import ButtonScrollTop from "../ButtonScrollTop";
import { IPoste } from "../../../utils/api/candidature/candidature.type";
import { Link } from "react-router-dom";
import moment from "moment";
import { useGetVisitorPostesQuery } from "../../../utils/api/candidature/candidature.api";
import { usePagination } from "../../../utils/hooks";
import { AlertInfo } from "../../common/Alert";
import { useGetLandingDataQuery } from "../../../utils/api/config/config.api";
import { calculatePageCount } from "../../../utils/utils";
import ReactPagination from "../../common/ReactPagination";

function OfferItem({ item }: { item: IPoste }) {
  return (
    <Link
      to={`/postes/${item?.slug}`}
      className="joinus-offer-card flex-md-row p-3 mb-3"
      state={item}
    >
      <div>
        <h3>{item?.nom}</h3>
        <p>
          {item?.type} {item?.lieu && <> à {item?.lieu}</>}
        </p>
      </div>
      <div>
        <p>Publiée {moment(item.created_at).fromNow()}</p>
      </div>
    </Link>
  );
}

const JoinUs = () => {
  const { perPage, page, setPage } = usePagination(10);
  const { data: fronData } = useGetLandingDataQuery();
  const { data, isLoading } = useGetVisitorPostesQuery({
    limit: perPage,
    page,
  });

  const pageCount = calculatePageCount(data?.count, perPage);
  const handlePageClick = (event: { selected: number }) => {
    setPage(event?.selected + 1);
  };

  return (
    <div className="join-us-component overflow-x-hidden">
      <HeaderLp />
      <section className="container-section-about-us-page bg-gradient-theme mb-5 container-section-join-us-page">
        <motion.section
          initial={{
            x: -500,
          }}
          animate={{
            x: 0,
          }}
          transition={{
            duration: 1,
          }}
        >
          <div className="joinus-container">
            <h2 className="joinus-title">{fronData?.join_us?.title}</h2>
          </div>
          <div className="container-text-describe-kiwu-abot-us">
            <div className=" w-100">
              <div className="container-form-contact-us mt-5">
                {!isLoading ? (
                  data && data?.results?.length > 0 ? (
                    data?.results?.map((item) => (
                      <OfferItem key={item?.slug} item={item} />
                    ))
                  ) : (
                    <AlertInfo message="Aucune offre publiée encore" />
                  )
                ) : (
                  <></>
                )}
              </div>
              {data?.count && data?.count > perPage ? (
                <div className="d-flex justify-content-center pt-5">
                  <ReactPagination
                    handlePageClick={handlePageClick}
                    pageCount={pageCount}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </motion.section>
      </section>
      <ButtonScrollTop />
      <FooterLandingpage />
    </div>
  );
};

export default JoinUs;
