import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useDeleteFaqMutation } from "../../../../../utils/api/config/config.api";
import { useDelete, useToggle } from "../../../../../utils/hooks";
import { ButtonDelete, ButtonEdit } from "../../../../common/Button";
import { Faq } from "../../../../../utils/api/config/config.type";
import { createMarkup } from "../../../../../utils/utils";

export function DeleteFaq({ item }: { item?: Faq }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteFaqMutation();
	const onDelete = useDelete<Faq>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette question ?",
		successMessage: "Question supprimée",
	});
	return <ButtonDelete onClick={onDelete} />;
}

type FaqProps = {
	item?: Faq;
	openEditModal: (item?: Faq) => void;
};
export const FaqItem = ({ item, openEditModal }: FaqProps) => {
	const { isOpen, toggleMenu: toggleActive } = useToggle();
	return (
		<details className="card questioncard  mb-3">
			<summary
				className="card__header d-flex align-items-center justify-content-between px-2"
				onClick={toggleActive}
			>
				<h6 className="title-collapse-question mb-0">{item?.titre}</h6>
				<div className="d-flex align-items-center gap-2" onClick={toggleActive}>
					<ButtonEdit
						data-bs-toggle="modal"
						data-bs-target="#AddEditFaqModal"
						onClick={() => openEditModal(item)}
					/>
					<DeleteFaq item={item} />
					<div
						onClick={toggleActive}
						role="button"
						style={{ color: "#7c8087", fontSize: "20px" }}
					>
						{isOpen ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
					</div>
				</div>
			</summary>
			<div className="card__body">
				<div
					className="accordion-body text-dark mb-0 px-2 py-0"
					dangerouslySetInnerHTML={createMarkup(item?.description)}
				/>
			</div>
		</details>
	);
};

export const FaqItemSkeleton = () => {
	return (
		<div className="card card-collapse-questioncard mb-3">
			<div className="card__header gap-3">
				<div className="flex-fill">
					<VolkenoSkeleton width="100%" height={15} variant="rect" />
				</div>
				<VolkenoSkeleton width={100} height={15} variant="rect" />
			</div>
		</div>
	);
};
