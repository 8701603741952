import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { fr } from 'date-fns/locale';

interface DynamicDatePickerProps {
  date: Date;
  setDate: (date: Date) => void;
  query: string;
}

const DynamicDatePicker: React.FC<DynamicDatePickerProps> = ({ date, setDate, query }) => {
  const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
  const [showYearPicker, setShowYearPicker] = useState(false);

  useEffect(() => {
    setShowMonthYearPicker(query === 'month');
    setShowYearPicker(query === 'year');
  }, [query]);

  const renderDatePicker = () => {
    if (showYearPicker) {
      return (
        <DatePicker
          selected={date}
          onChange={(date: Date) => setDate(date)}
          showYearPicker
          dateFormat="yyyy"
          locale={fr}
          className="form-select form-select-date-graph-farmer"
        />
      );
    } else if (showMonthYearPicker) {
      return (
        <DatePicker
          selected={date}
          onChange={(date: Date) => setDate(date)}
          showMonthYearPicker
          dateFormat="MM/yyyy"
          locale={fr}
          className="form-select form-select-date-graph-farmer"
        />
      );
    } else {
      return (
        <DatePicker
          selected={date}
          onChange={(date: Date) => setDate(date)}
          maxDate={new Date()}
          dateFormat="dd/MM/yyyy"
          locale={fr}
          className="form-select form-select-date-graph-farmer"
        />
      );
    }
  };

  return renderDatePicker();
};

export default DynamicDatePicker;