import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BsTrashFill } from "react-icons/bs";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import GestionCoinsTableData from "./GestionCoinsTableData";
import GestionCoinsTableSkeleton from "./GestionCoinsTableSkeleton";

function GestionCoinsTable() {
  const [page] = useState(GestionCoinsTableData);
  const [isLoading] = React.useState();

  const nameFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <img
          src={row.image}
          alt="type document"
          className="photo-Bubblezone-table"
        />{" "}
        <div className="ps-2">
          <p className="mb-0">{row.nom}</p>
          <p className="mb-0 text-sous-titre-coin-table">{row.sousTitle}</p>
        </div>
      </div>
    );
  };

  const requetteFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <img
          src={row.requette}
          alt="requette"
          className="img-requette-coin-table"
        />
      </div>
    );
  };

  const prixFormatter = (cell, row) => {
    if (row.commentPrix > 0) {
      return (
        <div className="table-actions-btn-container-img text-right">
          <p className="mb-0">{row.prix}</p>
          <p className="text-green-prix-coin-table">+ {row.commentPrix}</p>
        </div>
      );
    }
    if (row.commentPrix < 0) {
      return (
        <div className="table-actions-btn-container-img text-right">
          <p className="mb-0">{row.prix}</p>
          <p className="text-red-prix-coin-table">{row.commentPrix}</p>
        </div>
      );
    }
  };

  const notifFormatter = (cell, row) => {
    if (cell === "actif") {
      return (
        <div className="text-center">
          <div className="switch2">
            <label className="switch">
              <input type="checkbox" />
              <span className="slider"></span>
            </label>
          </div>
        </div>
      );
    }
    if (cell === "inactif") {
      return (
        <div className="text-center">
          <div className="switch2">
            <label className="switch">
              <input type="checkbox" checked />
              <span className="slider"></span>
            </label>
          </div>
        </div>
      );
    }
  };

  const actionFormatter: any = ({ cell, row }: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-modal-icon"
              data-tooltip-content="Supprimer"
            >
              <BsTrashFill />
            </button>
          </div>
        </div>
      </>
    );
  };

  const options = {
    pageStartIndex: 1,
    sizePerPage: 5,
    hideSizePerPage: true,
  };

  const columns = [
    {
      dataField: "nom",
      text: "",
      formatter: (cell: any, row: any) => nameFormatter(cell, row),
    },
    {
      dataField: "requette",
      text: "",
      formatter: (cell: any, row: any) => requetteFormatter(cell, row),
    },
    {
      dataField: "prix",
      text: "",
      formatter: (cell: any, row: any) => prixFormatter(cell, row),
    },
    {
      dataField: "notification",
      text: "Actif",
      formatter: (cell: any, row: any) => notifFormatter(cell, row),
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
		<>
			{isLoading && <GestionCoinsTableSkeleton />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={page}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						pagination={paginationFactory(options)}
					/>
				</>
			)}
		</>
  );
}

export default GestionCoinsTable;
