import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDelete, useItem, usePagination } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { createMarkup, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import AddSpecialiteModal from "../../../modal/admin/AddSpecialiteModal";
import { Specialite } from "../../../../utils/api/config/config.type";
import {
	useDeleteSpecialiteMutation,
	useGetSpecialiteQuery,
} from "../../../../utils/api/config/config.api";

export function DeleteSpecialite({ item }: { item: Specialite }) {
	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteSpecialiteMutation();
	const onDelete = useDelete<Specialite>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette spécialité",
		successMessage: "Spécialité supprimée avec succès !",
	});
	return <ButtonDelete onClick={onDelete} />;
}

function ListeSpecialitesTable() {
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetSpecialiteQuery({
		page,
		limit,
	});

	const { item, resetItem, getItem } = useItem<Specialite>();

	const actionFormatter = (cell: string, row: Specialite) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonEdit
						data-bs-toggle="modal"
						data-bs-target="#AddSpecialiteModal"
						onClick={() => getItem(row)}
					/>
					<DeleteSpecialite item={row} />
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: "Titre",
		},
		{
			dataField: "desription",
			text: "Description",
			formatter: (cell: string) =>
				cell ? (
					<div
						dangerouslySetInnerHTML={createMarkup(
							cell?.slice(0, 200)
						)}
					/>
				) : (
					""
				),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: Specialite) =>
				actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() =>
							"Aucune donnée trouvée"
						}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="AddSpecialiteModal"
				aria-labelledby="AddSpecialiteModalLabel"
				aria-hidden="true"
			>
				<AddSpecialiteModal item={item} resetItem={resetItem} />
			</div>
		</>
	);
}

export default ListeSpecialitesTable;
