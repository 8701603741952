import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AdminQuery, GraphData, IAdmin, IAdminDashboardData, IAdminFormData } from "./admin.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, UserType } from "../user/user.type";
import { QueryUrl } from "../../utils";

export const AdminApi = createApi({
	reducerPath: "adminApi",
	tagTypes: ["admin", "dashboard"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		meAdmin: builder.query<IAdmin, void>({
			query: () => "auth/me-admin/",
			providesTags: ["admin"],
		}),
		getAdminsList: builder.query<PaginationResults<IAdmin>, AdminQuery>({
			query: (query) => QueryUrl("user/admins/", query),
			providesTags: ["admin"],
		}),
		addOrUpdateAdmin: builder.mutation<
			IAdmin,
			{
				slug?: string;
				user_type?: UserType.admin | UserType.superAdmin;
				data: IAdminFormData | FormData;
			}
		>({
			invalidatesTags: ["admin"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `user/admins/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `user/admins/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editAdmin: builder.mutation<
			IAdmin,
			{
				slug?: string;
				user_type?: "admin" | "superadmin";
				data: IAdminFormData | FormData;
			}
		>({
			query: ({ slug, user_type, data }) => {
				return {
					url: `${
						user_type === UserType.admin
							? `user/admins/${slug}/`
							: `users/${slug}/`
					}`,
					method: "PUT",
					body: data,
				};
			},
			invalidatesTags: ["admin"],
		}),
		deleteAdmin: builder.mutation<IAdmin, { slug: string; data: { code: string } }>({
			query: ({ slug, data }) => ({
				url: `user/${slug}/suppression/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["admin"],
		}),
		getAdminDashboardData: builder.query<IAdminDashboardData, { date: string; q?: string }>(
			{
				query: ({ date, q }) => ({
					url: "dashboard/",
					params: { date, q },
				}),
				providesTags: ["admin", "dashboard"],
			}
		),
		getDashboardGraphData: builder.query<GraphData, { date: string; q?: string }>({
			query: ({ date, q }) => ({
				url: "dashboardgraph/",
				params: { date, q },
			}),
			providesTags: ["admin", "dashboard"],
		}),
	}),
});
export const {
	useMeAdminQuery,
	useAddOrUpdateAdminMutation,
	useEditAdminMutation,
	useLazyMeAdminQuery,
	useGetAdminsListQuery,
	useDeleteAdminMutation,
	useGetAdminDashboardDataQuery,
	useGetDashboardGraphDataQuery,
} = AdminApi;
