import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDelete, useItem, usePagination, useSearch } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import { ITheme } from "../../../../utils/api/forum/forum.type";
import AddThemeModal from "../../../modal/admin/AddThemeModal";
import { useDeleteThemeMutation, useGetThemesQuery } from "../../../../utils/api/forum/forum.api";
import { InputFilter } from "../../../common/Input";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";

export function DeleteTheme({ item }: { item: ITheme }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteThemeMutation();
	const onDelete = useDelete<ITheme>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet thème",
		successMessage: "Thème supprimée avec succès !",
	});
	return <ButtonDelete onClick={onDelete} />;
}

function ListeThemesTable() {
	const { search, handlefilter } = useSearch();
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetThemesQuery({
		page,
		limit,
		name: search,
	});
	// console.log({ data });

	const { item, resetItem, getItem } = useItem<ITheme>();

	const actionFormatter = (cell: string, row: ITheme) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonEdit data-bs-toggle="modal" data-bs-target="#AddThemeModal" onClick={() => getItem(row)} />
					<DeleteTheme item={row} />
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "name",
			text: "Nom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="name" placeholder="Entrer un nom" onChange={handlefilter} />,
		},
		{
			dataField: "description",
			text: "Description",
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: ITheme) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div className="modal fade" id="AddThemeModal" aria-labelledby="AddThemeModalLabel" aria-hidden="true">
				<AddThemeModal item={item} resetItem={resetItem} />
			</div>
		</>
	);
}

export default ListeThemesTable;
