import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { AdminInput } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/Input";
import ReactQuill from "react-quill";
import useCrudSpecialite from "../../TableauDebord/Admin/ParametreAdmin/hooks/useCrudSpecialite";
import { Specialite } from "../../../utils/api/config/config.type";

const AddSpecialiteModal = ({
	item,
	resetItem,
}: {
	item?: Specialite;
	resetItem: () => void;
}) => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		reset,
		description,
		handleChangeDescription,
	} = useCrudSpecialite(item, resetItem);

	const resetAll = () => {
		resetItem();
		reset();
	};
	return (
		<div className="modal-dialog">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{item ? "Modifier la" : "Ajouter une"}{" "}
						spécialité
					</h5>
					<CloseModalBtn onClick={resetAll} />
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-12">
									<AdminInput
										label="Titre"
										id="titre"
										type="text"
										placeholder="Titre"
										{...register("nom")}
										error={errors?.nom}
										required
									/>
								</div>
								<div className="col-md-12">
									<label
										htmlFor="contenu"
										className="form-label form-label-modal-custom"
									>
										Description
									</label>
									<ReactQuill
										value={description}
										onChange={
											handleChangeDescription
										}
									/>
									<FormError
										error={
											errors?.desription
										}
									/>
								</div>
							</div>
							<div className="container-btn-modal d-flex justify-content-end gap-3 mt-4">
								<button
									type="button"
									className="btn btn-cancelled"
									data-bs-dismiss="modal"
									onClick={resetAll}
								>
									Fermer
								</button>
								<BtnSubmit
									isLoading={isLoading}
									label={
										item
											? "Modifier"
											: "Ajouter"
									}
									style={{
										width: "inherit",
									}}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddSpecialiteModal;
