import React from "react";
import { IUser } from "../../../../utils/api/user/user.type";
import { useItem, useLocationState, usePagination } from "../../../../utils/hooks";
import { BtnSubmit, ButtonGoBack } from "../../../common/Button";
import DetailUserModal from "../../../modal/admin/DetailUserModal";
import { IContact } from "../../../../utils/api/contact/contact.type";
import { createMarkup, getName, isShowPagination } from "../../../../utils/utils";
import moment from "moment";
import ReactQuill from "react-quill";
import { FormError } from "../../../common/Input";
import useResponseContactForm from "./hooks/useResponseContactForm";
import { AlertInfo } from "../../../common/Alert";
import { useGetResponsesContactMessagesQuery } from "../../../../utils/api/contact/contact.api";
import { CustomPagination } from "../../../common/CustomPagination";

const DetailMessage = () => {
	const item = useLocationState<IContact>(undefined);
	const { item: user, getItem } = useItem<IUser>();
	const { errors, onSubmit, response, handleChangeResponse, isLoading } =
		useResponseContactForm(item);
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data, isLoading: isLoad } = useGetResponsesContactMessagesQuery({
		slug: item?.slug,
		page,
		limit,
	});
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header d-flex align-items-center">
						<ButtonGoBack />
						<h4 className="crypta-dash-header-titre m-0">
							Réponse à ({item?.name})
						</h4>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="form-container px-3">
						<div>
							<h5 className="modal-title admin-modal-title text-start fs-18 m-0 lh-28">
								Sujet:
							</h5>
							<p className="admin-content-message">{item?.subject}</p>
						</div>
						<div className="row border-bottom pb-2">
							<h5 className="modal-title admin-modal-title text-start fs-18 m-0 lh-28">
								Message:
							</h5>
							<div className="col-md-12 form-group">
								<div
									className="admin-content-message"
									dangerouslySetInnerHTML={createMarkup(
										item?.message
									)}
								/>
							</div>
						</div>
						<div className="my-2">
							<h5 className="modal-title admin-modal-title text-start fs-18 m-0">
								Réponses
							</h5>
							<div className="responses-container">
								{!isLoad ? (
									data?.results && data?.results?.length > 0 ? (
										data?.results?.map((item) => (
											<div
												className="b-t-1 pt-3"
												key={item?.id}
											>
												<div
													className="admin-response-message"
													dangerouslySetInnerHTML={createMarkup(
														item?.response
													)}
												/>
												<p
													className="text-notif-type-message-forum mb-2"
													style={{
														textAlign:
															"left",
													}}
												>
													Par{" "}
													<span
														style={{
															fontWeight: 600,
															paddingLeft: 5,
															paddingRight: 5,
														}}
														className="hover__underline"
														role="button"
														data-bs-toggle="modal"
														data-bs-target="#DetailUserModal"
														onClick={() =>
															getItem(
																item?.user
															)
														}
													>
														{item?.user
															?.prenom
															? getName(
																	item?.user
															  )
															: "Admin"}
													</span>
													Le{" "}
													{moment(
														item?.created_at
													)?.format(
														"DD-MM-YYYY"
													)}
												</p>
											</div>
										))
									) : (
										<AlertInfo
											message="Aucune réponse"
											size="small"
										/>
									)
								) : (
									<></>
								)}
								{isShowPagination(data?.count || 0) && (
									<CustomPagination
										nbPages={data?.count}
										page={page}
										onChange={(page, limit) => {
											setPerPage(limit);
											setPage(page);
										}}
										perPage={limit}
									/>
								)}
							</div>
						</div>
						<form onSubmit={onSubmit}>
							<div className="row mb-4 mt-4">
								<div className="col-md-12 mb-3">
									<div className="mb-4 form-group">
										<label className="form-label label-form-admin">
											Réponse
										</label>
										<ReactQuill
											value={response}
											onChange={handleChangeResponse}
										/>
										<FormError error={errors?.response} />
									</div>
								</div>
							</div>
							<div className="gap-3 flex-r align-center row my-4">
								<div className="col-md-3 col-xl-2">
									<BtnSubmit
										label="Envoyer"
										isLoading={isLoading}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="DetailUserModal"
				aria-labelledby="DetailUserModalLabel"
				aria-hidden="true"
			>
				<DetailUserModal item={user} title="Informations sur l'auteur" />
			</div>
		</div>
	);
};

export default DetailMessage;
