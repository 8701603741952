import { ReactElement } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { Page403 } from "../components/shared/ErrorsPage/Errors";
import { useAppSelector } from "../redux/hooks";
import { AdminType, IAdmin } from "../utils/api/admin/admin.type";
import { IUser, UserType } from "../utils/api/user/user.type";
import { Authorisations, FieldType } from "../utils/constantes";

interface ProtectedRoutesProps {
	children: ReactElement;
	userType: UserType[];
}
export function isAdmin(user?: IUser | IAdmin) {
	return user && (user.user_type === UserType.admin || user.user_type === UserType.superAdmin);
}
export function isSuperAdmin(user?: IUser | IAdmin) {
	return user && user.user_type === UserType.superAdmin;
}

export function isSuperSuperAdmin(admin?: IAdmin) {
	if (admin) {
		return (
			admin.user_type === UserType.superAdmin ||
			admin.admin_type === AdminType.superadmin
		);
	}
	return false;
}

export const ProtectedRoutes = ({ children, userType }: ProtectedRoutesProps) => {
	let location = useLocation();
	const { user } = useAppSelector((state) => state.user);

	const isAccess = userType?.includes(user?.user_type);
	const auth = user?.id;
	return isAccess && isAccess !== undefined ? (
		children
	) : auth && auth !== undefined ? (
		<Navigate to="/" replace />
	) : (
		<Navigate to="/connexion" state={{ from: location }} replace />
	);
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
	const { token, user } = useAppSelector((state) => state.user);
	let path = "/admin/dashboard";
	if (user?.user_type === UserType.admin) {
		for (let index = 0; index < Authorisations.length; index++) {
			const element = Authorisations[index];
			if (user[`${element?.field}`] === true) {
				path = element.path;
				break;
			}
		}
	}

	return !token && !user?.id ? children : <Navigate to={path} replace />;
};

export const IsAdminAllow = ({ children, field }: { children: ReactElement; field: FieldType }) => {
	const { user } = useAppSelector((state) => state.user);
	const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
	if (!user[field] && !is_SuperAdmin) {
		return <Page403 />;
	}
	return children;
};