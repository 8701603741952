export enum conditionType {
	cgu = "cgu",
	policy = "policy",
	aide = "aide",
}
export interface ICondition {
	slug: string;
	text: string;
	type: conditionType;
}

export type ConditionFormData = {
	text: string;
	type: conditionType;
	code?: string;
};

export type ConditionUpdateFormData = ConditionFormData & {
	slug: ICondition["slug"];
};
