import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { QueryError } from "../../../../../utils/type";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { toast } from "react-hot-toast";
import {
	ICandidature,
	ResponseCandidatureFormData,
} from "../../../../../utils/api/candidature/candidature.type";
import { useResponseCandidatureMutation } from "../../../../../utils/api/candidature/candidature.api";

const useResponseCandidature = (item?: ICandidature) => {
	const validationSchema = yup.object().shape({
		response: yup.string().required().label("Le réponse"),
	});
	const [response, setResponse] = useState<string>("");
	const [sendMessage, { isLoading }] = useResponseCandidatureMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<ResponseCandidatureFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const handleChangeResponse = (value: string) => {
		setResponse(value);
		setValue("response", value);
	};

	const onsubmit = async (data: ResponseCandidatureFormData) => {
		const res = await sendMessage({
			...data,
			candidature: item?.id as number,
			subject: `Candidature pour le poste ${item?.poste?.nom}`,
		});
		if ("data" in res) {
			toast.success("Réponse envoyée avec succès!");
			reset();
			setResponse("");
			onHide("DetailsMessageModal");
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		response,
		handleChangeResponse,
		setResponse,
	};
};

export default useResponseCandidature;
