import React from "react";
import { useGetRdvByDossierQuery } from "../../../../utils/api/dossier/dossier.api";
import { formatRdvStatus, formattedDate, getName } from "../../../../utils/utils";
import DetailUserModal from "../../../modal/admin/DetailUserModal";
import { useItem } from "../../../../utils/hooks";
import { IUser } from "../../../../utils/api/user/user.type";

const Rdv = ({ slug }: { slug?: string }) => {
	const { data: rdv } = useGetRdvByDossierQuery({
		slug,
	});
	const { item: user, getItem } = useItem<IUser>();
	const handleClick = (item?: IUser) => {
		if (item) {
			getItem(item);
		}
	};
	return (
		<>
			<div className="py-2 row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Praticien</p>
				</div>
				<div className="col-md-8">
					<p
						className="text-info-profil-admin-value underline"
						data-bs-toggle="modal"
						data-bs-target="#DetailUserModal"
						role="button"
						onClick={() => handleClick(rdv?.professionnel)}
					>
						{getName(rdv?.professionnel)}
					</p>
				</div>
			</div>
			<div className="py-2 row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Date</p>
				</div>
				<div className="col-md-8">
					<p className="text-info-profil-admin-value">
						{formattedDate(rdv?.date)}
					</p>
				</div>
			</div>
			<div className="py-2 row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Heure</p>
				</div>
				<div className="col-md-8">
					<p className="text-info-profil-admin-value">{rdv?.heure}</p>
				</div>
			</div>
			<div className="py-2 row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Adresse</p>
				</div>
				<div className="col-md-8">
					<p className="text-info-profil-admin-value">{rdv?.adresse}</p>
				</div>
			</div>
			<div className="py-2  row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Statut</p>
				</div>

				<div className="col-md-8">
					<p className="text-info-profil-admin-value text-capitalize">
						{formatRdvStatus(rdv?.etat)}
					</p>
				</div>
			</div>
			<div className="py-2  row container-info-profil-admin">
				<div className="col-md-4">
					<p className="text-info-profil-admin-libelle">Honoré</p>
				</div>

				<div className="col-md-8">
					<p className="text-info-profil-admin-value text-capitalize">
						{rdv?.is_honored ? "Oui" : "Non"}
					</p>
				</div>
			</div>
			{rdv?.motif && (
				<div className="py-2 row container-info-profil-admin">
					<div className="col-md-4">
						<p className="text-info-profil-admin-libelle">Motif</p>
					</div>
					<div className="col-md-8">
						<p className="text-info-profil-admin-value">{rdv?.motif}</p>
					</div>
				</div>
			)}
			<div
				className="modal fade"
				id="DetailUserModal"
				aria-labelledby="DetailUserModalLabel"
				aria-hidden="true"
			>
				<DetailUserModal item={user} title="Informations du praticien" />
			</div>
		</>
	);
};

export default Rdv;
