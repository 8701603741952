import React from "react";
import { CustomPagination } from "../../../common/CustomPagination";
import { formatMontant, formattedDate, isShowPagination } from "../../../../utils/utils";
import TableSkeleton from "../../../common/TableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import { usePagination } from "../../../../utils/hooks";
import { useGetConsultationsByDossierQuery } from "../../../../utils/api/dossier/dossier.api";

const HistoriquesConsultations = ({ slug }: { slug?: string }) => {
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetConsultationsByDossierQuery({
		slug,
		page,
		limit,
	});

	const columns = [
		{
			dataField: "libelle",
			text: "Nom du document",
		},
		{
			dataField: "created_at",
			text: "Date ",
			formatter: (cell: string) => formattedDate(cell),
		},
		{
			dataField: "montant",
			text: "Montant",
			formatter: (cell: string) => formatMontant(cell),
		},
		{
			dataField: "dossier.paiement.paid",
			text: "Paiement",
			formatter: (cell: string) => (cell ? "Effectué" : "Non effectué"),
		},
		// {
		// 	dataField: "ordonnance",
		// 	text: "Ordonnance",
		// },
		// {
		// 	dataField: "id",
		// 	text: "Actions",
		// 	style: { textAlign: "center" },
		// 	formatter: (cell: string, row: IConsultation) => actionFormatter(cell, row),
		// },
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
		</>
	);
};

export default HistoriquesConsultations;
