/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { toast } from "react-hot-toast";
import { useAddOrUpdatePosteMutation } from "../../../../../utils/api/candidature/candidature.api";
import { IPoste, PosteFormData } from "../../../../../utils/api/candidature/candidature.type";

function useCrudPoste(item?: IPoste, resetItem?: () => void) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		description: yup
			.string()
			.label("Description")
			.nullable()
			.transform((val) => (val === "<p><br></p>" ? "" : val)),
		lieu: yup.string().label("Lieu").nullable(),
		type: yup.string().label("Type").nullable(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<PosteFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdateTheme, { isLoading }] = useAddOrUpdatePosteMutation();
	const [address, setAdress] = useState("");
	const [description, setDescription] = useState("");

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("nom", item?.nom);
			setValue("description", item?.description);
			setValue("type", item?.type);
			setValue("lieu", item?.lieu);
			if (item?.description) {
				setDescription(item?.description);
			} else {
				setDescription("");
			}
			if (item?.lieu) {
				setAdress(item?.lieu);
			} else {
				setAdress("");
			}
		}
	}, [item]);

	const handleDescription = (content: string) => {
		setValue("description", content);
		setDescription(content);
	};
	const handleChangeAdress = (value) => {
		setValue("lieu", value?.rue);
		setAdress(value?.rue);
	};
	const handleReset = () => {
		setDescription("");
		setAdress("");
		reset();
	};

	const onSubmit = async (data: PosteFormData) => {
		console.log("data", data);

		const res = await addOrUpdateTheme({
			slug: item?.slug,
			data: data,
		});
		if ("data" in res) {
			toast.success(
				!item
					? "Poste ajouté avec succès"
					: "Le poste a été modifié avec succès !"
			);
			handleReset();
			onHide("AddPosteModal");
			resetItem && resetItem();
		}
		if ("error" in res) {
			const err = res.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${
						item ? "la modification" : "l'ajout"
				  }`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		handleDescription,
		description,
		handleReset,
		handleChangeAdress,
		address,
	};
}

export default useCrudPoste;
