import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError, findNonStringProperty } from "../../../../../utils/utils";
import { AboutData, UploadFileResult } from "../../../../../utils/api/config/config.type";
import {
	useGetAboutQuery,
	useUpdateAboutMutation,
} from "../../../../../utils/api/config/config.api";
import { QueryError } from "../../../../../utils/type";
import { UPLOAD_FILE_END_POINT } from "../../../../../utils/constantes";

function useAbout() {
	const validationSchema = yup.object().shape({
		about: yup.object({
			section_one_title: yup.string().label("Titre"),
			section_one_description: yup.string().required().label("Description"),
			section_two_title: yup.string().required().label("Titre"),
			section_two_description: yup.string().required().label("Description"),
			section_three_title: yup.string().required().label("Titre"),
			section_three_description: yup.string().required().label("Description"),
			section_four_title: yup.string().required().label("Titre"),
			section_four_description: yup.string().required().label("Description"),
		}),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<AboutData>({
		resolver: yupResolver(validationSchema),
	});

	const { data } = useGetAboutQuery();
	const [update, { isLoading, isSuccess, error, isError }] = useUpdateAboutMutation();

	useEffect(() => {
		if (data) {
			setValue("about.section_one_title", data.about.section_one_title);
			setValue("about.section_two_title", data.about.section_two_title);
			setValue("about.section_three_title", data.about.section_three_title);
			setValue("about.section_four_title", data.about.section_four_title);
			setValue("about.section_one_description", data.about.section_one_description);
			setValue("about.section_two_description", data.about.section_two_description);
			setValue("about.section_three_description", data.about.section_three_description);
			setValue("about.section_four_description", data.about.section_four_description);
			setValue("about.section_one_image", data.about.section_one_image);
			setValue("about.section_two_image", data.about.section_two_image);
			setValue("about.section_three_image", data.about.section_three_image);
			setValue("about.section_four_image", data.about.section_four_image);
		}
	}, [data]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Modifié avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			});
		}
		const err = error as QueryError;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : "Une erreur a survenu",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	useEffect(() => {
		// console.log({ errors });
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: AboutData) => {
		// console.log({ data });
		const images = {
			section_one_image: data.about?.section_one_image,
			section_two_image: data.about?.section_two_image,
			section_three_image: data.about?.section_three_image,
			section_four_image: data.about?.section_four_image,
		};
		let imageToUploadKey = findNonStringProperty(images);
		console.log(imageToUploadKey);

		if (imageToUploadKey && data.about) {
			try {
				let fd = new FormData();
				let files = data.about[imageToUploadKey];
				fd.append("project_name", "kivu");
				fd.append("media_type", "image");
				fd.append("media", files);

				const response = await fetch(UPLOAD_FILE_END_POINT, {
					method: "POST",
					body: fd,
				});
				if (response.ok) {
					const res: UploadFileResult = await response.json();
					if ("success" in res) {
						data.about[imageToUploadKey] = res.file_url;
						update(data);
					}
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			update(data);
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading: isLoading,
		data: data?.about,
		setError,
		isSuccess,
	};
}
export default useAbout;
