import React from "react";
import ListePostesTable from "./ListePostesTable";
import { IoMdAdd } from "react-icons/io";
import { ButtonGoBack } from "../../../common/Button";

const GestionPostes = () => {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="d-flex align-items-center">
						<div>
							<ButtonGoBack />
						</div>
						<h4 className="crypta-dash-header-titre m-0">
							Liste des postes
						</h4>
					</div>
					<button
						className="btn btn-add-theme"
						data-bs-toggle="modal"
						data-bs-target="#AddPosteModal"
					>
						<IoMdAdd />
						<span className="ps-2">Ajouter un poste</span>
					</button>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<ListePostesTable />
				</div>
			</div>
		</div>
	);
};

export default GestionPostes;
