import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import { QueryError } from "../../../../../utils/type";
import {
	useGetEchangesQuery,
	useUpdateEchangesMutation,
} from "../../../../../utils/api/config/config.api";
import { EchangeFormData } from "../../../../../utils/api/config/config.type";
import { useEchangeListener } from "../../../../../utils/pusher";

function useEchangeForm() {
	const validationSchema = yup.object().shape({
		montant_gain_eleve: yup
			.number()
			.required()
			.label("Montant")
			.typeError("Montant est un entier"),
		montant_gain_moyen: yup
			.number()
			.required()
			.label("Montant")
			.typeError("Montant est un entier"),
		montant_gain_faible: yup
			.number()
			.required()
			.label("Montant")
			.typeError("Montant est un entier"),
	});

	useEchangeListener();
	const { data } = useGetEchangesQuery();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<EchangeFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [update, { isLoading, isSuccess, error, isError }] =
		useUpdateEchangesMutation();

	useEffect(() => {
		if (data) {
			setValue("montant_gain_eleve", data?.montant_gain_eleve);
			setValue("montant_gain_moyen", data?.montant_gain_moyen);
			setValue("montant_gain_faible", data?.montant_gain_faible);
			setValue("eleve_is_active", data?.eleve_is_active);
			setValue("moyen_is_active", data?.moyen_is_active);
			setValue("faible_is_active", data?.faible_is_active);
		}
	}, [data]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Modifié avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			});
		}
		const err = error as QueryError;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur a survenu",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const handleChecked = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.id === "eleve_is_active") {
			setValue("eleve_is_active", true);
			setValue("moyen_is_active", false);
			setValue("faible_is_active", false);
		}
		if (e.currentTarget.id === "moyen_is_active") {
			setValue("eleve_is_active", false);
			setValue("moyen_is_active", true);
			setValue("faible_is_active", false);
		}
		if (e.currentTarget.id === "faible_is_active") {
			setValue("eleve_is_active", false);
			setValue("moyen_is_active", false);
			setValue("faible_is_active", true);
		}
	};

	const onSubmit = (data: EchangeFormData) => {
		console.log(data);
		update(data);
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading: isLoading,
		handleChecked,
		data,
	};
}
export default useEchangeForm;
