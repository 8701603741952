import React from "react";
import { ButtonGoBack } from "../../../common/Button";
import { getAvatar } from "../../../../utils/utils";
import InfoPersonelles from "./InfoPersonelles";
import Observations from "./Observations";
import HistoriquesConsultations from "./HistoriquesConsultations";
import AbonnementUsers from "./AbonnementUsers";
import Rdv from "./Rdv";
import { useDossierFromLocation } from "../../../../utils/api/dossier/dossier.api";

const DetailDossier = () => {
	const [item] = useDossierFromLocation();
	console.log({ item });
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header d-flex align-items-center">
						<ButtonGoBack />
						<h4 className="crypta-dash-header-titre m-0">
							Dossier medical{" "}
							<span className="fw-400">{item?.numero}</span>
						</h4>
					</div>
				</div>
				<div className="flex-c mt-3 my-5">
					<img
						src={getAvatar(item?.rdv?.patient?.avatar)}
						alt="photo du patient"
						style={{
							width: "156px",
							height: "156px",
							borderRadius: "5px",
							objectFit: "cover",
						}}
					/>
				</div>
				<div className="pt-5">
					<ul
						className="nav nav-tabs nav-tabs-setting"
						id="myTab"
						role="tablist"
					>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting active"
								id="info-perso-tab"
								data-bs-toggle="tab"
								data-bs-target="#info-perso"
								type="button"
								role="tab"
								aria-controls="info-perso"
								aria-selected="true"
							>
								Informations personnelles
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="rdv-tab"
								data-bs-toggle="tab"
								data-bs-target="#rdv"
								type="button"
								role="tab"
								aria-controls="rdv"
								aria-selected="false"
							>
								Rendez-vous
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="historique-tab"
								data-bs-toggle="tab"
								data-bs-target="#historique"
								type="button"
								role="tab"
								aria-controls="historique"
								aria-selected="false"
							>
								Historique des consultations
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="documents-tab"
								data-bs-toggle="tab"
								data-bs-target="#documents"
								type="button"
								role="tab"
								aria-controls="documents"
								aria-selected="false"
							>
								Observations
							</button>
						</li>
						<li className="nav-item nav-item-setting" role="presentation">
							<button
								className="nav-link nav-link-setting"
								id="abonnements-tab"
								data-bs-toggle="tab"
								data-bs-target="#abonnements"
								type="button"
								role="tab"
								aria-controls="abonnements"
								aria-selected="false"
							>
								Abonnement
							</button>
						</li>
					</ul>
				</div>
			</div>

			<div className="bg-white p-3 mt-3">
				<div className="tab-content" id="myTabContent">
					<div
						className="tab-pane fade show active"
						id="info-perso"
						role="tabpanel"
						aria-labelledby="info-perso-tab"
					>
						<InfoPersonelles user={item?.rdv?.patient} />
					</div>
					<div
						className="tab-pane fade show"
						id="rdv"
						role="tabpanel"
						aria-labelledby="rdv-tab"
					>
						<Rdv slug={item?.slug} />
					</div>
					<div
						className="tab-pane fade"
						id="historique"
						role="tabpanel"
						aria-labelledby="historique-tab"
					>
						<HistoriquesConsultations slug={item?.slug} />
					</div>
					<div
						className="tab-pane fade"
						id="documents"
						role="tabpanel"
						aria-labelledby="documents-tab"
					>
						<Observations slug={item?.slug} />
					</div>
					<div
						className="tab-pane fade"
						id="abonnements"
						role="tabpanel"
						aria-labelledby="abonnements-tab"
					>
						<AbonnementUsers slug={item?.rdv?.patient?.slug} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailDossier;
