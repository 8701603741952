import React from "react";
import { ITransaction } from "../../../../utils/api/transaction/transaction.type";
import { useLocationState } from "../../../../utils/hooks";
import { formatMontant, formattedDateTime } from "../../../../utils/utils";
import { ButtonGoBack } from "../../../common/Button";
import DetailUserModal from "../../../modal/admin/DetailUserModal";

const DetailTransaction = () => {
	const item = useLocationState<ITransaction>(undefined);
	// console.log({ item });
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header d-flex align-items-center">
						<ButtonGoBack />
						<h4 className="crypta-dash-header-titre m-0">
							Détail de la transaction{" "}
							<span className="fw-400">#{item?.transaction_id}</span>
						</h4>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="detail-transaction">
						<div className="mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Description
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{item?.description}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Type de transactions
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{item?.type === "IN" ? "Débit" : "Crédit"}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">De</p>
							</div>
							<div className="content-text-info-transaction-value">
								{item?.sender ? (
									<p
										className="text-info-transaction-value underline"
										role="button"
										data-bs-toggle="modal"
										data-bs-target="#DetailUserModal"
									>
										<>
											{item?.sender?.prenom +
												" " +
												item?.sender?.nom}
										</>
									</p>
								) : (
									<p className="text-info-transaction-value">
										From service
									</p>
								)}
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Vers
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p
									className="text-info-transaction-value underline"
									role="button"
									data-bs-toggle="modal"
									data-bs-target="#DetailUserModal"
								>
									{item?.receiver?.prenom +
										" " +
										item?.receiver?.nom}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Date & Heure
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{formattedDateTime(item?.created_at)}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Montant
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{formatMontant(item?.value)}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Montant Actuel
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{formatMontant(item?.solde_restant)}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Motif
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value text-capitalize">
									{item?.motif}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Statut
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									Success
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="DetailUserModal"
				aria-labelledby="DetailUserModalLabel"
				aria-hidden="true"
			>
				<DetailUserModal item={item?.user} />
			</div>
		</div>
	);
};

export default DetailTransaction;
