import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { AdminInput, FormError } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import useCrudPoste from "../../TableauDebord/Admin/Candidatures/hooks/useCrudPoste";
import { IPoste } from "../../../utils/api/candidature/candidature.type";
import ReactQuill from "react-quill";
import GoogleInput from "../../common/GoogleInput";

const AddPosteModal = ({
	item,
	resetItem,
}: {
	item?: IPoste;
	resetItem: () => void;
}) => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		description,
		handleDescription,
		handleReset,
		handleChangeAdress,
		address,
	} = useCrudPoste(item, resetItem);
	const reset = () => {
		resetItem();
	};
	return (
		<div className="modal-dialog modal-lg modal-dialog-centered">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{item ? "Modifier le" : "Ajouter un"} poste
					</h5>
					<CloseModalBtn onClick={() => handleReset()} />
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-12">
									<AdminInput
										label="Intitulé de poste"
										id="nom"
										type="text"
										placeholder="Intitulé de poste"
										{...register("nom")}
										error={errors?.nom}
										required
									/>
								</div>
								<div className="col-md-12">
									<AdminInput
										label="Type"
										id="nom"
										type="text"
										placeholder="Stage 3 à 6 mois"
										{...register(
											"type"
										)}
										error={errors?.type}
									/>
								</div>
								<div className="col-md-12 mb-3">
									<label
										htmlFor="adress"
										className="form-label form-label-modal-custom"
									>
										Lieu
									</label>
									<GoogleInput
										value={address}
										onChange={
											handleChangeAdress
										}
										className="form-control form-control-modal-custom"
									/>
									<FormError
										error={errors?.lieu}
									/>
								</div>
								<div className="col-12 mb-3">
									<label
										htmlFor="advantages"
										className="form-label form-label-modal-custom"
									>
										Description
									</label>
									<ReactQuill
										className="editor-cgu"
										value={description}
										onChange={
											handleDescription
										}
									/>
									<FormError
										error={
											errors?.description
										}
									/>
								</div>
								<div className="col-12 mb-4">
									<input
										type="radio"
										id="status"
										className="form-check-input gain-form-check-input"
										defaultChecked={
											item?.status ??
											true
										}
										{...register(
											"status"
										)}
									/>
									<label
										htmlFor="status"
										className="form-label form-label-modal-custom pt-1 ms-2"
									>
										Statut
									</label>
								</div>
							</div>
							<div className="container-btn-modal d-flex justify-content-end gap-3 mt-4">
								<button
									type="button"
									className="btn btn-cancelled"
									data-bs-dismiss="modal"
									onClick={() => {
										reset();
										handleReset();
									}}
								>
									Fermer
								</button>
								<BtnSubmit
									isLoading={isLoading}
									label={
										item
											? "Modifier"
											: "Ajouter"
									}
									style={{
										width: "inherit",
									}}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddPosteModal;
