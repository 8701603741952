import React from "react";
import "./ListeCoins.css";
import GestionCoinsTable from "../../../tables/admin/GestionCoinsTable/GestionCoinsTable";
import { AiOutlinePlusSquare } from "react-icons/ai";
import AddCoinModal from "../../../modal/admin/AddCoinModal";

const ListeCoins = () => {
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="crypta-dash-locataire-requetes-header">
            <h4 className="crypta-dash-header-titre">Coins</h4>
          </div>
          <div className="content-btn-add-theme">
            <button
              className="btn btn-add-theme"
              data-bs-toggle="modal"
              data-bs-target="#AddCoinModal"
            >
              <AiOutlinePlusSquare style={{ color: "#08BB90", fontSize: 20 }} />
              <span className="ps-2">Ajouter un coin</span>
            </button>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="">
            <div className="flex-sb align-items-center mb-4">
              <div className="tab-dash-admin-filter-container">
                <form className="row g-3 tab-dash-admin-filter-form">
                  <div className="col-auto">
                    <label
                      htmlFor="filtreLogement"
                      className="tab-dash-admin-filter-label"
                    >
                      Filtrer
                    </label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="text"
                      className="form-control tab-dash-admin-filter-input"
                      id="filtreLogement"
                      placeholder="Entrer un nom"
                    />
                  </div>
                </form>
              </div>
            </div>
            <GestionCoinsTable />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddCoinModal"
        aria-labelledby="AddCoinModalLabel"
        aria-hidden="true"
      >
        <AddCoinModal />
      </div>
    </div>
  );
};

export default ListeCoins;
