import React from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/appImages/logo.png";
import useLogin from "./hooks/useLogin";
import { AuthInput } from "../common/Input";
import { BtnSubmit } from "../common/Button";

function LoginForm() {
	const { register, onSubmit, errors, isLoading } = useLogin();
	return (
		<form id="auth-form" onSubmit={onSubmit}>
			<div className="row auth-form-row">
				<div className="col-md-12 auth-input-col mb-3">
					<AuthInput
						label="Adresse mail"
						type="text"
						id="email"
						placeholder="Adresse mail"
						data-testid="emailId"
						{...register("email")}
						error={errors?.email}
						inputMode="text"
					/>
				</div>
				<div className="col-md-12 auth-input-col mb-2">
					<AuthInput
						label="Mot de passe"
						type="password"
						id="password"
						placeholder="Mot de passe"
						data-testid="passwordId"
						{...register("password")}
						error={errors?.password}
						inputMode="text"
					/>
				</div>
				<div className="d-flex justify-content-end mb-3">
					<Link
						to={"/mot-de-passe-oublie"}
						className="forget-password-link"
					>
						Mot de passe oublié ?
					</Link>
				</div>
				<div className="col-md-12 auth-submit-btn-container mt-5">
					<BtnSubmit
						label="Connexion"
						isLoading={isLoading}
					/>
				</div>
			</div>
		</form>
	);
}

const Login = () => {
	return (
		<div>
			<div className="fixed-top">
				<div className="empty-header"></div>
			</div>
			<div className="">
				<div className="row login-page-row">
					<div className="col-md-4 offset-md-4 right-side-col">
						<div className="login-form-container">
							<div className="login-form-introduction d-flex flex-column align-items-center">
								<div className="mb-4">
									<img
										src={Logo}
										alt="logo"
										className="logo-auth-login-img"
									/>
								</div>
							</div>
							<div className="mt-4">
								<LoginForm />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-footerV-page">
				<p className="text-footer-login-page">
					©2021 All Rights Reserved. Volkeno Inc
				</p>
			</div>
		</div>
	);
};

export default Login;
