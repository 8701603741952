import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../../redux/hooks";
import { useResponseContactMutation } from "../../../../../utils/api/contact/contact.api";
import { IContact, TResponseContact } from "../../../../../utils/api/contact/contact.type";
import { QueryError } from "../../../../../utils/type";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { toast } from "react-hot-toast";

const useResponseContactForm = (item?: IContact) => {
	const validationSchema = yup.object().shape({
		response: yup
			.string()
			.required()
			.label("La réponse")
			.transform((val) => (val === "<p><br></p>" ? "" : val)),
	});
	const { user: authUser } = useAppSelector((s) => s.user);
	const [response, setResponse] = useState<string>("");
	const [sendMessage, { isLoading }] = useResponseContactMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<TResponseContact>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const handleChangeResponse = (value: string) => {
		setResponse(value);
		setValue("response", value);
	};

	const onsubmit = async (data: TResponseContact) => {
		const res = await sendMessage({
			...data,
			user: authUser?.id,
			message: item?.id as number,
		});
		if ("data" in res) {
			toast.success("Réponse envoyée avec succès!");
			reset();
			setResponse("");
			onHide("DetailsMessageModal");
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		response,
		handleChangeResponse,
		setResponse,
	};
};

export default useResponseContactForm;
