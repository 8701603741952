import React from "react";
import "./HeaderAdmin.css";
import { NavLink } from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import { FiChevronDown, FiMenu } from "react-icons/fi";
import Logo from "../../../../assets/landingpage/logo-footer.png";
import LogoutBtn from "./LogoutBtn";
import { useAppSelector } from "../../../../redux/hooks";
import { formatNotifType, getAvatar, getName } from "../../../../utils/utils";
import { useNotification } from "../../../../utils/api/notification/notification.api";
import moment from "moment";
import { useToggle } from "../../../../utils/hooks";
import { MobileMenu } from "../SidebarAdmin/SidebarAdmin";

const HeaderAdmin: React.FC = () => {
	const { user } = useAppSelector((s) => s?.user);
	const { count, notifications, readNotif, readAll } = useNotification();
	var [showNotifications, setShowNotifications] = React.useState(false);

	var onShowAndHideNotification = () => {
		if (showNotifications === false) {
			setShowNotifications(true);
		} else {
			setShowNotifications(false);
		}
	};
	const { isOpen, toggleMenu } = useToggle();
	// console.log({ notifications });
	return (
		<div>
			<div className="admin-header-component">
				<nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top px-3">
					<div className="headering-admin">
						<div className="logo-item">
							<div className="logo-container">
								<NavLink
									className="navbar-brand"
									to="/admin/dashboard"
								>
									<img
										src={Logo}
										alt="data-gouvernance"
										className="logo-dashboard-admin"
									/>
								</NavLink>
							</div>
						</div>

						<div className="header-others-items">
							<div className="d-flex gap-2">
								<div
									className="notification-item-container linkable position-relative d-flex align-items-center"
									onClick={() => onShowAndHideNotification()}
								>
									<div className="notification-item-content">
										<IoIosNotificationsOutline
											fontSize={20}
										/>
										{count > 0 && (
											<span className="notification-counter">
												{count}
											</span>
										)}
									</div>
								</div>

								<div className="connected-user-container">
									<div className="connected-user-content ml-auto">
										<div className="dropdown dis-flex">
											<button
												className="user-acount-dashboard-header dropdown-toggle"
												type="button"
												id="dropdownMenuButton"
												data-bs-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<div className="user-acount-dashboard-header-display">
													<img
														src={getAvatar(
															user?.avatar
														)}
														width="45"
														className="user-avatar"
														alt="User Avatar"
													/>
													<FiChevronDown />
												</div>
											</button>
											<div
												className="dropdown-menu dropdown-menu-custom"
												aria-labelledby="dropdownMenuButton"
											>
												<NavLink
													to="/admin/profil"
													className="dropdown-item dropdown-item-header-btn"
												>
													{user?.nom
														? getName(user)
														: "Dev"}
												</NavLink>
												<div className="dropdown-divider"></div>
												<LogoutBtn customClass="dropdown-item dropdown-item-header-btn" />
											</div>
										</div>
									</div>
								</div>
								<button
									className="menu-button no-view-desktop"
									onClick={toggleMenu}
								>
									<FiMenu
										fontSize={20}
										color="#000"
										fontWeight={600}
									/>
								</button>
							</div>
						</div>
					</div>

					<div className="hiddenable-nav-mobile-container no-view-desktop">
						<div className="hiddenable-nav-mobile-content"></div>
					</div>
					{showNotifications && (
						<div
							className={"header-hiddenable-notification-section"}
							onPointerLeave={onShowAndHideNotification}
						>
							<ul className="header-hiddenable-notification-ul">
								{notifications && notifications?.length > 0 ? (
									<ul className="header-hiddenable-notification-ul mb-0">
										{notifications?.map((item) => (
											<li
												className="header-hiddenable-notification-li"
												key={item?.slug}
											>
												<NavLink
													to={`/admin/${
														item?.notif_type ===
														"demande_paiement"
															? "demandes"
															: item?.notif_type ===
															  "remboursement"
															? "remboursements"
															: item?.notif_type ===
															  "depot"
															? "transactions"
															: item?.notif_type ===
															  "signalement"
															? "signalements"
															: "patients"
													}`}
													className="notification-item-link d-flex no-link"
												>
													<div
														className="content-text-notif d-flex flex-col  flex-md-row justify-content-between ps-2 flex-fill"
														onClick={() =>
															readNotif(
																item?.slug
															)
														}
													>
														<div>
															<p className="text-notif mb-1">
																{
																	item?.content
																}
															</p>
															<p className="text-date-notif mb-0">
																{moment(
																	item?.created_at
																).format(
																	"DD/MM/YYYY - HH:MM"
																)}
															</p>
														</div>
														<div>
															<p
																className={`notif-type bg-${item?.notif_type} m-0`}
															>
																{formatNotifType(
																	item?.notif_type
																)}
															</p>
														</div>
													</div>
												</NavLink>
											</li>
										))}
										<li className="header-hiddenable-notification-li">
											<NavLink
												to="/admin/notifications"
												className="notification-item-link d-flex no-link"
												onClick={readAll}
											>
												<div className="content-text-notif ps-2">
													<p className="text-notif m-0">
														Voir toutes les
														notifications
													</p>
												</div>
											</NavLink>
										</li>
									</ul>
								) : (
									<div className="content-text-notif ps-2">
										<NavLink
											to="/admin/notifications"
											className="notification-item-link d-flex no-link"
											onClick={readAll}
										>
											<div className="content-text-notif ps-2">
												<p className="text-notif m-0">
													Voir toutes les
													notifications
												</p>
											</div>
										</NavLink>
									</div>
								)}
							</ul>
						</div>
					)}
				</nav>
			</div>
			<MobileMenu isOpen={isOpen} toggleMenu={toggleMenu} />
		</div>
	);
};

export default HeaderAdmin;
