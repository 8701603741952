import React from "react";
import Logo from "../../assets/landingpage/logo-footer.png";
import { useNewsLetter } from "../LandingPage/requestForm/useNewsletter";
const ComingSoonPage = () => {
  const { register, errors, onSubmit, isLoading } = useNewsLetter();
  return (
    <div className="coming-soon-component">
      <div className="gradient-background">
        <div className="content-newsletter-coming-soon">
          <section className="newsletter">
            <div className="newsText">
              <h1>La plateforme Kivu est en construction.</h1>
              <p className="pt-4">
                Inscrivez-vous pour être informé lors du lancement.
              </p>
              <img
                src="https://images2.imgbox.com/22/73/1f6acDGg_o.png"
                alt="Flêche"
              />
            </div>
            <div className="newsSign">
              <div className="content-logo-app-coming-soon text-center pb-4">
                <img src={Logo} alt="Logo" className="logo-app-coming-soon" />
              </div>
              <form onSubmit={onSubmit}>
                <div className="input-field">
                  {/* <label
                    htmlFor="exampleInputEmail"
                    className="form-label form-label-coming-soon"
                  >
                    Adresse mail
                  </label> */}
                  <input
                    type="text"
                    className="form-control input-newsletter-coming-soon"
                    placeholder="Adresse mail"
                    {...register("email")}
                    inputMode="text"
                  />
                </div>
                {!isLoading ? (
                  <button
                    type="submit"
                    name="submit"
                    className="btn btn-newletter-coming-soon"
                  >
                    Envoyer
                  </button>
                ) : (
                  <button
                    className="btn btn-newletter-coming-soon"
                    type="submit"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}

                {errors?.email && (
                  <span className="text-danger">{errors?.email?.message}</span>
                )}
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;
