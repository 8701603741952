import React from "react";
import ReactQuill from "react-quill";
import { Faq } from "../../../../../utils/api/config/config.type";
import useFaqForm from "./hooks/useFaqForm";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import { AdminInput, FormError } from "../../../../common/Input";
import { BtnSubmit } from "../../../../common/Button";

type AddFaqModalProps = { reset: () => void; item?: Faq };

const AddEditFaqModal: React.FC<AddFaqModalProps> = ({ reset, item }) => {
	const { register, onSubmit, errors, onChangeResponse, response, isLoading } =
		useFaqForm(item);
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h4 className="fas-modal-title mb-4">Questions/Reponses</h4>
					<CloseModalBtn onClick={reset} />
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="col-12 mb-3">
								<AdminInput
									label="Questions"
									id="titre"
									type="text"
									placeholder="Ajouter une question"
									{...register("titre")}
									error={errors?.titre}
									data-testid="questionId"
								/>
							</div>
							<div className="form-group">
								<label
									htmlFor="reponse"
									className="form-label form-label-auth-login"
								>
									Réponse
								</label>
								<ReactQuill
									className="editor-cgu"
									value={response}
									onChange={onChangeResponse}
								/>
								<FormError error={errors?.description} />
							</div>
							<div className="flex-r mt-5 gap-4 col-md-6 offset-md-6">
								<BtnSubmit
									label="Enregistrer"
									isLoading={isLoading}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddEditFaqModal;
