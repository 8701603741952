import React from "react";
import { IoClose } from "react-icons/io5";

type Props = React.HtmlHTMLAttributes<HTMLButtonElement>;

const CloseModalBtn: React.FC<Props> = ({ ...props }) => {
	return (
		<button className="no-style-btn" data-bs-dismiss="modal" type="button" {...props}>
			<IoClose />
		</button>
	);
};

export default CloseModalBtn;
