import React from "react";
import { ITransaction } from "../../../utils/api/transaction/transaction.type";
import { formatMontant, formattedDateTime } from "../../../utils/utils";
import CloseModalBtn from "../../shared/CloseModalBtn";

const DetailTransactionModal = ({ item }: { item?: ITransaction }) => {
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="admin-modal-title text-start" id="changePasswordLabel">
						Détails de la transaction #{item?.id}
					</h5>
					<CloseModalBtn />
				</div>
				<div className="form-container p-3">
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Référence</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{item?.transaction_id}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">
								Type de transactions
							</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								Transfert d’argent
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">De</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{item?.sender?.prenom + " " + item?.sender?.nom}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Vers</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{item?.receiver?.prenom + " " + item?.receiver?.nom}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">
								Date & Heure
							</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{formattedDateTime(item?.created_at)}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Montant</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{formatMontant(item?.value)}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Statut</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">Success</p>
						</div>
					</div>
					{/* <div className="text-center my-4">
						<button className="btn btn-cancelled-transaction">
							Annuler la transaction
						</button>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default DetailTransactionModal;
