import React from "react";
import { BtnSubmit } from "../../../common/Button";
import useJoinUs from "./hooks/useJoinUs";
import { AdminInput } from "../../../common/Input";

const GestionJoinUs = () => {
	const { register, onSubmit, errors, isLoading } = useJoinUs();

	return (
		<div className="tab-content-admin px-3 py-3">
			<form onSubmit={onSubmit}>
				<div className="mt-3 row">
					<div className="col-12 mb-3">
						<AdminInput
							label="Titre"
							id="titre"
							type="text"
							placeholder="Titre"
							{...register("join_us.title")}
							error={errors && errors?.join_us && errors?.join_us.title}
							required
						/>
					</div>
				</div>
				<div className="px-2 flex-r col-md-4 offset-md-8 gap-3 py-3">
					<BtnSubmit label="Modifier" isLoading={isLoading} />
				</div>
			</form>
		</div>
	);
};

export default GestionJoinUs;
