/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IAdminFormData } from "../../../../../utils/api/admin/admin.type";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useEditAdminMutation } from "../../../../../utils/api/admin/admin.api";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { toast } from "react-hot-toast";

function useEditProfil() {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		prenom: yup.string().required().label("Prénom"),
		telephone: yup.string().required().label("Téléphone"),
		email: yup.string().email().required().label("Email"),
		adresse: yup
			.string()
			.required()
			.label("Adresse")
			.typeError("Adresse est un champ obligatoire"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<IAdminFormData>({
		resolver: yupResolver(validationSchema),
	});
	const dispatch = useAppDispatch();
	const [phone, setPhone] = useState<string>();

	const [editAdmin, { isLoading }] = useEditAdminMutation();

	const { user } = useAppSelector((s) => s.user);

	const onChangePhone = (phone: string) => {
		setPhone(phone);
		setValue("telephone", `+${phone}`);
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (user?.id) {
			let fields: (keyof IAdminFormData)[] = [
				"nom",
				"prenom",
				"telephone",
				"email",
				"adresse",
			];
			for (let field of fields) {
				register(field);
				setValue(field, user[field]);
			}
			setPhone(user?.telephone);
		}
	}, [user]);

	const handleChangeAdress = (value) => {
		// console.log(value);
		setValue("adresse", value?.rue);
		setValue("longitude", value?.longitude);
		setValue("latitude", value?.latitude);
	};

	const onSubmit = async (data: IAdminFormData) => {
		console.log("data", data);
		const res = await editAdmin({
			slug: user?.slug,
			user_type: user?.user_type,
			data: data,
		});
		if ("data" in res) {
			dispatch(onSetUser(res.data));
			toast.success("Informations modifiées avec succès!");
			reset();
			onHide("EditNameModal");
			setPhone("");
		}
		if ("error" in res) {
			const err = res.error as any;
			if (err?.data?.email?.includes("user with this email already exists.")) {
				Swal.fire({
					icon: "error",
					title: "Un utilisateur avec cet email exite déjà!",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				let message = err?.data?.message
					? err?.data?.message
					: err?.data?.email
					? "Un utilisateur avec cet email existe déjà"
					: err?.data?.telephone
					? "Un utilisateur avec ce numero de téléphone existe déjà"
					: "Une erreur inconnue a survenue lors de la modification";
				Swal.fire({
					icon: "error",
					title: message,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		phone,
		onChangePhone,
		setPhone,
		user,
		handleChangeAdress,
	};
}

export default useEditProfil;
