import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "../../../redux/store";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl } from "../../utils";
import { ChangePasswordData } from "../auth/auth.type";
import {
	AuthState,
	DemandeSupAccount,
	IAbonnement,
	ICompteur,
	IModePaiement,
	IParticipant,
	IUser,
	MedecinQuery,
	PaginationResults,
	PharmacienQuery,
	UserFormData,
	UserQuery,
} from "./user.type";
import { useNavigate, useParams } from "react-router-dom";
import { useLocationState } from "../../hooks";
import { useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const prepareHeaders = (headers: Headers, { getState }: { getState: any }) => {
	const token =
		(getState() as RootState).user.token ?? AppStorage.getItem<AuthState>("user")?.token;
	if (token) {
		headers.set("Authorization", `Bearer ${token}`);
	}
	return headers;
};
export const UserApi = createApi({
	reducerPath: "userApi",
	tagTypes: [
		"user",
		"medecin",
		"pharmacien",
		"remboursements",
		"participant",
		"compteur",
		"modepaiement",
		"demandeSupCompte",
	],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		me: builder.query<IUser, void>({
			providesTags: ["user"],
			transformResponse: ({ data }) => data,
			query: () => {
				return "auth/me/";
			},
		}),
		getUsers: builder.query<PaginationResults<IUser>, UserQuery>({
			query: (query) => QueryUrl("users/", query),
			providesTags: ["user"],
		}),
		getMedicins: builder.query<PaginationResults<IUser>, MedecinQuery>({
			query: (query) => QueryUrl("medecin/", query),
			providesTags: ["medecin"],
		}),
		getPharmaciens: builder.query<PaginationResults<IUser>, PharmacienQuery>({
			query: (query) => QueryUrl("pharmacien/", query),
			providesTags: ["pharmacien"],
		}),
		getPatients: builder.query<PaginationResults<IUser>, UserQuery>({
			query: (query) => QueryUrl("patient/", query),
			providesTags: ["user"],
		}),
		getParticipantsByUser: builder.query<IParticipant[], UserQuery>({
			query: ({ slug, ...query }) => QueryUrl(`user/${slug}/participants`, query),
			providesTags: ["participant"],
		}),
		addOrUpdateUser: builder.mutation<
			IUser,
			{ slug?: string; data: UserFormData | FormData | Partial<UserFormData> }
		>({
			invalidatesTags: ["user", "medecin", "pharmacien"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `users/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `users/`,
					method: "POST",
					body: data,
				};
			},
		}),
		changePassword: builder.mutation<IUser, ChangePasswordData>({
			query: (data) => ({
				url: `auth/change-password/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["user"],
		}),
		toggleActiveUser: builder.mutation<IUser, { slug: string; is_active: boolean }>({
			query: ({ slug, is_active }) => {
				if (is_active) {
					return {
						url: `user/${slug}/desactivation/ `,
						method: "GET",
					};
				}
				return {
					url: `user/${slug}/activation/ `,
					method: "GET",
				};
			},
			invalidatesTags: ["user", "medecin", "pharmacien"],
		}),
		deleteUserAccount: builder.mutation<IUser, string>({
			query: (slug) => ({
				url: `/users/${slug}/`,
				method: "Delete",
			}),
			invalidatesTags: ["user", "medecin", "pharmacien"],
		}),
		sendDeleteUserCode: builder.query<IUser, void>({
			query: () => ({
				url: `suppression_compte/request_code/`,
				method: "GET",
			}),
			providesTags: ["user"],
		}),
		deleteUser: builder.mutation<IUser, { slug: string; data: { code: string } }>({
			query: ({ slug, data }) => ({
				url: `/users/${slug}/`,
				method: "Delete",
				body: data,
			}),
			invalidatesTags: ["user", "medecin", "pharmacien"],
		}),
		deleteConfirmUserAccount: builder.mutation<
			IUser,
			{ slug: string; data: { code: string } }
		>({
			query: ({ slug, data }) => ({
				url: `user/${slug}/suppression/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["user", "medecin", "pharmacien"],
		}),
		editParticipant: builder.mutation<
			IParticipant,
			{
				slug?: string;
				data: Partial<IParticipant>;
			}
		>({
			query: ({ slug, data }) => {
				return {
					url: `participants/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
			invalidatesTags: ["participant"],
		}),
		getAbonnementsByUser: builder.query<PaginationResults<IAbonnement>, UserQuery>({
			query: ({ slug, ...query }) => QueryUrl(`patient/${slug}/abonnements`, query),
			providesTags: ["user"],
		}),
		getDemandeRenitCompteurs: builder.query<PaginationResults<ICompteur>, UserQuery>({
			query: (query) => QueryUrl("reinitialisation_compteurs/", query),
			providesTags: ["compteur"],
		}),
		getDemandeSupCompte: builder.query<PaginationResults<DemandeSupAccount>, UserQuery>({
			query: (query) => QueryUrl("user/suppression_comptes/", query),
			providesTags: ["demandeSupCompte"],
		}),
		editCompteur: builder.mutation<ICompteur, Partial<ICompteur>>({
			invalidatesTags: ["compteur"],
			query: ({ slug, ...data }) => {
				return {
					url: `reinitialisation_compteurs/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		reinitialisationCompteur: builder.mutation<ICompteur, string>({
			invalidatesTags: ["compteur", "user"],
			query: (slug) => {
				return {
					url: `reinitialiser_compteur/${slug}/`,
					method: "GET",
				};
			},
		}),
		getUserBySlug: builder.query<IUser, string>({
			query: (slug) => `users/${slug}`,
			providesTags: ["user", "compteur"],
		}),
		getModePaiementsForVisitors: builder.query<IModePaiement[], void>({
			query: (query) => QueryUrl("modepaiement/visitors/", query),
			providesTags: ["modepaiement"],
		}),
		deleteMultipleUsers: builder.mutation<IUser, { users: number[]; code?: string }>({
			query: (data) => ({
				url: `user/suppression_multiple/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["user"],
		}),
	}),
});

export const {
	useMeQuery,
	useLazyMeQuery,
	useAddOrUpdateUserMutation,
	useChangePasswordMutation,
	useDeleteUserAccountMutation,
	useGetUsersQuery,
	useGetMedicinsQuery,
	useGetPharmaciensQuery,
	useGetPatientsQuery,
	useGetParticipantsByUserQuery,
	useEditParticipantMutation,
	useGetAbonnementsByUserQuery,
	useGetDemandeRenitCompteursQuery,
	useEditCompteurMutation,
	useReinitialisationCompteurMutation,
	useLazyGetUserBySlugQuery,
	useGetModePaiementsForVisitorsQuery,
	useDeleteConfirmUserAccountMutation,
	useLazySendDeleteUserCodeQuery,
	useDeleteUserMutation,
	useDeleteMultipleUsersMutation,
	useToggleActiveUserMutation,
	useGetDemandeSupCompteQuery,
} = UserApi;

export function useUserFromLocation(): [IUser, boolean, string, (id: string) => void] {
	const itemState = useLocationState<IUser | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyGetUserBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [findBySlug, itemState, slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError, itemState, navigate]);
	useEffect(() => {
		if (data) {
			// console.log("data",data)
			setItem(data);
		}
	}, [data]);

	return [item as IUser, isLoading, slug as string, findBySlug];
}
