import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { usePagination } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { formatRdvStatus, formattedDate, getName, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonViewLink } from "../../../common/Button";
import { useGetRdvQuery } from "../../../../utils/api/rdv/rdv.api";
import { IRdv } from "../../../../utils/api/rdv/rdv.type";
import { IUser } from "../../../../utils/api/user/user.type";

type Props = {
	motif?: string;
	expediteur?: string;
	beneficiaire?: string;
	type?: string;
};
function ListeRendezVousTable({ expediteur, beneficiaire, motif, type }: Props) {
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetRdvQuery({
		page,
		limit,
		expediteur,
		beneficiaire,
		motif,
		type,
	});

	console.log({ data });

	const actionFormatter = (cell: string, row: IRdv) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonViewLink to={`${row?.slug}`} state={row} />
					<div className="container-btn-action-icon-modal"></div>
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "patient",
			text: "Patient",
			formatter: (cell: IUser) => getName(cell),
		},
		{
			dataField: "professionnel",
			text: "Professionnel",
			formatter: (cell: IUser) => getName(cell),
		},
		{
			dataField: "date",
			text: "Date & Heure",
			formatter: (cell: string, row: IRdv) => formattedDate(cell) + " à " + row?.heure,
		},
		{
			dataField: "adresse",
			text: "Adresse",
		},
		{
			dataField: "etat",
			text: "Statut",
			formatter: (cell: string) => formatRdvStatus(cell),
		},
		{
			dataField: "is_honored",
			text: "Honoré",
			formatter: (cell: boolean) => (cell ? "Oui" : "Non"),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IRdv) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
		</>
	);
}

export default ListeRendezVousTable;
