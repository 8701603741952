import React, { useState } from "react";
import { AdminInput } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";
import useBanner from "./hooks/useBanner";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import ErrorMessage from "../../../common/ErrorMessage";
import moment from "moment";
registerLocale("fr", fr);

const GestionBanner = () => {
	const [date, setDate] = useState<Date>(new Date());
	const { register, onSubmit, errors, isLoading, setValue } = useBanner();

	const handleChangeDate = (date) => {
		setDate(date)
		setValue("home.jours", moment(date).format("YYYY-MM-DD"))
	}
	
	return (
		<div className="tab-content-admin px-3 py-3">
			<form onSubmit={onSubmit}>
				<div className="mt-3 row">
					<div className="col-12 mb-3">
						<AdminInput
							label="Phrase d'accroche"
							id="phrase_accrocher"
							type="text"
							placeholder="Phrase d'accroche"
							{...register("home.description")}
							error={errors?.home?.description}
							required
						/>
					</div>
					<div className="col-md-12 mb-3">
						<label className="form-label form-label-modal-custom">
							Date de lancement
							<span className="text-danger" style={{ fontSize: 24 }}>
								*
							</span>
						</label>
						<DatePicker
							className="form-control form-control-modal-custom"
							selected={date}
							onChange={handleChangeDate}
							minDate={new Date()}
							dateFormat="dd/MM/yyyy"
							locale="fr"
						/>
						<ErrorMessage message={errors?.home?.jours?.message} />
					</div>
					{/* <div className="col-md-6 mb-3">
						<AdminInput
							label="Heure de lancement"
							id="heure"
							type="time"
							placeholder="heure"
							{...register("home.heures")}
							error={errors?.home?.heures}
							required
						/>
					</div> */}
				</div>
				<div className="px-2 flex-r col-md-4 offset-md-8 gap-3 py-3">
					<BtnSubmit label="Modifier" isLoading={isLoading} />
				</div>
			</form>
		</div>
	);
};

export default GestionBanner;
