import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoMdAttach } from "react-icons/io";
import { useModal, usePagination, useSearch, useSelectSearch } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import {
	formatMontant,
	formatRemboursementStatus,
	formatRemboursementStatusClass,
	formattedDate,
	isShowPagination,
	isValidRemboursement,
} from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { useGetRemboursementsQuery } from "../../../../utils/api/remboursement/remboursement.api";
import { IRemboursement, RemboursementType } from "../../../../utils/api/remboursement/remboursement.type";
import { ButtonDelete, ButtonEdit, ButtonViewLink, Spinner } from "../../../common/Button";
import PieceJointeModal from "../../../modal/admin/PieceJointeModal";
import ChangeStatusRemboursementModal from "./ChangeStatusRemboursementModal";
import { BsCheckLg } from "react-icons/bs";
import { useValidRemboursement } from "./useRemboursement";
import { Color } from "../../../../utils/theme";
import AddRemboursementModal from "../../../modal/admin/AddRemboursementModal";
import moment from "moment";
import { useAppSelector } from "../../../../redux/hooks";
import { isSuperAdmin } from "../../../../routes/routerUtils";
import { maxAMountRemboursement } from "../../../../utils/constantes";
import { useDeleteRemboursement } from "./hooks/useAddOrEditRemboursement";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { InputFilter } from "../../../common/Input";
import { SelectInputFilter } from "../../../common/SelectInput";
import { statusList } from "./Remboursements";
registerLocale("fr", fr);

export function DeleteRemboursement({ item }: { item: IRemboursement }) {
	const { user } = useAppSelector((s) => s?.user);
	const onDelete = useDeleteRemboursement(item);
	if (item?.status === "acceptee" || user?.id !== item?.auteur?.id) {
		return null;
	}
	return <ButtonDelete onClick={onDelete} />;
}

export function ValidRemboursement({ item }: { item: IRemboursement }) {
	const { onValid, isLoadSendCode } = useValidRemboursement(item);
	const issuperadmin = useAppSelector((s) => isSuperAdmin(s?.user?.user));

	return (
		<>
			{isValidRemboursement(item) ? (
				<button className="btn btn-action-modal-icon with-tooltip" data-tooltip-content="Déjà validé" disabled>
					<BsCheckLg color={isValidRemboursement(item) ? Color.success : ""} />
				</button>
			) : (
				<button
					className="btn btn-action-modal-icon with-tooltip"
					data-tooltip-content="Valider"
					onClick={() => onValid()}
					disabled={!issuperadmin && item?.montant > maxAMountRemboursement}
				>
					{!isLoadSendCode ? <BsCheckLg color={isValidRemboursement(item) ? Color.success : ""} /> : <Spinner />}
				</button>
			)}
		</>
	);
}

const modalId = "ChangeStatusRemboursementModalHospital";
export type RemProps = {
	type_remboursement?: string;
	nom?: string;
	prenom?: string;
	date?: Date;
	matricule?: string;
	hopital?: string;
	appareils?: string;
	status?: string;
};

function ListeHospitalisationsTable() {
	const [date, setDate] = useState<Date>();
	const { search: nom, handlefilter } = useSearch();
	const { search: prenom, handlefilter: handlefilterPrenom } = useSearch();
	const { search: matricule, handlefilter: handlefilterMatricule } = useSearch();
	const { search: hopital, handlefilter: handlefilterHopital } = useSearch();
	const { search: status, handlefilter: handleFilterByStatus } = useSelectSearch();
	const { page, setPage, limit, setPerPage } = usePagination(10);

	const { data = { results: [] }, isLoading } = useGetRemboursementsQuery({
		page,
		limit,
		type_remboursement: "hospitalisation",
		nom,
		date: date ? moment(date).format("DD-MM-YYYY") : undefined,
		matricule,
		hopital,
		prenom,
		status,
	});

	const { item, getItem, resetItem, openEditModal } = useModal<IRemboursement>();

	const actionFormatter = (cell: string, row: IRemboursement) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal"></div>
					<ValidRemboursement item={row} />
					<ButtonViewLink to={`${row?.slug}`} state={row} />

					{isValidRemboursement(row) ? (
						<></>
					) : (
						<ButtonEdit
							data-bs-toggle="modal"
							data-bs-target="#AddRemboursementModal"
							onClick={() => openEditModal(row)}
						/>
					)}
					<div className="container-btn-action-icon-modal">
						<button
							className="btn btn-action-modal-icon with-tooltip"
							data-tooltip-content="Justificatif"
							data-bs-toggle="modal"
							data-bs-target="#ListeDossiersModal"
							onClick={() => getItem(row)}
						>
							<IoMdAttach />
						</button>
					</div>
					<DeleteRemboursement item={row} />
				</div>
			</>
		);
	};

	const statutFormatter = (cell, row) => {
		return (
			<div className="table-actions-btn-container-img d-flex align-items-center">
				<span
					className={`statut-dossier-item text-capitalize ${formatRemboursementStatusClass(cell)}`}
					role="button"
					onClick={() => getItem(row)}
					data-bs-toggle="modal"
					data-bs-target={`#${modalId}`}
				>
					{formatRemboursementStatus(cell)}
				</span>
			</div>
		);
	};

	const columns = [
		{
			dataField: "matricule",
			text: "Matricule",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="matricule" placeholder="Entrer un matricule" onChange={handlefilterMatricule} />,
		},
		{
			dataField: "patient.prenom",
			text: "Prénom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="prenom" placeholder="Entrer un prénom" onChange={handlefilterPrenom} />,
		},
		{
			dataField: "patient.nom",
			text: "Nom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="nom" placeholder="Entrer un nom" onChange={handlefilter} />,
		},
		{
			dataField: "montant",
			text: "Montant",
			formatter: (cell: string) => formatMontant(cell),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="nom" placeholder="Entrer un montant" onChange={handlefilter} />,
		},
		{
			dataField: "hopital",
			text: "Hopital",
			filter: customFilter(),
			filterRenderer: () => (
				<InputFilter id="hopital" placeholder="Entrer un hôpital" onChange={handlefilterHopital} defaultValue={hopital} />
			),
		},
		{
			dataField: "created_at",
			text: "Date",
			formatter: (cell: string) => formattedDate(cell),
			filter: customFilter(),
			filterRenderer: () => (
				<div className="date-picker-container">
					<DatePicker
						className="form-control tab-dash-admin-filter-input"
						selected={date}
						onChange={(date: Date) => setDate(date)}
						maxDate={new Date()}
						dateFormat="dd/MM/yyyy"
						placeholderText="Choisir une date"
						isClearable
						locale="fr"
					/>
				</div>
			),
		},
		{
			dataField: "status",
			text: "Statut",
			formatter: (cell: string, row: IRemboursement) => statutFormatter(cell, row),
			filter: customFilter(),
			filterRenderer: () => (
				<SelectInputFilter id="motif" placeholder="Statut" onChange={handleFilterByStatus} options={statusList} />
			),
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IRemboursement) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="slug"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div className="modal fade" id="ListeDossiersModal" aria-labelledby="ListeDossiersModalLabel" aria-hidden="true">
				<PieceJointeModal item={item} />
			</div>
			<ChangeStatusRemboursementModal modalId={modalId} item={item} />
			<div className="modal fade" id="AddRemboursementModal" aria-labelledby="AddRemboursementModalLabel" aria-hidden="true">
				<AddRemboursementModal
					resetItem={resetItem}
					item={item}
					currentType={RemboursementType.hospitalisation}
					modalId="AddRemboursementModal"
				/>
			</div>
		</>
	);
}

export default ListeHospitalisationsTable;
