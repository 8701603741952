import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl } from "../../utils";
import { IDemande, DemandeQuery, DemandeFormData } from "./demande.type";

export const DemandeApi = createApi({
	reducerPath: "DemandeApi",
	tagTypes: ["demandes"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getDemande: build.query<PaginationResults<IDemande>, DemandeQuery>({
			query: (query) => QueryUrl("paiements/", query),
			providesTags: ["demandes"],
		}),
		editDemande: build.mutation<IDemande, DemandeFormData>({
			invalidatesTags: ["demandes"],
			query: ({ slug, ...data }) => {
				return {
					url: `paiements/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
	}),
});

export const { useGetDemandeQuery, useEditDemandeMutation } = DemandeApi;
