import React from "react";
import { MdLogout } from "react-icons/md";
import Swal from "sweetalert2";
import { useAppDispatch } from "../../../../redux/hooks";
import { onlogout } from "../../../../redux/slice/User.slice";
import { Color } from "../../../../utils/theme";

const LogoutBtn = ({ customClass }: { customClass?: string }) => {
	const dispatch = useAppDispatch();
	const onLogout = () => {
		Swal.fire({
			title: `Êtes vous vraiment sûr de vouloir-vous déconnecter`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			iconColor: Color.danger,
			confirmButtonColor: Color.danger,
			preConfirm: (confirm) => {
				if (confirm) {
					dispatch(onlogout());
				}
			},
		});
	};
	return (
		<button type="button" onClick={onLogout} className={`dropdown-item ${customClass}`}>
			Déconnexion
		</button>
	);
};

export const LogoutBtnSidebar = () => {
	const dispatch = useAppDispatch();
	const onLogout = () => {
		Swal.fire({
			title: `Êtes vous vraiment sûr de vouloir-vous déconnecter`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			iconColor: Color.danger,
			confirmButtonColor: Color.danger,
			preConfirm: (confirm) => {
				if (confirm) {
					dispatch(onlogout());
				}
			},
		});
	};
	return (
		<button className="btn nav-link nav-link-vertival-custom" onClick={onLogout}>
			<MdLogout />
			<span className="hiddenable-navlink-label">Déconnexion</span>
		</button>
	);
};

export default LogoutBtn;
