import React from "react";
import { ApiBaseUrl } from "../../../../utils/http";
import { AlertInfo } from "../../../common/Alert";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { checkIfIsImage } from "../../../../utils/utils";

const PieceJointeDemandeModal = ({ item }: { item?: string | null }) => {
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						Pièces jointes
					</h5>
					<CloseModalBtn />
				</div>
				<div className="modal-body">
					<div className="container-form">
						{item ? (
							<>
								{checkIfIsImage(item) ? (
									<img
										width="100%"
										height={500}
										src={
											ApiBaseUrl +
											item
										}
									/>
								) : (
									<iframe
										height={500}
										width="100%"
										src={
											ApiBaseUrl +
											item
										}
									/>
								)}
							</>
						) : (
							<AlertInfo message="Aucun fichier trouvé" />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PieceJointeDemandeModal;
