import React from "react";
import { NavLink } from "react-router-dom";

const DownloadAppSection = () => {
	return (
		<section className="section-info-download-app">
			<div className="landingpage-crypta-container">
				<div className="info-download-app-container">
					<div className="content-title-info-download-app mb-3 mb-md-0">
						<h2 className="title-info-download-app">
							N’attendez plus, <br /> rejoignez-nous !
						</h2>
					</div>
					<div className="container-img-download-app">
						{/* <div className="content-img-download-app-item">
              <img
                src={AppStore}
                alt="App store"
                className="img-download-app-item"
              />
            </div>
            <div className="content-img-download-app-item">
              <img
                src={PlayStore}
                alt="Play store"
                className="img-download-app-item"
              />
            </div> */}
						<NavLink to="/nous-rejoindre" className="btn btn-postuler">
							Nous rejoindre
						</NavLink>
					</div>
				</div>
			</div>
		</section>
	);
};

export default DownloadAppSection;
