import React from "react";
import { IScreen } from "../../../../../utils/api/screen/screen.type";
import useCrudScreen from "../hooks/useCrudScreen";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import { AdminInput, FormError } from "../../../../common/Input";
import ReactQuill from "react-quill";
import { BtnSubmit } from "../../../../common/Button";
import { getImage } from "../../../../../utils/utils";


const AddOrEditSreenModal = ({
	item,
	resetItem,
}: {
	item?: IScreen;
	resetItem: () => void;
}) => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		reset,
        image,
        setImage,
        handleChangeScreenImage,
		description,
        setDescription,
		handleChangeDescription,
	} = useCrudScreen(item, resetItem);

	const resetAll = () => {
		resetItem();
        setImage(undefined);
        setDescription("");
		reset();
	};
	return (
		<div className="modal-dialog">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{item ? "Modifier cet" : "Ajouter un"}{" "}
						écran
					</h5>
					<CloseModalBtn onClick={resetAll} />
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
							<div className="col-md-12">
								<AdminInput
									label="Position"
									id="position"
									type="number"
									placeholder="Position"
									{...register("position")}
									error={errors?.position}
									required
								/>
							</div>
                                <div className="col-md-12">
									<AdminInput
										label="Image"
                                        name="image"
										id="image"
										type="file"
                                        accept="image/*"
                                        placeholder="Image"
										onChange={
                                            (e) => handleChangeScreenImage(e)
                                        }
										error={errors?.image}
										required
									/>
                                    {!!image && (
                                        <div className="preview-container">
                                            <p 
                                                className="m-0 pb-1"
                                                style={{ fontSize: 10 }}
                                            >
                                                nouvelle image
                                            </p>
                                            <img 
                                                src={image} 
                                                alt={`screen`} 
                                                style={{
                                                    width: '5rem',
                                                    height: '5rem',
                                                    objectFit: 'cover'
                                                }}
                                                className="preview" 
                                            />
                                        </div>
                                    )}
                                    {!!item && (
                                        <div className="preview-container">
                                            <p 
                                                className="m-0 pb-1"
                                                style={{ fontSize: 10 }}
                                            >
                                                image actuelle
                                            </p>
                                            <img 
                                                src={getImage(item?.image)} 
                                                alt={`screen-${item?.id}`} 
                                                style={{
                                                    width: '5rem',
                                                    height: '5rem',
                                                    objectFit: 'cover'
                                                }}
                                                className="preview" 
                                            />
                                        </div>
                                    )}
								</div>
								<div className="col-md-12">
									<AdminInput
										label="Titre"
										id="titre"
										type="text"
										placeholder="Titre"
										{...register("titre")}
										error={errors?.titre}
										required
									/>
								</div>
								<div className="col-md-12">
									<label
										htmlFor="description"
										className="form-label form-label-modal-custom"
									>
										Description
                                        <span className="text-danger" style={{ fontSize: 24 }}>
                                            *
                                        </span>
									</label>
									<ReactQuill
										value={description}
										onChange={(val: string) =>
											handleChangeDescription(val)
										}
									/>
									<FormError
										error={
											errors?.desription
										}
									/>
								</div>
							</div>
							<div className="container-btn-modal d-flex justify-content-end gap-3 mt-4">
								<button
									type="button"
									className="btn btn-cancelled"
									data-bs-dismiss="modal"
									onClick={resetAll}
								>
									Fermer
								</button>
								<BtnSubmit
									isLoading={isLoading}
									label={
										item
											? "Modifier"
											: "Ajouter"
									}
									style={{
										width: "inherit",
									}}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddOrEditSreenModal;
