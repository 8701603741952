import React from "react";
import "./ParametreAdmin.css";
import { IoMdAdd } from "react-icons/io";
import TermesConditions from "./TermesConditions";
import Cgu from "./Cgu";
import ListeAdminsTable from "./ListeAdminsTable";
import { useGetConditionsQuery } from "../../../../utils/api/condition/condition.api";
import {
	conditionType,
	ICondition,
} from "../../../../utils/api/condition/condition.type";
import ListeSlidesTable from "./ListeSlidesTable";
import { useAppSelector } from "../../../../redux/hooks";
import { isSuperSuperAdmin } from "../../../../routes/routerUtils";
import ListeModePaiementTable from "./ListeModePaiementTable";
import Gains from "./Gains";
import ListeDiplomesTable from "./ListeDiplomesTable";
import ListeSpecialitesTable from "./ListeSpecialitesTable";
import ScreensTable from "./Screens/ScreensTable";

const ParametreAdmin = () => {
	const { data: conditions } = useGetConditionsQuery();
	let policy = conditions?.filter(
		(item: ICondition) => item?.type === conditionType.policy
	)[0];
	let cgu = conditions?.filter(
		(item: ICondition) => item?.type === conditionType.cgu
	)[0];
	const { user } = useAppSelector((s) => s?.user);
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="crypta-dash-locataire-requetes-header mb-4">
					<h4 className="crypta-dash-header-titre">
						Paramètres
					</h4>
				</div>
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<ul
						className="nav nav-tabs nav-tabs-setting"
						id="myTab"
						role="tablist"
					>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting active"
								id="admins-tab"
								data-bs-toggle="tab"
								data-bs-target="#admins"
								type="button"
								role="tab"
								aria-controls="admins"
								aria-selected="true"
							>
								Administrateurs
							</button>
						</li>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting"
								id="screens-tab"
								data-bs-toggle="tab"
								data-bs-target="#screens"
								type="button"
								role="tab"
								aria-controls="screens"
								aria-selected="true"
							>
								Écrans
							</button>
						</li>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting"
								id="cgu-tab"
								data-bs-toggle="tab"
								data-bs-target="#pub"
								type="button"
								role="tab"
								aria-controls="pub"
								aria-selected="false"
							>
								Publicités
							</button>
						</li>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting"
								id="cgu-tab"
								data-bs-toggle="tab"
								data-bs-target="#modepaiement"
								type="button"
								role="tab"
								aria-controls="modepaiement"
								aria-selected="false"
							>
								Mode de paiements
							</button>
						</li>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting"
								id="termes-conditions-tab"
								data-bs-toggle="tab"
								data-bs-target="#termes-conditions"
								type="button"
								role="tab"
								aria-controls="termes-conditions"
								aria-selected="false"
							>
								Politiques de confidentialité
							</button>
						</li>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting"
								id="cgu-tab"
								data-bs-toggle="tab"
								data-bs-target="#cgu"
								type="button"
								role="tab"
								aria-controls="cgu"
								aria-selected="false"
							>
								CGU
							</button>
						</li>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting"
								id="gain-tab"
								data-bs-toggle="tab"
								data-bs-target="#gain"
								type="button"
								role="tab"
								aria-controls="gain"
								aria-selected="false"
							>
								Gains/Echanges
							</button>
						</li>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting"
								id="diplome-tab"
								data-bs-toggle="tab"
								data-bs-target="#diplome"
								type="button"
								role="tab"
								aria-controls="diplome"
								aria-selected="false"
							>
								Diplômes
							</button>
						</li>
						<li
							className="nav-item nav-item-setting"
							role="presentation"
						>
							<button
								className="nav-link nav-link-setting"
								id="specialite-tab"
								data-bs-toggle="tab"
								data-bs-target="#specialite"
								type="button"
								role="tab"
								aria-controls="specialite"
								aria-selected="false"
							>
								Spécialités
							</button>
						</li>
					</ul>
				</div>
				<div className="col-left-dashboard-responsive">
					<div className="tab-content " id="myTabContent">
						<div
							className="tab-pane fade show active"
							id="admins"
							role="tabpanel"
							aria-labelledby="admins-tab"
						>
							<div className="">
								{isSuperSuperAdmin(user) && (
									<div className="content-btn-add-theme flex-r mb-4">
										<button
											className="btn btn-add-theme"
											data-bs-toggle="modal"
											data-bs-target="#AddAdminModal"
										>
											<IoMdAdd />
											<span className="ps-2">
												Ajouter
												un
												administrateur
											</span>
										</button>
									</div>
								)}
								<ListeAdminsTable />
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="screens"
							role="tabpanel"
							aria-labelledby="screens-tab"
						>
							<div className="">
								<div className="content-btn-add-theme flex-r mb-4">
									<button
										className="btn btn-add-theme"
										data-bs-toggle="modal"
										data-bs-target="#AddOrEditScreenModal"
									>
										<IoMdAdd />
										<span className="ps-2">
											Ajouter un écran
										</span>
									</button>
								</div>
								<ScreensTable />
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="pub"
							role="tabpanel"
							aria-labelledby="pubs-tab"
						>
							<div className="">
								<div className="content-btn-add-theme flex-r mb-4">
									<button
										className="btn btn-add-theme"
										data-bs-toggle="modal"
										data-bs-target="#AddSlideModal"
									>
										<IoMdAdd />
										<span className="ps-2">
											Ajouter une
											slide
										</span>
									</button>
								</div>
								<ListeSlidesTable />
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="modepaiement"
							role="tabpanel"
							aria-labelledby="modepaiement-tab"
						>
							<div className="">
								<div className="content-btn-add-theme flex-r mb-4">
									<button
										className="btn btn-add-theme"
										data-bs-toggle="modal"
										data-bs-target="#AddMoyenPaiementModal"
									>
										<IoMdAdd />
										<span className="ps-2">
											Ajouter
										</span>
									</button>
								</div>
								<ListeModePaiementTable />
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="termes-conditions"
							role="tabpanel"
							aria-labelledby="termes-conditions-tab"
						>
							<TermesConditions
								content={policy}
								type={conditionType.policy}
							/>
						</div>
						<div
							className="tab-pane fade"
							id="cgu"
							role="tabpanel"
							aria-labelledby="cgu-tab"
						>
							<Cgu
								content={cgu}
								type={conditionType.cgu}
							/>
						</div>
						<div
							className="tab-pane fade"
							id="gain"
							role="tabpanel"
							aria-labelledby="gain-tab"
						>
							<Gains />
						</div>
						<div
							className="tab-pane fade"
							id="diplome"
							role="tabpanel"
							aria-labelledby="diplome-tab"
						>
							<div className="content-btn-add-theme flex-r mb-4">
								<button
									className="btn btn-add-theme"
									data-bs-toggle="modal"
									data-bs-target="#AddDiplomeModal"
								>
									<IoMdAdd />
									<span className="ps-2">
										Ajouter
									</span>
								</button>
							</div>
							<ListeDiplomesTable />
						</div>
						<div
							className="tab-pane fade"
							id="specialite"
							role="tabpanel"
							aria-labelledby="specialite-tab"
						>
							<div className="content-btn-add-theme flex-r mb-4">
								<button
									className="btn btn-add-theme"
									data-bs-toggle="modal"
									data-bs-target="#AddSpecialiteModal"
								>
									<IoMdAdd />
									<span className="ps-2">
										Ajouter
									</span>
								</button>
							</div>
							<ListeSpecialitesTable />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ParametreAdmin;
