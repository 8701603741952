import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { AdminInput } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/Input";
import useCrudTheme from "../../TableauDebord/Admin/ForumMessage/hooks/useCrudTheme";
import { ITheme } from "../../../utils/api/forum/forum.type";

const AddThemeModal = ({ item, resetItem }: { item?: ITheme; resetItem: () => void }) => {
	const { register, onSubmit, errors, isLoading } = useCrudTheme(item, resetItem);
	const reset = () => {
		resetItem();
	};
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{item ? "Modifier le" : "Ajouter un"} thème
					</h5>
					<CloseModalBtn />
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-12">
									<AdminInput
										label="Nom"
										id="nom"
										type="text"
										placeholder="Nom"
										{...register("name")}
										error={errors?.name}
										required
									/>
								</div>
								<div className="col-md-12">
									<label
										htmlFor="description"
										className="form-label form-label-modal-custom"
									>
										Description
									</label>
									<textarea
										id="description"
										className="form-control"
										{...register("description")}
										style={{
											minHeight: "150px",
											maxHeight: "150px",
										}}
									/>
									<FormError error={errors?.description} />
								</div>
							</div>
							<div className="container-btn-modal d-flex justify-content-end gap-3 mt-4">
								<button
									type="button"
									className="btn btn-cancelled"
									data-bs-dismiss="modal"
									onClick={reset}
								>
									Fermer
								</button>
								<BtnSubmit
									isLoading={isLoading}
									label={item ? "Modifier" : "Ajouter"}
									style={{ width: "inherit" }}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddThemeModal;
