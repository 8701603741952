import { IUser, TypeQuery, UserType } from "../user/user.type";

export enum AdminType {
	admin = "admin",
	superadmin = "superadmin",
}

export type IAdmin = Pick<
	IUser,
	| "nom"
	| "prenom"
	| "email"
	| "telephone"
	| "is_active"
	| "is_archive"
	| "avatar"
	| "created_at"
	| "adresse"
	| "user_type"
	| "slug"
	| "id"
	| "longitude"
	| "latitude"
> & {
	admin_type: AdminType;
	parent: number;
	dashboard: boolean;
	faqs: boolean;
	abonnemements: boolean;
	messages: boolean;
	parametres: boolean;
	patients: boolean;
	transactions: boolean;
	professionnels: boolean;
	forum: boolean;
	remboursements: boolean;
	dossiers: boolean;
	demandes: boolean;
	signalements: boolean;
	landing_page: boolean;
	offres: boolean;
	abonnements: boolean;
	user_type: UserType;
};

export type IAdminFormData = Partial<IAdmin>;

export interface IWeekData {
	day: string;
	nb_user: number;
}

export interface IYearData {
	month: string;
	users_count: number;
}
export interface IMonthData {
	start_day: string;
	end_day: string;
	users_count: number;
}

export interface IAdminDashboardData {
	utilisateurs: number;
	transactions: number;
	progression: number;
	nb_sms_restant: number;
	homme: number;
	femme: number;
	graph: IWeekData[];
}

export type GraphData = IWeekData[] | IYearData[] | IMonthData;

export type AdminQuery = TypeQuery & {
	nom?: string;
	type?: string;
	prenom?: string;
	telephone?: string;
	email?: string;
	adresse?: string;
};
