import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults } from "../user/user.type";
import { ISlide, SlideFormData, SlideQuery } from "./slide.type";
import { prepareHeaders } from "../user/user.api";

export const SlideApi = createApi({
	reducerPath: "slideApi",
	tagTypes: ["slides"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getSlides: builder.query<PaginationResults<ISlide>, SlideQuery>({
			query: (query) => QueryUrl("/slides/", query),
			providesTags: ["slides"],
		}),
		deleteSlide: builder.mutation<ISlide, string>({
			query: (slug) => ({
				url: `/slides/${slug}/`,
				method: "Delete",
			}),
			invalidatesTags: ["slides"],
		}),
		editSlide: builder.mutation<ISlide, SlideFormData>({
			invalidatesTags: ["slides"],
			query: ({ slug, ...data }) => {
				return {
					url: `slides/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		addOrUpdateSlide: builder.mutation<
			ISlide,
			{
				slug?: string;
				data: SlideFormData | FormData;
			}
		>({
			invalidatesTags: ["slides"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `slides/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `slides/`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useGetSlidesQuery,
	useDeleteSlideMutation,
	useEditSlideMutation,
	useAddOrUpdateSlideMutation,
} = SlideApi;
