import React from "react";
import { BtnSubmit } from "../../common/Button";
import { AdminInput } from "../../common/Input";
import CloseModalBtn from "../../shared/CloseModalBtn";
import "./AdminModal.css";
import useAddOrEditRemboursement from "../../TableauDebord/Admin/Remboursements/hooks/useAddOrEditRemboursement";
import {
	IRemboursement,
	RemboursementType,
} from "../../../utils/api/remboursement/remboursement.type";
import { SelectInput } from "../../common/SelectInput";
import Select from "react-select";

const types = [
	{
		label: "Hospitalisations",
		value: RemboursementType.hospitalisation,
	},
	{
		label: "Appareils (dentition, vue, motricité, etc...)",
		value: RemboursementType.appareils,
	},
];
type Props = {
	resetItem: () => void;
	item?: IRemboursement;
	currentType: RemboursementType;
	modalId: string;
};
const AddRemboursementModal = ({
	item,
	resetItem,
	currentType,
	modalId,
}: Props) => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		handleChangeJustifcatif,
		handleChangeType,
		type,
		filterOptions,
		inputValue,
		handleInputChange,
		handleChange,
		loadOptions,
		options,
		option,
	} = useAddOrEditRemboursement(resetItem, currentType, modalId, item);

	const reset = () => {
		resetItem();
	};
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{item ? "Modifier la" : "Ajouter une"}{" "}
						demande de remboursement
					</h5>
					<CloseModalBtn onClick={reset} />
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-12">
									<div className="mb-3 react-custom-select-input">
										<label
											htmlFor="nombre_stock"
											className="form-label form-label-modal-custom"
										>
											Patient
											<span
												className="text-danger"
												style={{
													fontSize: 17,
												}}
											>
												*
											</span>
										</label>
										<Select
											options={
												options
											}
											className="react-select-container c-react-select"
											classNamePrefix="react-select"
											inputValue={
												inputValue
											}
											onInputChange={
												handleInputChange
											}
											onChange={
												handleChange
											}
											filterOption={
												filterOptions
											}
											onMenuOpen={() =>
												loadOptions(
													inputValue
												)
											}
											menuIsOpen={
												inputValue.length >=
												2
											}
											placeholder="Entrer le nom d'un patient"
											value={option}
										/>
									</div>
								</div>
								<div className="col-md-12">
									<AdminInput
										label="Montant"
										id="montant"
										type="text"
										placeholder="Montant"
										{...register(
											"montant"
										)}
										error={
											errors?.montant
										}
										required
										inputMode="text"
									/>
								</div>
								<div className="col-md-12">
									<SelectInput
										className="form-control form-control-modal-custom"
										label="Type de remboursement"
										id="type"
										placeholder="Type de remboursement"
										{...register(
											"type"
										)}
										error={errors?.type}
										required
										options={types}
										hiddenFirstOption
										onChange={
											handleChangeType
										}
										value={currentType}
										disabled
									/>
								</div>
								<div className="col-md-12">
									{type ===
									RemboursementType.hospitalisation ? (
										<AdminInput
											label="Hôpital"
											id="hopital"
											type="text"
											placeholder="Hôpital"
											{...register(
												"hopital"
											)}
											error={
												errors?.hopital
											}
											required
										/>
									) : (
										<AdminInput
											label="Appareils"
											id="appareil"
											type="text"
											placeholder="Appareils"
											{...register(
												"appareils"
											)}
											error={
												errors?.appareils
											}
											required
										/>
									)}
								</div>
								<div className="col-md-12">
									<AdminInput
										label="Justificatif"
										id="fichier"
										type="file"
										error={
											errors?.fichier
										}
										onChange={
											handleChangeJustifcatif
										}
										accept="application/pdf, image/*"
										required
									/>
								</div>
							</div>
							<div className="container-btn-modal d-flex gap-3 mt-4">
								<button
									type="button"
									className="btn btn-cancelled"
									data-bs-dismiss="modal"
									onClick={reset}
								>
									Annuler
								</button>
								<BtnSubmit
									isLoading={isLoading}
									label={
										item
											? "Modifier"
											: "Ajouter"
									}
									style={{
										width: "inherit",
									}}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddRemboursementModal;
