import React from "react";
import { toast } from "react-hot-toast";
import { AlertInfo } from "../../../common/Alert";
import { ButtonDelete } from "../../../common/Button";
import {
  useDeleteNotificationMutation,
  useNotification,
} from "../../../../utils/api/notification/notification.api";
import { formatNotifType, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { Link } from "react-router-dom";

const Notifications = () => {
  const { data, isLoading, limit, page, setPage, setPerPage, total } =
    useNotification();
  console.log({ data });
  const [deleteItem] = useDeleteNotificationMutation();
  const onDelete = async (slug) => {
    const res = await deleteItem(slug);
    if ("data" in res) {
      toast.success("Supprimé avec succès");
    } else if ("error" in res) {
      toast.error("Une erreur a survenue");
    }
  };
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3 mt-4 filter-and-table-container">
            <div className="container-head-admin mb-4">
              <div className="crypta-dash-locataire-requetes-header">
                <h4 className="crypta-dash-header-titre">Notifications</h4>
              </div>
            </div>
            <div className="container-coolapse-questions mt-5">
              {!isLoading ? (
                data && data?.length > 0 ? (
                  <>
                    {data?.map((item) => (
                      <div
                        className="card card-collapse-questioncard  mb-3"
                        key={item?.slug}
                      >
                        <div className="row">
                          <div className="col-md-9">
                            <Link
                              to={`/admin/${
                                item?.notif_type === "demande_paiement"
                                  ? "demandes"
                                  : item?.notif_type === "remboursement"
                                  ? "remboursements"
                                  : item?.notif_type === "depot"
                                  ? "transactions"
                                  : item?.notif_type === "signalement"
                                  ? "signalements"
                                  : "patients"
                              }`}
                              className="no-underline"
                            >
                              <h6 className="notification-content mb-0 fw-300">
                                {item?.content}
                              </h6>
                            </Link>
                          </div>
                          <div className="col-md-2">
                            <p
                              className={`notif-type bg-${item?.notif_type} m-0`}
                            >
                              {formatNotifType(item?.notif_type)}
                            </p>
                          </div>
                          <div className="col-md-1 d-flex align-items-center justify-content-end gap-2">
                            <ButtonDelete
                              onClick={() => onDelete(item?.slug)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {isShowPagination(total || 0) && (
                      <CustomPagination
                        nbPages={total}
                        page={page}
                        onChange={(page, limit) => {
                          setPerPage(limit);
                          setPage(page);
                        }}
                        perPage={limit}
                      />
                    )}
                  </>
                ) : (
                  <AlertInfo message="Aucune donnée trouvée" />
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
