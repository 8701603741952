import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { QueryError } from "../../../utils/type";
import { cleannerError, onHide } from "../../../utils/utils";
import { BtnSubmit } from "../../common/Button";
import { AdminInput, FormError } from "../../common/Input";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { toast } from "react-hot-toast";
import { IScreen, ScreenFormData } from "../../../utils/api/screen/screen.type";
import { useAddOrUpdateScreenMutation } from "../../../utils/api/screen/screen.api";

function ChangePositionScreenModal({ item, resetItem }: { item?: IScreen, resetItem: () => void }) {
	const validationSchema = yup.object().shape({
		position: yup.number().required().label("Position"),
	});
	const [changePosition, { isLoading }] = useAddOrUpdateScreenMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<ScreenFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
			setValue("position", item?.position);
		}
	}, [item]);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const onSubmit = async (data: ScreenFormData) => {
		const res = await changePosition({ slug: data?.slug, data: data });
		if ("data" in res) {
			toast.success("Position changée avec  succès!");
			reset();
			resetItem();
			onHide("ChangePositionScreenModal");
		} else if ("error" in res) {
			console.log("error", res.error);
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	const resetAll = () => {
		reset();
		resetItem()
	};
	return (
		<div
			className="modal fade"
			id="ChangePositionScreenModal"
			aria-labelledby="ChangePositionScreenModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="ChangePositionScreenModal"
						>
							Changer la position de l'écran
						</h5>
						<CloseModalBtn onClick={resetAll} />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form className="w-100" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								
                            <div className="col-md-12 mb-3">
								<AdminInput
									label="Position"
									id="position"
									type="number"
									placeholder="Position"
									{...register("position")}
									error={errors?.position}
									required
								/>
							</div>
							</div>
							<div className="flex-end mt-5 gap-4">
								<BtnSubmit
									label="Enregistrer"
									isLoading={isLoading}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangePositionScreenModal;
