import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDeleteWithCode, useItem, usePagination } from "../../../../utils/hooks";
import TableSkeleton from "../../../common/TableSkeleton";
import { formatMontant, formatOffreFrequence, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { ButtonDelete, ButtonEdit, ButtonView, Spinner } from "../../../common/Button";
import {
	useAddOrEditOffreMutation,
	useDeleteOffreMutation,
	useGetOffresQuery,
	useLazySendOfferDeleteCodeQuery,
	useUpdateOffreMutation,
	useUpdateStatusOffreMutation,
} from "../../../../utils/api/offres/offres.api";
import DetailOffreModal from "../../../modal/admin/DetailOffreModal";
import { IOffres } from "../../../../utils/api/offres/offres.type";
import AddOffreModal from "../../../modal/admin/AddOffreModal";
import { useOffresListener } from "../../../../utils/pusher";
import ChangePositionOffreModal from "../../../modal/admin/ChangePositionOffreModal";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { toast } from "react-hot-toast";
import { QueryError } from "../../../../utils/type";
import Switch from "react-switch";

export function DeleteOffre({ item }: { item: IOffres }) {
	const [sendCode, { isLoading }] = useLazySendOfferDeleteCodeQuery();

	const [deleteItem, { isSuccess, isError, error }] =
		useDeleteOffreMutation();
	const onDelete = useDeleteWithCode<IOffres>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		sendCode,
		title: "Suppression d'une offre",
		successMessage: "Offre supprimée avec succès !",
	});
	if (isLoading) return <Spinner />;
	return <ButtonDelete onClick={onDelete} />;
}

function ListeOffresTable() {
	useOffresListener();
	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data, isLoading } = useGetOffresQuery({
		page,
		limit,
	});

	console.log('data', data)

	const { item, getItem, resetItem } = useItem<IOffres>();
	const [editData, { isError, error }] = useUpdateStatusOffreMutation();

	const actionFormatter = (cell: string, row: IOffres) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonView
						data-bs-toggle="modal"
						data-bs-target="#DetailOffreModal"
						onClick={() => getItem(row)}
					/>
					<ButtonEdit
						data-bs-toggle="modal"
						data-bs-target="#AddOffreModal"
						onClick={() => getItem(row)}
					/>
					<DeleteOffre item={row} />
				</div>
			</>
		);
	};

	const toggleActive = async (item: IOffres) => {
		let data = {
			pre_commande: !item.pre_commande,
		};
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${
				!item?.pre_commande === true ? "activer" : "désactiver"
			} le boutton pré-commande ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.blue,
			confirmButtonColor: Color.blue,
			preConfirm: () => {
				return editData({ slug: item.slug, data: data });
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				toast.success(
					`Boutton pré-commande ${
						result?.value?.data?.active ? "activé " : "désactivé"
					} avec succès!`
				);
			}
		});
		useEffect(() => {
			if (isError) {
				const err = error as QueryError;
				const message =
					err?.data?.message ??
					`Une erreur de status ${err?.status} est survenue!`;
				toast.error(message);
			}
		}, [isError]);
	};
	const statutFormatter = (cell, row) => {
		return (
			<Switch
				checked={cell}
				handleDiameter={18}
				height={18}
				width={42}
				offHandleColor="#C4C4C4"
				onHandleColor="#C4C4C4"
				borderRadius={2}
				uncheckedIcon={<div className="toggle-style-off toggle-style">Non</div>}
				checkedIcon={<div className="toggle-style-on toggle-style">Oui</div>}
				className="react-switch"
				id="small-radius-switch"
				onChange={() => toggleActive(row)}
			/>
		);
	};

	const positionFormatter = (cell: string, row: IOffres) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<button
							className={`btn custon-btn-position px-4`}
							data-bs-toggle="modal"
							data-bs-target="#ChangePositionOffreModal"
							aria-expanded="false"
							onClick={() => getItem(row)}
						>
							{cell}
					</button>
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "title",
			text: "Titre",
		},
		{
			dataField: "frequence",
			text: "Fréquence",
			formatter: (cell: string) => formatOffreFrequence(cell),
		},
		{
			dataField: "price",
			text: "Prix",
			formatter: (cell) => formatMontant(cell),
		},
		{
			dataField: "taux_hospitalisation",
			text: "Taux hospitalisation",
			formatter: (cell) => (
				<span className="ps-5">{Number(cell)}%</span>
			),
		},
		{
			dataField: "position",
			text: "Position",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IOffres) =>
				positionFormatter(cell, row),
		},
		{
			dataField: "pre_commande",
			text: "Précommande",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IOffres) => statutFormatter(cell, row),
			headerStyle: () => {
				return { width: "160px", textAlign: "center" };
			},
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IOffres) =>
				actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() =>
							"Aucune donnée trouvée"
						}
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div
				className="modal fade"
				id="DetailOffreModal"
				aria-labelledby="DetailOffreModalLabel"
				aria-hidden="true"
			>
				<DetailOffreModal item={item} />
			</div>
			<div
				className="modal fade"
				id="AddOffreModal"
				aria-labelledby="AddOffreModalLabel"
				aria-hidden="true"
			>
				<AddOffreModal item={item} resetItem={resetItem} />
			</div>
			<ChangePositionOffreModal item={item} resetItem={resetItem} />
		</>
	);
}

export default ListeOffresTable;
