import React, { useState } from "react";
import Capture1 from "../../../assets/landingpage/captures1.png";
import Capture2 from "../../../assets/landingpage/captures2.png";
import Capture3 from "../../../assets/landingpage/captures3.png";
import Capture4 from "../../../assets/landingpage/captures4.png";
import Capture5 from "../../../assets/landingpage/captures5.png";
import "./CapturesCaroussel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const handleDragStart = (e) => e.preventDefault();
const captures = [
  {
    id: 1,
    image: Capture1,
    onDragStart: { handleDragStart },
  },
  {
    id: 2,
    image: Capture2,
    onDragStart: { handleDragStart },
  },
  {
    id: 3,
    image: Capture3,
    onDragStart: { handleDragStart },
  },
  {
    id: 4,
    image: Capture4,
    onDragStart: { handleDragStart },
  },
  {
    id: 5,
    image: Capture5,
    onDragStart: { handleDragStart },
  },
];

export const responsiveAlice = {
  0: { items: 1 },
  768: { items: 2 },
  1024: { items: 4 },
};

function CapturesCaroussel() {
  const [selectedIndex] = useState<number>(0);

  return (
    <div className="carousel-produit-marketplace">
      {/* Swiper */}
      <Swiper
        spaceBetween={10}
        // slidesPerView={4}
        centeredSlides={false}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        navigation={true}
        modules={[Autoplay, Navigation, Pagination]}
        className="mySwiper container-with-dots"
      >
        {captures?.map((capture, index) => (
          <SwiperSlide>
            <div className="horizontal-scroll__item" key={capture?.id}>
              <img
                src={capture?.image}
                alt="Capture"
                className={`capture-mobile ${
                  selectedIndex === index && "selectedIndex"
                }`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default CapturesCaroussel;
