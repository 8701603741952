import React, { useState } from "react";
import "./MessageContact.css";
import DetailsMessageModal from "../../../modal/admin/DetailMessageModal";
import { useGetContactMessagesQuery } from "../../../../utils/api/contact/contact.api";
import MessageContactItem, { ContactMessageItemSkeleton } from "./MessageContactItem";
import { AlertInfo } from "../../../common/Alert";
import { useItem, useSearch } from "../../../../utils/hooks";
import { IContact } from "../../../../utils/api/contact/contact.type";
import ChangeStatusMessageModal from "../../../modal/admin/ChangeStatusMessageModal";
import { InputFilter } from "../../../common/Input";

const MessageContact = () => {
	const [status, setStatus] = useState<string>();

	const { search, handlefilter } = useSearch();
	const { search: author, handlefilter: handlefilterByAuthor } = useSearch();
	const { data, isLoading } = useGetContactMessagesQuery({ status, q: search, name: author });
	const { item, getItem } = useItem<IContact>();

	const handlefilterStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setStatus(e.currentTarget.value);
	};

	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header">
						<h4 className="crypta-dash-header-titre">Messages</h4>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="d-flex gap-4 align-items-center mb-4">
						<div className="tab-dash-admin-filter-container">
							<div>
								<label
									htmlFor="filtreLogement"
									className="tab-dash-admin-filter-label"
								>
									Rechercher par
								</label>
							</div>
							<InputFilter
								id="subject"
								placeholder="Sujet"
								onChange={handlefilter}
							/>
						</div>
						<div>
							<label
								htmlFor="filtreLogement"
								className="tab-dash-admin-filter-label"
							>
								Fitrer par statut
							</label>
							<select
								className="form-control tab-dash-admin-filter-input"
								id="status"
								onChange={handlefilterStatus}
							>
								<option value="">Tous</option>
								<option value="new">Nouveau</option>
								<option value="in_progress">En cours</option>
								<option value="traited">Traité</option>
							</select>
						</div>
						<div className="tab-dash-admin-filter-container">
							<div>
								<label
									htmlFor="filtreLogement"
									className="tab-dash-admin-filter-label"
								>
									Rechercher par
								</label>
							</div>
							<InputFilter
								id="authoe"
								placeholder="Auteur"
								onChange={handlefilterByAuthor}
							/>
						</div>
					</div>
					{!isLoading ? (
						data && data?.length > 0 ? (
							<>
								{data?.map((item) => (
									<MessageContactItem
										key={item?.slug}
										item={item}
										getItem={getItem}
									/>
								))}
							</>
						) : (
							<AlertInfo message="Aucune donnée trouvée" />
						)
					) : (
						<>
							{[...Array(5)].map((item, i) => (
								<ContactMessageItemSkeleton key={i} />
							))}
						</>
					)}
				</div>
			</div>
			<div
				className="modal fade"
				id="DetailsMessageModal"
				aria-labelledby="DetailsMessageModalLabel"
				aria-hidden="true"
			>
				<DetailsMessageModal item={item} />
			</div>
			<ChangeStatusMessageModal item={item} />
		</div>
	);
};

export default MessageContact;
