import Swal from "sweetalert2";
import { useReinitialisationCompteurMutation } from "../../../../../utils/api/user/user.api";
import { IUser } from "../../../../../utils/api/user/user.type";
import { Color } from "../../../../../utils/theme";
import { toast } from "react-hot-toast";
import { useEffect } from "react";

export function useReinitCompteur(user: IUser) {
	const [reinitCompteur, { error }] = useReinitialisationCompteurMutation();
	useEffect(() => {
		if (error) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `${err?.data?.message ?? "Erreur"}`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [error]);
	const onReinit = async () => {
		await Swal.fire({
			title: `Êtes-vous sure de vouloir de reinitialiser le compteur de ce patient?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.blue,
			confirmButtonColor: Color.blue,
			preConfirm: () => {
				return reinitCompteur(user?.slug);
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				toast.success("Compteur reinitialisé avec succès");
			}
		});
	};
	return onReinit;
}
