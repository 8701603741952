import React from "react";
import { usePasswordRule } from "../../auth/hooks/useRegister";
import { PassWordRuleView } from "../../auth/PasswordRuleView";
import { BtnSubmit } from "../../common/Button";
import { AdminInput } from "../../common/Input";
import CloseModalBtn from "../../shared/CloseModalBtn";
import useChangePassword from "../../TableauDebord/Admin/ParametreAdmin/hooks/useChangePassword";

const ChangePasswordModal: React.FC = () => {
	const { register, onSubmit, errors, isLoading, setValue } = useChangePassword();
	const {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
	} = usePasswordRule(setValue, "new_password");
	const props = {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
	};
	return (
		<div className="modal-dialog modal-dialog-centered">
			<div className="modal-content">
				<div className="modal-header">
					<h5
						className="admin-modal-title text-start"
						id="changePasswordLabel"
					>
						Changer mot de passe
					</h5>
					<CloseModalBtn />
				</div>
				<div className="modal-body">
					<form onSubmit={onSubmit}>
						<AdminInput
							label="Mot de passe*"
							type="password"
							id="old_password"
							placeholder="Confimer le mot de passe"
							{...register("old_password")}
							error={errors?.old_password}
							data-testid="oldPasswordId"
							inputMode="text"
						/>
						<AdminInput
							label="Nouveau Mot de passe*"
							type="password"
							id="password"
							placeholder="Nouveau Mot de passe"
							onChange={handleChangePassword}
							onFocus={showPasswordRule}
							onBlur={showPasswordRule}
							error={errors?.new_password}
							data-testid="newPasswordId"
							inputMode="text"
						/>
						{isShowPasswordRules && (
							<PassWordRuleView {...props} />
						)}
						<AdminInput
							label="Confimer le mot de passe*"
							type="password"
							id="new_password_confirm"
							placeholder="Confimer le mot de passe"
							{...register("confirm_password")}
							error={errors?.confirm_password}
							data-testid="confirmNewPasswordId"
							inputMode="text"
						/>
						<div className="gap-3 flex-r align-center mt-4">
							<button
								type="button"
								className="btn btn-cancelled"
								data-bs-dismiss="modal"
							>
								Annuler
							</button>
							<BtnSubmit
								isLoading={isLoading}
								label="Terminer"
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
export default ChangePasswordModal;
