import React, { useState } from "react";
import { IDemande } from "../../../../utils/api/demande/demande.type";
import { IUser } from "../../../../utils/api/user/user.type";
import { useItem, useLocationState } from "../../../../utils/hooks";
import { formatMontant, formattedDateTime, getName } from "../../../../utils/utils";
import { ButtonGoBack } from "../../../common/Button";
import DetailUserModal from "../../../modal/admin/DetailUserModal";
import DetailDossierModal from "../../../modal/admin/DetailDossierModal";
import { Link } from "react-router-dom";

const DetailDemande = () => {
	const item = useLocationState<IDemande>(undefined);
	const { item: user, getItem } = useItem<IUser>();
	const [title, setTitle] = useState<string>("");
	const handleChangeTitle = (title: string) => {
		setTitle(title);
	};
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="admin-page-title-header-container flex-sb align-items-center">
					<div className="crypta-dash-locataire-requetes-header d-flex align-items-center">
						<ButtonGoBack />
						<h4 className="crypta-dash-header-titre m-0">
							Détail de la demande{" "}
							<span className="fw-400">#{item?.id}</span>
						</h4>
					</div>
				</div>
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="detail-demande">
						<div className="mb-2 flex-sb align-items-center">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Patient
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p
									className="text-info-transaction-value underline"
									role="button"
									data-bs-toggle="modal"
									data-bs-target="#DetailUserModal"
									onClick={() =>
										handleChangeTitle("Details du patient")
									}
								>
									{getName(item?.patient)}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Professionnel
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p
									className="text-info-transaction-value underline"
									role="button"
									data-bs-toggle="modal"
									data-bs-target="#DetailUserModal"
									onClick={() => {
										item?.medecin
											? getItem(item?.medecin)
											: getItem(item?.pharmacien);
										handleChangeTitle(
											"Details du professionnel"
										);
									}}
								>
									{item?.medecin
										? `Dr ${getName(item?.medecin)}`
										: `${getName(item?.pharmacien)}`}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Dossier
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p>
									<Link
										className="text-info-transaction-value underline"
										role="button"
										to={`/admin/dossiers/${item?.dossier?.slug}`}
										state={item?.dossier}
									>
										{item?.dossier?.numero}
									</Link>
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Date & Heure
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{formattedDateTime(item?.created_at)}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Montant à payer
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{formatMontant(item?.montant)}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Montant prise en charge par Kivu
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{formatMontant(item?.montant_pris_en_charge)}
								</p>
							</div>
						</div>
						<div className="flex-sb align-items-center mb-2">
							<div className="content-text-info-transaction-libelle">
								<p className="text-info-transaction-libelle">
									Montant total
								</p>
							</div>
							<div className="content-text-info-transaction-value">
								<p className="text-info-transaction-value">
									{formatMontant(item?.montant_total)}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="DetailUserModal"
				aria-labelledby="DetailUserModalLabel"
				aria-hidden="true"
			>
				<DetailUserModal item={user} title={title} />
			</div>
			<div
				className="modal fade"
				id="DetailDossierModal"
				aria-labelledby="DetailDossierModalLabel"
				aria-hidden="true"
			>
				<DetailDossierModal item={user} title={title} />
			</div>
		</div>
	);
};

export default DetailDemande;
