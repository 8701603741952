/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { toast } from "react-hot-toast";
import {
	Diplome,
	DiplomeFormData,
} from "../../../../../utils/api/config/config.type";
import { useAddOrUpdateDiplomeMutation } from "../../../../../utils/api/config/config.api";

function useCrudDiplome(item?: Diplome, resetItem?: () => void) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Titre"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<DiplomeFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [description, setDescription] = useState<string>();
	const [addOrUpdateitem, { isLoading }] = useAddOrUpdateDiplomeMutation();

	const handleChangeDescription = (value: string) => {
		setDescription(value);
		setValue("desription", value);
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("nom", item?.nom);
			setValue("desription", item?.desription);
			setDescription(item?.desription);
		}
	}, [item]);

	const onSubmit = async (data: DiplomeFormData) => {
		console.log("data", data);

		const res = await addOrUpdateitem({
			slug: item?.slug,
			data,
		});
		if ("data" in res) {
			toast.success(
				!item
					? "Diplôme ajouté avec succès"
					: "Le diplôme a été modifié avec succès !"
			);
			reset();
			setDescription("");
			onHide("AddDiplomeModal");
			resetItem && resetItem();
		}
		if ("error" in res) {
			const err = res.error as any;

			let message = err?.data?.message
				? err?.data?.message
				: `Une erreur a survenue lors de ${
						item ? "la modification" : "l'ajout"
				  }`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		description,
		handleChangeDescription,
		reset,
	};
}

export default useCrudDiplome;
