import React from "react";
import { getName, onHide } from "../../../utils/utils";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { ISignalements } from "../../../utils/api/signalements/signalements.type";
import { Link } from "react-router-dom";

const DetailSignalementModal = ({ item }: { item?: ISignalements }) => {
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="admin-modal-title text-start" id="changePasswordLabel">
						Détail du signalement
					</h5>
					<CloseModalBtn />
				</div>
				<div className="form-container p-3">
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Motif</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{item?.motif}
							</p>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Forum</p>
						</div>
						<div className="content-text-info-transaction-value">
							<Link
								className="text-info-transaction-value underline"
								to={`/admin/forum/${item?.forum?.slug}`}
								state={item?.forum}
								onClick={() => onHide("DetailSignalementModal")}
							>
								{item?.forum?.title}
							</Link>
						</div>
					</div>
					<div className="flex-sb align-items-center mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">Auteur</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{getName(item?.user)}
							</p>
						</div>
					</div>
					<div className="mb-2">
						<div className="content-text-info-transaction-libelle">
							<p className="text-info-transaction-libelle">
								Commentaire
							</p>
						</div>
						<div className="content-text-info-transaction-value">
							<p className="text-info-transaction-value">
								{item?.comment?.content}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default DetailSignalementModal;
