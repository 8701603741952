import { IUser, UserType } from "./api/user/user.type";
import { ApiBaseUrl } from "./http";
import $ from "jquery";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import * as Yup from "yup";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Avatar from "../assets/appImages/Avatars.png";
import defaultImage from "../assets/appImages/NoImage.png";
import moment from "moment";
import { IAdmin } from "./api/admin/admin.type";
import { imageExt } from "./constantes";
import { IRemboursement } from "./api/remboursement/remboursement.type";

const phoneUtil = PhoneNumberUtil.getInstance();

export function getInTechPhone(phone: string) {
  const number = phoneUtil.parse(phone, "SN");
  if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
    return number.getNationalNumber();
  }
  return phone;
}
export function getInTechTel(phone: string) {
  const number = phoneUtil.parse(phone, "SN");
  if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
    return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
  }
  return phone;
}

export function validateNumber(phone?: string, country?: string) {
  if (!phone || !country) return true;
  try {
    const number = phoneUtil.parse("+" + phone, country);
    let code = number.getCountryCode();
    let val = phone?.split(`${code}`)[1];

    if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
      return true;
    }

    if (code === 33 && val?.length >= 9) {
      return true;
    }
  } catch (ex) {
    console.log("error phone", ex);
  }
  return false;
}

export function validatePhone(yup: typeof Yup, country: string) {
  return yup
    .string()
    .test("passwords-match", "Numéro de téléphone invalide", (val: any) =>
      validateNumber(val, country)
    )
    .nullable();
}

export const getImage = (img: string | any) => {
  if (img && !img?.includes("default.png") && !img?.includes("static/media/")) {
    if (img.indexOf("http") === 0) return img;
    return ApiBaseUrl + img;
  }
  return defaultImage;
};

export const getAvatar = (avatar: string | any) => {
  if (avatar && !avatar?.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;
    return ApiBaseUrl + avatar;
  }
  return Avatar;
};

export function createMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  return {
    __html: `${numberSlice ? text.slice(0, numberSlice) : text}${
      showDots === true ? "..." : ""
    }`,
  };
}

export function cleannerError(
  errors: any,
  cleanner: any,
  timeOut: number = 5000
) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => cleanner(key)),
      timeOut
    );
  }
}

export const onHide = (id: string) => {
  //   document.getElementById(modalId)?.classList.remove("show");
  //   $(".modal-backdrop").remove();
  //   $(`#${modalId}`).hide();
  //   $(`#${modalId}`).click();
  //   $(`body`).css({ overflowY: "scroll" });
  const element = document.getElementById(id);
  const myModal = Modal.getInstance(element);
  $(".modal-backdrop").remove();
  $("body").removeClass("modal-open");
  $("body").css("padding-right", "");
  $("body").css("overflow", "auto");
  myModal?.hide();
  setTimeout(() => {
    $("body").css("overflow", "auto");
  }, 1000);
};

export const showModal = (id: string) => {
  // console.log("id", id);
  const element = document.getElementById(id);

  const myModal = new Modal(element);
  myModal.show();
};

export const closeModal = (id: string) => {
  const element = document.getElementById(id);
  const myModal = Modal.getInstance(element);
  $(".modal-backdrop").remove();
  $("body").removeClass("modal-open");
  $("body").css("padding-right", "");
  $("body").css("overflow", "auto");
  myModal?.hide();
  setTimeout(() => {
    $("body").css("overflow", "auto");
  }, 1000);
};

export function formattedDate(date: string | Date | undefined) {
  return date ? moment(date).format("DD/MM/YYYY") : "";
}
export function formattedDateTime(date: string | Date | undefined) {
  return date ? moment(date).format("DD/MM/YYYY à HH:MM") : "";
}

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];

      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = val.map((v) => `${key}=${v}`).join("&");
          if (baseUrl.includes("?")) {
            baseUrl += `&${string}`;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}

export function isShowPagination(count: number): boolean {
  if (count > 0) {
    return true;
  }
  return false;
}

export function getName(item?: IUser | IAdmin) {
  if (item) {
    return item?.prenom + " " + item?.nom;
  }
  return "";
}

export function formatUserType(user_type: string) {
  if (user_type === UserType.medecin) {
    return "Médecin";
  }
  if (user_type === UserType.patient) {
    return "Patient";
  }
  if (user_type === UserType.pharmacien) {
    return "Pharmacien";
  }
  if (user_type) {
    return user_type;
  }
  return "";
}

export function formatAmount(amount?: number | string) {
  if (amount || amount === 0)
    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "XOF",
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(parseFloat(String(amount)) || 0);

  return "-";
}
export const formatMontant = (num?: number | string) => {
  return formatAmount(num);
};

export function formatMessageStatus(status: string) {
  return status === "new"
    ? "Nouveau"
    : status === "traited"
    ? "Traité"
    : "En cours";
}

export function formatMessageStatusClass(status: string) {
  return status === "new"
    ? "bg-new"
    : status === "traited"
    ? "bg-success text-bg-success"
    : "bg-secondary text-bg-success";
}

export function formatRemboursementStatus(status: string) {
  if (status === "en_attente") return "En attente";
  if (status === "acceptee") return "Validé";
  if (status === "rejetee") return "Rejeté";
  if (status === "annulee") return "Annulé";
  return "";
}

export function formatRemboursementStatusClass(status: string) {
  if (status === "en_attente") return "stat_in_progress";
  if (status === "acceptee") return "stat_valide";
  if (status === "rejetee") return "stat_rejet";
  if (status === "annulee") return "stat_new";
  return "";
}

export const formatTaux = (num?: number | string) => {
  if (num === 0) return 0;
  if (num) return Number(num) + "%";
  return "";
};
export const formatOffreFrequence = (frequence?: string) => {
  if (frequence === "MONTH") {
    return "Mois";
  }
  return "Année";
};
export const formatOffreFrequenceFront = (frequence?: string) => {
  if (frequence === "MONTH") {
    return "Mois";
  }
  return "An";
};
export const formatDeviseFront = (devise?: string) => {
  if (devise === "XOF") {
    return "F CFA";
  }
  return devise;
};
export const formatTauxPourcentage = (taux?: string) => {
  if (taux) {
    const tauxNumber = parseFloat(taux);
    return `${Math.round(tauxNumber)}%`;
    // return `${tauxNumber.toFixed(2)}%`;
  }
  return taux;
};
export const formatMontantFCFA = (montant?: string, devise?: string) => {
  if (montant && devise === "XOF") {
    const montantNumber = parseFloat(montant);
    const montantFormate = montantNumber.toLocaleString('fr-FR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return `${montantFormate}`;
  }
  return montant;
};
export function formatNotifType(type: string) {
  if (type === "demande_paiement") {
    return "Demande de paiement";
  }
  if (type === "paiement") {
    return "Paiement";
  }
  if (type === "dossier") {
    return "Dossier";
  }
  if (type === "rdv") {
    return "Remboursement";
  }
  if (type === "depot") {
    return "Depôt";
  }
  if (type === "remboursement") {
    return "Remboursement";
  }
  if (type === "participant") {
    return "Participant";
  }
  if (type === "forum") {
    return "Forum";
  }
  if (type === "reinitialisation_compteur") {
    return "Compteur";
  }
  return type;
}

export function formatRdvStatus(status?: string) {
  if (status === "nouveau") return "Nouveau";
  if (status === "accepte") return "Accepté";
  if (status === "refuse") return "Refusé";
  if (status === "annuler") return "Annulé";
  return "";
}

export function checkIfIsImage(img: string) {
  let fileExt = img?.slice(-3);
  let fileExt1 = img?.slice(-4);
  if (imageExt?.includes(fileExt) || imageExt?.includes(fileExt1)) {
    return true;
  } else {
    return false;
  }
}

export function formatDmenadeSupAccountStatus(status: string) {
  if (status === "notification_envoyee") return "En attente";
  if (status === "acceptee") return "Acceptée";
  if (status === "rejetee") return "Refusée";
  if (status === "nouvelle") return "Nouvelle";
  if (status === "archivee") return "Archivée";
  return "";
}

export function formatDmenadeSupAccountStatusClass(status: string) {
  if (status === "notification_envoyee") return "stat_in_progress";
  if (status === "acceptee") return "stat_valide";
  if (status === "refusee") return "stat_rejet";
  if (status === "archivee") return "stat_new";
  if (status === "nouvelle") return "bg_new";
  return "";
}

export const getFileExtension = (filename?: string) => {
  if (filename) {
    return filename.split(".").pop();
  }
  return "mp4";
};

export function convertFileSizeByteToMegaByte(sizeInBytes: number): number {
  const sizeInMegabytes = (sizeInBytes / (1024 * 1024)).toFixed(2);
  return Number(sizeInMegabytes);
}

export function isValidRemboursement(item: IRemboursement) {
  if (item?.status === "acceptee") {
    return true;
  }
  return false;
}

export function findNonStringProperty(obj: { [key: string]: any }): string | null {
	console.log({ obj });
	for (const key in obj) {
		if (typeof obj[key] !== "string" && obj[key] !== undefined) {
			return key;
		}
	}
	return null;
}
export const calculatePageCount = (total?: number, perPage: number = 10) => {
	if (total) {
		return Math.ceil(total / perPage);
	}
	return 0;
};

