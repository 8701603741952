import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults } from "../user/user.type";
import { IAbonnements, AbonnementFormData, AbonnemmentQuery } from "./abonnement.type";
import { prepareHeaders } from "../user/user.api";

export const AbonnementsApi = createApi({
	reducerPath: "abonnementsApi",
	tagTypes: ["abonnements"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getAbonnements: builder.query<PaginationResults<IAbonnements>, AbonnemmentQuery>({
			query: (query) => QueryUrl(`abonnements/`, query),
			providesTags: ["abonnements"],
		}),
		addOrEditAbonnement: builder.mutation<
			IAbonnements,
			{ slug?: string; data: AbonnementFormData }
		>({
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `abonnements/${slug}/`,
						method: "PUT",
						body: data,
					};
				} else {
					return {
						url: `patient/abonnements/`,
						method: "POST",
						body: data,
					};
				}
			},
			invalidatesTags: ["abonnements"],
		}),

		deleteAbonnement: builder.mutation<IAbonnements, string>({
			query: (slug) => ({
				url: `abonnements/${slug}/`,
				method: "Delete",
			}),
			invalidatesTags: ["abonnements"],
		}),
	}),
});

export const {
	useGetAbonnementsQuery,
	useAddOrEditAbonnementMutation,
	useDeleteAbonnementMutation,
} = AbonnementsApi;
