import React from "react";
import ReactDOM from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "animate.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { fr } from "yup-locales";
import { setLocale } from "yup";
import "moment/locale/fr";
import "react-phone-input-2/lib/style.css";
import "react-multi-carousel/lib/styles.css";
import "volkeno-react-skeleton/dist/index.css";
import "react-alice-carousel/lib/alice-carousel.css";

setLocale(fr);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
