import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults } from "../user/user.type";
import { IRemboursement, RemboursementFormData, RemboursementQuery } from "./remboursement.type";
import { prepareHeaders } from "../user/user.api";

export const RemboursementApi = createApi({
	reducerPath: "remboursementApi",
	tagTypes: ["remboursements"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getRemboursements: builder.query<PaginationResults<IRemboursement>, RemboursementQuery>(
			{
				query: (query) => QueryUrl("/remboursements/", query),
				providesTags: ["remboursements"],
			}
		),
		deleteRemboursemnt: builder.mutation<IRemboursement, string>({
			query: (slug) => ({
				url: `/remboursements/${slug}/`,
				method: "Delete",
			}),
			invalidatesTags: ["remboursements"],
		}),
		editRemboursement: builder.mutation<IRemboursement, RemboursementFormData>({
			invalidatesTags: ["remboursements"],
			query: ({ slug, ...data }) => {
				return {
					url: `remboursements/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		validRemboursement: builder.mutation<IRemboursement, { slug: string; code: string }>({
			invalidatesTags: ["remboursements"],
			query: ({ slug, ...data }) => {
				return {
					url: `remboursements/${slug}/validation/`,
					method: "POST",
					body: data,
				};
			},
		}),
		sendCode: builder.query<IRemboursement, void>({
			query: () => {
				return {
					url: `remboursement/request_code/`,
					method: "GET",
				};
			},
		}),
		addOrUpdateRemboursement: builder.mutation<
			IRemboursement,
			{
				slug?: string;
				data: RemboursementFormData | FormData;
			}
		>({
			invalidatesTags: ["remboursements"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `remboursements/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `remboursements/`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useGetRemboursementsQuery,
	useDeleteRemboursemntMutation,
	useEditRemboursementMutation,
	useValidRemboursementMutation,
	useSendCodeQuery,
	useLazySendCodeQuery,
	useAddOrUpdateRemboursementMutation,
} = RemboursementApi;
