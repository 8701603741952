import React from "react";
import ListeDemandesSuppresionCompteTable from "./ListeDemandesSuppresionCompteTable";
const ListeDemandesSuppresionCompte = () => {
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page bg-white p-3">
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="">
						<ListeDemandesSuppresionCompteTable />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListeDemandesSuppresionCompte;
