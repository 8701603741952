import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { IOrder, OrderFormData } from "./order.type";
import { PaginationResults, UserQuery } from "../user/user.type";
import { QueryUrl } from "../../utils";
import { prepareHeaders } from "../user/user.api";

export const OrderApi = createApi({
	reducerPath: "OrderApi",
	tagTypes: ["orders", "preOrders"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		createOrder: build.mutation<IOrder, OrderFormData>({
			query: (data) => {
				return {
					url: `/visitor/pre_orders/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: [],
		}),
		getPreOrders: build.query<PaginationResults<IOrder>, UserQuery>({
			query: (query) => QueryUrl("pre_orders/", query),
			providesTags: ["preOrders"],
		}),
	}),
});

export const { useCreateOrderMutation, useGetPreOrdersQuery } = OrderApi;
