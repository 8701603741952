import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAddCandidatureMutation } from "../../../utils/api/candidature/candidature.api";
import {
	IPoste,
	TCandidatureForm,
} from "../../../utils/api/candidature/candidature.type";
import { cleannerError } from "../../../utils/utils";
import { alphaRegex, alphaSpaceRegex } from "../../../utils/constantes";

function useCandidatureForm(close: () => void, item?: IPoste) {
	const validationSchema = yup.object().shape({
		nom: yup
			.string()
			.required()
			.matches(alphaRegex, "Nom doit contenir que des lettres")
			.min(2, "Nom doit être au minimum 2 lettes")
			.label("Le nom"),
		prenom: yup
			.string()
			.required()
			.matches(
				alphaSpaceRegex,
				"Prénom doit contenir que des lettres"
			)
			.min(2, "Prénom doit être au minimum 2 lettes")
			.label("Le prénom"),
		email: yup.string().email().required().label("Email"),
		cv: yup.mixed().required().label("Le cv"),
		lettre_de_motivation: yup.mixed().label("La lettre de motivation"),
		telephone: yup.string().label("Téléphone"),
		motivation: yup.string().label("Le message").nullable(),
		date_disponibilite: yup
			.string()
			.required()
			.label("Date de disponibilté"),
	});

	const [sendCandidature, { isLoading, isError, isSuccess, error }] =
		useAddCandidatureMutation();
	const [motivation, setMotivation] = useState<string>("");

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setError,
		setValue,
	} = useForm<TCandidatureForm>({
		resolver: yupResolver(validationSchema),
	});

	const [cv, setCv] = useState<File>();

	const handleChangeCv = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files !== null) {
			setValue("cv", e.currentTarget.files[0]);
			setCv(e.currentTarget.files[0]);
		}
	};
	const handleChangeLettre = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files !== null) {
			setValue("lettre_de_motivation", e.currentTarget.files[0]);
			setCv(e.currentTarget.files[0]);
		}
	};

	const handleChangeMotivation = (value: string) => {
		setValue("motivation", value);
		setMotivation(value);
	};

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Candidature envoyée avec succès!",
				iconColor: "#08bb90",
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				close();
				setMotivation("");
			});
		}
		const err = error as any;

		if (isError) {
			if (
				err?.data?.email?.includes(
					"candidature with this email already exists."
				)
			) {
				Swal.fire({
					icon: "error",
					title: "Candidature avec cet email existe déjà.",
					showConfirmButton: false,
					timer: 2000,
				});
			} else if (
				err?.data?.cv?.includes("No file was submitted.")
			) {
				Swal.fire({
					icon: "error",
					title: "Le CV est obligatoire",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: `Une erreur de statut ${
								err?.status || "inconnue"
						  } est survenue`,
					showConfirmButton: false,
					timer: 3000,
				});
			}
		}
	}, [isLoading]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onsubmit = async (data: TCandidatureForm) => {
		if (item) {
			data["poste"] = item?.id;
		}

		const nom = data.nom;
		const prenom = data.prenom;
		const regex = /^[a-zA-Z ]{2,}$/;

		if (!regex.test(nom)) {
			return setError("nom", {
				message: "Nom doit être au minimum 2 lettes et ne doit contenir que des lettres",
			});
		}
		if (!regex.test(prenom)) {
			return setError("prenom", {
				message: "Prénom doit être au minimum 2 lettres et ne doit contenir que des lettres",
			});
		}
		console.log({ data });

		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const val = data[key];
			fd.append(key, val);
		}

		await sendCandidature(fd);
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		handleChangeCv,
		handleChangeLettre,
		cv,
		motivation,
		handleChangeMotivation,
	};
}

export default useCandidatureForm;
