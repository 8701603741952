import React, { useEffect } from "react";
import { useGetSocialMediaQuery } from "../../../../utils/api/config/config.api";
import { BtnSubmit } from "../../../common/Button";
import { AdminInput, FormError, RequiredStar } from "../../../common/Input";
import useSocialMediaForm from "./hooks/useSocialMediaForm";
import GoogleInput from "../../../common/GoogleInput";
import PhoneInput from "react-phone-input-2";

const SocialMedia = () => {
	const { data } = useGetSocialMediaQuery();
	const { register, onSubmit, errors, isLoading, setValue, phone,
		onChangePhone,
		setPhone,
		handleChangeAdress, } = useSocialMediaForm();
	useEffect(() => {
		if (data) {
			setValue("slug", data?.slug);
			setValue("facebook", data?.facebook);
			setValue("linkedin", data?.linkedin);
			setValue("instagram", data?.instagram);
			setValue("twitter", data?.twitter);
			setValue("adresse", data?.adresse);
			setValue("telephone", data?.telephone);
			setValue("email", data?.email);
			setPhone(data?.telephone);
		}
	}, [data]);
	return (
		<div className="tab-content-admin px-3 py-3">
			<form onSubmit={onSubmit}>
				<div className="mt-3 row">
					<div className="col-md-6 mb-3">
						<AdminInput
							label="Facebook"
							id="facebook"
							type="text"
							placeholder="Lien"
							{...register("facebook")}
							error={errors?.facebook}
							inputMode="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<AdminInput
							label="Instagram"
							id="instagram"
							type="text"
							placeholder="Lien"
							{...register("instagram")}
							error={errors?.instagram}
							inputMode="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<AdminInput
							label="Linkedin"
							id="linkedin"
							type="text"
							placeholder="Lien"
							{...register("linkedin")}
							error={errors?.linkedin}
							inputMode="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<AdminInput
							label="Twitter"
							id="twitter"
							type="text"
							placeholder="Lien"
							{...register("twitter")}
							error={errors?.twitter}
							inputMode="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<AdminInput
							label="Email"
							required
							id="email"
							type="text"
							placeholder="Email"
							{...register(
								"email"
							)}
							error={
								errors?.email
							}
							inputMode="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<div className="mb-3">
							<label
								htmlFor="telephone"
								className="form-label form-label-modal-custom"
							>
								Numéro de
								téléphone
								<RequiredStar />
							</label>
							<PhoneInput
								defaultMask={
									".. ... .. .."
								}
								containerClass="form-control auth-form-control mb-3 p-0"
								inputClass="form-control form-control-modal-custom"
								country={"sn"}
								placeholder=""
								inputProps={{
									name: "telephone",
									required: true,
								}}
								prefix="+"
								value={phone}
								onChange={(
									phone
								) =>
									onChangePhone(
										phone
									)
								}
								inputStyle={{
									width: "100%",
								}}
							/>
							<FormError
								error={
									errors
										?.telephone
										?.message
								}
							/>
						</div>
					</div>
					<div className="col-md-6 mb-3">
						<label
							htmlFor="adress"
							className="form-label form-label-modal-custom"
						>
							Adresse
							<RequiredStar />
						</label>
						<GoogleInput
							value={
								data?.adresse ??
								""
							}
							onChange={
								handleChangeAdress
							}
							className="form-control form-control-modal-custom"
						/>
						<FormError
							error={
								errors?.adresse
							}
						/>
					</div>
				</div>
				<div className="px-2 flex-r col-md-4 offset-md-8 gap-3 py-3">
					<BtnSubmit
						label="Modifier"
						isLoading={isLoading}
					/>
				</div>
			</form>
		</div>
	);
};

export default SocialMedia;
