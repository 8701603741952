import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Table.css";
import { usePagination, useSearch } from "../../../../../utils/hooks";
import { CustomPagination } from "../../../../common/CustomPagination";
import { formattedDate, isShowPagination } from "../../../../../utils/utils";
import { ButtonViewLink } from "../../../../common/Button";
import TableSkeleton from "../../../../common/TableSkeleton";
import { useGetPreOrdersQuery } from "../../../../../utils/api/order/order.api";
import { IOrder } from "../../../../../utils/api/order/order.type";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import { InputFilter } from "../../../../common/Input";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
registerLocale("fr", fr);

function ListePrecommandesTable() {
	const [date, setDate] = useState<Date | null>(null);
	const { search: nom, handlefilter } = useSearch();
	const { search: prenom, handlefilter: handleFilterByPrenom } = useSearch();
	const { search: email, handlefilter: handleFilterByEmail } = useSearch();
	const { search: telephone, handlefilter: handleFilterByPhone } = useSearch();

	const { page, setPage, perPage, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetPreOrdersQuery({
		page,
		limit: perPage,
		nom,
		prenom,
		email,
		telephone,
		date: date !== null ? moment(date).format("YYYY-MM-DD") : null,
	});
	const actionFormatter = (cell: string, row: IOrder) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonViewLink to={`/admin/precommandes/${row?.slug}`} state={row} />
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: "prenom",
			text: "Prénom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="prenom" placeholder="Prénom" onChange={handleFilterByPrenom} />,
		},
		{
			dataField: "nom",
			text: "Nom",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="nom" placeholder="Nom" onChange={handlefilter} />,
		},
		{
			dataField: "email",
			text: "Email",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="email" placeholder="Email" onChange={handleFilterByEmail} />,
		},
		{
			dataField: "telephone",
			text: "Téléphone",
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="tel" placeholder="Téléphone" onChange={handleFilterByPhone} />,
		},
		{
			dataField: "created_at",
			text: "Date d'inscription",
			formatter: (cell: string) => formattedDate(cell),
			filter: customFilter(),
			filterRenderer: () => (
				<div className="date-picker-container">
					<DatePicker
						className="form-control tab-dash-admin-filter-input"
						selected={date}
						onChange={(date: Date) => setDate(date)}
						maxDate={new Date()}
						dateFormat="dd/MM/yyyy"
						placeholderText="Choisir une date"
						isClearable
						locale="fr"
					/>
				</div>
			),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IOrder) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, perPage) => {
								setPerPage(perPage);
								setPage(page);
							}}
							perPage={perPage}
						/>
					)}
				</>
			)}
		</>
	);
}

export default ListePrecommandesTable;
