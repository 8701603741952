import React from "react";
import { BtnSubmit } from "../../../../common/Button";
import { FormError, RequiredStar } from "../../../../common/Input";
import useEchangeForm from "../hooks/useEchangeForm";

const FormEchange = () => {
	const { register, errors, onSubmit, isLoading, handleChecked, data } =
		useEchangeForm();

	return (
		<form onSubmit={onSubmit}>
			<div className="gain-level_list">
				<div className="gain-level_item">
					<div className="input">
						<input
							type="radio"
							id="eleve_is_active1"
							className="form-check-input gain-form-check-input"
							onChange={handleChecked}
							defaultChecked={data?.eleve_is_active}
							name="echange"
						/>
						<label
							htmlFor="eleve_is_active1"
							className="ms-2 gain-form_label"
						>
							Elevé
						</label>
					</div>
					<div className="col-12">
						<div className="item-c gap-2">
							<label
								htmlFor="nb_points_e1"
								className="gain-form_label"
							>
								Equivalent en fcfa
								<RequiredStar />:
							</label>
							<input
								className="form-control form-control-modal-custom"
								id="nb_points_e1"
								type="text"
								placeholder="Equivalent en fcfa"
								{...register(
									"montant_gain_eleve"
								)}
							/>
						</div>
						<FormError
							error={errors.montant_gain_eleve}
						/>
					</div>
				</div>
				<div className="gain-level_item">
					<div className="input">
						<input
							type="radio"
							id="moyen_is_active1"
							className="form-check-input gain-form-check-input"
							onChange={handleChecked}
							defaultChecked={data?.moyen_is_active}
							name="echange"
						/>
						<label
							htmlFor="moyen_is_active1"
							className="ms-2 gain-form_label"
						>
							Moyen
						</label>
					</div>
					<div className="col-12">
						<div className="item-c gap-2">
							<label
								htmlFor="nb_points_e2"
								className="gain-form_label"
							>
								Equivalent en fcfa
								<RequiredStar />:
							</label>
							<input
								className="form-control form-control-modal-custom"
								id="nb_points_e2"
								type="text"
								placeholder="Equivalent en fcfa"
								required
								{...register(
									"montant_gain_moyen"
								)}
							/>
						</div>
						<FormError
							error={errors.montant_gain_moyen}
						/>
					</div>
				</div>
				<div className="gain-level_item">
					<div className="input">
						<input
							type="radio"
							id="faible_is_active1"
							className="form-check-input gain-form-check-input"
							onChange={handleChecked}
							defaultChecked={
								data?.faible_is_active
							}
							name="echange"
						/>
						<label
							htmlFor="faible_is_active1"
							className="ms-2 gain-form_label"
						>
							Faible
						</label>
					</div>
					<div className="col-12">
						<div className="item-c gap-2">
							<label
								htmlFor="nb_points_e3"
								className="gain-form_label"
							>
								Equivalent en fcfa
								<RequiredStar />:
							</label>
							<input
								className="form-control form-control-modal-custom"
								id="nb_points_e3"
								type="text"
								placeholder="Equivalent en fcfa"
								{...register(
									"montant_gain_faible"
								)}
							/>
							<FormError
								error={
									errors.montant_gain_faible
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="flex-r">
				<BtnSubmit
					isLoading={isLoading}
					label="Enregistrer"
					style={{
						width: "inherit",
					}}
				/>
			</div>
		</form>
	);
};

export default FormEchange;
