import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoMdAttach } from "react-icons/io";
import PieceJointeModal from "../../../modal/admin/PieceJointeModal";
import { useItem, usePagination, useSearch } from "../../../../utils/hooks";
import { useGetDossierQuery } from "../../../../utils/api/dossier/dossier.api";
import { IDossier } from "../../../../utils/api/dossier/dossier.type";
import TableSkeleton from "../../../common/TableSkeleton";
import { formattedDate, getName, isShowPagination } from "../../../../utils/utils";
import { CustomPagination } from "../../../common/CustomPagination";
import ChangeStatusDossierModal from "./ChangeStatusDossierModal";
import { ButtonViewLink } from "../../../common/Button";
import { Link } from "react-router-dom";
import moment from "moment";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { InputFilter } from "../../../common/Input";
registerLocale("fr", fr);

function ListeDossiersTable() {
	const [status, setStatus] = useState<string>();

	const [date, setDate] = useState<Date | undefined>(undefined);
	const { search: patient, handlefilter } = useSearch();
	const { search: medecin, handlefilter: handlefilterByPro } = useSearch();
	const { search: matricule, handlefilter: handleFilterByMatricule } = useSearch();
	const handlefilterStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setStatus(e.currentTarget.value);
	};

	const { page, setPage, limit, setPerPage } = usePagination(10);
	const { data = { results: [] }, isLoading } = useGetDossierQuery({
		page,
		limit,
		patient,
		medecin,
		matricule,
		statut: status,
		date: date ? moment(date).format("YYYY-MM-DD") : undefined,
	});

	console.log(data);

	const { item, getItem } = useItem<IDossier>();

	const actionFormatter = (cell: string, row: IDossier) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<ButtonViewLink to={row?.slug} state={row} />
					<div className="container-btn-action-icon-modal">
						<button
							className="btn btn-action-modal-icon with-tooltip"
							data-tooltip-content="Voir"
							data-bs-toggle="modal"
							data-bs-target="#PieceJointeModal"
							onClick={() => getItem(row)}
						>
							<IoMdAttach />
						</button>
					</div>
				</div>
			</>
		);
	};
	const statutFormatter = (cell, row) => {
		return (
			<div className="table-actions-btn-container-img d-flex align-items-center">
				<span
					className={`statut-dossier-item text-capitalize ${
						cell === "en_pause" ? "stat_in_progress" : cell === "nouveau" ? "stat_new" : "stat_valide"
					}`}
					role="button"
					onClick={() => getItem(row)}
					data-bs-toggle="modal"
					data-bs-target="#ChangeStatusDossierModal"
				>
					{cell === "en_pause" ? "En pause" : cell === "cloturer" ? "Clôturé" : cell}
				</span>
			</div>
		);
	};
	const matriculeFormatter = (cell: string, row: IDossier) => {
		return (
			<Link to={row?.slug} state={row} className="redirect-link">
				{cell}
			</Link>
		);
	};

	const columns = [
		{
			dataField: "rdv.patient.nom",
			text: "Patient",
			formatter: (cell: string, row: IDossier) => getName(row.rdv.patient),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="patient" placeholder="Patient" onChange={handlefilter} />,
		},
		{
			dataField: "numero",
			text: "Matricule",
			formatter: (cell: string, row: IDossier) => matriculeFormatter(cell, row),
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="matricule" placeholder="Matricule" onChange={handleFilterByMatricule} />,
		},
		{
			dataField: "rdv.professionnel.nom",
			text: "Professionnel",
			formatter: (cell: string, row: IDossier) => `Dr. ${getName(row.rdv.professionnel)}`,
			filter: customFilter(),
			filterRenderer: () => <InputFilter id="professionnel" placeholder="Professionnel" onChange={handlefilterByPro} />,
		},
		{
			dataField: "created_at",
			text: "Date",
			formatter: (cell: string) => formattedDate(cell),
			filter: customFilter(),
			filterRenderer: () => (
				<div className="date-picker-container">
					<DatePicker
						className="form-control tab-dash-admin-filter-input"
						selected={date}
						onChange={(date: Date) => setDate(date)}
						maxDate={new Date()}
						dateFormat="dd/MM/yyyy"
						placeholderText="Choisir une date"
						isClearable
						locale="fr"
					/>
				</div>
			),
		},
		{
			dataField: "statut",
			text: "Statut",
			formatter: (cell: string, row: IDossier) => statutFormatter(cell, row),
			filter: customFilter(),
			filterRenderer: () => (
				<select className="form-control tab-dash-admin-filter-input" id="status" onChange={handlefilterStatus}>
					<option value="">Statut</option>
					<option value="nouveau">Nouveau</option>
					<option value="en_pause">En pause</option>
					<option value="cloturer">Clôturé</option>
				</select>
			),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IDossier) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
						filter={filterFactory()}
						filterPosition="inline"
					/>
					{isShowPagination(data?.count || 0) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, limit) => {
								setPerPage(limit);
								setPage(page);
							}}
							perPage={limit}
						/>
					)}
				</>
			)}
			<div className="modal fade" id="PieceJointeModal" aria-labelledby="ListeDossiersModalLabel" aria-hidden="true">
				<PieceJointeModal item={item} />
			</div>
			<ChangeStatusDossierModal item={item} />
		</>
	);
}

export default ListeDossiersTable;
